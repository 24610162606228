import { Injectable, NgZone } from "@angular/core";
import { Constants } from "../../constants.enum";
import { StorageService as Storage, StorageService } from "./../../services/storage.service";
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpEventType, HttpEvent, } from "@angular/common/http";
import { ApiService } from "src/app/services/api.service";
import { Observable, Subject, throwError } from "rxjs";
import { AppService } from "src/app/app.service";
import { environment as ENV } from './../../../environments/environment';
import { Platform } from "@ionic/angular";
import * as imageCompression from 'browser-image-compression';
import { MEDIA_OBJECT, COMPRESS_OPTIONS } from './media-interfaces';
import { filter, first } from "rxjs/operators";

export const DummyImage = ('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
@Injectable({
    providedIn: "root",
})
export class MediaApiService {
    isAdmin: any = false;
    whitePicture = DummyImage;
    hashImages = [];
    defaultDeviceImage = 'assets/device_markers/paj_iconset_logo.svg';
    SHARE_LINK = ENV.mediaServer + ENV.API_VERSION + 'shared/_media/';
    constructor(private appService: AppService, private storage: Storage,
        private http: HttpClient, private platform: Platform, private zone: NgZone) {
        this.storage.get(Constants.IS_ADMIN).then(r => this.isAdmin = r);
    }

    get(collectionName) {
        let user = this.appService.user;
        return new Promise ( (resolve, reject) => {
            const getMedia = (user) => {
                let url = ENV.mediaServer + ENV.API_VERSION + 'media/collections/' + collectionName + '/' + user.id;
                return this.http.get(url).subscribe( r => {
                    resolve(r);
                }, error => {
                    console.log("ERROR: ", error);
                    reject(error);
                });
            };

            if (!user) this.appService.user$.pipe(first()).subscribe( u => getMedia(u) );
            else  getMedia(user);
        });
    }

    //_ @File
    //_ Return a File
    async compressFile(file: any, compressOptions: COMPRESS_OPTIONS = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true }) {
        return new Promise((resolve, reject) => {
            this.platform.ready().then(() => {
                imageCompression.default(file, compressOptions).then(img => {
                    resolve(img);
                }).catch(e => reject(e));
            });
        });
    }

    //_ Upload without compression
    upload(file: any, collection, useFileName = false, fileName = ''): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        let url = ENV.mediaServer + ENV.API_VERSION + 'media/collections' + '/' + this.appService.user.id;

        formData.append('images[]', file, useFileName ? fileName : null);
        formData.append('collection', collection);
        formData.append('useFileName', JSON.stringify(useFileName));

        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }

    delete(ids) {
        let url = ENV.mediaServer + ENV.API_VERSION + 'media/delete/' + this.appService.user.id;
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, { ids }).subscribe(r => {
                resolve(r);
            }, error => {
                console.log("ERROR: ", error);
                reject(error);
            });
        })
    }

    //_ @Return a MEDIA_OBJECT
    createImageObject(collection_name, file_name) {
        const isShared = this.appService.isSharedView;
        const userId = this.appService.user ? this.appService.user.id : -1;
        return {
            model_id: isShared ? -1 : userId,
            collection_name,
            file_name
        }
    }

    getImageData(image: MEDIA_OBJECT, showWhitePicture = true) {
        const mediaPath = ENV.mediaServer + ENV.API_VERSION + '_media';
        let url = mediaPath + '/' + image.model_id + '/' + image.collection_name + '/' + image.file_name;

        if (image.model_id == -1)
            url = ENV.mediaServer + ENV.API_VERSION + 'shared/_media/' + image.file_name;

        return this.checkIfExist(url, showWhitePicture);
    }

    checkIfExist(url, showWhitePicture = true) {
        return new Observable(observer => {
            if (showWhitePicture)
                observer.next(this.whitePicture);
            this.zone.run(() => {
                // console.log('[DEBUG] checkIfExists', { exists: this.hashCheck(url) });
                if (this.hashCheck(url)) //_ Check if image is already loaded
                    return observer.next(this.hashImages[url]);

                //_ If not is loaded, so request the image
                const requestImage = (attemptRequest = 1) => {
                  // if (attemptRequest > 1)
                  //   console.log('[DEBUG] Found after ' + attemptRequest + ' attempt.');
                  this.http.get(url, { responseType: "blob" }).subscribe(blob => {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                          this.hashImages[url] = reader.result; //_ Save in hash array to serve leter
                          observer.next(reader.result); // emit the base64 string result
                          return;
                      }

                      reader.readAsDataURL(blob); // convert blob to base64
                  }, error => {
                      // console.log('[DEBUG] Requesting image', { attemptRequest });
                      if (attemptRequest < 3 && error?.status === 500) {
                        requestImage(attemptRequest+1);
                      } else {
                        console.log('[DEBUG] ERROR checkIfExists', { error });
                        //_ Return default image paj icon
                        observer.next(this.defaultDeviceImage);
                        return;
                      }
                      // let breakPicture = "assets/images/broken-picture-dark.svg" // for light
                      // if ( this.appService.darkMode )
                      // breakPicture =  "assets/images/broken-picture.svg" // for dark
                      // observer.next(breakPicture);
                  });
                }

                requestImage();
                return { unsubscribe() { } };
            });
        });
    }

    deleteByName(fileName) {
        let url = ENV.mediaServer + ENV.API_VERSION + 'media/delete' + '/' + this.appService.user.id + '/' + fileName;
        return new Promise<any>((resolve, reject) => {
            this.http.delete(url).subscribe((r: any) => {
                if (r.success) {
                    const mediaPath = ENV.mediaServer + ENV.API_VERSION + '_media';
                    const url = mediaPath + '/' + this.appService.user.id + '/' + fileName.split('---')[0] + '/' + fileName;
                    this.hashImages[url] = null;
                    resolve(r);
                }
                reject(r);
            }, error => {
                console.log("ERROR: ", error);
                reject(error);
            });
        })
    }

    hashCheck(name) {
        if (this.hashImages[name])
            return this.hashImages[name];
        return false;
    }

    //_ IMAGE DATA AND FILES UTILS
    //_ **************************
    getBase64(image) {
        if (image instanceof String)
            return this.getBase64FromSrc(image);
        else
            return this.getBase64FromFile(image);
    }

    getBase64FromSrc(source) {
        return new Promise((resolve, reject) => {
            let canvas: any = document.createElement('canvas');
            let img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                canvas.ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL());
            };
            img.src = source;
        });
    }

    getBase64FromFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => { resolve(reader.result) };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    base64ToFile(dataurl, filename) {
        return this.zone.run(() => {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            const blob: any = new Blob([u8arr], { type: mime });
            blob.lastModifiedDate = new Date();
            blob.filename = filename;
            blob.name = filename;
            return blob;
            // return new File([u8arr], filename, {type:mime});
        })
    }

}
