<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "imageEditTab.imageEditTool" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content mode="ios">
  <!-- Loading to show when is compressing images-->
  <div class="ion-text-center ion-padding" *ngIf="loading">
    <ion-spinner color="primary"></ion-spinner>
  </div>

  <!-- LEFT BUTTONS -> Options buttons -->
  <div class="left-buttons">
    <ion-button
      [color]="isFramming ? 'primary' : 'light'"
      shape="round"
      size="small"
      [class.disable]="isCropping"
      title="{{ 'imageEditTab.frame' | translate }}"
      (click)="enableFrame()"
    >
      <ion-icon
        src="assets/images/media-gallery/frame.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ "imageEditTab.frame" | translate }}</ion-label>
    </ion-button>
    <ion-button
      [color]="isCropping ? 'primary' : 'light'"
      shape="round"
      size="small"
      (click)="enableCrop()"
      title="{{ 'imageEditTab.crop' | translate }}"
      [class.disable]="isFramming"
    >
      <ion-icon
        src="assets/images/media-gallery/crop-tool.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ "imageEditTab.crop" | translate }}</ion-label>
    </ion-button>

    <ion-button
      [color]="cropCircle ? 'primary' : 'light'"
      shape="round"
      size="small"
      class="sub-menu"
      *ngIf="isCropping"
      (click)="cropCircle = !cropCircle"
      [@slideDown]="'slideDown'"
      title="Crop as circular shape"
    >
      <ion-icon src="assets/images/media-gallery/circle.svg"></ion-icon>
    </ion-button>

    <ion-button
      color="light"
      shape="round"
      size="small"
      class="sub-menu"
      title="Rotate image"
      *ngIf="isCropping"
      (click)="rotation = rotation + 45"
      [@slideDown]="'slideDown'"
    >
      <ion-icon src="assets/images/media-gallery/rotation.svg"></ion-icon>
    </ion-button>
  </div>

  <!-- DEFAULT IMAGE PREVIEW -->
  <ion-img
    [src]="selectedImage?.image | async"
    class="sample-image"
    *ngIf="!isCropping && !isFramming && !loading"
  >
  </ion-img>

  <!-- IMAGE WITH FRAME -->
  <ion-img
    [src]="sampleImage"
    class="sample-image with-padding"
    *ngIf="isFramming"
    [@fadeIn]="'fadeIn'"
  >
  </ion-img>

  <!-- CROPPER TOOL -->
  <image-cropper
    *ngIf="isCropping"
    [@fadeIn]="'fadeIn'"
    [imageBase64]="sampleImage"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    format="png"
    (imageCropped)="imageCropped($event)"
    [roundCropper]="cropCircle"
    backgroundColor="#ffffff"
    [canvasRotation]="rotation"
    [containWithinAspectRatio]="false"
    class="edit-tool-cropper"
    output="base64"
  >
  </image-cropper>

  <!-- BOTTOM BUTTONS SAVE AND CROP-->
  <ion-fab slot="fixed" class="upload-button">
    <ion-button
      shape="round"
      color="primary"
      *ngIf="!isCropping && !isFramming"
      (click)="upload()"
    >
      <ion-icon
        src="assets/images/map/popup/upload.svg"
        slot="start"
        *ngIf="showAcceptIcon"
      ></ion-icon>
      <ion-label>{{ acceptText }}</ion-label>
    </ion-button>

    <ion-button
      shape="round"
      color="light"
      *ngIf="isCropping || isFramming"
      (click)="cancel()"
      class="small-btn no-shadow"
    >
      <ion-label>{{ "imageEditTab.cancel" | translate }}</ion-label>
    </ion-button>
    <ion-button
      shape="round"
      color="primary"
      *ngIf="isCropping || isFramming"
      (click)="applyChange()"
      class="small-btn"
    >
      <ion-icon name="save-outline" slot="start"></ion-icon>
      <ion-label>{{ "imageEditTab.accept" | translate }}</ion-label>
    </ion-button>
  </ion-fab>
</ion-content>

<!-- IMAGES LIST -->
<ion-footer *ngIf="imagesData?.length > 1">
  <ion-row class="images-footer">
    <ion-item
      lines="none"
      class="ion-no-padding uploading-item"
      button
      mode="md"
      *ngFor="let f of imagesData"
      (click)="selectImage(f)"
      [class.disable]="(isCropping || isFramming) && f.id != selectedImage?.id"
    >
      <ion-checkbox
        color="primary"
        [checked]="selectedImage?.id == f.id"
        [hidden]="selectedImage?.id != f.id"
      >
      </ion-checkbox>
      <ion-img [src]="f.image | async"> </ion-img>
    </ion-item>
  </ion-row>
</ion-footer>
