import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PajUserProfileImageModule } from 'src/app/shared/modal/user-img/paj-user-profile-image.module';
import { SideBarComponent } from './side-bar.component';

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      TranslateModule,
      PajUserProfileImageModule
    ],

    declarations: [SideBarComponent],
    exports: [SideBarComponent, PajUserProfileImageModule]
  })

  
  export class PajSidebarModule {}