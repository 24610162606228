<!-- <ng-container *ngIf="isSmallScreen? mobileList: desktopList "></ng-container> -->
<dashboard-sidebar
  *ngIf="!isSmallScreen"
  (onMenuChange)="devicesDashboardMenuChange($event)"
>
</dashboard-sidebar>

<app-devices-sidebar
  *ngIf="!isSmallScreen"
  (onMenuChange)="devicesMenuChange($event)"
  [isAnimatingMenu]="animatingDeviceMenu"
>
</app-devices-sidebar>

<!-- DEVICES ACTIVITY FOR MOBILES -->
<div
  class="mobile-devices-activity paj-animations"
  *ngIf="isSmallScreen && devicesActivityService.shouldLoadMobileView|async"
  [hidden]="!(devicesActivityService.openInMobiles|async)"
  [class.slide-up]="(devicesActivityService.openInMobiles|async)"
>
  <app-devices-dashboard (onMenuChange)="devicesDashboardMenuChange($event)">
  </app-devices-dashboard>
</div>

<!-- showDeviceMenuInfo -->
<app-new-pullup
  *ngIf="isSmallScreen && deviceMenuOpen"
  [openPullup]="deviceMenuOpen"
  (onViewInit)="pullupNavigateToDevice($event)"
  #pullup
>
</app-new-pullup>

<ion-header
  *ngIf="isSmallScreen"
  class="header map-page-header transparent-click"
  [class.no-animate-pull-down]="mainMenuOpen"
>
  <ion-toolbar class="map-page-toolbar transparent-click">
    <ion-buttons slot="end">
      <ion-fab-button
        title="{{'Logout'|translate}}"
        *ngIf="isGhost===true"
        (click)="logoutUser()"
        color="dark !important"
        shape="round"
        size="small"
        fill="solid"
        style="margin: 6px 6px"
        [class.left-sidebar-open]="mainMenuOpen"
        class="click-events"
      >
        <ion-icon name="log-out"></ion-icon>
      </ion-fab-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-header
  *ngIf="!isSmallScreen"
  [class.device-menu-open-content]="deviceMenuOpen || devicesDashboardIsOpen"
  class="header map-page-header transparent-click ion-hide-md-down"
  [class.no-animate-pull-down]="mainMenuOpen"
>
  <ion-toolbar class="map-page-toolbar transparent-click">
    <ion-buttons slot="start">
      <ion-menu-toggle
        (click)="toggle('mainMenu')"
        autoHide="false"
        menu="mainMenu"
      >
        <!--  PLEASE DO NOT DELETE THE ID "mainMenu", IT IS USING BY MAP TOUR -->
        <ion-fab-button
          title="{{ 'map.menu' | translate }}"
          size="small"
          shape="round"
          fill="solid"
          class="fab-menu-button click-events mainMenuButton"
          [color]="mainMenuOpen?'primary': 'dark'"
          id="mainMenu"
        >
          <ion-icon
            [name]="mainMenuOpen? 'close':'menu'"
            class="removeIconTitle"
          ></ion-icon>
        </ion-fab-button>
      </ion-menu-toggle>
    </ion-buttons>

    <!-- <ion-title class="header-logo">
      <img
        class="header-logo"
        [ngStyle]="{ 'margin-right': authService.isUserLogin? '65px' : 'none' }"
        (click)="reloadPage()"
        src="assets/default_brand/img/logo_main.png"/>
    </ion-title> -->

    <ion-buttons class="ion-hide-md-down" [hidden]="mainMenuOpen">
      <ion-button
        title="{{'reloadApp.reload'|translate}}"
        class="header-logo-button click-events"
        color="dark !important"
        shape="round"
        size="small"
        fill="solid"
        style="
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          margin-left: 28px;
        "
        (click)="reloadPage()"
      >
        <img
          class="header-logo-button-img"
          src="assets/default_brand/img/logo_main.png"
        />
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-fab-button
        title="{{'Logout'|translate}}"
        *ngIf="isGhost===true"
        (click)="logoutUser()"
        color="dark !important"
        shape="round"
        size="small"
        fill="solid"
        style="margin: 6px 6px"
        [class.left-sidebar-open]="mainMenuOpen"
        class="click-events"
      >
        <ion-icon name="log-out"></ion-icon>
      </ion-fab-button>

      <ion-menu-toggle
        menu="devicesDashboard"
        autoHide="false"
        (click)="showDeviceDashboard()"
        id="notificationButton"
        class="notificationButton"
      >
        <ion-fab-button
          title="{{'notifications.notificationsText'|translate}}"
          color="dark !important"
          shape="round"
          size="small"
          fill="solid"
          style="margin: 6px 6px"
          class="notifications-icon click-events"
          [color]="devicesDashboardIsOpen ? 'primary' : 'dark'"
        >
          <ion-icon
            name="mail-outline"
            *ngIf="!devicesDashboardIsOpen"
          ></ion-icon>
          <ion-icon
            *ngIf="devicesDashboardIsOpen"
            name="close"
            style="color: rgb(255, 255, 255); image-rendering: crisp-edges"
          ></ion-icon>

          <!-- <div *ngIf="nCount > 0 && !devicesDashboardIsOpen" >
            <ion-badge color="danger" class="notification-badge" mode="ios"
              >{{nCount > 99 ? '99+':nCount}}</ion-badge
            >
          </div> -->
        </ion-fab-button>
        <div *ngIf="nCount > 0 && !devicesDashboardIsOpen">
          <ion-badge color="danger" class="notification-badge" mode="ios"
            >{{nCount > 99 ? '99+':nCount}}</ion-badge
          >
        </div>
      </ion-menu-toggle>

      <!-- <div id="notificationButton">
        <ion-fab-button
          title="{{'notifications.notificationsText'|translate}}"
          color="dark !important"
          shape="round"
          size="small"
          fill="solid"
          style="margin: 6px 6px"
          (click)="appService.user?.isadmin == 1 ? showDeviceDashboard() : showNotifications($event);"
          class="notifications-icon click-events"
        >
          <ion-icon name="notifications-outline"></ion-icon>

          <div *ngIf="nCount > 0">
            <ion-badge color="danger" class="notification-badge" mode="md"
              >{{nCount > 10 ? '9+':nCount}}</ion-badge
            >
          </div>
        </ion-fab-button>
      </div> -->

      <!-- <ion-fab-button (click)="showDeviceDashboard()"
        class="click-events" color="dark" size="small"
        fill="solid" title="Last Activity"
        *ngIf="appService.user?.isadmin == 1"
      >
        <ion-icon src="assets/images/map/device-dashboard/list-icon.svg"></ion-icon>
      </ion-fab-button> -->

      <ion-menu-toggle
        menu="deviceMenu"
        autoHide="false"
        (click)="menu.enable(true, 'deviceMenu'); togglePullUp()"
        class="devicesMenuButton"
      >
        <ion-fab-button
          title="{{'map.deviceList'|translate}}"
          shape="round"
          fill="solid"
          size="small"
          style="margin: 6px 6px"
          [color]="deviceMenuOpen?'primary':'dark'"
          class="click-events"
        >
          <ion-icon
            [name]="deviceMenuOpen? 'close':'list-outline'"
            style="color: rgb(255, 255, 255); image-rendering: crisp-edges"
          ></ion-icon>
        </ion-fab-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="trackingDeactivatedMsg">
  <ion-item
    class="ion-no-margin"
    color="transparent"
    lines="none"
    *ngIf="disablesCount?.length>0"
  >
    <ion-buttons slot="end">
      <ion-button class="buttonColor" (click)="showNonTrackingDevices()">
        <ion-icon name="chevron-down" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label class="ion-no-margin ion-text-center ion-text-wrap">
      <h6 style="font-weight: 500">
        {{"nonTracking.trackingDeactivated"|translate}}
        ({{disablesCount.length}}) {{disablesCount.length == 1 ?
        ('nonTracking.device'|translate) : ('nonTracking.devices'|translate)}}
      </h6>
    </ion-label>
  </ion-item>
</div>
<ion-content scrollY="false" scrollX="false" slot="fixed" id="main-map-content">
  <!-- The map component -->

  <app-testaccount-message *ngIf="testUser" class="custom-message">
  </app-testaccount-message>
  <main-map
    #mainMap
    [height]="'100%'"
    [pitch]="0"
    (onClickFeature)="clickFeature($event)"
    (geoMouseDown)="geoMouseDown($event)"
    (geoMouseUp)="geoMouseUp($event)"
    (geoDragVertex)="geoDragVertex($event)"
    (geoVertexMouseUp)="geoMouseUpVertex($event)"
    (onClickMap)="mapClicked($event)"
    [timerTemplate]="CountDown"
    [sliderTemplate]="MapSlider"
    [hasTabBar]="true"
    [useMapbox]="useMapboxLib"
  ></main-map>

  <!-- SLIDER BAR TO SEE THE DATAPOINTS AS TIMELINE -->
  <ng-template #MapSlider>
    <button
      style="--bottom-postion: {{this.isSmallScreen ? '15px' : (showCountDown ? '55px' : '0') }}"
      [ngStyle]="showSlider ? { 'margin-bottom': '20px' } : {}"
      class="float-map-style-button"
      (click)="showMapStylePopup($event)"
    >
      <ng-container *ngFor="let style of mapStyles">
        <picture *ngIf="style.value == this.map?.mapStyle">
          <ion-thumbnail style="width: 35px; height: 35px">
            <img [src]="style.image" />
          </ion-thumbnail>
        </picture>
      </ng-container>
    </button>

    <app-slider
      @inOutSliderAnimation
      *ngIf="showSlider"
      (onClose)="hideSlider()"
      [sliderDevice]="sliderDevice"
      [map]="map"
      [dataPoints]="sliderDevice.dataPoints"
      (sliderChanged)="showPopup($event)"
      [maxSliderPoints]="maxSliderPoints"
      [loadingSnapRoute]="loading.snap"
      [pullupMenu]="pullup"
      (routePopUpEventEmitter)="handleMapEvent($event)"
      [snapToRoad]="appService.user.snap_to_road"
    >
    </app-slider>
  </ng-template>
</ion-content>

<ng-template #NEW_TIMER_COUNT_TEMPLATE let-s="s" let-m="m" let-h="h">
  <ng-container *ngIf="!petMode">
    <button
      class="circle-timer safe-area"
      (click)="refreshingMapData();"
      id="hover-trigger"
      #timerTooltip
      (mouseenter)="showTimerTooltip();"
      (mouseleave)="hideTimerTooltip();"
    >
      <svg
        viewBox="0 0 100 100"
        [ngStyle]="{ '--timer-progress': s, '--timer-max': 10 }"
      >
        <circle class="progress-background" cx="50" cy="50" r="45"></circle>
        <circle class="progress" cx="50" cy="50" r="45"></circle>
      </svg>
      <span>
        {{ s }} <br />
        {{ 'dateTime.secShort' | translate }}
      </span>
    </button>
    <div #tooltipBadge class="tooltipBadge">
      {{ 'dateTime.counterTooltipText' | translate }} {{s}} {{
      'dateTime.secShort' | translate }}
    </div>
  </ng-container>
</ng-template>

<div [class.fade-in-s]="!loading.route" [hidden]="!showCountDown">
  <app-timer-count
    #countdown
    [showHour]="false"
    [showMin]="false"
    [isSmall]="true"
    [timerTemplate]="NEW_TIMER_COUNT_TEMPLATE"
  >
  </app-timer-count>
</div>

<ng-template #CountDown>
  <!-- LOADING INDICATOR -->
  <!-- <div class="loadingDiv" *ngIf="loading.route" @inOutSliderAnimation>
    <ion-button expand="block" fill="clear" size="small">
      <ion-spinner slot="start" color="primary" name="bubbles"></ion-spinner>
      &nbsp;
      <ion-text color="dark" *ngIf="loading.snap">
        <p class="ion-no-margin">
          {{ 'map.slider.optimizingRoutes' | translate }}
        </p>
      </ion-text>
    </ion-button>
  </div> -->

  <div
    *ngIf="loading.route && !loading.snap"
    @inOutSliderAnimation
    class="loadingMainDiv"
  >
    <ion-button expand="block" fill="clear" size="small">
      <ion-spinner slot="start" color="primary" name="bubbles"></ion-spinner>
    </ion-button>
  </div>

  <div
    *ngIf="loading.snap && !showSlider"
    @inOutSliderAnimation
    class="loadingDiv"
  >
    <ion-button expand="block" fill="clear" size="small">
      <ion-spinner slot="start" color="primary" name="bubbles"></ion-spinner>
      &nbsp;
      <ion-text color="dark">
        <p class="ion-no-margin">
          {{ 'map.slider.optimizingRoutes' | translate }}
        </p>
      </ion-text>
    </ion-button>
  </div>

  <!-- <div
    *ngIf="loading.route"
    class="loadingDiv"
    [class.fade-in-s]="loading.route"
    [class.fade-out-s]="!loading.route"
  >
    <ion-spinner color="primary"></ion-spinner>
    <ion-label class="ion-text-center" *ngIf="loading.snap" color="dark"
      >{{ 'map.slider.optimizingRoutes' | translate }}
    </ion-label>
  </div> -->
</ng-template>
<!-- <map-submenu-action-buttons [map]="map" #mapMarkerMenu></map-submenu-action-buttons> -->

<ng-template #Content>
  <ion-grid fixed class="ion-no-padding">
    <ion-item color="transparent" lines="none">
      <ion-icon slot="start" name="warning"></ion-icon>
      <ion-label class="ion-text-wrap">
        {{ 'map.OpenTooMuchData' | translate}}
      </ion-label>
    </ion-item>

    <ion-item color="transparent" lines="none">
      <ion-button slot="end" color="light" (click)="closePopUp()">
        <ion-label>{{ 'dashboard.accept' | translate }}</ion-label>
      </ion-button>
    </ion-item>
  </ion-grid>
</ng-template>
