import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ModalController,
  LoadingController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { Constants } from './constants.enum';
import { StorageService as Storage } from './services/storage.service';
import { PwaUpdateService } from './services/pwa-update.service';
import { AppService } from './app.service';
import { environment } from './../environments/environment';
import { MapService } from './members/map/components/map/map.service';
import { ApiService } from './services/api.service';
import { NotificationsService } from './services/notifications.service';
import { STORAGE_SETTINGS } from './services/storage-settings';
import { ADMIN_URL } from './providers/base_factory';
import { KeyboardTrickService } from './services/keyboard-trick.service';
import {
  initializePhraseAppEditor,
  PhraseConfig,
} from 'ngx-translate-phraseapp';
import { Network } from '@capacitor/network';
//import { StatusBar } from "@capacitor/status-bar";
import { BadgeService } from './services/plugins/badge.service';
import { DeepLinkService } from './services/plugins/deep-link.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { FcmService } from './services/plugins/fcm.service';
import { ThreedeetouchService } from './services/plugins/threedeetouch.service';
import { MessagingService } from './services/messaging.service';
import { DevicesSidebarService } from './members/map/devices-sidebar/devices-sidebar.service';
import { AppupdateService } from './services/plugins/appupdate.service';
import { ApiErrorPopupComponent } from './components/api-error-popup/api-error-popup.component';
import { register } from 'swiper/element/bundle';
import { Navigation, EffectFade, Pagination, Zoom } from 'swiper/modules';
import Swiper from 'swiper';
import { PermissionsService } from './services/permissions.service';
import { Capacitor } from '@capacitor/core';

import { IonicSlides } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { animations } from './animations/animations';
import { StartupService } from './services/startup.service';
import { Title } from '@angular/platform-browser';
register();
Swiper.use([IonicSlides, Navigation, Pagination, EffectFade, Zoom]);
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: animations,
})
export class AppComponent implements OnInit, AfterViewInit {
  checkDeepLinkStatus = false;
  language: any;
  darkMode = null;
  currentRoute = '';
  currentParameters = '';
  autoplay: boolean = true;
  isPlaying: boolean = false;
  brand = environment.BRAND;
  mainMenuOpen = false;
  deviceMenuisOpen = false;
  currentWidth: number;

  @ViewChild('video', { read: ElementRef, static: false }) video: ElementRef;
  public networkStatus: boolean = null;
  guardLoading$ = new BehaviorSubject(false);

  constructor(
    private startupService: StartupService,
    private router: Router,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private storage: Storage,
    private mapService: MapService,
    public el: ElementRef,
    private zone: NgZone,
    private apiService: ApiService,
    private pwaService: PwaUpdateService,
    private appService: AppService,
    private notifService: NotificationsService,
    private deeplinkService: DeepLinkService,
    private keyboardService: KeyboardTrickService,
    private badgeService: BadgeService,
    private threedeetouchService: ThreedeetouchService,
    private fcmService: FcmService,
    private messagingService: MessagingService,
    private devicesSidebarService: DevicesSidebarService,
    private updateService: AppupdateService,
    private popoverCtrl: PopoverController,
    private permissions: PermissionsService,
    private titleService: Title,
  ) {
    this.currentRoute = window.location.pathname;
    this.currentParameters = window.location.search;
    this.initLogoutOnClose();
  }

  async ngOnInit() {
    this.currentWidth = this.platform.width();
    this.guardLoading$ = this.appService.guardLoading;

    await this.initStorage();
    this.initMenus();
    try {
      this.fcmService.getFcmToken().then((res) => (this.firebaseToken = res));
    } catch {}

    this.platform.ready().then(() => {
      if (environment.BRAND > 1) this.initBranding();
      //Cordova/Capacitor
      this.hideSplashScreen();
      this.initDeepLinks();
      //this.initAndroidiOSStatusbar();
      this.initNetworkStatus();
      this.initAdminSettings();
      this.keyboardService.init();
      this.initObservables();
      this.threedeetouchService.enable3dtouch();
      this.checkForAppUpdate();
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.currentWidth = this.platform.width();
  }

  ngAfterViewInit(): void {}

  checkForAppUpdate() {
    //_ Check update for mobiles
    setTimeout(() => this.updateService.checkForUpdate(), 10 * 1000);
    //_ Check update for browsers
    setTimeout(() => this.pwaService.initUpdateCheck(), 10 * 1000);
  }

  ionViewDidEnter() {
    document.addEventListener(
      'backbutton',
      function (e) {
        // Do nothing
      },
      true,
    );
  }

  async initStorage() {
    await this.storage.init();

    this.storage.storageReady.subscribe(async (r) => {
      console.error('storageReady')
      if (r) {
        const path = window.location.pathname;
        let currentUserId = -1;
        this.storage.get(Constants.USER_ID).then((r) => {
          currentUserId = r;
          //_ permissions from this service are only used in logbook pages so moved to logbook root page
          // if (r)
          //   this.permissions.getPermissions(r);
        });
        if (path.startsWith(ADMIN_URL) && window['isAdmin']) {
          const userId = path.split('/')[3];
          const lastUserId = await this.storage.get(
            Constants.LAST_USER_LOGGEDIN,
          );
          await this.storage.set(Constants.USER_ID, +userId);
          await this.storage.set(STORAGE_SETTINGS.ISGHOST, true);
          this.appService.ghostMode.set(true);
          this.devicesSidebarService.devicesFlatList.set(null);
          //this.devicesSidebarService.resetFilters();
          this.storage.remove(STORAGE_SETTINGS.DEVICES_SIDEBAR_FILTERS);

          if (lastUserId != userId)
            //_ Still need more check this to add more remove storage vars for this condition
            this.storage.remove(Constants.LASTPOINTS);

          this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);
          this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE);
          this.devicesSidebarService.filters.remove();
          this.storage.set(Constants.LAST_USER_LOGGEDIN, userId);
        } else {
          this.storage.set(Constants.LAST_USER_LOGGEDIN, currentUserId);
        }
        //_ Need Workaround when user back to previous page without admin route | is fixed refreshing the page manually
        //_ It could be fixed using guards to pretect routes !
      }
    });
  }

  initMenus() {
    this.appService.menuClosed.subscribe((status) => {
      this.mainMenuOpen = status;
    });
    this.appService.deviceMenuisOpen.subscribe((status) => {
      this.deviceMenuisOpen = status;
    });
  }

  initBranding() {
    this.titleService.setTitle(environment.PAGE_TITLE);
  }

  // async initAndroidiOSStatusbar(){
  //   if (this.platform.is("ios") || this.platform.is("android")){
  //     StatusBar.styleBlackTranslucent();
  //     StatusBar.backgroundColorByHexString("#222428"); //_ Default ion-color-dark
  //   }
  // }

  async initObservables() {
    this.notifService.notifications.subscribe(async (r: any) => {
      let notifications: any = await this.notifService.getNotificationsCount(
        {},
      );
      if (this.platform.is('cordova') || this.platform.is('capacitor'))
        this.badgeService.setBadge(notifications?.number_of_unread);
    });

    this.authenticationService.logoutStatus.subscribe((s) => {
      if (s == true) this.currentRoute = '';
    });

    // this.router.events.subscribe(event  =>  {
    //   if (event instanceof NavigationEnd){
    //   }
    // });

    if (gtag) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-WSE3ZMCEN4', {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }

    this.appService.onLoadCustomerDataError.subscribe((r) => {
      // console.error('DEBUG APP ERROR', r);
      this.openErrorPopup();
    });
  }

  async initAdminSettings() {
    //Admin settings:
    let adminSettings = await this.storage.get(Constants.ADMIN_SETTINGS);

    //In App Translations:
    if (adminSettings) {
      if (
        (await this.appService.isAdmin()) &&
        adminSettings.enablePhraseInContext
      ) {
        let config: PhraseConfig = {
          projectId: '42fb97acc0b0d54ffb2f724be27973b1',
          accountId: '9bce57528c6dd58ca4c55ca7bc8c4495',
          phraseEnabled: true,
          useOldICE: false,
          fullReparse: true,
          autoLowercase: false,
          debugMode: false,
        };
        initializePhraseAppEditor(config);
      }
    }
  }

  async initNetworkStatus() {
    //if (this.platform.is("cordova") || this.platform.is("capacitor")){
    const status = await Network.getStatus();
    this.networkStatus = status.connected;
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      this.zone.run(() => {
        this.networkStatus = status.connected;
      });
    });
    //}
  }

  /*
  async initializeApp() {
    await this.storage.init();
    //this.hideSplashScreen();
    this.storage.storageReady.subscribe(async (r) => {
      if (r) {
        const path = window.location.pathname;
        if (path.startsWith(ADMIN_URL) && window["isAdmin"]) {
          const userId = path.split("/")[3];
          await this.storage.set(Constants.USER_ID, +userId);
          await this.storage.set(STORAGE_SETTINGS.ISGHOST, true);
          this.appService.ghostMode.set(true);
        }
        //_ Need Workaround when user back to previous page without admin route | is fixed refreshing the page manually
        //_ It could be fixed using guards to pretect routes !
      }
    });

    this.notifService.notifications.subscribe((r: any) => {
      //await this.notifService.getUnreadNotifications().then(r => {
      let notifications: Array<any> =
        this.notifService.getUnreadNotifications();
        this.badgeService.setBadge(notifications?.length)
    });

    this.authenticationService.logoutStatus.subscribe((s) => {
      if (s == true) this.currentRoute = "";
    });

    this.platform.ready().then(
      async () => {
        setTimeout(() => this.pwaService.checkForUpdate(), 30 * 1000); //_ wait 30 seconds

        if (this.platform.is("hybrid")) {
          this.setup3dTouch();
          StatusBar.setBackgroundColor({ color: "#222428" }); //_ Default ion-color-dark
        }

        const favicon = document.createElement("link");
        favicon.type = "image/png";
        favicon.rel = "icon";
        favicon.href = "assets/default_brand/img/favicon.png";
        const title = document.createElement("title");
        title.innerHTML = environment.PAGE_TITLE;
        const head = document.getElementsByTagName("head")[0];
        if (head !== null && head !== undefined) {
          document.head.appendChild(favicon);
          document.head.appendChild(title);
        }

        if (this.platform.width() > 900) {
          this.gifToShow = "large";
        }


        this.initLogoutOnClose();
        this.storage.get(Constants.LANGUAGE).then(async (res) => {
          if (res) {
            res = res == "gr" ? "el" : res;
          }
          await this.setLanguage(res ? res : "en");
        });

        // this.checkDeepLink();
        this.deeplinkService.initializeDeepLink()

        //_ Please do not call more than one time this checkloginState method;
        //_ Is disabled here because is checked in Check DeepLink Method
        // this.checkLoginState();

        this.darkMode = await this.storage.get("darkMode");
        this.brandingService.activateBranding("salind");
        if (this.darkMode) {
          document.body.setAttribute("color-theme", "dark");
        } else {
          document.body.removeAttribute("color-theme");
        }
      },
      (error) => {
        console.log("ERROR: ", error);
      }
    );

    this.router.events.subscribe(event  =>  {
      if (event instanceof NavigationEnd){
      }
    });

    if (gtag) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag("config", "UA-42777288-12", {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }

    this.keyboardService.init();

    //Admin settings:
    let adminSettings = await this.storage.get(Constants.ADMIN_SETTINGS);

    //In App Translations:
    if (adminSettings) {
      if (
        (await this.appService.isAdmin()) &&
        adminSettings.enablePhraseInContext
      ) {
        let config = {
          projectId: "42fb97acc0b0d54ffb2f724be27973b1",
          phraseEnabled: true,
          //prefix: "{{__",
          //suffix: "__}}",
          prefix: "",
          suffix: "",
          fullReparse: true,
          autoLowercase: false,
        };
        initializePhraseAppEditor(config);
      }
    }
  }
  */

  async hideSplashScreen() {
    if (this.platform.is('capacitor') || this.platform.is('cordova')) {
      await SplashScreen.hide();
    }
  }

  async getUserSettings() {
    this.apiService
      .getUserSettings()
      .then(async (res: any) => {
        if (res && res.success) {
          this.appService.userSettings = res.success;
          this.appService._userSettings.next(res.success);
          this.appService._darkMode.next(
            res.success.dark_mode == 1 ? true : false,
          );
          this.appService.showIMEI.next(res.success.show_imei);
          this.appService.logoutOnClose.next(res.success.logout_onclose);
          this.mapService.iconSize.next(
            this.appService.getIconSize(res.success.icon_size),
          );
        }
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
  }

  async initDeepLinks() {
    this.deeplinkService.initializeDeepLink();
  }

  //_ Clear data if logoutOnClose flag is true -> Trigger when close the app
  async initLogoutOnClose() {
    //_ Check token is working fine in all platforms, else nothing would load on the app.
    //_ Maybe the order or in other place some trace is missing, but app flow is fine calling this method
    //_ Only one time on the authentication service.
    //await this.authenticationService.checkToken(); //_ Please find other way to check that;
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      this.authenticationService.clearData.subscribe(async (r) => {
        this.clearFCMToken();
      });
    }
  }

  firebaseToken: any = '';
  async clearFCMToken() {
    let firebaseToken: any = null;
    this.router.navigate(['login'], { replaceUrl: true });
    const isMobileApp =
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() == 'ios' ||
        Capacitor.getPlatform() == 'android');
    if (isMobileApp) {
      firebaseToken = await this.fcmService.getFcmToken();
      this.firebaseToken = firebaseToken;
      // console.log('FIREBASE TOKEN login', firebaseToken); //_ for testing
    } else {
      if (!window['isSafari']) {
        this.messagingService.getafMessagingtoken().subscribe((token) => {
          firebaseToken = token;
        });
      }
    }
    if (firebaseToken) {
      await this.authenticationService.logOut(firebaseToken);
    }
    this.clearStorageData();
    this.authenticationService.logout();
  }
  async clearStorageData() {
    this.storage.remove(Constants.USER_DATA);
    this.storage.remove(Constants.USER_ID);
    this.storage.remove(Constants.TOKEN_KEY);
    this.storage.remove(Constants.UPDATE_LOGIN);
    this.storage.remove(Constants.IS_ADMIN);
    this.storage.remove(Constants.NOTIFICATIONS_LIST);
    this.storage.remove(Constants.DEVICE_DATA);
    this.storage.remove(Constants.LASTPOINTS);
    this.storage.remove(Constants.REFRESH_TOKEN_KEY);

    this.appService.adminId.remove();
    this.appService.ghostMode.remove();
    this.devicesSidebarService.devicesFlatList.remove();
    this.devicesSidebarService.groups.remove();
    this.devicesSidebarService.userPermissions.remove();
  }

  async openErrorPopup() {
    const popup = await this.popoverCtrl.create({
      component: ApiErrorPopupComponent,
      cssClass: 'paj-popover-template',
      // translucent: false,
      backdropDismiss: false,
      mode: 'ios',
      // componentProps: { },
    });

    this.startupService.hideLoadingScreen$.next(true);
    await popup.present();
  }
}
