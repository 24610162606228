import { AppService } from "src/app/app.service";
import { GlobalEventService } from "src/app/services/globa-event.service";

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener
} from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import {
  MenuController,
  IonReorderGroup,
  Platform,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { StorageService as Storage } from "./../../services/storage.service";
import { Constants } from "../../constants.enum";
import { TranslateService } from "@ngx-translate/core";

import {
  ToggleCheck,
  DeviceModel,
  DeviceInfo,
  SideBarTranslation,
} from "src/app/geo-json-template";

import { Router } from "@angular/router";
import { UserImgComponent } from "src/app/shared/modal/user-img/user-img.component";
import { sidebarService } from "./side-bar.service";
import { PurchasemodalPage } from "src/app/members/logbook/purchasemodal/purchasemodal.page";
import { environment } from "src/environments/environment";
import { MessagingService } from "src/app/services/messaging.service";
import {
  AppModesEnum,
  AppModesType,
} from "src/app/members/map/components/app-modes-popup/app-modes-helper";
import { FcmService } from "src/app/services/plugins/fcm.service";
import { AppInfoService } from "src/app/services/plugins/app-info.service";
import { GeolocationService } from "src/app/services/plugins/geolocation.service";
import { GeocoderService } from "src/app/services/plugins/geocoder.service";
import { BrowserService } from "src/app/services/plugins/browser.service";
import { DevicesSidebarService } from "src/app/members/map/devices-sidebar/devices-sidebar.service";
import { DeviceListService } from "src/app/members/map/devices-sidebar/devices-list/device-list.service";
import { STORAGE_SETTINGS } from "src/app/services/storage-settings";
import { Capacitor } from "@capacitor/core";
import { SupportLinkService } from "../../services/support-link.service";
import { CsPopoverComponent } from "../cspopover/cspopover.component";
import { PajSidebarMixin } from "./paj-sidebar.mixin";
import { ThisReceiver } from "@angular/compiler";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent extends PajSidebarMixin implements OnInit {
  @ViewChild(IonReorderGroup, { static: true }) reorderGroup: IonReorderGroup;
  @Output() outputData = new EventEmitter<string>();
  @Input() data: any = null;
  @Input() markersInfo: any = null;
  @Input() isAdmin: string = null;
  @Output() deviceChecked = new EventEmitter<{}>();
  @Output() flyToDevice = new EventEmitter<{}>();
  public selectedIndex = null;
  isSmallScreen:boolean = false;
  deviceName: string;
  colorClassActive: boolean[] = [false, false, false];
  highlightSelectedDevice = false;
  selDeviceID: number = null;
  radioCheck: any;
  selectedTab = 0;
  notFirstTime = false;
  startDate: any;
  endDate: any;
  lastMinutes: any;
  lastPoints: any;
  segmentID: any;
  toggleArray: ToggleCheck[] = [];
  deviceModel = new DeviceModel();
  deviceAlarmsValues = new DeviceModel();
  deviceInfoValues = new DeviceInfo();
  alarmgeschwindigkeitab: number;
  deviceStatus: string;
  lastLatLng = {};
  public title: string;
  language: string;
  translationsTexts = new SideBarTranslation();
  currentAddress = "";
  userProfile = "assets/images/company/user.svg";
  fName: string;
  lName: string;
  userID: number;
  userData: any;
  currenctUrl: string;
  AccountUrl: any;
  app_version: string;
  user = { isadmin: false }; //_ Fixed to no show duplicated menu items
  brand = 0;
  appModes: AppModesType;
  logbookObject = {
    id: 3,
    title: "bottom-fab.logBook",
    url: "/logbook",
    svgicon: "assets/icons/logbook.svg",
    beta: true,
  };
  deviceManagementObject = {
    id: 2,
    title: "bottom-fab.deviceManagement",
    url: "/device-management",
    svgicon: "assets/icons/device-management.svg",
    beta: false,
  };
  nearByDevicesObject = {
    id: 5,
    title: "locateDevice.name",
    url: "/members/nearby-devices",
    svgicon: "assets/icons/locate-device.svg",
    beta: false,
  };
  renderVideoObject = {
    id: 6,
    title: "generateVideo.createVideoText",
    url: "/members/generate-video",
    svgicon: "assets/icons/video-camera.svg",
    beta: true,
  };

  menuOpenCount = false;
  appPages: {
    id: number;
    title: any;
    url: string;
    svgicon: string;
    forAdmin?: boolean;
    beta: boolean;
  }[];
  adminMenu: { title: string; url: string; svgicon: string }[];

  companyMenu = {
    title: "company.menuUsers",
    url: "/members/company/users",
    svgicon: "assets/icons/user-management.svg",
  };

  menuFilter = (item, index) => item.childrens === null;
  companyFilter = (item, index) => item.childrens !== null;
  userRole = null;
  isNative: boolean = false;
  showMenuItem: Boolean = false

  constructor(
    public authService: AuthenticationService,
    public menu: MenuController,
    public storage: Storage,
    public _translate: TranslateService,
    public router: Router,
    private browserService: BrowserService,
    public platform: Platform,
    private modalController: ModalController,
    private geolocationService: GeolocationService,
    private _sidebarService: sidebarService,
    private geocoderService: GeocoderService,
    public appService: AppService,
    public globalEventService: GlobalEventService,
    public _deviceListService: DeviceListService,
    public messagingService: MessagingService,
    public fcmService: FcmService,
    private appInfo: AppInfoService,
    public devicesSidebarService: DevicesSidebarService,
    private supportLinkService: SupportLinkService,
    private popoverCtrl: PopoverController
  ) {
    super(
      authService,
      menu,
      storage,
      _translate,
      router,
      platform,
      appService,
      globalEventService,
      _deviceListService,
      messagingService,
      fcmService,
      devicesSidebarService
    );
    this.isNative = platform.is('capacitor');

  }

  async ngOnInit() {
    this.platform.ready().then(async () => {
      this.app_version = await this.appInfo.getVersion();
      // console.log("app_version cap", this.app_version);
    });

    this.appPages = [
      // {
      //   title: 'bottom-fab.map',
      //   url: '/map',
      //   svgicon: 'assets/icons/location-outline.svg'
      // },
      {
        id: 1,
        title: "dashboard.name",
        url: "/dashboard",
        svgicon: "assets/icons/dashboard.svg",
        beta: false,
      },
      {
        id: 2,
        title: "bottom-fab.deviceManagement",
        url: "/device-management",
        svgicon: "assets/icons/device-management.svg",
        beta: false,
      },
      {
        id: 3,
        title: "bottom-fab.logBook",
        url: "/logbook",
        svgicon: "assets/icons/logbook.svg",
        beta: true,
      },
      {
        id: 4,
        title: "bottom-fab.alarm",
        url: "/members/notifications",
        svgicon: "assets/icons/bell.svg",
        beta: false,
      },
      {
        id: 5,
        title: "locateDevice.name",
        url: "/members/nearby-devices",
        svgicon: "assets/icons/locate-device.svg",
        beta: false,
      },
      /*
      {
        id: 6,
        title: "generateVideo.createVideoText",
        url: "/members/generate-video",
        svgicon: "assets/icons/video-camera.svg",
        beta: true,
      },
      */
      // {
      //   id: 6,
      //   title: "route-plan",
      //   url: "/route-plan",
      //   svgicon: "assets/icons/video-camera.svg",
      //   beta: true,
      // },

      {
        id: 7,
        title: "bottom-fab.appSettings",
        url: "/members/app-settings",
        svgicon: "assets/icons/settings.svg",
        beta: false,
      },
      {
        id: 8,
        title: "admin-main.page-heading",
        url: "/members/admin-main/users",
        svgicon: "assets/icons/user-management.svg",
        forAdmin: true,
        beta: false,
      },
      /*
      {
        id: 9,
        title: "admin-device-management.page-heading",
        url: "/admin-device-management/0",
        svgicon: "assets/icons/device-management.svg",
        forAdmin: true,
        beta: false,
      },
      {
        id: 10,
        title: "customer-feedback.page-heading",
        url: "/members/customer-feedbacks",
        svgicon: "assets/icons/device-management.svg",
        forAdmin: true,
        beta: true,
      },
      {
        id: 11,
        // title: 'bottom-fab.adminServerAlert',
        title: "admin-server-alert.page-heading",
        url: "/admin-server-alert",
        svgicon: "assets/images/admin-server-alert/server-alert.svg",
        forAdmin: true,
        beta: false,
      },
      */
    ];

    this.brand = environment.BRAND;
    if (this.brand == 3) {
      //this.appPages = this.appPages.filter(obj => obj.id !== 11);
      //this.appPages = this.appPages.filter(obj => obj.id !== 8);
      //this.appPages = this.appPages.filter(obj => obj.id !== 3);
    }

    this.disableSwipeMenu();
    this.authService.profileImgUrl.subscribe((img) => {
      this.userProfile = img;
    });

    /*this._sidebarService.updateUserInfo.subscribe(() => {
      this.updateUserInfo();
    });*/

    this.appService._userSettings$.subscribe((r: any) => {
      if (r.app_modes) this.updateAccordingToAppModesAndPermissions();
    });

    this.appService.appModeChanged.subscribe((r: any) => {
      this.updateAccordingToAppModesAndPermissions();
    });

    this.appService.user$.subscribe((r: any) => {
      if (r) {
        this.user = r;
        this.userRole = r.company_role;
        this.updateUserInfo(this.user);
      }
    });
    this.appService.doLogout.subscribe((r:any)=>{
      this.logout()
    })
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.getScreenSize();
  }

  getUserPin () {
    this.authService.getUserPin(this.appService.user.id)
    .then(async (r: any) => {
      if (r && r.success) {
        this.authService.pin = r.success.pin;
      }
    }).catch( error => {
      console.log("ERROR: ", error);
    });
  }

  updateAccordingToAppModesAndPermissions() {
    this.storage.get(Constants.APP_MODES).then((res) => {
      this.appModes = res;

      //_ SHOW DEFAULT ALL FEATURES FOR NO MODE SELECTED
      if (this.appModes.no_mode == 1) {
        this.addMenuPage(this.deviceManagementObject, 2);
        this.addMenuPage(this.logbookObject, 3);
        this.addMenuPage(this.nearByDevicesObject, 5);
        //this.addMenuPage(this.renderVideoObject, 6);
        this.appPages = this.appPages.sort((a, b) => (a.id < b.id ? -1 : 1));
      }

      //_ CAR MODE
      if (this.appModes.car_mode == 1) {
        this.addMenuPage(this.logbookObject, 3);
        //this.addMenuPage(this.renderVideoObject, 6);
        this.appPages = this.appPages.sort((a, b) => (a.id < b.id ? -1 : 1));
      } else if (this.appModes.no_mode == 0) {
        this.removeMenuPage(3);
        this.removeMenuPage(6);
      }

      //_ MENU PAGE FOR PETS MODE
      if (this.appModes.pet_mode == 1) {
      }

      //_ MENU PAGE FOR PEOPLE MODE
      if (this.appModes.people_mode == 1) {
      }

      //_ MENU PAGE FOR BUSINESS MODE
      if (this.appModes.business_mode == 1) {
        this.addMenuPage(this.deviceManagementObject, 2);
        this.addMenuPage(this.nearByDevicesObject, 5);
        //this.addMenuPage(this.renderVideoObject, 6);
        this.appPages = this.appPages.sort((a, b) => (a.id < b.id ? -1 : 1));
      } else if (this.appModes[AppModesEnum.NO_MODE] == 0) {
        this.removeMenuPage(2);
        this.removeMenuPage(5);
        this.removeMenuPage(6);
      }

      //_ Fix Menu Items for company roles
      // if (this.appService.user.company_role){
      //   const companyUserRole = this.appService.user.company_role;
      //   //_ Remove Logbook Menu Item
      //   if (companyUserRole.name != 'Admin'){
      //     this.removeMenuPage(3);
      //   }
      // }
    });
  }

  findObject(key) {
    const indexOfObject = this.appPages.findIndex((object) => {
      return object.id === key;
    });
    return indexOfObject;
  }

  async startTour() {
    this.menu.close();
    await this.storage.remove("choice.tour");
    this._sidebarService.choiceTour.next(true);
  }

  updateMenuState(state: boolean) {
    this.appService.menuClosed.next(state);
  }

  async updateUserInfo(user) {
    //await this.storage.get(Constants.USER_ID).then(async r => {
    //  if (r) {
    this.userID = user.id;
    //    await this.authService.getUserInfo(this.userID).then(
    //      res => {
    this.userData = user;
    this.fName = this.userData.firstname ? this.userData.firstname : "";
    this.lName = this.userData.lastname ? this.userData.lastname : "";
    this.authService.userName = this.fName + " " + this.lName;
    // this.authService.pin = this.userData.pin;
    //this.storage.set(Constants.USER_DATA, this.userData);
    this.authService.userData.next(this.userData);
    //      }
    //    ).catch(error => {
    //      console.log("ERROR: ", error);
    //    });
    // }

    // if (!this.menuOpenCount){
    //   await this.storage.get(Constants.IS_ADMIN).then(res => {
    //     if (res === false) {
    //       this.appPages.splice(7, 0, this.adminMenu[0], this.adminMenu[1], this.adminMenu[2]);
    //       this.menuOpenCount = true;
    //     }
    //   });
    // }

    //});

    // this.showAddress();
  }

  async open() {
    //_ Request user pin
    if (this.authService.pin === '')
      this.getUserPin();

    this.disableSwipeMenu();
    const index = this.appPages.findIndex((item) => {
      return item.url === "/admin-user-management";
    });
    //this.showAddress();

    this.language = this.appService.language;
    this.showMenuItem = (this.language === 'en' || this.language === 'en_US' || this.language === 'de') ? true : false;
  }

  close() {
    this.disableSwipeMenu();
  }

  // DISABLE SWIPE MENU IN SPECIFIC PAGES
  // Add the page that u want swipe be disabled
  disableSwipeMenu() {
    this.menu.swipeGesture(false, "mainMenu");
    if (this.router.url.startsWith("/map"))
      this.menu.swipeGesture(false, "mainMenu");

    if (this.router.url.startsWith("/members/nearby-devices"))
      this.menu.swipeGesture(false, "mainMenu");
  }

  // showAddress() {
  //   this.platform.ready().then(() => {
  //     let options = { enableHighAccuracy: true, timeout: 5000 };
  //     this.geolocationService
  //       .getCurrentPosition(options)
  //       .then((resp) => {
  //         this.geocoderService
  //           .reverseGeocode(resp.coords.latitude, resp.coords.longitude)
  //           .then((result) => {
  //             if (!result) return;
  //             //console.log("result of reverseGeocode", result);
  //             const addrArr = [];
  //             if (result[0].subLocality) {
  //               addrArr.push(result[0].subLocality);
  //             } // Area
  //             if (result[0].subAdministrativeArea) {
  //               addrArr.push(result[0].subAdministrativeArea);
  //             } // City
  //             if (result[0].administrativeArea) {
  //               addrArr.push(result[0].administrativeArea);
  //             } // State
  //             if (result[0].countryName) {
  //               addrArr.push(result[0].countryName);
  //             } // Country
  //             this.currentAddress = addrArr.join(", ");
  //           })
  //           .catch((error: any) => {
  //             console.log("ERROR: ", error);
  //           });
  //       })
  //       .catch((error) => {
  //         console.log("ERROR: ", error);
  //       });
  //   });
  // }

  drawStreetAddress(streetInfo) {
    this.deviceInfoValues.street = streetInfo.address.road;
    this.deviceInfoValues.building =
      streetInfo.address.postcode +
      " " +
      streetInfo.address.city +
      " " +
      streetInfo.address.neighbourhood;
    this.deviceInfoValues.city =
      streetInfo.address.country + " (" + streetInfo.address.county + ")";
  }

  // async exitApp() {
  //   await this.appInfo.exitApp();
  // }
  // async logout() {
  //   let firebaseToken: any = null;
  //   this.router.navigate(["login"], { replaceUrl: true });
  //   const isMobileApp = Capacitor.isNativePlatform() && (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
  //   if (isMobileApp) {
  //     firebaseToken = await this.fcmService.getFcmToken();
  //   } else {
  //     if (!window["isSafari"]) {
  //       this.messagingService.getafMessagingtoken().subscribe((token) => {
  //         firebaseToken = token;
  //         this.messagingService.deleteToken();
  //       });
  //     }
  //   }
  //   if (firebaseToken) {
  //     this.authService.logOut(firebaseToken);
  //   }
  //   this.clearOnLogout();
  // }

  // async clearOnLogout() {
  //   this.globalEventService.publishMessageCode("STOP_BACKGROUND_API");
  //   let darkMode = await this.storage.get("darkMode");
  //   await this.menu.close();
  //   await this.storage.set(Constants.UPDATE_MAP, 1);
  //   await this.authService.logoutStatus.next(true);

  //   this.menuOpenCount = false;
  //   // this.appPages.splice(7, 1);
  //   // this.appPages.splice(7, 1);
  //   this.fName = "";
  //   this.lName = "";
  //   this.userProfile = "";
  //   await this.storage.get(Constants.LANGUAGE).then((res) => {
  //     if (res) {
  //       this.language = res;
  //     } else {
  //       this.language = "en";
  //     }
  //   });
  //   //this.storage.set(Constants.LANGUAGE, this.language);
  //   await this.clearStorageData();
  //   //await this.storage.set('darkMode', darkMode);
  //   await this.authService.logout();
  //   this.appService.user = null;
  //   this.appService.devices = null;
  //   this._deviceListService._customDeviceGroups = []; //clear the reorder device list while logout
  //   this._deviceListService.deviceData = []; //clear the viertual list device list while logout
  //   // this.router.navigate(["login"], { replaceUrl: true });
  //   // window.location.href = '/login';
  //   // setTimeout(() => location.reload(), 500);
  // }

  // async clearStorageData() {
  //   this.storage.remove(Constants.USER_DATA);
  //   this.storage.remove(Constants.USER_ID);
  //   this.storage.remove(Constants.TOKEN_KEY);
  //   this.storage.remove(Constants.UPDATE_LOGIN);
  //   this.storage.remove(Constants.IS_ADMIN);
  //   this.storage.remove(Constants.NOTIFICATIONS_LIST);
  //   this.storage.remove(Constants.DEVICE_DATA);
  //   this.storage.remove(Constants.LASTPOINTS);

  //   this.appService.adminId.remove();
  //   this.appService.ghostMode.remove();
  //   this.devicesSidebarService.devicesFlatList.remove();
  //   this.devicesSidebarService.groups.remove();
  //   this.devicesSidebarService.userPermissions.remove();
  //   this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);
  //   this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE);
  //   // this.devicesSidebarService.filters.remove();
  //   this.devicesSidebarService.resetFilters();
  //   // console.log('Storaged cleared');
  // }

  openPrivacyPolicy() {
    this.browserService.openBrowser(
      this._translate.instant("app.privacy"),
      "_system"
    );
  }

  openImprint() {
    this.browserService.openBrowser(
      this._translate.instant("app.imprint"),
      "_system"
    );
  }

  async openAccount() {
    window.open(this._translate.instant("app.account"), "_blank");
    await this.menu.toggle();
    // console.log('openAccount')
    // await this.browserService.openBrowser(
    //   this._translate.instant("app.account"),
    //   "_blank"
    // );
  }

  async openHelp() {
    await this.menu.toggle();
    await this.supportLinkService.openHelp();
  }

  async openManuals() {
    await this.menu.close();
    await this.supportLinkService.openManuals();
  }

  async openDeviceGuard() {
    await this.menu.close();
    await this.supportLinkService.openDeviceGuard();
  }

  async toggleSidebar(item) {
    //console.log('toggleSidebar', item)
    await this.menu.toggle();
    if (item?.id == 3) {
      let allDevices = this.appService.devices;
      let devices = this.reduceLogbookDevicesModels(allDevices);
      if (devices?.length) {
        if (!item.childrens) {
          // await this.menu.toggle();
          await this.router.navigate([item.url]);
        } else {
          item.open = !item.open;
        }
      } else {
        await this.logbookPurchaseModal();
      }
    } else {
      if (!item.childrens) {
        // await this.menu.toggle();
        await this.router.navigate([item.url]);
      } else {
        item.open = !item.open;
      }
    }
  }

  //_ Return array with devices fit by permited models for logbook feature
  reduceLogbookDevicesModels(devices) {
    const modelsForLogbook = environment.MODELS_FOR_LOGBOOK;
    const grantAccess = environment.GRANT_LOGBOOK_ACCESS_FOR.includes(
      this.appService.user.id
    );
    return devices.filter((d) => {
      const models = d.device_models.map((d) => d.model_nr);
      for (let i = 0; i <= modelsForLogbook.length; i++) {
        if (
          d.device_models[0]?.logbook_access ||
          grantAccess ||
          d.logbook_access // specific device access
        )
          return true;
      }
    });
  }

  async logbookPurchaseModal() {
    const modal = await this.modalController.create({
      component: PurchasemodalPage,
      componentProps: { value: 123 },
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.data == "open") {
      this.browserService.openBrowser(
        "https://www.paj-gps.de/fahrtenbuch/",
        "_system"
      );
    }
  }

  async navigateRoute() {
    await this.menu.toggle();
    await this.router.navigate(["route-plan"]);
  }

  async changeProfile() {
    const modal = await this.modalController.create({
      component: UserImgComponent,
      cssClass: "User-Img-Component-modal-css",
      componentProps: {
        userId: this.userID,
      },
    });
    modal.onDidDismiss();
    await modal.present();
  }

  async getChangeLog() {
    await this.router.navigate(["/change-log"]);
    this.menu.toggle();
  }

  addMenuPage(obj, index) {
    let indexOfObject = this.findObject(index);
    if (indexOfObject < 0) {
      this.appPages.push(obj);
    }
  }

  removeMenuPage(index) {
    let indexOfObject = this.findObject(index);
    if (indexOfObject > -1) {
      this.appPages.splice(indexOfObject, 1);
    }
  }

  async showInfoPopup(event, title, description, image, useGif = true) {
    const cssClass: any = event.y > window.innerHeight / 2 ? "top-popover" : "";
    let popup = await this.popoverCtrl.create({
      componentProps: { title, description, image, useGif },
      component: CsPopoverComponent,
      event: event,
      mode: "ios",
      translucent: false,
      cssClass: cssClass,
    });

    await popup.present();
    popup = null;
  }
  getScreenSize() {
    this.isSmallScreen =
    document.documentElement.clientWidth < 769 ? true : false;
  }
}
