import { HttpClient } from "@angular/common/http";
import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "../app.service";
import { MediaApiService } from "../components/media-managment/media-api.service";

//_ THIS PIPE NEEDS TO BE CHECKED -- PERFORMANCE IS BAD !!
//_ MAYBE THE SERVICE HAS ISSUES
@Pipe({ name: 'secureMedia', })
export class SecureMediaPipe implements PipeTransform {

    lastThemeStatus = null;
    image = null;
    constructor(private http: HttpClient, private mediaApi: MediaApiService,
        private appService: AppService) {
        this.lastThemeStatus = this.appService.darkMode;
    }

    //_ @Async PIPE
    transform(image, shouldUpdate = false) {
        this.image = image;
        return this.mediaApi.getImageData(image);
    }

    checkForThemeChanges() {
        this.appService.darkMode$.subscribe(newStatus => {
            if (this.lastThemeStatus != newStatus)
                this.transform(this.image, true);
        })
    }
}