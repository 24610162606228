import { Injectable } from "@angular/core";
import { Platform, PopoverController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { RequestPermissionsComponent } from "../components/request-permissions/request-permissions.component";
import { zoomInAnimation } from "../animations/ionic-animations";
import { AvailablePermissions } from "../components/request-permissions/permissions";
import { FileOpenerService } from "./plugins/file-opener.service";
import { FileService } from "./plugins/file.service";
import { SocialShareService } from "./plugins/social-share.service";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import * as JSZip from "jszip";

@Injectable({
  providedIn: "root",
})
export class fileDownloadService {
  myToast;
  constructor(
    private platform: Platform,
    private fileService: FileService,
    private fileOpener: FileOpenerService,
    private toast: ToastController,
    private translate: TranslateService,
    private aPermissions: AndroidPermissions,
    private popoverCtrl: PopoverController,
    private socialShare: SocialShareService
  ) { }

  async downloadBlob(
    file,
    fileName,
    type = "application/pdf",
    customType = false,
    compression = false
  ) {
    //_ Adding time stamp for the filename asdf-34234234.FILE_TYPE
    const fileParts = fileName.split('.');
    fileName = fileParts[0] + '-' + moment().unix() + '.' + fileParts[1];
    // this.requestPermission(null);
    // return ;
    this.platform.ready().then(async () => {
      //_ If is webbrowser and ios
      // Open links only works in browser and ios
      const isBrowser = this.platform.is('desktop') || this.platform.is('mobileweb'); //_ this variable is asigned in the index using navJS lib
      // const isBrowser = false;
      if (isBrowser) {
        this.downloadBrowser(fileName, file, type, compression);
      } else if (this.platform.is('android')) {
        console.log('android')
        if (compression) {
          return this.createZip(file, fileName, type)
          .then((r) => this.writeFileInAndroid(r, fileName, true));
        } else return this.writeFileInAndroid(file, fileName);
        // await this.aPermissions.checkPermission(this.aPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(async s => {
        //   console.log('PERMISSION response', s.hasPermission);
        //   if (!s.hasPermission) {
        //     console.log('NOT HAS PERMISSION', s.hasPermission);
        //     const requestFn = () => { return this.fileService.writeFile(fileName, new Blob([file])) };
        //     return this.requestPermission(requestFn);
        //     // await this.aPermissions.requestPermissions([this.aPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.aPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE]);
        //   }
        //   return this.fileService.writeFile(fileName, file).then((val)=>{
        //     console.log('android write',val)
        //   })
        // });
      } else {
        if (compression)
          this.createZip(file, fileName, type).then((r) => this.writeFileInMobile(r, fileName, type, true))
        else this.writeFileInMobile(file, fileName, type);
        /* this.fileService.writeFile(fileName, new Blob([file])).then((r) => {
          console.log("writeFile: ", r);
          this.fileOpener.showOpenWithDialog(
            r.uri,
            type
          );
        }).catch((error) => {
          this.downloadBrowser(fileName, file, type);
          this.showToast(
            "",
            this.translate.instant("fileDownloadService.error"),
            3000,
            "danger"
          );
          console.log("ERROR: ", error);
        }); */
      }
    });
  }

  async createZip(file, fileName: string, type: string) {
    const zip = new JSZip();
    zip.file(fileName,  new Blob([file], { type: type }),
    { compression: "DEFLATE", compressionOptions: { level: 5 }});

    return await zip.generateAsync({type:  'blob'});
  }

  async writeFileInAndroid(file, fileName: string, zip=false) {

    if (zip) fileName = this.generateZipFileName(fileName);
    else file = new Blob([file]);

    return await this.fileService.writeFile(fileName, file)
      .then((r) => {
        console.log('writeFile',r)
        return Filesystem.getUri({
          directory: Directory.Cache,
          path: fileName
        });
      })
      .then((uriResult) => {
        console.log('uriResult',uriResult)
        return Share.share({
          title: fileName,
          text: fileName,
          url: uriResult.uri,
        });
      });
  }

  async writeFileInMobile(file, fileName: string, type: string, zip=false) {

    if (zip) fileName = this.generateZipFileName(fileName);
    else file = new Blob([file]);

    this.fileService.writeFile(fileName, file).then((r) => {
      console.log("writeFile: ", r);
      this.fileOpener.showOpenWithDialog(
        r.uri,
        type
      );
    }).catch((error) => {
      this.downloadBrowser(fileName, file, type);
      this.showToast(
        "",
        this.translate.instant("fileDownloadService.error"),
        3000,
        "danger"
      );
      console.log("ERROR: ", error);
    });

  }

  async downloadBrowser(fileName, file, type, compression=false) {

    let blobFile = new Blob([file], { type });
    console.log("File:", blobFile)
    let zipFileName: string;
    if (compression)  {
      blobFile = await this.createZip(file, fileName, type);
      zipFileName = this.generateZipFileName(fileName);
    }

    console.log("ZipFile:", blobFile)

    const url = window.URL.createObjectURL(blobFile);
    const link = window.document.createElement("a");
    link.href = url;
    link.setAttribute("download", compression ? zipFileName : fileName);
    window.document.body.appendChild(link);
    link.click();
    link.remove();
  }

  generateZipFileName(fileName: string) {
    return `${fileName.split('.')[0]}.zip`
  }
  async showToast(title, text, time, colorType) {
    if (this.myToast) this.myToast.dismiss();
    this.myToast = await this.toast.create({
      message: text,
      duration: time,
      color: colorType,
      position: "bottom",
      header: title,
    });

    this.myToast.present();
  }

  isApp(): boolean {
    return (document.URL.indexOf('http') !== 0 || document.URL.indexOf('http://localhost:8080') === 0);
  }

  // async saveFile(fileName, writeDirectory, file) {
  //   //_ Request permission if platform is android
  //   if (this.platform.is('android')) {
  //     await this.aPermissions.checkPermission(this.aPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(async s => {
  //       if (!s.hasPermission) {
  //         console.log('HAS NO PERMISSION', s.hasPermission);
  //         const requestFn = () => {
  //           return
  //           // this.createFile(fileName, writeDirectory)
  //         };
  //         return this.requestPermission(requestFn);
  //         // await this.aPermissions.requestPermissions([this.aPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.aPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE]);
  //       }
  //     });
  //   }
  //   // await this.createFile(fileName, writeDirectory);
  //   return this.writeToFile(fileName, writeDirectory, file);
  // }

  // async createFile(filename, path) {
  //   return this.fileService.createFile(path, filename, false).catch(err => {
  //     console.log('ERROR FILE CREATED ***');
  //     console.log(err);
  //   });
  // }

  async writeToFile(filename, path, file) {
    return this.fileService.writeFile(filename, file).then(createdFile => {

      this.showToast(
        this.translate.instant("fileDownloadService.title"),
        this.translate.instant("fileDownloadService.message"), 5000, "success"
      );
      if (this.platform.is('ios'))
        return createdFile;
    }).catch(error => {
      this.showToast("", this.translate.instant("fileDownloadService.error"), 3000, "danger");
      console.log("ERROR: ", error);
    });
  }

  async requestPermission(requestFn) {
    return new Promise(async (resolve, reject) => {
      const popup = await this.popoverCtrl.create({
        component: RequestPermissionsComponent,
        cssClass: "choice-tour-popover",
        componentProps: { permissionName: AvailablePermissions.Storage, requestFn },
        showBackdrop: true,
        mode: 'ios',
        enterAnimation: zoomInAnimation
      });

      popup.present();
      const { data } = await popup.onWillDismiss();

      if (data) {
        if (data.hasPermission) resolve(true);
      }

      resolve(false);
    })

  }

  downloadFileUrl(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
