import { Component, OnInit } from '@angular/core';
import { StorageService as Storage } from './../../services/storage.service';
import * as moment from 'moment';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { ApiServerAlert } from 'src/app/members/admin-main/admin-server-alert/apis';
import { WhatsnewPage } from 'src/app/public/whatsnew/whatsnew.page';
import { ModalController, PopoverController } from '@ionic/angular';
import { TourService } from '../tour/tour.service';
import { first } from 'rxjs/operators';
import {
  checkAlertIsReadUsingTriggeredInterval,
  checkAlertModelsAndLanguage,
  Error_Status,
  extractHtmlLinks,
  Geofencev1Alert,
  getDeviceModels,
  MaintenanceAlert,
} from './util';
import { ServerAlertService } from 'src/app/members/admin-main/admin-server-alert/server-alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PwaUpdateService } from 'src/app/services/pwa-update.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SurveyPopupComponent } from '../survey/survey-popup/survey-popup.component';
import { StartupService } from 'src/app/services/startup.service';

@Component({
  selector: 'app-server-alert',
  templateUrl: './server-alert.component.html',
  styleUrls: ['./server-alert.component.scss'],
})
export class ServerAlertComponent {
  alerts = [];
  currentIndex = 0;
  language;
  messageKey = 'message';
  deviceModels = null;
  runningAction = false;
  constructor(
    private api: ApiServerAlert,
    private storage: Storage,
    public appService: AppService,
    private modalController: ModalController,
    private alertsService: ServerAlertService,
    private tourService: TourService,
    public authService: AuthenticationService,
    private updateService: PwaUpdateService,
    public translateService: TranslateService,
    private router: Router,
    private popover: PopoverController,
    private startupService: StartupService
  ) {
    this.storage.storageReady.subscribe((r) => {
      if (r) this.init();
    });
  }

  minStart = 1654100128;
  status = Error_Status(this.minStart);
  currentStatus = null;
  async init() {
    this.appService.user$.pipe(first()).subscribe(async (r) => {
      this.appService.devices$.pipe(first()).subscribe(async (devices: any) => {
        this.deviceModels = getDeviceModels(devices);
        await this.loadLanguage();
        // this.showGeofencev1Alert(); //_ Show an alert if user still is using geofences in v1 app
        this.api
          // .getActiveAlerts()
          this.startupService.serverAlerts$.pipe(first()).toPromise()
          .then((r) => {
            if (r) {
              setTimeout(async () => {
                // console.log('ALERTS !', { alerts: this.alerts, res: r });
                // this.alerts = this.alerts.concat(r.success);
                if (r.success?.length > 0){
                  r.success.forEach(a => {
                    if (a !== null && a !== undefined) this.alerts.push(a)
                  });
                }

                if (!(await this.checkTourIsOpen())) {
                  this.showAlert();
                }

                this.checkIfShouldShowSurvey(devices, r);
              }, 6000); // one second after the tour is initialized
            }
          })
          .catch((error) => {
            console.log('ERROR: ', error);
          });
      });
    });

    //_ Listent to add alerts
    this.alertsService.addAlert$.subscribe((a) => {
      this.alerts.push(a);
    });
  }

  async loadLanguage() {
    return this.storage.get(Constants.LANGUAGE).then((r) => {
      this.language = r;
      this.messageKey =
        !this.language.startsWith('en') && this.language != null
          ? 'message_' + this.language
          : 'message';
      this.messageKey = this.language == 'pt' ? 'message_pr' : this.messageKey;
    });
  }

  //_ Only works for Maintenance Alert
  updateStatus() {
    const time = +moment().unix();
    let prevStatus = null;
    this.status.forEach((s) => {
      if (prevStatus) {
        if (time >= prevStatus.min && time <= s.min)
          this.currentStatus = prevStatus;
      }
      prevStatus = s;
    });
    if (!this.currentStatus) this.currentStatus = this.status[0];
  }

  //_ Only works for Maintenance Alert
  async addMaintenanceAlert() {
    await this.loadLanguage();
    this.updateStatus();
    setInterval(() => {
      this.updateStatus();
    }, 60 * 1000);

    this.alerts = [MaintenanceAlert];
  }

  async showAlert(index = 0) {
    //_ Iterate the list to show the next no read alert
    console.log('this.alerts', this.alerts);
    if (index < this.alerts.length) {
      this.currentIndex = index;
      const ignoreAlertTypes = ['update', 'survey'];

      if (!(await this.checkAlertIsRead(this.alerts[index])))
        if (
          this.showForModelsAndLanguage(this.alerts[index]) &&
          !ignoreAlertTypes.includes(this.alerts[index]?.type)
        ) {
          this.alerts[index]['show'] = true;
        } else {
          if (
            !(await this.checkAlertIsRead(this.alerts[index])) &&
            this.alerts[index]?.type == 'update' &&
            this.alerts[index]?.show != false
          ) {
            //_ Check app version to show whatsnew popup
            // await new Promise ((resolve) => setTimeout(() => resolve(true), 5000));
            // const alertVersion = this.alerts[index].name.replace( /[^\d\.]*/g, '');
            // if (alertVersion == this.appService.appVersion)
            //   await this.presentModal(this.alerts[index])
            this.saveAlertRead(this.alerts[index]);
            this.updateService.lastUpdateAlert.set({
              alert: this.alerts[index],
              date: moment().unix(),
            });
          }
        }
      this.showAlert(index + 1);
    }
  }

  async checkTourIsOpen() {
    return new Promise((resolve, reject) => {
      if (!this.tourService._tourVisible.value) {
        resolve(false);
      }
      else
        this.tourService._tourVisible.subscribe((v) => {
          if (v == false) resolve(false);
        });
    });
  }

  async presentModal(alert) {
    const modal = await this.modalController.create({
      component: WhatsnewPage,
      mode: 'ios',
      componentProps: {
        alert: alert,
        messageKey: this.messageKey,
      },
    });

    await modal.present();
    await modal.onWillDismiss();
    this.close(alert);
  }

  close(alert: any) {
    console.log('alert', alert);
    alert['show'] = false;
    this.saveAlertRead(alert);

    //_ Show next and wait 1.5 segundos until close animation prev alert was done
    setTimeout(() => {
      this.showAlert(this.currentIndex + 1);
    }, 1500);
  }

  saveAlertRead(alert) {
    //_ Store the alert with the current date
    this.storage.set('alert-' + alert.id, {
      id: alert.id,
      date: moment().unix(),
    });
  }

  async checkAlertIsRead(alert) {
    return await checkAlertIsReadUsingTriggeredInterval(alert, this.storage);
  }

  showForModelsAndLanguage(alert) {
    return checkAlertModelsAndLanguage(alert, this.deviceModels, this.language);
  }

  showGeofencev1Alert() {
    this.authService.getGeofencesV1(this.appService.user.id).then((r) => {
      if (r) {
        const geofences = r.success;
        //_ If there is some geofence returned means are activated some on v1
        if (geofences.length > 0) {
          this.alertsService.addAlert(Geofencev1Alert(this.translateService));
        }
      }
    });
  }

  action(alert) {
    this.runningAction = true;
    alert.actionData.fn(this).then((r) => {
      this.runningAction = false;
      this.close(alert);
    });
  }

  continue(alert) {
    const links = extractHtmlLinks(alert.message);
    links.forEach((link) => window.open(link, '_blanc'));
    this.close(alert);
  }

  //_ Load survey alerts, and check d.models lang and read status
  async checkIfShouldShowSurvey(devices, alerts) {
    if (!this.router.url.includes('tabs/map')) return;

    const deviceModels = getDeviceModels(devices);
    if (alerts.success?.length > 0) {
      const surveyAlerts = alerts.success.filter((al) => al.type === 'survey');

      if (surveyAlerts.length === 0) return;

      const alert = surveyAlerts[0];
      const language = await this.storage.get(Constants.LANGUAGE);
      const langAndModelOk = checkAlertModelsAndLanguage(
        alert,
        deviceModels,
        language ?? 'en',
      );
      const isRead = await checkAlertIsReadUsingTriggeredInterval(
        alert,
        this.storage,
      );
      // console.log('ALERT TO SHOW 2', { alert, langAndModelOk, isRead });
      console.warn('[DEBUG] check showSurvey', { devices, alerts, url: this.router.url.includes('tabs/map'), surveys: surveyAlerts,
        deviceModels, alert, language, langAndModelOk, isRead  })
      if (alert && langAndModelOk && !isRead) {
        this.showSurveyPopup(alert);
      }
    } else {
      console.warn('NOT ALERTS TO CHECK.');
    }
  }

  async showSurveyPopup(alertData) {
    //if(this.DONT_SHOW_SURVEY_POPUP.get().value) return
    const modalObj = await this.popover.create({
      component: SurveyPopupComponent,
      componentProps: { alert: alertData },
      mode: 'ios',
      cssClass: 'survey-popup',
      //backdropDismiss: false,
    });

    await modalObj.present();
  }
}
