import { Injectable } from "@angular/core";
import { environment as ENV } from 'src/environments/environment';
import { StorageService as Storage } from "../../../services/storage.service";
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpEventType, } from "@angular/common/http";
import { ApiService } from "src/app/services/api.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiServerAlert extends ApiService {
  alertsObs = new Subject();
  alerts = [];
  alertForEdit = null;
  constructor(storage: Storage, http: HttpClient) {
    super(storage, http);
  }

  getServerAlerts() {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + "a/server/alerts").subscribe((res: any) => {
      this.alerts = res.success;
      this.alertsObs.next(this.alerts);
      resolve(res);
    }, (error) => {
      this.alertsObs.next(null);
      resolve(this.requestError(error));
    });
    })
  }

  getAllUpdateServerAlerts() {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + "getallupdate/server/alerts").subscribe((res: any) => {
      this.alerts = res.success;
      this.alertsObs.next(this.alerts);
      resolve(res);
    }, (error) => {
      this.alertsObs.next(null);
      resolve(this.requestError(error));
    });
    })
  }

  storeServerAlert(data) {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.post(ENV.ROUTE + ENV.API_VERSION + "a/server/alerts", data).subscribe((res: any) => {
      this.alerts.push(res.success);
      this.alertsObs.next(this.alerts);
      resolve(res);
    }, error => {
      resolve(this.requestError(error));
    });
    })
  }

  updateServerAlert(data) {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.put(ENV.ROUTE + ENV.API_VERSION + "a/server/alerts/" + data.id, data).subscribe((res: any) => {
      this.alerts[this.alerts.map(it => it.id).indexOf(data.id)] = res.success;
      this.alertForEdit = null;
      this.alertsObs.next(this.alerts);
      resolve(res);
    }, (error) => {
      resolve(this.requestError(error));
    });
    })
  }

  deleteServerAlert(data) {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.delete(ENV.ROUTE + ENV.API_VERSION + "a/server/alerts/" + data.id).subscribe((res: any) => {
      this.alerts = this.alerts.filter(a => a.id != data.id);
      this.alertsObs.next(this.alerts);
      resolve(this.alerts)
      return res;
    }, (error) => {
      resolve(this.requestError(error));
    });
    })
  }

  getActiveAlerts() {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + "server/alerts").subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  uploadImages(data){
    return new Promise<any>((resolve, reject) => {
      this.http.post(ENV.ROUTE + ENV.API_VERSION + 'a/server/alerts/uploadImages', data).subscribe( res => {
      resolve(res);
    }, (error) => {
      resolve(this.requestError(error));
    })
    })
  }

  deleteImages(id){
    return new Promise<any>((resolve, reject) => {
      this.http.delete(ENV.ROUTE + ENV.API_VERSION + 'a/server/alerts/deleteImage/' + id).subscribe( res => {
      resolve(res);
      }, (error) => {
        resolve(this.requestError(error));
      })
    })
  }
}