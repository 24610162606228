import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceData } from '../map/class/deviceData';
import { MapAddressPickerComponent } from 'src/app/components/map-address-picker/map-address-picker.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from '../../devicesData.service';
import { ApiService } from 'src/app/services/api.service';
import { SetupWifiService } from '../../devices-sidebar/device-item/device-submenu/command/setup-wifi/setup-wifi.service';
import { Subject, takeUntil } from 'rxjs';
import { SetupWifiComponent } from '../../devices-sidebar/device-item/device-submenu/command/setup-wifi/setup-wifi.component';
import { GuidedSetupComponent } from '../../devices-sidebar/device-item/device-submenu/command/setup-wifi/guided-setup/guided-setup.component';
import { DeviceStatusEnum } from '../../devices-sidebar/device-item/device-submenu/info/info.service';
import moment from 'moment';
import { EXTRA_COMMAND_PROPS } from '../../devices-sidebar/device-item/device-submenu/command/commands-list-props';

@Component({
  selector: 'app-map-wifi-popup',
  templateUrl: './map-wifi-popup.component.html',
  styleUrls: ['./map-wifi-popup.component.scss'],
})
export class MapWifiPopupComponent implements OnInit, OnDestroy {
  @Input() feature;
  @Input() device: DeviceData = null;
  deviceCommand = null;
  loadingDeviceCommand = false;
  loadingDeviceStatus = false;
  destroyed$ = new Subject();
  deviceStatus = DeviceStatusEnum[1];

  constructor(
    private popoverController: PopoverController,
    private translate: TranslateService,
    public modalController: ModalController,
    private authService: AuthenticationService,
    private appService: AppService,
    private devicesService: DeviceDataService,
    private apiService: ApiService,
    private setupWifiService: SetupWifiService
    ) {

   }

  ngOnInit() {
    this.getDeviceCommands();

    this.setupWifiService.sendCommand$.pipe(takeUntil(this.destroyed$)).subscribe((r: any)=>{
      if(r){
        this.sendWifiSetupCommand(r.commandId, r.item);
      }
    });

    this.getDeviceStatus();
  }

  onCancel() {
    this.popoverController.dismiss(null)
  }

  async editAddress(){
    const coords = { lat: this.feature.properties.lat, lng: this.feature.properties.lng }
    const wifiNumber = this.feature.properties.wifiIndex

    const modal = await this.modalController.create({
      component: MapAddressPickerComponent,
      // cssClass: 'map-address-picker-popup',
      componentProps: {
        coords: coords,
        markerTitle: '',
        address: '',
        showWorkAndHomeOptions: false,
        mapStyle: 'pajLite'
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.authService.saveWifiAddress(
        this.device.id,
        {
          wifiNumber,
          lat: data.coords.lat,
          lng: data.coords.lng
        }
      ).subscribe(async (r: any) => {
        if(r.success){
          const newDeviceInfo = this.devicesService.devices[this.device.id];
          newDeviceInfo.parseAndStoreWifis(r.success);
          this.devicesService.updateDeviceInfo(newDeviceInfo);

          this.appService.showToast(null, this.translate.instant('commandsTab.SetupWifi.alertUpdateLocationSuccess'),
          2000,
          "success")
        }else{
          this.appService.showToast(null, this.translate.instant('commandsTab.SetupWifi.alertUpdateLocationError'),
          2000,
          "error")
        }
      });
    }
  }

  getDeviceCommands () {
    this.loadingDeviceCommand = true;
    this.apiService.getDeviceCommands(this.device?.properties.imei).then((response) => {
      if (response['data'].length) {
        //_ Add translation key and image to the response
        response['data'] = response['data'].map(d => {
            return EXTRA_COMMAND_PROPS[d.name] ? { ...d, ...EXTRA_COMMAND_PROPS[d.name] } : d;
        });

        this.deviceCommand = response['data'].find(c => c.name === 'XmPetTriggerWH');
        console.log('DEVICE COMMANDS', this.deviceCommand);
        this.loadingDeviceCommand = false;
      }
    }, error => {
      console.error('ERROR GETTING DEVICE COMMANDS');
      this.loadingDeviceCommand = false;
    });
  }
  async setupWifis () {
    this.setupWifiService.item = this.deviceCommand;
    this.setupWifiService.currentWifi = 'wifi' + this.feature.properties.wifiIndex;
    this.setupWifiService.deviceId = this.feature.properties.deviceId;
    this.setupWifiService.openModal(GuidedSetupComponent);
  }

  sendWifiSetupCommand(commandId, item){
    item.loading = true;
    this.apiService.sendDeviceCommand(this.device?.properties.imei, commandId, item.parameters).then((response) => {
      if (response['data']) {
        item.remainingTime = response['data']['commandPolicy']['cooldown'];
        this.startSecondsTimer(item);
      }
      else {
        item.loading = false;
      }
    },
      (error) => {
        item.loading = false;
      })
  }

  startSecondsTimer(item) {
    var x = setInterval(function () {
      item.remainingTime -= 1;
      if (item.remainingTime < 0) {
        clearInterval(x);
        item.loading = false;
      }
    }, 1000);
  }

  async deleteWifiHome(){
    const confirmDelete = await this.appService.confirmDialog('Delete wifi home ' + this.feature.properties.wifiIndex, 'Are you sure to delete this wifi home?');

    if (confirmDelete) {
      //_ SEND COMMAND TO DELETE FROM JS SERVICE AND DISABLE TRACKING WIFI IN DEVICE

      //_ DELETE IN DB
      this.authService.saveWifiAddress(
        this.device.id,
        {
          wifiNumber: this.feature.properties.wifiIndex,
          lat: null,
          lng: null,
          deleteMac: true
        }
      ).subscribe(async (r: any) => {
        if(r.success){
          const newDeviceInfo = this.devicesService.devices[this.device.id];
          newDeviceInfo.parseAndStoreWifis(r.success);
          this.devicesService.updateDeviceInfo(newDeviceInfo);
          this.closeMapPopup();
        }else{
          //_ ERROR MESSAGE
        }
      });
    }

  }

  //_ Delete map popup
  closeMapPopup () {
    const popups = document.getElementsByClassName("mapboxgl-popup");
    if (popups.length)
      for (let i=0; i<popups.length; i++)
        popups.item(i).remove();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getDeviceStatus() {
    this.loadingDeviceStatus = true;
    this.authService.getDeviceStatusData(this.device.id).then((res) => {
      if (res) {
        if (res.success) {
          if ((+res.success.last_update_by_service + 300) < (moment.utc().valueOf() / 1000)) {
            this.deviceStatus = DeviceStatusEnum[2];
          }
          else
            this.deviceStatus = DeviceStatusEnum[res.success.connection_status];
        }
      }
    }).finally(() => {
      this.loadingDeviceStatus = false;
    })
  }
}
