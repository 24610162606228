import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import {
  ModalController,
  AlertController,
  LoadingController,
  ToastController,
  PopoverController,
} from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeviceMarkers } from 'src/app/geo-json-template';
import { environment as ENV } from 'src/environments/environment';

import { StorageService as Storage } from '../../../../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CenterPoint } from 'src/app/members/map/components/map/class/geofence_center';
import { AlertService } from './alert.service';
import { GeoSelectComponent } from './geo-select/geo-select.component';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AppService } from 'src/app/app.service';
import { GeofenceinfopopupComponent } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/alert/geofenceinfopopup/geofenceinfopopup.component';
import { DecimalPipe } from '@angular/common';
import { ShockAlertPopupComponent } from './shock-alert-popup/shock-alert-popup.component';
import { SelectDeviceComponent } from '../select-device/select-device.component';
import {
  createCircle,
  translatePolygon,
} from 'src/app/members/map/components/map/class/customDragmode';
import { DeviceListService } from '../../../devices-list/device-list.service';
import Pickr from "@simonwep/pickr";
import { AlertInfoPopupComponent } from "./alert-info-popup/alert-info-popup.component";
import { environment } from "src/environments/environment";
import { SupportLinkService } from 'src/app/services/support-link.service';

const turf = require('@turf/turf');

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() deviceID;
  @Input() data;
  @Input() map;
  @Input() isOnDeviceList = true;
  @Input() hideGeofencesFeature = false;
  @Input() toastPosition: any = 'bottom';
  @Output() onAlarmChange = new EventEmitter<any>();

  buttonColorOne = ' #acacac';
  buttonColorTow = ' #acacac';

  isDeviceList = false;
  @Input() deviceMarkers: DeviceMarkers[] = [];

  url: string = ENV.ROUTE;
  deviceAllData;
  deviceAlarmsValues;
  deviceId;
  deviceInfo;
  alarmgeschwindigkeitab: number;
  alarm_volt_value: number;
  deviceModel;
  language;
  shapeColor = '#ff4e00';
  draw;

  alarmsMessageHeader = [];
  alarmsMessageDescription = [];
  selectedDeviceGeoList = [];
  permissions = { see: true, modify: true, share: true };
  device = null;
  units = 'km/h';
  speed_unit = '';
  distance_unit = '';
  toggleVibrationTime = 50;
  observables = {};
  geofencesAreDisabled = false;
  pickrGL: any;
  brand = 0;
  showMenuItem: Boolean = false

  constructor(
    private modalController: ModalController,
    private authService: AuthenticationService,
    private alertCtrl: AlertController,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    public translate: TranslateService,
    private alertService: AlertService,
    private toastController: ToastController,
    private popoverCtrl: PopoverController,
    private deviceService: DeviceDataService,
    private centerPoint: CenterPoint,
    private toast: ToastController,
    private appService: AppService,
    private _translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private devicedataService: DeviceDataService,
    public deviceListService: DeviceListService,
    private supportLinkService: SupportLinkService,
  ) {
    let style = getComputedStyle(document.body);
    this.shapeColor = style.getPropertyValue('--ion-color-primary');
  }

  getGeofenceAlertsState(updateGeofences: Array<any> = null) {
    let deactiveGeofences = 0;
    const geofences = updateGeofences || this.device.geofences;

    geofences?.forEach((geofence: any) => {
      if (geofence.trigger_enter == 0 && geofence.trigger_leave == 0)
        deactiveGeofences = deactiveGeofences + 1;
    });

    this.geofencesAreDisabled = !(
      deactiveGeofences == this.device.geofences?.length
    );
  }

  async ngOnInit() {
    this.deviceService.devices$.subscribe((devices)=>{
      this.device = devices[this.deviceID];
      this.selectedDeviceGeoList = this.device.geofences.map((geo) => {
        return { ...geo, optionsObj: JSON.parse(geo.options) };
      });
    })
    this.draw = this.alertService.drawer;
    this.device = this.deviceService.devices[this.deviceID];

    this.getGeofenceAlertsState();
    this.selectedDeviceGeoList = this.device.geofences.map((geo) => {
      return { ...geo, optionsObj: JSON.parse(geo.options) };
    }); //.map(g => { return {...g, prevName: g.name } });
    if (this.device.deviceModel) {
      this.deviceId = this.device.id;
      this.deviceModel = this.device.deviceModel;
      this.deviceAlarmsValues = this.device.properties;
      this.alarmgeschwindigkeitab =
        this.deviceAlarmsValues.alarmgeschwindigkeitab;
      this.updateAlarmVoltValue();
      this.permissions = this.deviceService.devices[this.deviceID].permissions;
    }

    this.distance_unit = this.appService.user.distance_unit;
    this.speed_unit = this.translate.instant(
      'speed.' + this.distance_unit + 'H',
    );

    this.observables['onChanges'] = this.deviceService.deviceUpdated$.subscribe(
      (r) => {
        if (this.device.id == r.properties.id) {
          this.device.properties = r.properties;

          this.selectedDeviceGeoList = r.geofences.map((geo) => {
            return { ...geo, optionsObj: JSON.parse(geo.options) };
          });
        }
      },
    );

    this.observables['geofenceUpdated'] =
      this.alertService.geofenceUpdated.subscribe((geo: any) => {
        if (geo.iddevice === this.device.id) {
          this.selectedDeviceGeoList.forEach((geoInList) => {
            if (geoInList.id === geo.id && geoInList.iddevice == geo.iddevice) {
              geoInList.optionsObj = JSON.parse(geo.options);
              geoInList.name = geo.name;
            }
          });
        }
      });

    this.observables['geofencesReady'] =
      this.devicedataService.geofencesReady.subscribe((res) => {
        console.log('RES geofences ready', { res, device: this.device });
        if (res[this.device.id]) {
          this.getGeofenceAlertsState();
          this.device.properties = res[this.device.id].properties;

          this.selectedDeviceGeoList = res[this.device.id].geofences.map(
            (geo) => {
              return { ...geo, optionsObj: JSON.parse(geo.options) };
            },
          );
        }
      });
      this.resetShapeColor();

      this.brand = environment.BRAND;
  

    this.language = this.appService.language;
    this.showMenuItem = (this.language === 'en' || this.language === 'en_US' || this.language === 'de') ? true : false;

  
  }

  ngOnDestroy() {
    Object.keys(this.observables).forEach((k) =>
      this.observables[k].unsubscribe(),
    );
  }
  ionViewDidEnter() {
    this.alarmgeschwindigkeitab =
      this.deviceAlarmsValues.alarmgeschwindigkeitab;
    this.updateAlarmVoltValue();
  }

  updateAlarmVoltValue() {
    if (this.deviceAlarmsValues.alarm_volt_value.toString().length > 5) {
      if (this.deviceAlarmsValues.alarm_volt_value.toString().length === 6) {
        this.alarm_volt_value =
          Math.round(this.deviceAlarmsValues.alarm_volt_value * 10) / 10;
      } else {
        this.alarm_volt_value = Math.round(
          this.deviceAlarmsValues.alarm_volt_value,
        );
      }
    } else {
      this.alarm_volt_value = this.deviceAlarmsValues.alarm_volt_value;
    }
  }

  extractNameFromJson(obj) {
    obj = JSON.parse(obj);
    return obj;
  }

  async enableDisableGeofences(status) {
    await this.authService
      .enableDisableGeofences(this.deviceId, Number(status))
      .then((res) => {
        if (!res.success.length && !this.device.geofences?.length)
          this.geofencesAreDisabled = status;
        else this.getGeofenceAlertsState(res.success);

        //_ Update geofences status and then update the device in devices service
        this.device.geofences.forEach((geo) => {
          geo.trigger_enter = status;
          geo.trigger_leave = status;
        });

        this.deviceService.updateDeviceInfo(this.device);

        const message = status
          ? 'alerts.geofencesUpdatedEnableMessage'
          : 'alerts.geofencesUpdatedDisableMessage';
        this.showToast(null, this._translate.instant(message), 2000, 'success');
      })
      .catch((e) => {
        this.showToast(
          null,
          this._translate.instant('alerts.geofencesUpdatedErrorMessage'),
          2000,
          'danger',
        );
      });
  }

  async enableAlarmData(
    status,
    id = 'x',
    isSpeedCheck: number = 0,
    geofenceChecked: number = 0,
  ) {
    if (!this.permissions.modify) return null; //_ No do nothing if doesnt has permissino to edit.

    if (isSpeedCheck) {
      if (id === 'alarm_volt')
        this.deviceAlarmsValues.alarm_volt = Number(status);
      else if (id === 'alarmgeschwindigkeit')
        this.deviceAlarmsValues.alarmgeschwindigkeit = Number(status);
    }

    //_ Show shock alert popup
    if (id == 'alarmbewegung' && status && this.deviceModel) {
      this.showShockPopup();
      this.showAlertInfoPopup(id);
    }
    //_ Show info alert popup
    if (this.deviceModel?.alarm_drop && id == 'alarmsos' && status ) {
      this.showAlertInfoPopup(id);
    }

    //_ Propagate Alarm Changes
    this.onAlarmChange.emit({ name: id, status, device: this.device });

    // Change the alarm type with the status passed
    // this.device.deviceModel[id] = Number(status);
    await this.authService
      .updateDeviceAlarms(this.deviceId, id, Number(status))
      .then((res) => {
        const index = this.data.findIndex((obj) => obj.id === this.deviceId);
        const field = id;
        if (this.data[index]) this.data[index][field] = Number(status);
        //this.showToast(null, 'Alert Updated', 2000, 'success');
        this.showToast(
          null,
          this._translate.instant('alerts.alertUpdatedMessage'),
          2000,
          'success',
        );
      })
      .catch((e) => {
        this.showToast(
          null,
          this._translate.instant('alerts.alertNotUpdatedMessage'),
          2000,
          'danger',
        );
      });

    if (geofenceChecked) {
      if (Number(status) === 1) {
        this.map.showGeoFences(this.device);
      } else {
        this.map.hideGeoFences(this.device);
      }
    }
  }
  myToast: any = null;
  async showToast(title, text, time, colorType) {
    if (this.myToast) {
      this.myToast.dismiss();
    }

    this.myToast = await this.toast.create({
      message: text,
      duration: time,
      color: colorType,
      position: this.toastPosition,
      header: title,
      cssClass: 'centerToast',
    });

    this.myToast.present();
  }

  // hideGeoFences(deviceMarker, draw, map) {
  //   this.map.geofenceSource.features.forEach(geofence => {
  //     let geofenceID = geofence.id.split('_');
  //     if (this.device.id === + geofenceID[1]) {
  //       this.map.geofenceDrawer.delete(geofence.id);
  //       this.centerPoint.removeCenterPoint(geofence.id);
  //     }
  //   });
  // }

  // showGeoFences() {
  //   this.device.geofences.forEach( geo => {
  //     if (geo.status && this.device.properties.deviceshow) {
  //       const geometryShape = this.centerPoint.getGeometryShape(geo);
  //       const feature = this.centerPoint.getShapeFeature(geo, geometryShape);
  //       this.draw.add(feature);

  //       var obj = JSON.parse(geo.options);

  //       var obj = JSON.parse(geo.options);
  //       if (obj.fillColor == 'undefined') {
  //         obj.fillColor = '#000';
  //       }
  //       var draw = this.draw;
  //       var shape = draw.get(geo.id.toString() + '_' + geo.iddevice.toString());

  //       draw.add(draw.get(geo.id.toString() + '_' + geo.iddevice.toString()));
  //       draw.setFeatureProperty(geo.id.toString() + '_' + geo.iddevice.toString(), 'portColor', obj.fillColor);
  //       this.centerPoint.createSinglePoint(shape, this.map, this.draw, this.deviceMarkers);
  //     }
  //   })
  // }

  enableGeofence(status, geo) {
    if (!this.permissions.modify) return null; //_ No do noothing if doesnt has permission to modify
    var device = this.deviceService.devices[this.deviceId];
    if (device.properties.deviceshow) {
      var geoId = `${geo.id}_${this.deviceId}`;
      if (status) {
        geo.status = 1;

        const geoIndx = device.geofences.findIndex((obj) => obj.id === geo.id);
        let geofence = device.geofences[geoIndx];

        if (geofence.status && device.properties.deviceshow) {
          const geometryShape = this.centerPoint.getGeometryShape(geofence);
          const feature = this.centerPoint.getShapeFeature(
            geofence,
            geometryShape,
          );
          this.draw.add(feature);

          var obj = JSON.parse(geofence.options);

          var obj = JSON.parse(geofence.options);
          if (obj.fillColor == 'undefined') {
            obj.fillColor = '#000';
          }
          var draw = this.draw;
          const featureId =
            geofence.id.toString() + '_' + geofence.iddevice.toString();
          let shape = draw.get(featureId);

          draw.add(
            draw.get(
              geofence.id.toString() + '_' + geofence.iddevice.toString(),
            ),
          );
          draw.setFeatureProperty(featureId, 'deviceId', device.id);
          draw.setFeatureProperty(
            featureId,
            'deviceShow',
            device.properties.deviceshow ? 1 : 0,
          );
          draw.setFeatureProperty(
            featureId,
            'deviceName',
            device.properties.name,
          );
          draw.setFeatureProperty(featureId, 'portColor', obj.fillColor);

          this.centerPoint.createSinglePoint(
            shape,
            this.map,
            this.draw,
            this.deviceMarkers,
          );
        }
      } else {
        geo.status = 0;
        this.map.geofenceDrawer.delete(geoId);
        this.centerPoint.removeCenterPoint(geoId);
      }

      this.authService
        .updateGeofenceStatus(+geo.id, +this.deviceId, 'status', +status)
        .then((res) => {
          if (res) {
            if (res.success) {
              res = res.success;
              this.deviceService.setGeofence(res);
              this.appService.showToast(
                '',
                this.translate.instant('alerts.geofenceUpdateSuccessMessage'),
                2000,
                'success',
                this.toastPosition,
              );
            }
          }
        });
    }
  }

  updateGeofence(geo, value, property) {
    if (!this.permissions.modify) return null; //_ No do noothing if doesnt has permission to modify
    this.authService
      .updateGeofenceStatus(+geo.id, +this.deviceId, property, +value)
      .then((res) => {
        if (res) {
          if (res.success) {
            res = res.success;
            this.deviceService.setGeofence(res);
            this.appService.showToast(
              '',
              this.translate.instant('alerts.geofenceUpdateSuccessMessage'),
              2000,
              'success',
              this.toastPosition,
            );
          }
        }
      });
  }

  // getShapeFeature(geofence, geometryShape) {
  //   const feature = {
  //     id: geofence.id.toString() + '_' + geofence.iddevice.toString(),
  //     type: 'Feature',
  //     properties: {},
  //     geometry: geometryShape
  //   };
  //   const obj = JSON.parse(geofence.options);
  //   let properties;
  //   switch (geofence.shape_type) {
  //     case 1: // draw circle geomtry
  //       const lnglat = [0, 0];
  //       const circleProperties = { center : [], isCircle: true, radiusInKm: 0};
  //       const options = JSON.parse(geofence.options);

  //       let cc = options.center.split('[');
  //       cc = cc[1].split(',');
  //       lnglat[0] = +cc[0];
  //       cc = cc[1].split(']');
  //       lnglat[1] = +cc[0];
  //       circleProperties.center = lnglat;
  //       circleProperties.radiusInKm = + options.radius;
  //       circleProperties.radiusInKm = circleProperties.radiusInKm / 1000; // in kilometers
  //       properties = circleProperties;
  //       break;
  //     case 2: // draw rectangle
  //       properties = {};
  //       break;
  //     case 3: // draw polygon
  //       properties = {};
  //       break;
  //     default:
  //       break;
  //   }
  //   feature.properties = properties;
  //   return feature;
  // }

  // getGeometryShape(geofence) {
  //   let geometry;

  //   const coordinatesArray = JSON.parse('[' + geofence.coordinates + ']');

  //   geometry = {
  //         type: 'Polygon',
  //         coordinates: coordinatesArray
  //       };

  //   return geometry;
  // }

  updatingVoltage = false;

  updatingSpeed = false;
  async updateSpeed($event, alarmName) {
    let alarmUpdatedText = 'alerts.speedUpdateMessage';
    if (!this.permissions.modify) return null;
    if (alarmName === 'alarm_volt_value') {
      this.updatingVoltage = true;
      alarmUpdatedText = 'alerts.voltUpdateMessage';
    } else if (alarmName === 'alarmgeschwindigkeitab') {
      this.updatingSpeed = true;
      alarmUpdatedText = 'alerts.speedUpdateMessage';
    }

    //_ Convert speed if user settings is miles to -> km
    let speed = $event;
    if (alarmName == 'alarm_volt_value') {
      if (this.appService.language != 'en') {
        speed = speed.replace(/,/g, '.');
      }
    }

    if (
      this.appService.user.distance_unit != 'km' &&
      alarmName === 'alarmgeschwindigkeitab'
    ) {
      speed = this.decimalPipe.transform($event * 1.60934, '1.0-0');
    }
    if (alarmName === 'alarmgeschwindigkeitab' && speed < 10) {
      const toast = await this.toastController.create({
        message: this.translate.instant('alerts.speedValidError'),
        duration: 3000,
        color: 'danger',
        cssClass: 'centerToast',
        position: this.toastPosition,
      });
      toast.present();

      this.updatingSpeed = false;
      return false;
    }
    await this.authService
      .updateDeviceAlarms(this.deviceId, alarmName, speed)

      .then(async (res) => {
        const index = this.data.findIndex((obj) => obj.id === this.deviceId);

        this.data[index][alarmName] = speed;
        this.deviceAlarmsValues = res.success;

        const toast = await this.toastController.create({
          message: this.translate.instant(alarmUpdatedText),
          duration: 2000,
          color: 'success',
          cssClass: 'centerToast',
          position: this.toastPosition,
        });
        toast.present();
      })
      .catch((e) => {});

    if (alarmName === 'alarm_volt_value') this.updatingVoltage = false;
    else if (alarmName === 'alarmgeschwindigkeitab') this.updatingSpeed = false;
  }

  async showAlarmInfo(_header, msg, img = null, event = null, useGif = false) {
    let header = this.translate.instant('alerts.' + _header);
    let message = this.translate.instant('alerts.' + msg);
    if (msg == 'speedingLimitInfoText') {
      let speed_unit =
        ' ' + this.translate.instant('speed.' + this.distance_unit + 'H');
      message = this.translate.instant('alerts.' + msg, {
        minSpeed: this.distance_unit == 'km' ? 10 + speed_unit : 6 + speed_unit,
      });
    }

    this.appService.showInfoPopoup(header, message, img, event, useGif);
  }

  async showGeofenceInfo(
    _header,
    msg,
    img = null,
    event = null,
    useGif = false,
  ) {
    let header = this.translate.instant('alerts.' + _header);
    let message = this.translate.instant('alerts.' + msg);

    this.showGeofencePopoup(header, message, img, event, useGif);
  }

  async showGeofencePopoup(
    title,
    description,
    image = null,
    event,
    useGif = false,
  ) {
    let popup = await this.popoverCtrl.create({
      componentProps: {
        title: title,
        description: description,
        image: image,
        useGif,
      },
      component: GeofenceinfopopupComponent,

      event: event,
      mode: 'ios',
    });

    await popup.present();
    popup = null;
  }
  async presentSelectDevice(geo) {
    const popup = await this.popoverCtrl.create({
      component: SelectDeviceComponent,
      componentProps: {
        data: this.data,
      },
      mode: 'ios',
      cssClass: 'device-popup',
      // event: ev,
      translucent: false,
    });

    await popup.present();

    const { data } = await popup.onDidDismiss();

    if (data) {
      let device = data.devices[0];
      let deviceIDs = data.devices.map((d) => d.id);

      let updatedDevice = this.devicedataService.devices[data.devices[0].id];
      let geoCordinates = JSON.parse(geo.coordinates);

      const geoOptions: any = JSON.parse(geo.options);
      const polyCoors = [geoCordinates];
      const polygon = turf.polygon(polyCoors);
      const centerPolygon = turf.centroid(polygon).geometry.coordinates;

      //_ Check if exist lastpoint otherwise use same center of the geofence
      let newCenter = this.checkLastlocation(updatedDevice.lastLocation)
        ? updatedDevice.lastLocation
        : centerPolygon;

      let newCoordinates = [];
      if (geo.shape_type == 1)
        newCoordinates = createCircle(centerPolygon, +geoOptions.radius / 1000);
      else newCoordinates = translatePolygon(polygon, centerPolygon, centerPolygon);

      geoOptions.center = JSON.stringify(centerPolygon);
      this.authService
        .createGeofences(
          deviceIDs,
          geo.shape_type,
          JSON.stringify(geoOptions),
          JSON.stringify(newCoordinates[0]),
        )
        .then((createdRes) => {
          if (createdRes) {
            this.devicedataService.getGeoFences(deviceIDs).then((res) => {
              let newGeo = geo;
              newGeo.coordinates = JSON.stringify(newCoordinates[0]);

              this.alertService.selectGeoZone({
                type: 'fitGeo',
                dismissed: true,
                closeBtn: '',
                device: device,
                shapeName: '',
                shapeColor: '',
                draw: this.draw,
                geoId: `${createdRes[0].id}_${device.id}`,
              });
            });
          }
        });
    }
  }

  drawShape(e) {
    if (!this.permissions.modify) return null; //_ No do noothing if doesnt has permission to modify

    var device = this.data.find((e) => {
      return e.id == this.deviceId;
    });

    if (device.deviceshow) {
      if (device.deviceshow && this.shapeName != '') {
        this.addGeozone('', '', this.shapeName, this.shapeColor);
        if (!this.geofencesAreDisabled) {
          this.enableDisableGeofences(!this.geofencesAreDisabled)
        }
        this.resetShapeColor();
      } else {
        e.preventDefault();
        return null;
      }
    } else
      this.appService.showToast(
        '',
        this._translate.instant('alerts.geofenceAlert'),
        3000,
        'danger',
        this.toastPosition,
      );
  }

  async centerGeo(e, geo, showPopup) {
    const index = this.data.findIndex((obj) => obj.id === this.deviceId);

    if(this.appService.userSettings.hide_all_devices_default){
      const toggleValues = {
        deviceId: this.data[index].id,
        checked: true,
      };
      await this.deviceListService.toggleDevice(toggleValues);

      setTimeout(()=>{
        this.selectGeoZone(this.data[index], geo, showPopup);
      }, 1700)
    }else if (!this.data[index].deviceshow) {
      this.data[index].deviceshow = !this.data[index].deviceshow;
      const toggleValues = {
        deviceId: this.data[index].id,
        checked: this.data[index].deviceshow,
      };
      await this.deviceListService.toggleDevice(toggleValues);
      await this.devicedataService.updateDeviceInfo(this.devicedataService.devices[this.data[index].id]);

      this.selectGeoZone(this.data[index], geo, showPopup);
    } else {
      this.selectGeoZone(this.data[index], geo, showPopup);
    }
  }

  selectGeoZone(device, geo, showPopup) {
    setTimeout(() => {
      this.alertService.selectGeoZone({
        type: 'fitGeo',
        dismissed: true,
        closeBtn: '',
        device: device,
        shapeName: '',
        shapeColor: '',
        draw: this.draw,
        geoId: `${geo.id}_${this.deviceId}`,
      });
      if (showPopup) {
        this.openGeofencePopup(geo);
      }
    }, 500);

  }

  openGeofencePopup(geo) {
    const featureId = geo.id + '_' + geo.iddevice;
    const feature = this.draw.get(featureId);
    if (feature) {
      const polygon = turf.polygon(feature.geometry.coordinates);
      const centroid = turf.centroid(polygon);
      const event = {
        lngLat: {
          lng: centroid.geometry.coordinates[0],
          lat: centroid.geometry.coordinates[1],
        },
      };
      this.centerPoint.mouseUp.next({ feature, polygon, centroid, event });
    }
  }

  addGeozone(
    type: string = '',
    closeBtn = '',
    shapeName = '',
    shapeColor = '#000',
  ) {
    this.storage.set('currentModal', '');
    const index = this.data.findIndex((obj) => obj.id === this.deviceId);

    this.alertService.addGeoZone({
      type,
      dismissed: true,
      closeBtn,
      device: this.data[index],
      shapeName,
      shapeColor,
      draw: this.draw,
    });
  }

  changeColor(event, id) {
    var geoId = `${id}_${this.deviceId}`;
    var color = event.target.value;
    this.draw.setFeatureProperty(geoId, 'portColor', color);
    var feat = this.draw.get(geoId);
    this.draw.add(feat);
    this.updateGeofenceInBackend(feat);
    event.target.style.backgroundColor = event.target.value;
  }

  updateGeofenceInBackend(polygon) {
    this.alertService.updateGeofence(polygon).then((r) => {
      this.deviceService.setGeofence(r);
    });
  }

  async deleteGeo(event, id) {
    if (
      await this.appService.confirmDialogPopup(
        this.translate.instant('geofence.deleteGeofence'),
        this.translate.instant('geofence.sureToDeleteGeofence'),
      )
    ) {
      var geoId = `${id}_${this.deviceId}`;
      event.target.parentElement.parentElement.remove();
      this.map.geofenceDrawer.delete(geoId);
      this.centerPoint.removeCenterPoint(geoId);
      this.map.geofenceSource.features = this.map.geofenceSource.features.filter(obj => obj.id !== geoId);

      let removeIndex = this.device.geofences
        .map((item) => item.id)
        .indexOf(+id);

      if (removeIndex > -1) {
        this.device.geofences.splice(removeIndex, 1);
        this.devicedataService.updateDeviceInfo(this.device);
      }

      //_ Delete map popup
      const popups = document.getElementsByClassName("mapboxgl-popup maplibregl-popup new-map-popup");

      Array.prototype.forEach.call(popups, function(popup) {
        popup.remove();
      });

      this.authService.deleteGeofence(+id, +this.deviceId);
    }
  }

  drawFunctions() {}

  selectedGeoType = {
    type: 'circle',
    name: this.translate.instant('alerts.circleText'),
    image: 'assets/images/alarm/circle.svg',
  };
  shapeName = 'circle';
  async selectGeoType(ev, type = null) {
    const geoType = await this.popoverCtrl.create({
      component: GeoSelectComponent,
      componentProps: {
        selectedItem: this.selectedGeoType,
      },
      mode: 'ios',
      event: ev,
    });

    await geoType.present();
    const { data } = await geoType.onWillDismiss();
    if (data) {
      this.shapeName = data.geo.type;
      this.selectedGeoType = data.geo;
    }
  }

  async saveForAllDevices() {
    if (!this.permissions.modify) return null; //_ No do nothing when doesnt has permission to modify

    let tempAlarmValues = this.deviceAlarmsValues;
    var aDevices = this.data;
    for (let i = 0; i < aDevices.length; i++) {
      this.data[i].alarmbewegung = tempAlarmValues.alarmbewegung; // Shock Alert
      this.data[i].alarmstromunterbrechung =
        tempAlarmValues.alarmstromunterbrechung; // Power-Cut Alert
      this.data[i].alarmzuendalarm = tempAlarmValues.alarmzuendalarm; //Ignition Alert
      this.data[i].alarmgeschwindigkeit = tempAlarmValues.alarmgeschwindigkeit; //speeding alert
      this.data[i].alarmsos = tempAlarmValues.alarmsos; //SOS alert
      this.data[i].alarmakkuwarnung = tempAlarmValues.alarmakkuwarnung; // Low Battery Alert
      this.data[i].alarmgeschwindigkeitab =
        tempAlarmValues.alarmgeschwindigkeitab; //speeding alert
    }

    const data = {
      alarmbewegung: tempAlarmValues.alarmbewegung,
      alarmstromunterbrechung: tempAlarmValues.alarmstromunterbrechung,
      alarmzuendalarm: tempAlarmValues.alarmzuendalarm,
      alarmgeschwindigkeit: tempAlarmValues.alarmgeschwindigkeit,
      alarmsos: tempAlarmValues.alarmsos,
      alarmakkuwarnung: tempAlarmValues.alarmakkuwarnung,
      alarmgeschwindigkeitab: tempAlarmValues.alarmgeschwindigkeitab,
    };

    await this.authService
      .updateAllDevices(data, this.appService.userId)
      .then((r) => {
        this.appService.showToast(
          '',
          this.translate.instant('geofence.alertUpdatedforAllDevices'),
          2000,
          'success',
          this.toastPosition,
        );
      })
      .catch((e) =>
        this.appService.showToast(
          '',
          this.translate.instant('geofence.errorTryingUpdateDeviceData'),
          3000,
          'danger',
          this.toastPosition,
        ),
      );
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async setName(ev, geo) {
    if (geo.name != geo.prevName) {
      this.authService
        .updateGeofence(
          geo.id,
          geo.iddevice,
          geo.coordinates,
          geo.shape_type,
          geo.options,
          geo.name,
        )
        .then((res) => {
          if (res) {
            if (res.success) {
              res = res.success;
              this.deviceService.setGeofence(res);
              this.selectedDeviceGeoList.find((g) => geo.id == g.id).prevName =
                res.name;
              this.appService.showToast(
                '',
                this.translate.instant('alarms.nameUpdated'),
                2000,
                'success',
                this.toastPosition,
              );
            }
          }
        });
    }
  }

  async updateName(ev, geo) {
    geo.name = ev.detail.value;
  }
  async enableAlerts() {
    await this.authService
      .updateDeviceAlarms(this.deviceId, 'alarm_enabled', 1)
      .then((res) => {
        this.deviceService.devices[this.deviceId].properties.alarm_enabled =
          res['success'].alarm_enabled;
        this.device.properties.alarm_enabled = res['success'].alarm_enabled;
        this.deviceService.updateDeviceInfo(this.device);
        //this.showToast(null, this._translate.instant("enableDisableAllAlerts.allUpdated"), 2000, 'success');
      })
      .catch((e) => {
        this.showToast(
          null,
          this._translate.instant('enableDisableAllAlerts.errorWhileUpdating'),
          2000,
          'danger',
        );
      });
  }

  async showShockPopup() {
    //_ If is not model included in models OR dont show again popup is true GO OUT
    const shouldShowForThisModel =
      this.alertService.SHOCK_ALERT_MODELS.includes(this.deviceModel.model_nr);
    if (
      !shouldShowForThisModel ||
      this.alertService.DONT_ASK_AGAIN_SHOCK_ALERT_POPUP.get().value
    )
      return;

    let popup = await this.popoverCtrl.create({
      componentProps: { deviceId: this.deviceId },
      component: ShockAlertPopupComponent,
      cssClass: 'paj-popover-template',
      mode: 'ios',
    });

    popup.present();
    const { data } = await popup.onWillDismiss();

    if (data) {
      if (data.setupBattery) {
        const index = this.data.findIndex((obj) => obj.id === this.deviceId);

        //_ If setupBattery; apply changes to deviceAlarmValues and the data[index] device
        this.data[index]['alarm_volt'] = this.deviceAlarmsValues['alarm_volt'] =
          1;
        this.data[index]['alarm_volt_value'] = this.deviceAlarmsValues[
          'alarm_volt_value'
        ] = '11.3';
        this.updateAlarmVoltValue();
      }
    }
  }

  checkLastlocation(lastLocation) {
    return lastLocation ? lastLocation[0] : null;
  }

  getPickr() {
    if (this.pickrGL?.getRoot()) {
      this.pickrGL.destroyAndRemove();
      this.pickrGL = null;
    }

    const pickr = Pickr.create({
      el: '.color-button',
      // el:".color-box1",
      theme: "nano",
      // container: "app-device-settings-tab",
      default: this.shapeColor,
      //feature?.properties.portColor
      useAsButton: true,
      appClass: "pickr-border",
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: true,
          rgba: false,
          input: true,
          hsva: false,
          cancel: true,
          save: true
        }
      },
      i18n: {
        'btn:save': this._translate.instant("alerts.saveText"),
        'btn:cancel': this._translate.instant("alerts.cancel")
      }
    });

    pickr.on('save', (color, instance) => {

      const selectedColor = color.toHEXA().toString();
      this.shapeColor = selectedColor;

      pickr.destroyAndRemove();
    })
    pickr.show();
    this.pickrGL = pickr;
  }

  isFirstInstance(id: number, index: number): boolean {
    return this.selectedDeviceGeoList.findIndex(item => item.id === id) === index;
  }

  async showAlertInfoPopup(id) {
    let show = false;
    let infoText = '';
    if (id == 'alarmbewegung') { // vibration alarm
      show = !this.alertService.DONT_REMIND_AGAIN_SHOCK_ALERT_POPUP.get().value;
      infoText = 'alerts.shockInfoText'
    }
    else if (id == 'alarmsos') { // Removal alarm
      show = !this.alertService.DONT_REMIND_AGAIN_REMOVAL_ALERT_POPUP.get().value;
      infoText = 'alerts.removeFinderInfoText'
    }

    if (show) {
      let popup = await this.popoverCtrl.create({
        componentProps: { infoText: infoText, alertId: id },
        component: AlertInfoPopupComponent,
        cssClass: 'paj-popover-template',
        mode: 'ios',
      });

      popup.present();
    }
  }

  resetShapeColor() {
    if (this.device.color) {
      this.shapeColor = this.device.color;
    }
  }

  async openDeviceGuard() {
    await this.supportLinkService.openDeviceGuard();
  }
}
