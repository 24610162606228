import { Injectable, Pipe, PipeTransform, NgZone } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { MediaApiService } from '../components/media-managment/media-api.service';
import { environment as ENV } from 'src/environments/environment';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { MapService } from '../members/map/components/map/map.service';
import { ThreedModels } from '../members/map/components/map/class/features/3dModels';
import { ImageStackManagament } from '../members/map/components/map/class/imageStackManagament';


@Pipe({
    name: 'DeviceImage'
})

export class DeviceImagePipe implements PipeTransform { //duration in seconds
    constructor(private imageService: DeviceImageService) { }

    transform(device, userId): any {
        if (!device) return this.imageService.getAssetImage('paj_iconset_auto5');
        return this.imageService.getImage(device, userId);
        // return new Observable( obs => {
        //     this.imageService.getImage(device, userId).subscribe( (img:any) => {
        //     obs.next( this.domSanitizer.bypassSecurityTrustUrl(img) );
        //     });
        // });
    }
}


@Injectable({
    providedIn: 'root'
})
export class DeviceImageService {
    assetsPath = 'assets/device_markers/';
    serverUrl = ENV.ROUTE;
    whitePicture = '';
    defaultDeviceImage = 'assets/device_markers/paj_iconset_logo.svg';

    //_ Array to store all previous images requested; to not request existent images loaded before
    hashImages = [];
    imageStackManagament = new ImageStackManagament();
    constructor(private mediaApi: MediaApiService, private appService: AppService,
        private mapService: MapService, private zone: NgZone) {
            this.whitePicture = this.mediaApi.whitePicture;
         }

    getImage(device, userId = null) {
        //_ Return default image if is enable in settings
        // console.log('[DEBUG] SHOW DEFAULT IMAGE', this.mapService.showDefaultImage.value)
        if (this.mapService.showDefaultImage.value)
            return this.getAssetImage('paj_iconset_logo.svg');

        // console.log('[DEBUG] DEVICE IMAGE', { id: device.id, iconcustomimage: device.iconcustomimage, iconusecustom: device.iconusecustom })
        if ((device.iconusecustom == 1 && device.iconcustomimage ) || device.iconusecustom == 2)
            return this.getCustomImage(device, userId);
        else
            return this.getAssetImage(device.iconname);
    }

    getAssetImage(iconName) {
        let extention = '.png';
        return new Observable(observer => {
            if (iconName) {
                if (iconName.startsWith('paj_iconset')) { //_ Use .svg files
                    extention = '.svg';

                    if (iconName.endsWith('32px')) //_ Delete 32px from the name
                        iconName = iconName.slice(0, -4);
                }
            }

            // observer.next( this.assetsPath + iconName + extention );
            const imgUrl = this.assetsPath + iconName + extention;
            // const host = window.location.protocol + '//' + window.location.host;

            if (this.hashImages[imgUrl]) {
                observer.next(this.hashImages[imgUrl]);
                observer.complete();
                return;
            }

            this.imageStackManagament.imageExists(imgUrl, iconName).then(exists => {
                if (exists) {
                    this.hashImages[imgUrl] = imgUrl;
                    observer.next(imgUrl);
                    observer.complete();
                    return;
                } else {
                    let breakPicture = this.defaultDeviceImage; // for light
                    // if ( this.appService.darkMode )
                    //     breakPicture =  "assets/device_markers/broken-picture.svg" // for dark
                    this.hashImages[imgUrl] = breakPicture;
                    observer.next(breakPicture);
                    observer.complete();
                }
            })

            // observer.complete();
        });
    }

    //_ @Return async observable object
    getCustomImage(device, userId) {
        //_ Return 3d model image
        if (device.iconusecustom == 2 && this.mapService.use3dMarkers.value)
            return this.get3dImage(device);
        else{
            const imageObject = this.mediaApi.createImageObject('custom_markers', device.iconcustomimage);
            return this.mediaApi.getImageData(imageObject);
        }
    }

    get3dImage(device){
        return new Observable(observer => {
            this.zone.run(() => {
                const obj = ThreedModels.find(o => o.name == device.threedModel_name);
                observer.next(obj.img)
                return { unsubscribe() { } };
            });
        });
        // return new Promise ( (resolve) =>
        //     resolve(ThreedModels.find(o => o.name == device.threedModel_name).img)
        // );
    }

    async imageExists(url) {
        return new Promise((resolve) => {
            //_ Check first if exist otherwise should load and check if exist or not.
            if (this.hashImages[url])
                resolve(true);

            let img = new Image();
            img.onload = () => { resolve(true); };
            img.onerror = () => { resolve(false); };
            img.src = url;
        })
    }

    doesFileExist(urlToFile) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', urlToFile, false);
        xhr.send();

        if (xhr.status == 404)
            return false;
        return true;
    }

}
