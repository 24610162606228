import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { BadgeService } from './badge.service';

//via @capacitor-community/fcm
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from '@capacitor/core';

//via @capacitor-firebase/messaging
/*
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { environment } from "src/environments/environment";
*/

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  isMobileApp = false;
  constructor(private authService: AuthenticationService, private badgeService:BadgeService) {
    this.isMobileApp = Capacitor.isNativePlatform() && (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
  }

  //via @capacitor-community/fcm
  async initPushNotifications(notificationsService) {
    if (!this.isMobileApp) return;

    console.log('Initializing HomePage');
    console.log('initNotitifications');
    let permission = await PushNotifications.requestPermissions();
    console.log('permission', permission);
    await PushNotifications.register();

    const firebaseToken = await this.getFcmToken();
    // console.log('FIREBASE TOKEN init fcm', firebaseToken);

    // Get FCM token instead the APN one returned by Capacitor
    PushNotifications.addListener('registration', async (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      console.log('registration', await this.getFcmToken());
      await this.authService.registerFBToken(await this.getFcmToken());
    });
    PushNotifications.addListener('registrationError', async (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
      console.log('registration', await this.getFcmToken());
      await this.authService.registerFBToken(await this.getFcmToken());
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        notificationsService.notifyNew.next(notification);
        this.badgeService.increaseBadge();
      },
    );
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }

  getFcmToken() {
    return new Promise((resolve, reject) => {
      if (!this.isMobileApp) reject('FCM Plugin is NOT SUPPORTED ON BROWSER');
      FCM.getToken()
        .then((r) => {
          resolve(r.token);
          console.log('token', r.token);
        })
        .catch((error) => {
          console.log('Error getting Token', error);
          reject(error);
        });
    });
  }

  createChannel(obj) {
    return new Promise((resolve, reject) => {
      if (!this.isMobileApp) reject('FCM Plugin is NOT SUPPORTED ON BROWSER');
      PushNotifications.createChannel(obj)
        .then((r) => {
          resolve(r);
          console.log('createChannel', r);
        })
        .catch((error) => {
          console.log('Error  create Channel', error);
          reject(error);
        });
    });
  }

  //via @capacitor-firebase/messaging
  /*
  async initPushNotifications() {
    let check = await FirebaseMessaging.checkPermissions();
    console.log('FcmService.initPushNotifications check', check);

    let req = await FirebaseMessaging.requestPermissions();
    console.log('FcmService.initPushNotifications req', req);

    this.getFcmToken();

    FirebaseMessaging.addListener("tokenReceived", (event) => {
      console.log("tokenReceived: ", event.token);
      this.authService.registerFBToken(event.token);
    });

    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log("notificationReceived: ", { event });
      //this.notificationService.notifyNew.next(notification);
      this.badgeService.increaseBadge();
    });
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", { event });
    });
    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        console.log("serviceWorker message: ", { event });
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
        });
        notification.onclick = (event) => {
          console.log("notification clicked: ", { event });
        };
      });
    }
  }

  public async getFcmToken() {
    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey,
    };
    if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("firebase-messaging-sw.js");
    }
    const { token } = await FirebaseMessaging.getToken(options);
    console.log('FcmService.getFcmToken token', token);
    this.authService.registerFBToken(token);
    return token;
  }

  createChannel(obj) {
    return new Promise((resolve, reject) => {
      FirebaseMessaging.createChannel(obj)
        .then((r) => {
          resolve(r);
          console.log('createChannel', r);
        })
        .catch((error) => {
          console.log('Error  create Channel', error);
          reject(error);
        });
    });
  }
  */
}
