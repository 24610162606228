<ion-header
  class="draggable-header-item"
  #header
  style="position: absolute; top: 0"
  Gesture-Detect
  [listenFor]="['pan']"
  (eventFired)="headerGestures($event)"
  id="device-swipe-header"
>
  <div class="ion-hide-md-up">
    <!-- <ion-buttons style="justify-content: center; height: 18px">
      <ion-button
        class="toggle-btn"
        fill="clear"
        color="light"
        (click)="enablePullup()"
      >
        <ion-icon
          [class.rotate]="pullupBreakpoint == 'top'"
          name="chevron-up-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons> -->
    <!-- <ion-buttons *ngIf="isOpeninPullup" style="margin: 0px; display: flex;justify-content: center;height: 18px;">
      <ion-button fill="clear" color="primary" (click)="enablePullup()">
        <ion-icon [name]="pullUpService.currentBreakPoint.value == 'top' ? 'chevron-down-outline':'chevron-up-outline'"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </div>

  <!-- EXPANDED TOOLBAR -->
  <ion-toolbar #deviceToolbar>
    <ion-buttons slot="start">
      <ion-back-button [text]="''" (click)="clearSelectedDevice()"></ion-back-button>
    </ion-buttons>

    <loading-indicator [hide]="slideDevices.length > 0"></loading-indicator>
    <!-- DEVICES SLIDER -->
    <swiper-container #sliders class="ion-no-padding slider-item" [config]="slideOpts" (swiperafterinit)="swiperReady($event)" (swiperslidechange)="deviceSwiped()">
      <swiper-slide *ngFor="let item of slideDevices" class="alarm-slide">
    <!-- <ion-slides [options]="slideOpts" (ionSlideDidChange)="deviceSwiped($event)" [pager]="false" #sliders class="ion-no-padding" >
      <ion-slide *ngFor="let item of slideDevices"> -->
        <ng-container [ngTemplateOutlet]="DEVICE_HEADER_TEMPLATE_EXPANDED" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </swiper-slide>
    </swiper-container>
  </ion-toolbar>

  <!-- COLLAPSED TOOLBAR -->
  <ion-toolbar color="transparent" [class.ion-hide]="!toolbarIsHide">
    <ion-buttons slot="start" >
      <ion-back-button [text]="''" color="primary" (click)="clearSelectedDevice()">{{ toolbarIsHide }} </ion-back-button>
    </ion-buttons>

    <loading-indicator [hide]="slideDevices.length > 0"></loading-indicator>
    <!-- DEVICES SLIDER -->
    <!-- <ng-container [ngTemplateOutlet]="DEVICE_HEADER_TEMPLATE_COLLAPSED" [ngTemplateOutletContext]="{ item: device }"></ng-container> -->
    <swiper-container #sliders class="ion-no-padding slider-item" [config]="slideOpts" (swiperafterinit)="swiperReady($event)" (swiperslidechange)="deviceSwiped()">
    <!-- <ion-slides [options]="slideOpts" (ionSlideDidChange)="deviceSwiped($event)" [pager]="false" #collapsedSliders> -->
      <swiper-slide *ngFor="let item of slideDevices">
        <ng-container [ngTemplateOutlet]="DEVICE_HEADER_TEMPLATE_COLLAPSED" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </swiper-slide>
    </swiper-container>
  </ion-toolbar>

  <ng-template #DEVICE_HEADER_TEMPLATE_EXPANDED let-item="item">
    <ion-item class="ion-no-padding no-click" lines="none">
      <div class="left-actions">
        <ion-img
          src="{{ item?.properties | DeviceImage : user_id | async }}"
          class="imageStyle"
          @zoomIn
        ></ion-img>
      </div>

      <ion-label class="ion-text-wrap" style="font-size: 15px">
        <h3 class="device-name">
          <!-- <span class="device-color" [style.background]="item?.color"></span> -->
          {{ item?.properties.name }}
        </h3>
        <p
          class="text-selectable device-imei"
          title="{{ item?.properties.imei }}"
          *ngIf="userSettings.show_imei_btn"
        >
          {{ item?.properties.imei }} &nbsp;
          <ion-icon
            (click)="copytoClipBoard(item?.properties.imei, $event)"
            color="medium"
            slot="end"
            name="copy-outline"
            class="enable-click"
          >
          </ion-icon>
        </p>
        <p class="text-selectable device-imei" *ngIf="show_imeis">
          {{ item?.deviceModel?.model }}
        </p>
      </ion-label>
      <ion-buttons>
        <ion-button
          class="btn-fly ion-no-margin enable-click"
          shape="circle"
          fill="clear"
          (click)="flyTo($event)"
          title="{{ 'sidemenuDrag.flyToDeviceLocation' | translate }}"
          id="devicelist-eye{{ item?.properties.id }}"
          [isButton]="true"
          [colorForIcon]="true"
          [backgroundContrast]="item?.properties.spurfarbe"
        >
          <ion-icon src="/assets/images/devices-list/map-pin.svg"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end" style="margin-left: 10px" class="right-slot">
        <div
          *ngIf="userSettings.show_all_devices_btn"
          class="toggle-spinner-div"
          style="margin-bottom: 3px"
        >
          <ion-toggle
            mode="ios"
            class="enableDisableDeviceToggle ion-no-padding  enable-click"
            @zoomIn
            label=""
            (click)="preventDefault($event)"
            [checked]="item?.properties.deviceshow ? true : false"
            (ionChange)="passToggleValue($event.detail.checked, item)"
            title="{{ 'sidemenuDrag.enableDisableDevice' | translate }}"
            id="showhide-device{{ item?.device_id }}"
            aria-label=""
          >
          </ion-toggle>
        </div>
        <div *ngIf="!disablingAlarms && userSettings?.show_alarms_btn" class="toggle-spinner-div"  style="margin-top: 3px">
          <ion-spinner color="primary" class="toggle-spinner" *ngIf="disablingAlarms" @zoomIn ></ion-spinner>

          <ion-toggle
            [ngStyle]="dragActive ? { transform: 'scale(0.6)' } : {}"
            class="allAlertToggle ion-no-padding enable-click"
            @zoomIn
            mode="ios"
            slot="end"
            (ionChange)="enableDisableAllAlerts($event, device)"
            (click)="preventDefault($event)"
            [checked]="device?.properties.alarm_enabled == 1 ? true : false"
            title="{{ 'sidemenuDrag.enableDisableAllAlerts' | translate }}"
            id="enabledisable-alert{{ device?.device_id }}"
            aria-label=""
          >
          </ion-toggle>
        </div>
        <!-- <div>
          <ion-button
            shape="round"
            fill="clear"
            *ngIf="!item?.properties.note && userSettings.show_note_btn"
            (click)="openNote(item?.properties, $event)"
            title="{{ 'deviceNote.addNote' | translate }}"
            @fadeIn
          >
            <ion-icon
              src="assets/images/devices-list/add-note.svg"
              style="width: 20px; height: 20px"
            ></ion-icon>
          </ion-button>

          <ion-button
            *ngIf="item?.properties.note"
            (click)="openNote(item?.properties, $event)"
            title="{{ 'deviceNote.note' | translate }}"
          >

            <ion-icon
              class="note-pin"
              src="assets/images/devices-list/pin.svg"
              [isButton]="true"
              [appyContrastToColor]="false"
              [applyToColor]="
                item?.properties.note_color == '#f4f5f8' ? false : true
              "
              [backgroundContrast]="item?.properties.note_color"
            >
            </ion-icon>
            <ion-icon
              src="assets/images/devices-list/sticky-note.svg"
              style="width: 20px; height: 20px"
            >
            </ion-icon>
          </ion-button>
        </div> -->
      </ion-buttons>
    </ion-item>
  </ng-template>

  <ng-template #DEVICE_HEADER_TEMPLATE_COLLAPSED let-item="item">
    <ion-item lines="none" color="transparent" class="ion-no-padding level-two">
      <div
        slot="start"
        (click)="DeviceSettings(item?.properties, $event)"
        class="left-actions"
        *ngIf="toolbarIsHide"
        @zoomIn
      >
        <ion-img
          src="{{ item?.properties | DeviceImage : user_id | async }}"
          class="imageStyle small"
          @zoomIn
        ></ion-img>
      </div>

      <ion-label
        class="ion-text-wrap"
        style="font-size: 15px; margin: 0"
        *ngIf="toolbarIsHide"
        @fadeIn
      >
        <h2 class="device-name">
          <!-- <span class="device-color" [style.background]="item?.color"></span> -->
          {{ item?.properties.name }}
        </h2>
      </ion-label>
    </ion-item>
  </ng-template>

</ion-header>

<ion-content
  #content
  [HideHeader]="header"
  [hideToolbar]="deviceToolbar"
  [isEnabled]="enableScroll"
  (topReached)="topReached($event)"
  (onWillHideShow)="onHideShow($event)"
  [threshold]="60"
  [class.no-scroll]="!enableScroll"
  [hasDragableHeader]="true"
>
  <!-- <div class="submenu-content" id="device-view-tab-content" [ngStyle]="{ 'transform' : toolbarIsHide ? 'translateY(68px)' : 'translateY(153px)' }"> -->
  <div 
    style="height: 100%; overflow: auto;"
    [ngStyle]="{ display: this.isSmallScreen && 'contents'}"
  >
    <div
      class="submenu-content"
      id="device-view-tab-content"
      [ngStyle]="{ 'margin-top': deviceToolbarHeight + 'px' }"
    >
      <app-device-submenu
        [deviceID]="device?.id"
        [isPullup]="isPullup"
        [item]="device?.properties"
        [expand]="true"
        *ngIf="device"
      ></app-device-submenu>
    </div>
  </div>
</ion-content>
