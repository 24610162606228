import { Injectable } from "@angular/core";
import { Constants } from "../constants.enum";
import { environment as ENV } from "src/environments/environment";
import { StorageService as Storage } from "./storage.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { PolylinePoints } from "../geo-json-template";
import { map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import * as moment from "moment";
import * as momentTZ from "moment-timezone/moment-timezone";
import { STORAGE_SETTINGS } from "./storage-settings";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  public cantReach = "Cant reach the server, sorry.";
  private noAuthenticated = {
    data: null,
    message: "No authenticated.",
    code: 0,
  };
  private noReach = {
    data: null,
    message: "Cant reach the server.",
    code: 400,
  }; // Error
  private serverError = {
    data: null,
    message: "Error from server.",
    code: 500,
  }; // Error

  constructor(
    public storage: Storage,
    public http: HttpClient) {}

  async checkAuth() {
    await this.storage
      .getFromLocalOrSession(Constants.TOKEN_KEY)
      .then(async (res) => {
        return true;
      })
      .catch((error) => {
        throw new Error("No token exist or no Authenticated");
      });
    return false;
  }

  async getDevices(viewcheck = null) {
    let url = "";
    if(viewcheck)
    {
      url = ENV.ROUTE + ENV.API_VERSION + 'device/shared/' + viewcheck; //GET CUSTOMER DATA IF IS IN GHOST MODE
    }
    else
    {
      await this.checkAuth();
      url = ENV.ROUTE + ENV.API_VERSION + "device"; //GET CUSTOMER DATA IF IS IN GHOST MODE
      if (await this.isGhostMode())
        url =
          ENV.ROUTE +
          ENV.API_VERSION +
          "a/device/" +
          (await this.storage.get(Constants.USER_ID));
    }

    return new Promise<any>((resolve, reject) => {
      this.http
        .get(url)
        .subscribe((res) => {
          resolve(this.requestResponse(res))
        }, (error) => {
          resolve(this.requestError(error));
        });
    });
  }

  async getDevice(idDevice) {
    await this.checkAuth();
    return new Promise<any>((resolve, reject) => {
    this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "device/" + idDevice)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }
  async getGeofence(geofenceId){
    await this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(ENV.ROUTE + ENV.API_VERSION + "geofence/singlegeofence/" + geofenceId)
        .subscribe((res) => {
          resolve(this.requestResponse(res));
        }, (error) => {
          resolve(this.requestError(error));
        });
      });
  }
  async getNotifications(lastId) {
    await this.checkAuth();
    let url =
      ENV.ROUTE +
      ENV.API_VERSION +
      "notifications/" +
      (await this.storage.get(Constants.USER_ID)) +
      "/" +
      lastId; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if ((await this.storage.get(Constants.IS_ADMIN)) == 1)
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        "a/notifications/" +
        (await this.storage.get(Constants.USER_ID)) +
        "/" +
        lastId;

    return new Promise<any>((resolve, reject) => {
      this.http.get(url)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  async getMoreNotifications(obj, viewCheck, extraProps = {}) {
    let url = "";
    if(viewCheck)
    {
      const queryEncodeParams = new URLSearchParams(extraProps).toString();
      url = ENV.ROUTE + ENV.API_VERSION + "more/notifications/shared/" + viewCheck + '?' + extraProps;
    }
    else
    {
      await this.checkAuth();
      let customerId = await this.storage.get(Constants.USER_ID);
      obj["customerId"] = customerId;
      url = ENV.ROUTE + ENV.API_VERSION + "more/notifications"; //GET CUSTOMER DATA IF IS IN GHOST MODE
      if ((await this.storage.get(Constants.IS_ADMIN)) == 1) {
        url = ENV.ROUTE + ENV.API_VERSION + "a/more/notifications";
      }
    }

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, obj)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  async getNotificationTypes(data, viewcheck = null) {
    let url = "";
    if(viewcheck)
    {
      url = ENV.ROUTE + ENV.API_VERSION + "notification/types/shared/" + viewcheck;
    }
    else
    {
      await this.checkAuth();
      url = ENV.ROUTE + ENV.API_VERSION + "notification/types"; //GET CUSTOMER DATA IF IS IN GHOST MODE
    }
    if ((await this.storage.get(Constants.IS_ADMIN)) == 1)
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        "a/notification/types/" +
        (await this.storage.get(Constants.USER_ID));

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(error)
      });
    });
  }

  //get user settings //
  async getUserSettings() {
    await this.checkAuth();
    let url = ENV.ROUTE + ENV.API_VERSION + "customerSetting"; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if ((await this.storage.get(Constants.IS_ADMIN)) == 1)
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        "a/customerSetting/" +
        (await this.storage.get(Constants.USER_ID));

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(url)
      .subscribe(res => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        reject(error);
      });
    });
  }

  async getDevicePoints(
    deviceID: number,
    dateStart: any = null,
    dateEnd: any = null,
    lastMinutes: any = null,
    lastPoints: any = null
  ) {
    await this.checkAuth();

    let getData = {
      lastMinutes: lastMinutes ? lastMinutes.toString() : 0,
      lastPoints: lastPoints ? lastPoints.toString() : 0,
      dateStart: dateStart ? dateStart.toString() : "",
      dateEnd: dateEnd ? dateEnd.toString() : "",
    };

    let url = ENV.API_VERSION + "trackerdata/" + deviceID;
    if (lastMinutes) url = url + "/last_minutes";
    if (lastPoints) url = url + "/last_points";
    if (dateStart && dateEnd) url = url + "/date_range";

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + url, { params: getData })
      .subscribe(res => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  async getLocationAddress(lat, lng) {
    if (!this.checkAuth()) this.noAuthenticated.message;

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(
        ENV.ROUTE + ENV.API_VERSION + "streetAddress?lat=" + lat + "&lon=" + lng
      )
      .subscribe((res) => {
        resolve({ data: res, message: "ok", code: 200 });
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  public async getElevation(lat, lng) {
    let url = ENV.ROUTE + ENV.API_VERSION + "elevation";
    if (!this.checkAuth()) this.noAuthenticated.message;

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, null, { params: { lat: lat, lon: lng } })
      .subscribe((res) => {
        resolve({ data: res, message: "ok", code: 200 });
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  public async updateDevice(deviceId, data) {
    await this.checkAuth();

    return new Promise<any>((resolve, reject) => {
      this.http
      .put(ENV.ROUTE + ENV.API_VERSION + "device/" + deviceId, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      },  (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  public async enableDisableDevice(status, data) {
    await this.checkAuth();

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "device/status/" + status, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  public async updateAllDevicesAlarms(status, data) {
    await this.checkAuth();

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "device/alarm-status/" + status, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  async getCustomer(UserID: number) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "customer/" + UserID + "?page=1")
      .subscribe((res) => {
        resolve(res);
      }, error => {
        resolve(null);
      });
    });
  }

  async updateCustomer(data, UserID: number) {
    return this.http
      .put(ENV.ROUTE + ENV.API_VERSION + "customer/" + UserID, data)
      .subscribe(
        (data) => {
          return data;
        },
        (error) => {
          return null;
        }
      );
  }

  async markNotifications(userId, notifications, isRead = 1) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "notifications/markAll/" + userId, {
        notifications,
        isRead,
      })
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    });
  }

  async deleteNotifications(userId) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .delete(ENV.ROUTE + ENV.API_VERSION + "notifications/deleteAll/" + userId)
      .subscribe((r) => {
        resolve(r);
      }, (error) => {
        resolve(null);
      });
    });
  }

  async deleteNotification(notId) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .delete(ENV.ROUTE + ENV.API_VERSION + "notifications/" + notId)
        .subscribe({
          next: (r) => resolve(r),
          error: (error) => reject(error)
        });
    });
  }

  async restoreSingleNotification(notId, userId = null) {
    let url = userId ? ENV.ROUTE + ENV.API_VERSION + `notifications/restore/${notId}/${userId}` :
        ENV.ROUTE + ENV.API_VERSION + `notifications/restore/${notId}`
    return new Promise<any>((resolve, reject) => {
      this.http
        .put(url, {})
        .subscribe({
          next: (r) => resolve(r),
          error: (error) => resolve(null)
        });
    });
  }

  async getTrashedNotifications() {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      return new Promise<any>((resolve, reject) => {
        this.http
          .get(ENV.ROUTE + ENV.API_VERSION + 'notifications/trash/' + userId )
          .subscribe({
            next: (r) => resolve(r),
            error: (error) => resolve(null)
          });
      });
    });
  }

  async getMoreTrashedNotifications(skip: number = 0) {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      return new Promise<any>((resolve, reject) => {
        this.http
          .get(ENV.ROUTE + ENV.API_VERSION + 'notifications/trash-more/' + userId + `/` + skip )
          .subscribe({
            next: (r) => resolve(r),
            error: (error) => resolve(null)
          });
      });
    });
  }

  async deleteTrashedNotifications() {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      return new Promise<any>((resolve, reject) => {
        this.http
          .delete(ENV.ROUTE + ENV.API_VERSION + 'notifications/deleteTrashedNotifications/' + userId )
          .subscribe({
            next: (r) => resolve(r),
            error: (error) => resolve(null)
          });
      });
    });
  }

  async getNotificationsCount(postData?) {
    const isLoggedIn = await this.checkAuth();
    // if (!isLoggedIn) return Promise.reject();

    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      return new Promise<any>((resolve, reject) => {
        if (userId) {
          this.http
          .post(ENV.ROUTE + ENV.API_VERSION + 'count/notifications/' + userId, postData)
          .subscribe({
            next: (r) => resolve(r),
            error: (error) => resolve(null)
          });
        } else {
          reject();
        }
      });
    });
  }

  /**********************************************  */
  //       Catch responses for log after
  /*_____________________________________________ */
  requestError(error: any) {
    // CATCH ERRORS for log after
    console.warn("Server error response");
    console.warn(error);

    //_ For promises this breaks the flow of reject promise
    // if (error.status == 0) throw new Error(this.cantReach);
    // throw new Error(error.message);
    return error;
  }

  serverNoReach() {
    //Catch Other errors types
    console.warn("Server no reach");
    throw new Error(this.cantReach);
  }

  requestResponse(response) {
    //Log responses
    return new Promise<any>((resolve) =>
      resolve({
        data: response["success"],
        response,
        new_data: response["success_new"],
        message: "ok",
        code: 200,
      })
    );
  }

  /************************** GET DEVICES DATAPOINTS FOR DASHBOARD **************************** */
  async getDevicesData(
    startDate,
    endDate,
    type,
    deviceId = null,
    lastPoint = false,
    distanceUnit = "km",
    useMongoStats = false
  ) {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      const postData = {
        startDate,
        endDate,
        type,
        deviceId,
        lastPoint,
        distanceUnit,
      };

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats) url = url + 'customer/trackerStats/devicesDataStats/max-speed/' + userId;
      else url = url + 'customer/dashboard/devicesData/' + userId;

      return new Promise<any>((resolve, reject) => {
        this.http
          .post(url, postData)
          .subscribe((res) => {
            resolve(this.requestResponse(res));
          }, error => {
            resolve(this.requestError(error));
          });
        });
      });
  }

  /************************** GET DEVICES DATAPOINTS FOR DASHBOARD DISTANCE CHART**************************** */
  async getDevicesDistanceData(
    startDate,
    endDate,
    type,
    deviceId = null,
    lastPoint = false,
    distanceUnit = "km",
    useMongoStats = false
  ) {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      const postData = {
        startDate,
        endDate,
        type,
        deviceId,
        lastPoint,
        distanceUnit,
        useMongoStats
      };
      return new Promise<any>((resolve, reject) => {
        let url = ENV.ROUTE + ENV.API_VERSION;
        if (useMongoStats) url = url + 'customer/trackerStats/devicesDataStats/distance/' + userId;
        else url = url + 'customer/dashboard/devicesData/distance/' + userId;
        this.http
          .post(url, postData)
          .subscribe((res) => {
            resolve(this.requestResponse(res));
          }, (error) => {
            resolve(this.requestError(error));
          });
      });
    });
  }

  /************************** GET MULTIPLE DEVICES DATAPOINTS FOR DASHBOARD **************************** */
  async getMultipleDevicesData(postData: any, useMongoStats = false, requestLastPoint = false) {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      // const postData = { startDate, endDate, type, deviceId, lastPoint};

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats && !requestLastPoint) url = url + 'customer/trackerStats/devicesDataStats/speed-by-date/' + userId;
      else url = url + 'customer/dashboard/multipleDevicesData/' + userId;
      return new Promise<any>((resolve, reject) => {
        this.http
          .post(url, postData)
          .subscribe((res) => {
            resolve(this.requestResponse(res));
          }, (error) => {
            resolve(this.requestError(error));
          });
      });
    });
  }

  async getTripRestData(deviceId, startDate, endDate, useMongoStats = false) {
    await this.checkAuth();
    return await this.storage.get(Constants.USER_ID).then(async (userId) => {
      const postData = {
        startDate,
        endDate,
        deviceId,
        startStr: moment.unix(startDate).format("YYYY/MM/DDTHH:mm:ss"),
        endStr: moment.unix(endDate).format("YYYY/MM/DDTHH:mm:ss"),
      };

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats) url = url + 'customer/trackerStats/triprest_data/' + userId;
      else url = url + 'customer/dashboard/triprest_data/' + userId;

      return new Promise<any>((resolve, reject) => {
        this.http
          .post(url, postData)
          .subscribe((res) => {
            resolve(this.requestResponse(res));
          }, (error) => {
            resolve(this.requestError(error));
          });
      });
    });
  }
  /************************************************************************* */

  async getPolySTR(points, routingOptions = null) {
    //get Polylines Snaped To Road
    let polys = [];
    points.forEach((p) => {
      polys.push({
        id: p.id ? p.id : Date.now(),
        lat: p.lat,
        lon: p.lng,
        time: p.dateunix,
        speed: p.speed,
        heading: p.direction ? p.direction : "",
        type: "",
        prevType: "",
      });
    });

    let postData = {
      shape: polys,
    };

    if (routingOptions)
      postData = { ...postData, ...routingOptions };

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "renderPoints", postData)
      .subscribe((res: any) => {
        resolve({ data: res, message: "ok", code: 200 });
      }, (error) => {
        reject(error)
        throw error;
      });
    });
  }

  async searchUser(searchKey: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.http
          .get(ENV.ROUTE + ENV.API_VERSION + "a/searchUser?userName=" + searchKey)
          .subscribe((res) => {
            resolve(res);
          });
      } catch (error) {
        resolve(error);
      }
    })
  }

  async searchAll(searchKey: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.http
        .get(ENV.ROUTE + ENV.API_VERSION + "a/searchBoth?userName=" + encodeURIComponent(searchKey))
        .subscribe((res) => {
          resolve(res);
        });
      } catch (error) {
        resolve(error);
      }
    })

  }
  getUsers() {
    return this.http.get(ENV.ROUTE + ENV.API_VERSION + "customer");
  }

  storedUser(data) {
    return this.http.post(ENV.ROUTE + ENV.API_VERSION + "customer", data);
  }

  UpdateUser(data, id) {
    return this.http.put(ENV.ROUTE + ENV.API_VERSION + `customer/${id}`, data);
  }

  addDevice(data) {
    return this.http.post(ENV.ROUTE + ENV.API_VERSION + "device", data);
  }

  saveDevice(data, id) {
    return this.http.put(ENV.ROUTE + ENV.API_VERSION + `device/${id}`, data);
  }

  async getDeviceGroupList() {
    let url = "";
    if (!(await this.isGhostMode())) {
      url = ENV.ROUTE + ENV.API_VERSION + "customerDeviceGroup";
    } else {
      await this.storage.get(Constants.USER_ID).then((res) => {
        const userId = res;
        url = ENV.ROUTE + ENV.API_VERSION + "a/customerDeviceGroup/" + userId;
      });
    }

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(url)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async getSortedDeviceGroupList(page, filters) {
    let url = "";
    if (!(await this.isGhostMode())) {
      url = ENV.FILE_ROUTE + ENV.API_VERSION + "DevicesGroup";
    } else {
      await this.storage.get(Constants.USER_ID).then((res) => {
        const userId = res;
        url = ENV.FILE_ROUTE + ENV.API_VERSION + "a/DevicesGroup/" + userId;
      });
    }

    filters["sortGroups"] = "desc";
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(url + "?page=" + page + "&" + this.encodeParameters(filters))
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async getOnlyGroups() {
    let url = "";
    if (!(await this.isGhostMode())) {
      url = ENV.ROUTE + ENV.API_VERSION + "getOnlyGroups";
    } else {
      await this.storage.get(Constants.USER_ID).then((res) => {
        url = ENV.ROUTE + ENV.API_VERSION + "a/getOnlyGroups/" + res;
      });
    }

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(url + "?sortGroups=desc")
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(error);
      });
    })
  }

  async postDeviceGroupList(postArr) {
    let url = "";
    // return await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async data => {
    if (!(await this.isGhostMode())) {
      url = ENV.ROUTE + ENV.API_VERSION + "customerDeviceGroup";
    } else {
      await this.storage.get(Constants.USER_ID).then((res) => {
        const userId = res;
        url = ENV.ROUTE + ENV.API_VERSION + "a/customerDeviceGroup/" + userId;
      });
    }
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, postArr)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }
  async updateOrder(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + "customerSortDeviceInGroup";
    if (await this.isGhostMode())
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        `a/customerSortDeviceInGroup/` +
        (await this.storage.get(Constants.USER_ID));

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async deleteGroup(group_id) {
    let url = ENV.ROUTE + ENV.API_VERSION + `customerDeleteGroup/${group_id}`;
    if (await this.isGhostMode())
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        `a/customerCreateDeviceGroup/${group_id}/user/` +
        (await this.storage.get(Constants.USER_ID));

    return new Promise<any>((resolve, reject) => {
      this.http
      .delete(url)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async createGroup(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + "customerCreateDeviceGroup";
    // await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async r => {
    if (await this.isGhostMode())
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        `a/customerCreateDeviceGroup/` +
        (await this.storage.get(Constants.USER_ID));
    // });

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async renameGroup(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + "customerUpdateDeviceGroup";

    // await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async r => {
    if (await this.isGhostMode())
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        "a/customerUpdateDeviceGroup/" +
        (await this.storage.get(Constants.USER_ID));
    // });

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async reorderGroups(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + "sortGroups";
    if (await this.isGhostMode())
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        `sortGroups/` +
        (await this.storage.get(Constants.USER_ID));

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async getRoute(points, costing = "auto") {
    // get Polylines Snaped To Road //costing = auto, pedestrian, motorcycle
    let polys = [];
    points.forEach((p) => {
      polys.push({ id: Date.now(), lat: p.lat, lon: p.lng });
    });

    const postData = { locations: polys, costing: costing };

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "valhalla/route", postData)
      .subscribe((res: any) => {
        if (res.trip) {
          resolve({ data: res, message: "ok", code: 200 });
        } else {
          resolve({ data: res, message: "ok", code: 200 });
        }
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async searchAddress(query) {
    //_ Language support(installed) for reverse geocoding server
    let lang = await this.storage.get(Constants.LANGUAGE);
    const supportedLanguages = ["en", "de", "fr", "it"];
    lang = supportedLanguages.includes(lang) ? "&lang=" + lang : "";

    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "addressQuery?q=" + query + lang)
      .subscribe((r: any) => {
        resolve(r);
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async getShareLinkUserData(data) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(
        `${ENV.ROUTE}${ENV.API_VERSION}deviceViewMode?viewcheck=${data.viewcheck}&iddevice=${data.iddevice}`
      )
      .subscribe((result) => {
        resolve(result);
      }, (error) => {
        reject(this.requestError(error));
      });
    })
  }

  renderPolyline(polylinePoints: PolylinePoints[]) {
    const postData = {
      shape: polylinePoints,
    };

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "renderPoints", postData)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(null);
      });
    })
  }

  checkForUpdate(version, platform) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(
        ENV.ROUTE +
          ENV.API_VERSION +
          "getAppUpdate?appName=pajfinderportalv2&type=" +
          platform
      )
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(null);
      });
    })
  }

  async dashboardDownload(
    startDate,
    endDate,
    deviceId,
    dsType,
    translations = {},
    useMongoStats = false
  ) {
    let userId;

    await this.storage.get(Constants.USER_ID).then((r) => (userId = r));
    const postData = {
      startDate,
      endDate,
      deviceId,
      dsType,
      timeZone: momentTZ.tz.guess(),
      translations: JSON.stringify(translations),
    };
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    headers.set("Content-Type", "application/download");

    let url = ENV.ROUTE + ENV.API_VERSION;
    if (useMongoStats) url = url + 'customer/trackerStats/downloadpdf/' + userId;
    else url = url + 'customer/dashboard/downloadpdf/' + userId;
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, postData,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .subscribe((data: Blob) => {
        resolve(new Blob([data], { type: "application/pdf" }));
      }, (error) => {
        reject(new Error("Server error response"));
      });
    })
  }

  async isAdmin() {
    return (await this.storage.get(Constants.IS_ADMIN)) == 1;
  }

  async isGhostMode() {
    return await this.storage.get(STORAGE_SETTINGS.ISGHOST).then((r) => {
      // Use for Ghost Mode
      return r;
    });
  }

  async exportRoutes(data) {
    await this.checkAuth();
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/" + data.type);
    //return this.http.get(url, { headers: headers, responseType: 'blob' });
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.FILE_ROUTE + ENV.API_VERSION + "route/export", data, {
        headers: headers,
        responseType: "blob",
      })
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  /*downloadFile(data, name, type) {
    const blob = new Blob([data], { type});

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);;
    downloadLink.download = name;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    //window.open(url);
  }*/
  postCustomerFeedBack(formData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .post<any>(ENV.ROUTE + ENV.API_VERSION + "customerFeedback", formData, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  getCustomerFeedbackMessages(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .get<any>(ENV.ROUTE + ENV.API_VERSION + "appImprovement", {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  reorderImprovements(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .post<any>(ENV.ROUTE + ENV.API_VERSION + "reorderImprovement", data, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  getCustomerFeedbacks(firstId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .get<any>(ENV.ROUTE + ENV.API_VERSION + "customerFeedback/" + firstId, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  getCustomerRatings(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .get<any>(ENV.ROUTE + ENV.API_VERSION + "customerRating", {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  postAppImprovement(formData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .post<any>(ENV.ROUTE + ENV.API_VERSION + "appImprovement", formData, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  putAppImprovement(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.http
      .put<any>(
        ENV.ROUTE + ENV.API_VERSION + "updateImprovement/" + data.id,
        data,
        { headers: headers }
      )
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  deleteAppImprovement(improvement_ids) {
    let url = ENV.ROUTE + ENV.API_VERSION + "deleteAppImp";
    return this.http.post(url, { improvement_ids }).pipe(
      tap(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        }
      )
    );
    // let headers = new HttpHeaders();
    // headers = headers.set('Accept', 'application/json');
    // return this.http.delete<any>(ENV.ROUTE + '/api/appImprovement', { headers: headers })
  }

  async getCommands(device_id) {
    let url = ENV.ROUTE + ENV.API_VERSION + `device/${device_id}/rc/`;
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(url)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async postCommandAlert(device_id, id) {
    let url = ENV.ROUTE + ENV.API_VERSION + `device/${device_id}/rc/${id}/fire`;
    return new Promise<any>((resolve, reject) => {
      this.http
      .put(url, null)
      .subscribe((res) => {
        resolve(this.requestResponse(res));
      }, (error) => {
        resolve(this.requestError(error));
      });
    })
  }

  async updateDriverImage(formData, userId) {
    const header = new HttpHeaders();
    header.append("Content-Type", "multipart/form-data");
    header.append("Accept", "application/json");
    let url = ENV.mediaServer + ENV.API_VERSION + `media/collections/${userId}`;

    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, formData, {
        headers: header,
        //reportProgress: true,
        //observe: "events",
      })
      .subscribe((data) => {
        resolve(data);
      }, error => reject(error))
    })
  }
  async updateDriver(driverId, data) {
    console.log("updateDriver triggered in api service")
    return new Promise<any>((resolve, reject) => {
      this.http
      .put(ENV.ROUTE + ENV.API_VERSION + `logbook/driver/${driverId}`, data)
      .subscribe((data) => {
        resolve(data);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    })
  }
  forgotPassword(data): Observable<any> {
    let headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .post<any>(ENV.ROUTE + ENV.API_VERSION + "forgotPassword", data, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  createNewPassword(data): Observable<any> {
    let headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .post<any>(ENV.ROUTE + ENV.API_VERSION + "resetPassword", data, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  getCustomerInfo(token) {
    let headers = new HttpHeaders().set("Accept", "application/json");
    // .set('Token', token)
    return this.http
      .get<any>(ENV.ROUTE + ENV.API_VERSION + "resetPassword?token=" + token, {
        headers: headers,
      })
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  //_ For who cares
  //_ Pipes are used to do something else|stop|wait|break before or while is running some subscription
  //_ tap pipe is mostly used to debbuging propose ;)
  getNotification(id, userId) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "notification/" + id + "/" + userId)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(null);
      });
    })
  }
  getLastStopData(deviceID) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "trackerdata/getlaststop/" + deviceID)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  deactivateAccount() {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "delete-customer")
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(error);
      });
    })
  }
  addManufacturer(data) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(ENV.ROUTE + ENV.API_VERSION + "addmakemodel", data)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  async addManufacturerAndModel(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + "addmakeandmodel"; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if ((await this.storage.get(Constants.IS_ADMIN)) == 1)
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        "a/addmakeandmodel/" +
        (await this.storage.get(Constants.USER_ID));
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(url, data)
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  addTrackerpointNote(deviceId, data) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(
        ENV.ROUTE + ENV.API_VERSION + "trackerData/" + deviceId + "/note",
        data
      )
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  getDeviceCommands(imei) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .get(ENV.ROUTE + ENV.API_VERSION + "device-commands/" + imei + "/remote")
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  sendDeviceCommand(imei, commandId, parameters = {}) {
    return new Promise<any>((resolve, reject) => {
      this.http
      .post(
        ENV.ROUTE +
          ENV.API_VERSION +
          "send-command/" +
          imei +
          "/command/" +
          commandId,
          parameters ?? {}
      )
      .subscribe((res) => {
        resolve(res);
      }, (error) => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  convertValuesToStrings(obj) {
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = obj[key].toString();
      }
    }
    return result;
  }

  sendVoiceCommand(data) {
    return this.http.post(ENV.ROUTE + ENV.API_VERSION + "voice-messages/send/", data);
  }

  encodeParameters(data) {
    return Object.entries(data)
      .map((kv) => kv.map(encodeURIComponent).join("="))
      .join("&");
  }

  exportNotifications(data, customerId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/" + data.type);
    return this.http
      .post(
        ENV.FILE_ROUTE + ENV.API_VERSION + "route/export-notifications/" + customerId,
        data,
        { headers: headers, responseType: "blob" }
      )
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  deleteDevice(device_id: number): Observable<any> {
    return this.http
      .delete<any>(
        ENV.FILE_ROUTE +
          ENV.API_VERSION +
          `device/deletemobiletracker/${device_id}`
      )
      .pipe(
        tap(
          // Log the result or error
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getOptimizedDistanceChartByDevice(data, userId){
    // console.log('REQUESTING OPTIMIZED DISTANCE ' + data.deviceIds.length);
    // return of({ success: [{ id: -1, dataPoints: [] }] });
    return this.http.post(ENV.ROUTE + ENV.API_VERSION + "customer/dashboard/optimizedDistance/" + userId, data)
  }

  async getLogbookWhatsnew() {
    await this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + `logbookserveralerts`).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  async getSensorSupportDevice(userId: number) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + `sensordata/supportedDevices/${userId}`).subscribe(res => {
      resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  async exchangeLogbook(oldDeviceID, newDeviceID) {
    let data = null;
    return new Promise<any> ((resolve, reject) => {
      this.http.post(ENV.ROUTE + ENV.API_VERSION + 'exchangelogbook/' + oldDeviceID + '/' + newDeviceID, data).subscribe(res => {
        resolve(res);
      }, (error: HttpErrorResponse) => {
        console.log("ERROR: ", error);
        // return error;
        reject(error.error);
      });
    });
  }

  async updateInitialMileage(imei, initialMileage) {
    let data = {
      "imei": imei,
      "initial_mileage": initialMileage
    };
    return new Promise<any> ((resolve, reject) => {
      this.http.put(ENV.ROUTE + ENV.API_VERSION + 'a/car/initialMileage/' + imei, data).subscribe(res => {
        resolve(res);
      }, (error: HttpErrorResponse) => {
        console.log("ERROR: ", error);
        // return error;
        reject(error.error);
      });
    });
  }

  async getDeviceGuardToken() {
    //await this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + `deviceGuard`).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        resolve(null);
      });
    })
  }

  getPetStatsData(date, deviceId, userId) {
    const data = {
      startDate: moment(date).startOf('day').unix(),
      endDate: moment(date).endOf('day').unix(),
      deviceId: deviceId,
      type: 'bar',
      sensorType: 'steps'
    };

    return this.http.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/sensorData/${userId}`, data);
    // return this.http.post(ENV.ROUTE + ENV.API_VERSION + `getPetStats`, data);
  }

  getBpmStatsData(date, deviceId, userId) {
    const data = {
      startDate: moment(date).startOf('day').unix(),
      endDate: moment(date).endOf('day').unix(),
      deviceId: deviceId,
      type: 'bar',
      sensorType: 'bpm'
    };

    return this.http.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/sensorData/${userId}`, data);
    // return this.http.post(ENV.ROUTE + ENV.API_VERSION + `getPetStats`, data);
  }

  getWellnessData(date, deviceId, userId) {
    const data = {
      deviceId,
      date: moment(date).startOf('day').unix(),
    };

    return this.http.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/getWellnessData/${userId}`, data);
    // return this.http.post(ENV.ROUTE + ENV.API_VERSION + `getPetStats`, data);
  }

  getPetWeeklyStats(week, deviceId, userId) {
    const data = {
      startDate: moment(week).startOf('day').unix(),
      endDate: moment(week).endOf('day').unix(),
      deviceId: deviceId,
      type: 'bar',
      sensorType: 'steps'
    };

    return this.http.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/sensorData/${userId}`, data);
    // return this.http.post(ENV.ROUTE + ENV.API_VERSION + `getPetWeeklyStats`, data);
  }
}
