import { MapComponent } from "../../map.component";
import { AvailableHooks } from "../componentHooks";
import { Layer, layerType } from "../layer";
import { ImageWithBubble, svgToData } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import { environment as ENV } from './../../../../../../../environments/environment';
import { getSource } from "../castMaplibreData";
import { createSubAccountImage } from "./subAcctountsMarkersHelper";
import { ImageStackManagament } from "../imageStackManagament";
import { MainMapInterface } from "../../main-map.interface";

export class SubAccountsMarkers implements FeatureInterface {
    SOURCE_NAME: string = 'markersSource';
    LAYER_NAME: string = 'subAccountsLayer'
    source = null;
    layer = null;
    show = true;
    imageStackManager = new ImageStackManagament();
    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.source = this.main.markersSource;
        this.createLayer();
        this.load();
    }

    createLayer(){
        this.layer = new Layer(this.LAYER_NAME);
        this.layer.source = this.SOURCE_NAME;
        this.layer.type = layerType.symbol;
        this.layer.layout["icon-image"] = ["get", "subImage"];
        this.layer.layout["icon-rotate"] = ["get", "rotate"];
        this.layer.layout["text-anchor"] = "top";
        this.layer.layout["icon-size"] = 1 // ["get", "size"];
        this.layer.layout["icon-anchor"] = "bottom";
        this.layer.layout["text-field"] = ["get", "title"];
        this.layer.layout["text-offset"] = [0, 2]; //2
        this.layer.layout["icon-allow-overlap"] = true;
        this.layer.layout["text-allow-overlap"] = true;
        this.layer.layout["icon-rotation-alignment"] = "viewport"; //could be viewport|map
        this.layer.paint['icon-translate'] = [25, -20];

        this.layer["filter"] = [
            "all",
            ["==", ['get', "deviceShow"], 1], //_ Show only if deviceShow == 1
            ['==', ['get', 'hasSubAccounts'], true],
            ["has", ['get', 'subImage']],
            ['==', ['get', 'show'], true],
            [
                'any',
                ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
                ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
            ]
        ];
    }

    //_ Listen for Component hooks to load data
    load(){
        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'clusterMarkers');
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onMarkerAdded, async (data) => {
            if (!data.device) return;
            //_ prevent create images if feature is disabled
            if (!this.show) 
                return;
            
            const device = data.device;
            const sourceIndex = this.main.markersSource.data.features.map((x: any) => x.properties['deviceId']).indexOf(device.id);
            const sourceFeature = this.main.markersSource.data.features[sourceIndex];
            
            sourceFeature.properties['hasSubAccounts'] = device.properties.subAccounts?.length > 0 || 0;

            if (sourceFeature.properties['hasSubAccounts']){
                const img: any = await createSubAccountImage(device.properties.subAccounts, this.imageStackManager); //await svgToData(imgPath, 300, 300);

                const imageName = 'subAccount_'+device.properties.id + '_' + device.properties.subAccounts[0].id;
                //_ Verify if exist the image in other icon property of the marker
                let imageAdded = !(this.main.markersImages.map((x) => x.name).indexOf(imageName) == -1);

                if (!imageAdded) {
                    await this.uploadImage(imageName, img).then( r => {
                        if (r){
                            sourceFeature.properties['subImage'] = imageName;
                            sourceFeature.properties['show'] = this.show;
                            sourceFeature.properties['isSliderViewOpen'] = this.main.isSliderViewEnable;
                            this.main.markersSource.data.features[sourceIndex] = sourceFeature;
                            
                            if (this.main.map.getSource(this.SOURCE_NAME) && data.updateMap)
                                getSource(this.main.map, this.SOURCE_NAME).setData(this.main.markersSource.data);
                            return ;
                        }
                    });
                }
                
            }
        });

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
            this.setSliderView(e);
        });

        if (this.main.mapService){
            this.main.mapService.showSubAccountMarkers.subscribe( status => {
                this.show = status;
                this.enableDisable(status);
            })
        }
    }

    async uploadImage(name, dataImage){
        return new Promise ( async (resolve, reject) => {
            await this.main.map.loadImage(dataImage, async (error, image) => {
                if (error){ 
                    console.log("ERROR: ", error);
                    reject();
                }
                else{
                    if (!this.main.map.hasImage(name)) {
                        await this.main.map.addImage(name, image);
                        this.main.markersImages.push({ name: name, url: dataImage });
                        resolve(true);
                    }
                }
            });
        });
    }

    enableDisable(status){
        if (this.main.markersSource){
            this.main.markersSource.data.features.forEach((f) => {
                f.properties["show"] = status;
            });
        
            // And then update the source in the map :)
            if (this.main.map.getSource(this.SOURCE_NAME))
                getSource(this.main.map, this.SOURCE_NAME).setData(this.main.markersSource.data);
        }
    }

    setSliderView (ev) {
        // NO NEED THIS BECAUSE USES SAME MARKERS SOURCE FROM MAIN MAP
        // //_ Filter source
        // this.source.data.features.forEach((f) => {
        //     f.properties["isSliderViewOpen"] = ev.isEnable;
        // });
    
        // //_ Update source
        // if (this.main.map.getSource(this.SOURCE_NAME))
        //     getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }


}