<ion-header mode="ios" *ngIf="!hideHeader">
  <ion-toolbar color="dark" mode="ios">
    <ion-title>{{ "dashboard.AlarmActivtiy" | translate }} </ion-title>
    <ion-buttons *ngIf="isSmallScreen || petmode" slot="end">
      <ion-button (click)="close()">
        <ion-icon
          slot="icon-only"
          name="close-circle-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar color="light" mode="ios">
    <ion-buttons class="submenuTabButtonsWrapper">

      <ion-button [color]="selectedTab == 'activity' ? 'primary' : 'dark'" shape="round" fill="solid" (click)="selectTab('activity')">
        <div class="submenuTabButtonsInner">

        <ion-icon src="assets/images/map/device-dashboard/activity.svg"></ion-icon>
        <ion-label>{{ "dashboard.activityLabel" | translate }}</ion-label>
        </div>
      </ion-button>

      <ion-button [color]="selectedTab == 'alarms' ? 'primary' : 'dark'" shape="round" fill="solid" (click)="selectTab('alarms')">
        <div class="submenuTabButtonsInner">
          <ion-icon name="notifications-outline"></ion-icon>
        <ion-label>{{ "dashboard.alarms" | translate }}</ion-label>

      </div>

      <div *ngIf="nCount > 0" >
        <ion-badge color="danger" class="notification-badge" mode="ios"
          >{{nCount > 99 ? '99+':nCount}}</ion-badge
        >
      </div>
      </ion-button>

      <ion-button [color]="selectedTab == 'timeline' ? 'primary' : 'dark'" shape="round" fill="solid" (click)="selectTab('timeline')">
        <div class="submenuTabButtonsInner">

        <ion-icon src="assets/images/map/device-dashboard/timeline.svg"></ion-icon>
        <ion-label>{{ "dashboard.timeline" | translate }}</ion-label>
      </div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  </ion-header>

  <ion-content [scrollY]="selectedTab == 'timeline' ? false : true" class="content-background">
    <div 
      style="height: 100%; overflow: auto;"
      [ngStyle]="{ display: this.isSmallScreen && 'contents'}"
    >
      <app-notifications-list [type]="type" [hidden]="selectedTab !== 'alarms'" @fadeIn></app-notifications-list>
      <app-devices-activity [hidden]="selectedTab !== 'activity'" @fadeIn></app-devices-activity>
      <app-devices-timeline [hidden]="selectedTab !== 'timeline'" @fadeIn></app-devices-timeline>
    </div>
  </ion-content>
