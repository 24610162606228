import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../components/loading/loading.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeviceDetailsComponent } from '../pages/device/components/device-details/device-details.component';
import { DeviceSettingsComponent } from '../pages/device/components/device-settings/device-settings.component';
import { DeviceTrackerComponent } from '../pages/device/components/device-tracker/device-tracker.component';
import { HomeHeaderComponent } from '../pages/home/components/home-header/home-header.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SideMenuComponent } from '../tabs/components/side-menu/side-menu.component';
import { OptionsComponent } from '../pages/home/components/options/options.component';
import { TranslateModule } from '@ngx-translate/core';
import { TrackingComponent } from '../pages/home/components/tracking/tracking.component';
import { RouteSelectComponent } from '../pages/home/components/route-select/route-select.component';
import { TimeSelectComponent } from '../pages/home/components/time-select/time-select.component';
import { SharelinkOptionsModule } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/info/sharelink-popup/sharelink-options.module';
import { GeofenceSettingsComponent } from '../pages/device/components/geofence-settings/geofence-settings.component';
import { KmtoMilesPipe } from 'src/app/pipes/KmtoMilesPipe';



@NgModule({
  declarations: [
    LoadingComponent,
    DeviceDetailsComponent,
    DeviceSettingsComponent,
    DeviceTrackerComponent,
    HomeHeaderComponent,
    SideMenuComponent,
    OptionsComponent,
    TrackingComponent,
    RouteSelectComponent,
    TimeSelectComponent,
    GeofenceSettingsComponent
    
    
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    SharelinkOptionsModule,
    
  ],
  exports: [
    LoadingComponent,
    DeviceDetailsComponent,
    DeviceSettingsComponent,
    DeviceTrackerComponent,
    HomeHeaderComponent,
    SideMenuComponent,
    OptionsComponent,
    TrackingComponent,
    RouteSelectComponent,
    TimeSelectComponent,
    GeofenceSettingsComponent
  ]

})
export class PetModule { }
