import { TranslateService } from '@ngx-translate/core';
import { ApiCompany } from './members/company/apis';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  of,
} from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageService as Storage } from './services/storage.service';
import { Constants, DEMO_ACCOUNTS } from './constants.enum';
import {
  AlertController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { CsPopoverComponent } from './components/cspopover/cspopover.component';
import { MapService } from './members/map/components/map/map.service';
import { ApiService } from './services/api.service';
import { showLanguagePicker, showCurrencyPicker } from './app-helper';
import { StorageSetting, STORAGE_SETTINGS } from './services/storage-settings';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone/moment-timezone';
import { AppModesEnum } from './members/map/components/app-modes-popup/app-modes-helper';
import { PlatformLocation } from '@angular/common';
import { AppInfoService } from './services/plugins/app-info.service';
import { DataService } from './services/data.service';
import { Capacitor } from '@capacitor/core';
import { environment as ENV, environment } from '../environments/environment';
import { StartupService } from './services/startup.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public menuClosed = new Subject<boolean>();
  public deviceMenuisOpen = new BehaviorSubject<boolean>(false);

  // User logged:
  public _user = new Subject<{}>();
  public user$ = this._user.asObservable();
  public user = null;

  public _userSettings = new Subject<{}>();
  public _userSettings$ = this._userSettings.asObservable();
  public userSettings;

  public permissions = null;

  // User logged:
  public _userId = new Subject<number>();
  public userId$ = this._userId.asObservable();
  public userId;

  // Devices of the logged user:
  public _devices = new Subject<{}>();
  public devices$ = this._devices.asObservable();
  public devices;

  // user Admimn when is in ghost mode:
  public _adminUser = new Subject<{}>();
  public adminUser$ = this._adminUser.asObservable();
  public adminUser;

  // language
  public _language = new Subject<string>();
  public language$ = this._language.asObservable();
  public language = 'en';

  // DarkMode
  public _darkMode = new Subject<boolean>();
  public darkMode$ = this._darkMode.asObservable();
  public darkMode;

  // Date Format
  public _dateFormat = new Subject<string>();
  public dateFormat$ = this._dateFormat.asObservable();
  public dateFormat;

  appModeChanged = new Subject<boolean>(); // used in app settings page

  doLogout = new Subject<boolean>(); // used in app settings page for reset biometric feature

  logoutOnClose = new BehaviorSubject<boolean>(false);
  showIMEI = new BehaviorSubject<boolean>(true);

  public enableFeature = new BehaviorSubject(true);
  public isBrowser = false;
  public isSharedView = false; //_ Enable this if it is a shared page

  //_ New settings to save/update status using storage
  public ghostMode = new StorageSetting(
    this.storage,
    STORAGE_SETTINGS.ISGHOST,
    false,
  );
  public adminId = new StorageSetting(
    this.storage,
    STORAGE_SETTINGS.ADMIN_ID,
    null,
  );

  public _settingsChanged = new Subject<{}>();
  public settingsChanged$ = this._settingsChanged.asObservable();
  public settingsChanged;
  public groupZeroChecked = new BehaviorSubject(false);

  appVersion = '';
  onLoadCustomerDataError = new Subject<boolean>();
  public isMobileApp =
    Capacitor.isNativePlatform() &&
    (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
  guardLoading = new BehaviorSubject(false);
  constructor(
    private authService: AuthenticationService,
    private storage: Storage,
    private toast: ToastController,
    private popoverCtrl: PopoverController,
    private alert: AlertController,
    private appInfo: AppInfoService,
    private translate: TranslateService,
    private platform: Platform,
    private mapService: MapService,
    private apiService: ApiService,
    private router: Router,
    private platformLocation: PlatformLocation,
    private dataService: DataService,
    private startupService: StartupService,
  ) {
    //_ Wait storage ready to load the settings
    this.storage.storageReady.subscribe((r) => {
      if (r) {
        this.storage.get('darkMode').then((r) => {
          this.darkMode = r ? r : false;
          document.body.setAttribute(
            'color-theme',
            this.darkMode ? 'dark' : 'light',
          );
        });

        // this.storage.get("heatMapMode").then((r) => {
        //   this.heatMapMode = r ? r : false;
        //   // document.body.setAttribute('color-theme', this.darkMode ? 'dark' : 'light');
        // });

        this.storage.get('logoutOnClose').then(async (res) => {
          if (res === null) {
            this.storage.set('logoutOnClose', false);
            res = false;
          }

          this.logoutOnClose.next(res);
        });

        this.storage.get('enableFeature').then((res) => {
          if (res === null) {
            this.storage.set('enableFeature', true);
            res = true;
          }

          this.enableFeature.next(res);
        });

        this.storage.get(Constants.IMEI_STATUS).then((res) => {
          if (res === null) {
            this.storage.set(Constants.IMEI_STATUS, true);
            res = true;
          }

          this.showIMEI.next(res);
        });

        this.init();
      }
    });

    this.platform.ready().then(async () => {
      // this.isBrowser = document.URL.startsWith('http');
      this.isBrowser =
        this.platform.is('mobileweb') ||
        this.platform.is('desktop') ||
        this.platform.is('pwa') ||
        this.platform.is('electron');
      //console.info("isBrowser", this.isBrowser);
      //_ Get version number

      this.appVersion = await this.appInfo.getVersion();
    });
  }

  // Is left all catch when the server doesnt return a response
  // Be better to get data from cached or stored if no has a response from the server
  init() {
    this.startupService.startupData$.subscribe(async (res) => {
      // console.warn('DEBUG STARTUP DATA LOADED', res)
      if (res) {
        this.userId = await this.storage.get(Constants.USER_ID);
        if (this.userId) {
          //_ Async request for user and usersettings
          forkJoin([this.getUserData(), this.getUserSettings()]).subscribe({
            next: ([result1, result2]) => {
              // this.userId = this.user.id;
              this._user.next(this.user);
              this.mapService.mapStyle.next(this.user.map);
            },
          });
        }
      }
    });

    // Get language from storage
    //this.storage.get(Constants.LANGUAGE).then(r => this.updateLanguage(r ? r : 'en'));

    if (navigator.language) {
      this.storage.get(Constants.LANGUAGE).then(async (res) => {
        if (res != null) {
          res = this.fallBackLanguage(res);
          await this.updateLanguage(res);
        } else {
          const arr = navigator.language.split('-');
          let browserLang = arr[0];
          browserLang = this.fallBackLanguage(browserLang);
          await this.updateLanguage(browserLang);
        }
      });
    } else {
      this.storage.get(Constants.LANGUAGE).then((r) => {
        r = this.fallBackLanguage(r);
        this.updateLanguage(r);
      });
    }

    // Subscribe to the user, to get all devices from this user.
    combineLatest([this._user, firstValueFrom(this._userSettings$)]).subscribe(
      async ([user, userSettings]) => {
        // console.warn('[DEBUG] Combine obs appservice');
        // console.log('[DEBUG] user and userSettings loaded in device', {user, userSettings});
        // await this.authService.getDevices()
        this.startupService
          .obsToPromise(this.startupService.devices$)
          .then(async (r: any) => {
            // console.warn('[DEBUG] getDevices', r);
            if (r.success) {
              this.devices = r.success;
              this.dataService.setData(
                'hideDeviceListToolbar',
                this.devices.length >
                  Constants.HIDE_TOOLBAR_IN_DEVICE_LIST_MIN_COUNT
                  ? false
                  : true,
              );
              this._devices.next(r.success);
              if (this.userSettings.logbook_mode) {
                let logbookCount = 0;
                let logbookSingleDevice = null;
                this.devices.forEach((singleDevice) => {
                  if (singleDevice['carDevice_id']) {
                    if (logbookCount == 0)
                      logbookSingleDevice = singleDevice['carDevice_id'];
                    logbookCount++;
                  }
                });
                if (logbookCount > 1) {
                  this.router.navigate(['/logbook/devices']);
                } else if (logbookCount == 1) {
                  this.router.navigate(
                    ['/logbook/devices/' + '/' + logbookSingleDevice + '/logs'],
                    { replaceUrl: true },
                  );
                }
              } else {
                const appModes: boolean = await this.storage.get(
                  Constants.PET_MODE,
                );
                if (appModes && !this.router.url.includes('pets')) {
                  console.warn('appModes in auth guard', appModes);

                  this.router.navigate(['/pets/home'], { replaceUrl: true });
                }
              }
            }
          });
      },
    );

    // Change Dark Mode
    this.darkMode$.subscribe(async (dm) => {
      this.darkMode = dm;
      document.body.setAttribute('color-theme', dm ? 'dark' : 'light');
      await this.storage.set('darkMode', dm);
    });

    // this.heatMapMode$.subscribe(async (dm) => {
    //   this.heatMapMode = dm;
    //   // document.body.setAttribute('color-theme', dm ? 'dark' : 'light');
    //   await this.storage.set("heatMapMode", dm);
    // });

    this.logoutOnClose.subscribe(async (r) => {
      await this.storage.set('logoutOnClose', r);
      //_ Logout on close only should work for mobiles
      //_ Detect if token exist and logoutOnClose is active then move it to localSession storage
      if (
        Capacitor.isNativePlatform() &&
        (Capacitor.getPlatform() == 'ios' ||
          Capacitor.getPlatform() == 'android')
      ) {
        if (r) {
          const currentToken = await this.storage.get(Constants.TOKEN_KEY);
          if (currentToken) {
            sessionStorage.setItem(Constants.TOKEN_KEY, currentToken);
            await this.storage.remove(Constants.TOKEN_KEY);
          }
        } else {
          const sessionToken = sessionStorage.getItem(Constants.TOKEN_KEY);
          if (sessionToken) {
            await this.storage.set(Constants.TOKEN_KEY, sessionToken);
            sessionStorage.removeItem(Constants.TOKEN_KEY);
          }
        }
      }
    });

    this.enableFeature.subscribe(async (r) => {
      await this.storage.set('enableFeature', r);
    });

    this.showIMEI.subscribe(async (r) => {
      await this.storage.set(Constants.IMEI_STATUS, r);
    });

    this.devices$.subscribe(async (devices) => {
      this.devices = devices;
    });

    this.dateFormat$.subscribe(async (format) => {
      this.dateFormat = format;
    });
  }

  getUserData() {
    // return this.authService.getUserInfo(this.userId)
    return (
      this.startupService.customerData$
        .pipe(first())
        .toPromise()
        // console.warn('DEBUG SUBSCRIBED TO WAIT getUserData');
        // return this.startupService.obsToPromise(this.startupService.customerData$)
        .then(async (r: any) => {
          // console.warn('[DEBUG] getUSerData', r);
          if (r && r.success) {
            //_ CHECK LAST PASSWORD CHANGE
            console.warn('DEBUG - password', {
              route: this.router.url.includes('reset-password'),
            });
            // if (!r.success.last_password_change && !this.router.url.includes('reset-password')) {
            //   this.user = r.success;
            //   this.router.navigate(["/password-reset-advice"], { replaceUrl: true });
            //   return;
            // }

            this.storage.set(Constants.USER_DATA, r['success']);
            this.user = r.success;
            this.user['isTestAccount'] = DEMO_ACCOUNTS.includes(
              this.user.email,
            );

            //_ Check language of the user || use stored language for demo accounts
            const appLanguage = await this.storage.get(Constants.LANGUAGE);
            if (DEMO_ACCOUNTS.includes(r.success.email))
              this.updateLanguage(appLanguage);
            else {
              this.updateLanguage(r.success.actlang ?? appLanguage);
            }
            this.checkCustomerGroupZero();
          }

          //_ Handle userId doesnt exist
          if (r?.error) {
            if (r.error.message == 'Resource not found.')
              this.router.navigate(['/members/user-not-found'], {
                replaceUrl: true,
              });
          }
        })
        .catch((error) => {
          this.onLoadCustomerDataError.next(error);
          // console.log("ERROR: ", error);
        })
    );
  }

  getUserPin() {
    return this.authService
      .getUserPin(this.userId)
      .then(async (r: any) => {
        if (r && r.success) {
          return r.success;
        }
      })
      .catch((error) => {
        // this.onLoadCustomerDataError.next(true);
        console.log('ERROR: ', error);
      });
  }

  getUserSettings() {
    // console.warn('DEBUG SUBSCRIBED TO WAIT getUserSettings');
    // return this.apiService
    //   .getUserSettings()
    return (
      this.startupService.customerSettings$
        .pipe(first())
        .toPromise()
        // return firstValueFrom(this.startupService.customerSettings$)
        // return this.startupService.obsToPromise(this.startupService.customerSettings$)
        .then(async (res: any) => {
          // console.warn('[DEBUG] getUSerSettings', res);
          if (res && res.success) {
            this.userSettings = res.success;
            if (this.userSettings.logbook_mode) {
              this.router.navigate(['/logbook/devices']);
            }
            // this.userSettings.use_graphhopper = 1; //_ Enable for testing
            //this.userSettings.map_monitor_mode = 0;
            await this.storage.set(Constants.USER_SETTINGS, this.userSettings);
            //_ SAVE App Modes in Storage if exist, otherwise will create with 0 and use NO_MODE = 1

            await this.setupModes();
            //_ _______________________________________________________
            this._userSettings.next(res.success);
            this._darkMode.next(res.success.dark_mode);
            this.showIMEI.next(res.success.show_imei);
            this.logoutOnClose.next(res.success.logout_onclose);
            this.mapService.thickness.next(res.success.polyline_thickness);
            this.mapService.iconSize.next(
              this.getIconSize(res.success.icon_size),
            );
            this.mapService.showClusterMarkers.next(
              Boolean(res.success.cluster_markers),
            );
            this.mapService.showDotEffect.next(Boolean(res.success.green_dot));
            this.mapService.noAnimatedMarker.next(
              Boolean(res.success.disable_animation),
            );
            this.mapService.showDefaultImage.next(
              Boolean(res.success.default_image),
            );
            this.mapService.showSpeedPolylines.next(
              Boolean(res.success.show_speed_polylines),
            );
            this.mapService.showSpeedPolylinesBtn.next(
              Boolean(res.success.show_speed_polylines_btn),
            );
            this.mapService.mapMonitorMode.next(
              Boolean(res.success.map_monitor_mode),
            );
            this._dateFormat.next(res.success?.date_format);

            //_ Apply timezone to moment lib
            fetch(
              this.platformLocation.getBaseHrefFromDOM() +
                'assets/moment-timezone/latest.json',
            )
              .then((time) => time.json())
              .then((timeZoneData) => {
                moment.tz.load(timeZoneData);
                moment.tz.setDefault(this.userSettings.timezone);
              });

            //_ DEVICE NAME MARKER PROPS
            this.mapService.deviceNamefontSize.next(
              res.success.device_name_font_size,
            );
            this.mapService.showDeviceNameMarker.next(
              res.success.show_devicename_in_marker == 1 ? true : false,
            );
          }
        })
        .catch((error) => {
          // console.log("ERROR: ", error);
          this.onLoadCustomerDataError.next(error);
        })
    );
  }

  //_
  //_ Function to show a toast wherever you want
  //_ Colors are: danger, success, warning, primary, secondary ...
  myToast: any = null;

  async showToast(title, text, time, colorType, position: any = 'bottom') {
    if (this.myToast) this.myToast.dismiss();
    this.myToast = await this.toast.create({
      message: text,
      duration: time,
      color: colorType,
      position: position,
      cssClass: 'centerToast',
      //header: title
    });

    this.myToast.present();
  }

  async updateLanguage(newLanguage) {
    this.language = newLanguage;
    this._language.next(newLanguage);
    this.storage.set(Constants.LANGUAGE, newLanguage);
    this.translate.use(newLanguage);

    document.documentElement.setAttribute('lang', newLanguage);

    if (this.user && newLanguage !== this.user.actlang) {
      this.user.actlang = newLanguage;
      this.authService.updateUser({ actlang: newLanguage }, this.user.id);
    }
  }

  async showInfoPopoup(
    title,
    description,
    image = null,
    event,
    useGif = false,
  ) {
    //_ This method helps to load the gif image before to render it in the popover
    //_ then when is used in the popover component it is served by the cache of the browser
    if (image.endsWith('.gif')) {
      await this.getImageSize(image);
    }

    let popup = await this.popoverCtrl.create({
      componentProps: {
        title: title,
        description: description,
        image: image,
        useGif,
      },
      component: CsPopoverComponent,
      // cssClass: 'details-popup',
      //translucent: true,
      event: event,
      mode: 'ios',
    });

    await popup.present();
    popup = null;
  }

  getImageSize(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = url;
    });
  }

  //_
  //_ Delay to sleep for a while
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async isAdmin() {
    return new Promise((resolve, reject) => {
      this.storage.get(Constants.IS_ADMIN).then((res) => {
        if (res) resolve(res == 1 ? true : false);
        else resolve(false);
      });
    });
  }

  async confirmDialog(title, message, cssClass = 'alert-confirm') {
    return new Promise((resolve, reject) => {
      this.alert
        .create({
          header: title,
          //subHeader: 'Beware lets confirm',
          message: message,
          cssClass: cssClass,
          mode: 'ios',
          buttons: [
            {
              text: this.translate.instant('company.users.confirm.no'),
              handler: () => {
                resolve(false);
              },
            },
            {
              text: this.translate.instant('company.users.confirm.yes'),
              handler: () => {
                resolve(true);
              },
            },
          ],
        })
        .then(async (res) => {
          await res.present();
        });
    });
  }
  async confirmDialogPopup(title, message, cssClass = 'delete-alert') {
    return new Promise((resolve, reject) => {
      this.alert
        .create({
          header: title,
          //subHeader: 'Beware lets confirm',
          message: message,
          cssClass: cssClass,
          mode: 'ios',
          buttons: [
            {
              text: this.translate.instant('company.users.confirm.no'),
              handler: () => {
                resolve(false);
              },
            },
            {
              text: this.translate.instant('company.users.confirm.yes'),
              handler: () => {
                resolve(true);
              },
            },
          ],
        })
        .then(async (res) => {
          await res.present();
        });
    });
  }
  getIconSize(step) {
    return { width: 20 + step * 15, height: 20 + step * 15 };
  }

  pickLanguage(title, ev = null, selectedLan = null, showDropdown) {
    return showLanguagePicker(
      this.popoverCtrl,
      title,
      ev,
      selectedLan,
      showDropdown,
    );
  }

  pickCurrency(title, ev = null, selectedCurrency = null, showDropdown) {
    return showCurrencyPicker(
      this.popoverCtrl,
      title,
      ev,
      selectedCurrency,
      showDropdown,
    );
  }

  async isGhostMode() {
    return await this.storage.get(STORAGE_SETTINGS.ISGHOST);
  }

  async setupModes() {
    if (this.userSettings.app_modes == null) {
      this.userSettings.app_modes = {};
      Object.values(AppModesEnum).forEach(
        (k) => (this.userSettings.app_modes[k] = 0),
      );
      this.userSettings.app_modes[AppModesEnum.NO_MODE] = 1;
      this.userSettings.app_modes = JSON.stringify(this.userSettings.app_modes);

      let appModes = JSON.parse(this.userSettings.app_modes);
      // Object.keys(appModes).forEach(k => { this.storage.set(k, appModes[k] || null);  });
      await this.storage.set(Constants.APP_MODES, appModes);
      this.appModeChanged.next(true);
      this.authService.updateUserSettings(this.userId, this.userSettings);
    } else {
      let modes = this.userSettings.app_modes;
      if (typeof modes === 'string') modes = JSON.parse(modes);
      await this.storage.set(Constants.APP_MODES, modes);
    }
  }

  async checkCustomerGroupZero() {
    if (this.user && !this.groupZeroChecked.value) {
      return this.authService
        .checkCustomerGroupZero(this.user.id)
        .subscribe((res) => {
          this.groupZeroChecked.next(true);
        });
    }
  }

  fallBackLanguage(language) {
    return ENV.ALL_LANGUAGES.map((lang) => lang.toLowerCase()).find(
      (lang) => lang == language,
    )
      ? language
      : language == 'gr'
        ? 'el'
        : 'en';
  }

  log(message: any, ...params: any[]) {
    if (!environment.production) {
      console.log(message, ...params);
    }
  }

  filterItems(searchTerm: string) {
    return this.devices?.filter((item) => {
      return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }
  getDeviceById(id: number): Observable<any | undefined> {
    const device = this.devices?.find((d) => d.id === Number(id));
    console.log('device', device);
    return of(device);
  }
}

export enum SCREEN_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
