import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
    selector: '[HideHeader]'
})
export class HideHeaderDirective implements OnInit, OnDestroy {

    @Input('HideHeader') header: any;
    @Input('toolbarHeight') toolbarHeight: number = 0;
    @Input('hideToolbar') hideToolbar = null;
    @Input('isEnabled') isEnabled = true;
    @Input('threshold') threshold = 0;
    @Input('hasDragableHeader') hasDragableHeader = false;
    @Input('dragableHeaderSize') dragableHeaderSize = 24;

    lastY = 0;
    isHeaderHide = false;
    animating = false;
    
    @Output() topReached = new EventEmitter<Object>();
    @Output() onHideShow = new EventEmitter<Object>();
    @Output() onWillHideShow = new EventEmitter<Object>();
    @Output() onScrollStarts = new EventEmitter<Object>();
    
    isOnTop = true;
    constructor( private element: ElementRef, private renderer: Renderer2,
        private domCtrl: DomController
    ) { }


    ngOnInit(): void {
        this.header = this.header.el;
        this.element.nativeElement.scrollEvents = true;
        
        this.domCtrl.write(() => {
          this.renderer.setStyle(this.header, 'transition', 'all 200ms');
          if (this.hideToolbar)
            this.renderer.setStyle(this.hideToolbar.el, 'transition', 'all 200ms');
        });

        //_ Listend for animation ends
        this.header.addEventListener(this.getTransitionEndEventName(), this.onHeaderTransitionEnds.bind(this));
        
        setTimeout( () => {
          const scroller = this.element.nativeElement.shadowRoot.querySelector('.inner-scroll');
          let scrollStart = false;
          let touchEndTimer = null;
          let touchEnd = false;
          if (scroller){
            let isScrolling = false;
            let scrollStop = null;
            let startY = 0;
            scroller.addEventListener("scroll", (ev)=> {
              // console.log('SCROLLING CONTENT', { lastY: this.lastY, currentY: scroller.scrollTop, MIN: Math.min(this.lastY, scroller.scrollTop) });
              // if (Math.min(this.lastY, scroller.scrollTop) > this.threshold)
                // console.log('SHOULD HIDE THE HEADER');
                // console.log('SCROLLING CONTENT', ev)
              if (scroller.scrollTop == 0)
                this.showHeader();

              if (scrollStop)
                clearTimeout( scrollStop );

              if (!scrollStop){
                startY = scroller.scrollTop < 0 ? 0 : scroller.scrollTop;
                // console.log('SCROLL STARTS', { startY });
              }
                
              const tc = this;
              scrollStop = setTimeout(() => {
                // console.log('Scrolling has stopped.');
                scrollStop = null;
                startY = 0;
                if (touchEnd){
                  // clearTimeout(touchEndTimer)
                  // tc.checkTop(scroller);
                  touchEnd = false;
                }

                scrollStart = false;
              }, 100);

              const scrolledY = startY - scroller.scrollTop;
              // console.log('scrolledY', scrolledY);

              if (scrolledY <= -this.threshold)
                this.hideHeader();
              if (scrolledY >= this.threshold)
                this.showHeader();

              // this.checkTop(scroller);
              this.lastY = scroller.scrollTop;

              //_ Check scrollStart
              if (!scrollStart) {
                // Scroll start event
                // console.log('Scroll started');
                // clearTimeout(touchEndTimer)
                // clearTimeout(scrollStop)
                // this.onScrollStarts.emit({ start: true });
                // this.checkTop(scroller);
                // scrollStart = true;
                // touchEnd = false;
              }
            })
          }

          scroller.addEventListener("touchend", (ev) => {
            touchEnd = true;
            // if (touchEndTimer) clearTimeout(touchEndTimer)
            setTimeout( () => this.checkTop(scroller), 100 );
            // touchEndTimer = setTimeout( () => {
            //   this.checkTop(scroller);
            // }, 200 );
          });
          
        }, 300);

    }

    onHeaderTransitionEnds(e){
      this.animating = false;
      
      this.element.nativeElement.scrollEvents = true;
      this.onHideShow.emit({ isHide: this.isHeaderHide });
      // this.renderer.setStyle(this.element.nativeElement.scrollEvents, 'overflow', 'auto');
    }

    // currentY = 0;
    // @HostListener('ionScroll', ['$event']) onContentScroll(ev: any) {
    //   // if (ev.detail.scrollTop == 0) this.showHeader();
    //   console.log('SCROLLING LIST', {...ev.detail, Min: Math.min(ev.detail.currentY, this.currentY)});
    //   // if (Math.min(ev.detail.currentY, this.currentY) > this.threshold)
    //   //   this.hideHeader();

    //   this.checkTop(ev.detail);
    //   this.currentY = ev.detail.currentY;
    // }

    hideHeader(){
      //_ only do the effect when isEnable true
      if (!this.isEnabled) return;
      // if (this.isHeaderHide) return;

      //_ Stop listening scrollevents
      this.element.nativeElement.scrollEvents = false;
      // this.renderer.setStyle(this.element.nativeElement.scrollEvents, 'overflow', 'hidden');
      this.animating = true;
      this.isHeaderHide = true;
      this.onWillHideShow.emit({ isHide: this.isHeaderHide });

      if (!this.toolbarHeight)
          this.toolbarHeight = this.hideToolbar.el.clientHeight;

      this.domCtrl.write(() => {
       
        let height =   window.innerWidth < 768 ? Number(this.toolbarHeight)+ 18 : this.toolbarHeight ; // 18 is the hight of the toolbar;
        // console.log('height',height)
        this.renderer.setStyle(this.header, 'margin-top', `-${ height }px`);
        if (this.hideToolbar)
          this.renderer.setStyle(this.hideToolbar.el, 'opacity', '0');
        // this.renderer.setStyle(this.header, 'opacity', '0');
        
        if (this.getSecondToolbar() && this.hasDragableHeader && window.screen.width <= 768){
          //this.dragableHeaderSize
          this.renderer.setStyle(this.getSecondToolbar(), 'padding-top', (this.dragableHeaderSize) + 'px', RendererStyleFlags2.Important);
        }
          
      });
    }

    showHeader(){
      //_ only do the effect when isEnable true
      if (!this.isEnabled) return;
      // if (!this.isHeaderHide) return;

      //_ Stop listening scrollevents
      this.element.nativeElement.scrollEvents = false;
      // this.renderer.setStyle(this.element.nativeElement.scrollEvents, 'overflow', 'hidden');
      this.animating = true;
      this.isHeaderHide = false;
      this.onWillHideShow.emit({ isHide: this.isHeaderHide });

      this.domCtrl.write(() => {
        this.renderer.setStyle(this.header, 'margin-top', '0');
        if (this.hideToolbar)
          this.renderer.setStyle(this.hideToolbar.el, 'opacity', '1');
        // this.renderer.setStyle(this.header, 'opacity', '1');

        if (this.getSecondToolbar() && this.hasDragableHeader){
          this.renderer.removeStyle(this.getSecondToolbar(), 'padding-top');
        }
      });
    }

    getTransitionEndEventName() {
      var transitions = {
          "transition"      : "transitionend",
          "OTransition"     : "oTransitionEnd",
          "MozTransition"   : "transitionend",
          "WebkitTransition": "webkitTransitionEnd"
       }
      let bodyStyle = document.body.style;
      for(let transition in transitions) {
          if(bodyStyle[transition] != undefined) {
              return transitions[transition];
          } 
      }
    }

    checkTop(scroller){
      //_ not check top if is disabled
      if (!this.isEnabled) 
        return;
        
      if (scroller.scrollTop <= 0 && !this.isOnTop){
        this.isOnTop = true;
        this.showHeader();
        // console.log('TOP REACHED', this.isOnTop);
        this.topReached.emit({ isOnTop: this.isOnTop, scrollTop: scroller.scrollTop });
      }else if(scroller.scrollTop > 0  && this.isOnTop){
        // console.log('NO TOP REACHED', this.isOnTop);
        this.isOnTop = false;
        this.topReached.emit({ isOnTop: this.isOnTop, scrollTop: scroller.scrollTop });
      }
    }

    getSecondToolbar () {
      const ionToolbars = this.header.querySelectorAll('ion-toolbar')
      if (ionToolbars?.length > 1){
        return ionToolbars[1];
      }
      return null;
    }

    ngOnDestroy() {
      this.header.removeEventListener(this.getTransitionEndEventName(), this.onHeaderTransitionEnds);
    }

}
