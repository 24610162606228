import { environment } from "src/environments/environment";
import { ImageStackManagament } from "../imageStackManagament";

const PREFIX_PATH = environment.mediaServer + environment.API_VERSION + 'shared/_media/';
export const createSubAccountImage = async (subAccounts, imageStackManager: ImageStackManagament) =>{
    const WIDTH = 400;
    const HEIGHT = 400;
    
    let canvas = document.createElement('canvas')
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    return new Promise( (resolve) => {
        const arrayOfImages = subAccounts.map(user => 
            (user.customer_img ? PREFIX_PATH + user.customer_img : 'assets/images/company/user.svg')
        );

        console.log(arrayOfImages);
        resolve (createImageArrange(arrayOfImages, imageStackManager))
    });
}
  
export const createImageArrange = (imageUrls, imageStackManager: ImageStackManagament) => {
    return new Promise(async (resolve) => {
        //_ Define maximum images and size
        const IMAGE_SIZE = 22;
        const PADDING = { x: 5, y: 5 };
        const ITEM_SIZE = 25;
        const maxImages = 4;

        //_ Create a canvas with a 400x400 size
        const canvas = document.createElement('canvas');
        canvas.height = imageUrls.length <= 2 ? ITEM_SIZE : ITEM_SIZE*2;
        canvas.width = imageUrls.length == 1 ? ITEM_SIZE : ITEM_SIZE*2;
        canvas.height = canvas.height + (PADDING.y*2);
        canvas.width = canvas.width + (PADDING.x*2);
        const ctx = canvas.getContext('2d');
    
        //_ Fill the canvas with an rgba background and rounded borders
        drawRoundedCanvas(canvas, 'rgba(0, 0, 0, 0.5', 5);
    
        const drawImage = async (i) => {
            const imageUrl = imageUrls[i];
            const image = await imageStackManager.getImageData(imageUrl);
            console.log('IMAGE OBTEINED', image);

            //_ Calculate the drawing size based on the image's aspect ratio
            let drawWidth, drawHeight;
            if (image.width > image.height) {
                drawWidth = IMAGE_SIZE;
                drawHeight = (image.height / image.width) * IMAGE_SIZE;
            } else {
                drawWidth = (image.width / image.height) * IMAGE_SIZE;
                drawHeight = IMAGE_SIZE;
            }
        
            const x = (i % 2) * (IMAGE_SIZE + PADDING.x); //_ Adding 5 for spacing
            const y = Math.floor(i / 2) * (IMAGE_SIZE + PADDING.y); //_ Adding 5 for spacing
        
            //_ Draw the image on the canvas
            ctx.drawImage(image, x + PADDING.x, y + PADDING.y, drawWidth, drawHeight);
        }

        //_ Load and draw images from the URLs
        for (let i = 0; i < Math.min(imageUrls.length, maxImages-1); i++) {
            await drawImage(i);
        }

        if (imageUrls.length == maxImages)
            await drawImage(maxImages-1);
        else if (imageUrls.length > maxImages) {
          //_ Draw a plus sign
          ctx.fillStyle = 'white';
          ctx.font = 'bold 30px Arial';
          const textPosition = { x: ITEM_SIZE + (ITEM_SIZE/2) - 4, y: ITEM_SIZE + (PADDING.y*2) + (ITEM_SIZE/2) + 7 };
          ctx.fillText('+', textPosition.x, textPosition.y);
        //   ctx.font = 'bold 100px Arial';
        //   ctx.fillText(imageUrls.length, 325, 260);
        }
    
        //_ Convert the canvas to a data URL
        const dataUrl = canvas.toDataURL('image/png');
    
        resolve(dataUrl);
      });
  }

  export const drawRoundedCanvas = (canvas, background, cornerRadius) => {
    const ctx = canvas.getContext('2d');
    //_ Create a rounded rectangle background with rgba color
    ctx.fillStyle = background;
    ctx.beginPath();
    ctx.moveTo(cornerRadius, 0);
    ctx.lineTo(canvas.width - cornerRadius, 0);
    ctx.arc(canvas.width - cornerRadius, cornerRadius, cornerRadius, -Math.PI / 2, 0);
    ctx.lineTo(canvas.width, canvas.height - cornerRadius);
    ctx.arc(canvas.width - cornerRadius, canvas.height - cornerRadius, cornerRadius, 0, Math.PI / 2);
    ctx.lineTo(cornerRadius, canvas.height);
    ctx.arc(cornerRadius, canvas.height - cornerRadius, cornerRadius, Math.PI / 2, Math.PI);
    ctx.lineTo(0, cornerRadius);
    ctx.arc(cornerRadius, cornerRadius, cornerRadius, Math.PI, -Math.PI / 2);
    ctx.closePath();
    ctx.fill();
  }