import 'hammerjs';
import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { StorageService } from './app/services/storage.service';
import { Drivers, Storage } from '@ionic/storage';
import { Constants } from './app/constants.enum';

if (environment.production) {
  enableProdMode();
  
  if(environment.hideLogs){
    window.console.log = window.console.warn = window.console.info = function(){}
  }
}

//_ Start storage before bootstraping the app
const storage = new StorageService( new Storage({ driverOrder: [ Drivers.LocalStorage, Drivers.IndexedDB ] }) );
storage.init();

storage.storageReady.subscribe( async r => {
  //_ Wait storage is ready
  if (r){
    window['isAdmin'] = await storage.get(Constants.IS_ADMIN);

    //_ Bootstraping App
    platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('ngsw-worker.js');
      }
    }).catch(error => {
      console.log("ERROR: ", error);
    });

    defineCustomElements(window);
  }
});


