<ion-content scrollY="false" scrollX="false" slot="fixed">
  <!-- <div class="test-item">
    <p>Device rotation</p>
    <input type="range" min="0" max="360" value="0" class="sliderInput" #range (input)="testFn(range.value)">
    <p>{{ range.value }} deg</p>
  </div> -->

  <app-slow-performance-popup></app-slow-performance-popup>
  <!-- <ion-buttons>
    <ion-button (click)="map.setBearing(map.getBear)</ion-button>
  </ion-buttons> -->
  <div
    id="map-{{ id }}"
    [style.width]="width"
    [style.height]="height"
    [mapStyleBackground]="mapStyle"
    class="main-map {{ mapType }} {{ dimension }}"
    [class.dark-style-bg]="
      (mapStyle != 'pajLite' && isDarkmode) ||
      (mapStyle != 'pajLite' && heatMapClass?.isEnabled)
    "
  ></div>
</ion-content>

<ng-template #FabButtons>
  <ion-fab
    #fab
    *ngIf="!hideFabButton && appModes"
    [activated]="!activateFab"
    vertical="bottom"
    horizontal="end"
    class="map-fab-menu"
    id="mainMap-fab-buttons"
    [class.hasTabs]="hasTabBar"
    [ngStyle]="{
      'pointer-events': isSmallScreen && hasTabBar ? 'none' : 'all',
    }"
  >
    <ion-fab-button
      size="small"
      title="{{ 'map.mapMenu' | translate }}"
      color="dark"
      class="small-screen"
      (click)="openFabList()"
      id="mainMap-fab-button"
      [ngClass]="{ 'fab-button-border': isDarkmode }"
    >
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>

    <ion-fab-list
      side="top"
      class="fablist ion-no-margin"
      [class.fab-margin]="!hasTabBar"
    >
      <ion-fab-button
        title="{{ 'map.polylines' | translate }}"
        [color]="showPolylines ? 'primary' : 'dark'"
        id="mapPolylines"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <img
          src="assets/images/map/map-route.svg"
          color="white"
          class="fab-button-image"
          (click)="togglePolylines($event)"
        />
        <ion-label (click)="togglePolylines($event)">{{
          "map.polylines" | translate
        }}</ion-label>
      </ion-fab-button>
      <ion-fab-button
        title="{{ 'map.direction' | translate }}"
        [color]="showDirections ? 'primary' : 'dark'"
        id="mapRoutemarker"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
        [class.disabled]="unloadedDirectionSource"
      >
        <img
          src="assets/images/map/direction-icon.svg"
          class="fab-button-image"
          (click)="toggleDirectionMarkers($event, 'gps')"
        />
        <ion-label (click)="toggleDirectionMarkers($event, 'gps')">{{
          "map.direction" | translate
        }}</ion-label>
      </ion-fab-button>

      <ion-fab-button color="dark">
        <ion-fab
          #FabSubmenu
          title="{{ 'deviceManagement.showAlarms' | translate }}"
          class="fab-submenu"
        >
          <ion-fab-button
            style="margin-right: 7px"
            size="small"
            [color]="
              pauseMarkersClass.show && alarmMarkersClass.show
                ? 'primary'
                : 'dark'
            "
            (click)="showMarkersMenu($event)"
            [activated]="markersMenu && isFabListOpen"
            [ngClass]="{
              'sub-fab-menu-active': markersMenu && isFabListOpen,
              'fab-button-border': isDarkmode,
            }"
            mode="md"
            id="mapAlarmMarker"
          >
            <img src="assets/images/map/markers.svg" class="fab-button-image" />
            <ion-label>{{
              "deviceManagement.showAlarms" | translate
            }}</ion-label>
          </ion-fab-button>
          <ion-fab-list side="start">
            <ng-container [ngTemplateOutlet]="SUB_FAB_MENU"></ng-container>
          </ion-fab-list>
        </ion-fab>
      </ion-fab-button>

      <ion-fab-button
        *ngIf="
          (appModes?.pet_mode == 1 ||
            appModes?.people_mode == 1 ||
            appModes?.no_mode == 1) &&
          userSettings?.show_heatmap_btn
        "
        title="{{ 'map.heatmap' | translate }}"
        [color]="heatMapClass?.isEnabled ? 'primary' : 'dark'"
        id="mapHeatmapToggle"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <img
          src="assets/images/map/heatmap.svg"
          class="fab-button-image"
          (click)="toggleHeatMap($event)"
        />
        <ion-label (click)="toggleHeatMap($event)">{{
          "map.heatmap" | translate
        }}</ion-label>
      </ion-fab-button>

      <ion-fab-button
        *ngIf="
          (appModes?.car_mode == 1 || appModes?.no_mode == 1) &&
          userSettings?.show_speed_polylines_btn
        "
        title="{{ 'appSettings.showSpeedPolylines' | translate }}"
        [color]="HighSpeedPolylinesClass.show ? 'primary' : 'dark'"
        id="mapSpeedPolylinesToggle"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <ion-icon
          src="assets/images/app-settings/gradient-path.svg"
          class="fab-button-icon"
          (click)="
            preventDefaultSColorGradient($event);
            HighSpeedPolylinesClass.toggleFeature(true)
          "
        ></ion-icon>
        <ion-label
          class="ion-text-wrap"
          style="max-width: 230px"
          (click)="
            preventDefaultSColorGradient($event);
            HighSpeedPolylinesClass.toggleFeature(true)
          "
          >{{ "appSettings.showSpeedPolylines" | translate }}</ion-label
        >
      </ion-fab-button>

      <ion-fab-button
        title="{{ 'map.resetDirection' | translate }}"
        (click)="preventDefault($event)"
        id="mapCompass"
        color="dark"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <div class="compass">
          <img
            class="left ion-hide-md-down"
            (click)="setBearing(90, $event)"
            src="assets/images/map/c4.png"
          />
          <!-- <img
            class="needle ion-hide-md-down"
            (click)="resetBearing($event)"
            [ngStyle]="{
              transform: 'rotate(-' + (currentBearing + 360) + 'deg)'
            }"
            src="assets/images/map/c3.png"
          /> -->
          <img
            class="needle ion-hide-md-down"
            (click)="resetBearing($event)"
            [ngStyle]="{
              transform: 'rotate(' + -currentBearing + 'deg)',
            }"
            src="assets/images/map/c3.png"
          />
          <!-- <img
            class="ion-hide-md-up fab-button-image"
            (click)="resetBearing($event)"
            [ngStyle]="{
              transform: 'rotate(-' + (currentBearing + 360) + 'deg)'
            }"
            src="assets/images/map/compass.svg"
          /> -->
          <img
            class="ion-hide-md-up fab-button-image"
            (click)="resetBearing($event)"
            [ngStyle]="{
              transform: 'rotate(' + -currentBearing + 'deg)',
            }"
            src="assets/images/map/compass.svg"
          />
          <img
            class="right ion-hide-md-down"
            (click)="setBearing(-90, $event)"
            src="assets/images/map/c4.png"
            style="transform: scaleX(-1)"
          />
          <ion-label (click)="preventDefault($event)">{{
            "map.resetDirection" | translate
          }}</ion-label>
        </div>
      </ion-fab-button>
      <ion-fab-button
        [ngStyle]="{ display: isBrowser ? 'none' : '' }"
        title="{{ 'map.location' | translate }}"
        color="dark"
        id="mapGps"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <img
          (click)="toggleClientLocation($event)"
          class="fab-button-image"
          [src]="
            clientLocationClass.clientLocationState == 'waiting'
              ? 'assets/images/map/gps-waiting.gif'
              : clientLocationClass.clientLocationState == 'off'
                ? 'assets/images/map/gps-location-off.svg'
                : clientLocationClass.clientLocationState == 'on'
                  ? 'assets/images/map/gps-location-on.svg'
                  : 'assets/images/map/gps-location-off.svg'
          "
        />
        <ion-label
          [color]="
            clientLocationClass.clientLocationState == 'on' ? 'primary' : 'dark'
          "
          (click)="toggleClientLocation($event)"
          >{{ "map.location" | translate }}</ion-label
        >
      </ion-fab-button>

      <ion-fab-button
        title="{{ 'map.view' | translate }}"
        color="dark"
        id="map3dview"
        mode="md"
        [ngClass]="{ 'fab-button-border': isDarkmode }"
      >
        <img
          class="fab-button-image"
          (click)="applyMapDimension(dimension == '3d' ? '2d' : '3d', $event)"
          [src]="
            dimension != '3d'
              ? 'assets/images/map/3d.svg'
              : 'assets/images/map/2d.svg'
          "
        />
        <ion-label
          (click)="
            setMapStyle(user.map, dimension == '3d' ? '2d' : '3d', $event)
          "
          >{{ "map.view" | translate }}</ion-label
        >
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

  <!-- SUB FAB MENU MARKERS -->
  <ng-template #SUB_FAB_MENU>
    <ion-fab-button
      mode="md"
      class="animate glow"
      title="{{ 'deviceManagement.showAlarms' | translate }}"
      [color]="alarmMarkersClass.show ? 'primary' : 'dark'"
      [hidden]="!markersMenu"
      [ngClass]="{ 'fab-button-border': isDarkmode }"
    >
      <img
        class="fab-button-image"
        src="assets/images/map/bell-white.svg"
        (click)="toggleAlarms($event)"
      />
    </ion-fab-button>

    <ion-fab-button
      mode="md"
      *ngIf="appModes?.car_mode == 1 || appModes?.no_mode == 1"
      class="animate glow"
      title="{{ 'deviceManagement.showPauses' | translate }}"
      [color]="pauseMarkersClass.show ? 'primary' : 'dark'"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [hidden]="!markersMenu"
      [ngClass]="{ 'fab-button-border': isDarkmode }"
    >
      <img
        src="assets/images/map/stop-marker-mono.svg"
        class="fab-button-image"
        (click)="togglePauses($event)"
      />
    </ion-fab-button>

    <ion-fab-button
      mode="md"
      class="animate glow"
      title="{{ 'deviceManagement.showSpeedCameras' | translate }}"
      [color]="speedCamerasClass?.show ? 'primary' : 'dark'"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      *ngIf="userSettings?.show_speed_cameras_btn"
      [hidden]="!markersMenu"
      [ngClass]="{ 'fab-button-border': isDarkmode }"
    >
      <ion-icon
        class="speed-cam"
        src="assets/images/map/speed-camera.svg"
        (click)="toggleSpeedCameras($event)"
      >
      </ion-icon>
    </ion-fab-button>

    <!-- <ion-fab-button
      class="animate glow"
      title="{{ 'map.fabButtons.addSpeedCameras' | translate }}"
      color="dark"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      *ngIf="userSettings?.show_speed_cameras_btn && speedCamerasClass?.show"
      [hidden]="!markersMenu"
    >
      <ion-icon class="speed-cam"
        src="assets/images/map/speed-camera-add.svg"
        (click)="speedCamerasClass.addSpeedCamera($event)"
      >
      </ion-icon>
    </ion-fab-button> -->
  </ng-template>
  <!--- -->
</ng-template>

<div
  class="legends-section-item"
  [class.legends-section-for-tabs]="hasTabBar"
  [class.left-sidebar-open]="mainMenuOpen"
  [hidden]="hideLegends"
>
  <!--<ion-item
    class="main-icon ion-no-padding content"
    *ngIf="userSettings?.use_graphhopper && !graphhopperLegendIsOpen"
    shape="round"
    lines="none"
    (click)="graphhopperLegendAction($event)"
    button
    mode="md"
  >
    <ion-icon
      class="ion-no-margin"
      src="assets/images/app-settings/animate-marker.svg"
      color="primary"
    ></ion-icon>
  </ion-item>!-->
  <ion-item
    class="content route-engine"
    #graphhopperLegend
    *ngIf="userSettings?.use_graphhopper && graphhopperLegendIsOpen"
    lines="none"
    style="--max-height: 30px; --height: 30px"
    @fadeIn
  >
    <ion-icon
      src="assets/images/app-settings/animate-marker.svg"
      slot="start"
      color="primary"
      style="margin: 0 15px 0 0"
    ></ion-icon>
    Using Graphhopper
  </ion-item>

  <ion-item
    class="main-icon ion-no-padding content"
    lines="none"
    *ngIf="userSettings?.map_monitor_mode && !mapMonitorLegendIsOpen"
    shape="round"
    (click)="mapMonitorLegendAction($event)"
    button
    mode="md"
  >
    <div class="circle-pulse" src=""></div>
  </ion-item>

  <ion-item
    class="content monitor-mode"
    *ngIf="userSettings?.map_monitor_mode && mapMonitorLegendIsOpen"
    lines="none"
    #mapMonitorLegend
  >
    <div class="circle-pulse" src="" slot="start"></div>
    {{ "appSettings.performance.monitorModeLegend" | translate }}
    <ion-icon
      name="information-circle-outline"
      color="primary"
      slot="end"
      (click)="
        showInfoPopup(
          $event,
          'appSettings.performance.monitorModeLegend',
          'appSettings.performance.monitorMode',
          null,
          null
        )
      "
    ></ion-icon>
  </ion-item>
</div>

<div
  *ngIf="!petmodeUI"
  id="map-bottom-controls"
  class="main-map-controls"
  [class.middle-break]="deviceMenuOpen"
  [class.device-menu-open-content]="deviceMenuOpen || dashboardMenuIsOpen"
  [class.left-sidebar-open]="mainMenuOpen"
>
  <div class="first-section">
    <ng-container
      [ngTemplateOutlet]="sliderTemplate ? sliderTemplate : null"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="timerTemplate ? timerTemplate : null"
    ></ng-container>
  </div>

  <div class="second-section">
    <ng-container [ngTemplateOutlet]="FabButtons"></ng-container>

    <div
      class="legends-section"
      [class.legends-section-for-tabs]="hasTabBar"
      [hidden]="hideLegends"
    >
      <!-- HEATMAP LEGENDs -->
      <div
        class="content"
        *ngIf="heatMapClass?.isEnabled && !isSliderOpen && !fabValue"
        @fadeIn
      >
        <div class="gradient-segment heatmap">
          <div>
            <span>{{ "map.heatmapLessPoints" | translate }}</span>
          </div>
          <div>
            <span>{{ "map.heatmapManyPoints" | translate }}</span>
          </div>
        </div>
        <p class="gradient-segment-name">
          {{ "dashboard.heatmapText" | translate }}
        </p>
      </div>

      <!-- SPEED POLYLINES LEGENDs -->
      <div
        class="content"
        *ngIf="
          HighSpeedPolylinesClass?.show &&
          HighSpeedPolylinesClass?.show_speed_btn &&
          !isSliderOpen &&
          !fabValue
        "
        @fadeIn
      >
        <div class="gradient-segment">
          <div class="slow">
            <span
              >0-{{
                HighSpeedPolylinesClass.SPEED_RANGES[0].max +
                  " " +
                  user?.distance_unit
              }}/h
            </span>
          </div>
          <div class="medium">
            <span
              >{{ HighSpeedPolylinesClass.SPEED_RANGES[1].min }}-{{
                HighSpeedPolylinesClass.SPEED_RANGES[1].max +
                  " " +
                  user?.distance_unit
              }}/h</span
            >
          </div>
          <div class="high">
            <span
              >+{{
                HighSpeedPolylinesClass.SPEED_RANGES[2].min +
                  " " +
                  user?.distance_unit
              }}/h
            </span>
          </div>
        </div>
        <p class="gradient-segment-name">
          {{ "appSettings.speedPolylines" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
