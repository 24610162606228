import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeviceData } from './components/map/class/deviceData';
import { AppService } from './../../app.service';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { GlobalEventService } from 'src/app/services/globa-event.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/services/storage.service';
import { Constants } from 'src/app/constants.enum';
import polyline from './components/map/class/polylines';
import { liveTrackingDevicesHandle } from './liveTrackingDevices';
import { RouteService } from './devices-sidebar/device-item/device-submenu/route/route.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from 'src/app/services/websocket.service';
import { DeviceListService } from './devices-sidebar/devices-list/device-list.service';
import { MapService } from './components/map/map.service';
import { CancelablePromise } from 'src/app/util/cancelablePromise';
import {
  ExchangeTripSegmentByCoors,
  getGraphhopperLegs,
} from './components/map/class/mapUtil';
import { UserActivityService } from 'src/app/services/user-activity.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService {
  //Device data:
  public _devices = new Subject<Array<DeviceData>>();
  devices$ = this._devices.asObservable();
  public devices = {} as Array<DeviceData>;
  //route-Date Range
  dateRange = new Subject<{}>();

  // Used to update last location of the device
  public _newDevice = new Subject<DeviceData>();
  newDevice$ = this._newDevice.asObservable();

  // Used to update last location of the device
  public _deviceMoved = new Subject<DeviceData>();
  deviceMoved$ = this._deviceMoved.asObservable();

  // Used when the device has new datapoints ( to add or create polylines)
  public _newDataPoint = new Subject<DeviceData>();
  newDataPoint$ = this._newDataPoint.asObservable();

  // Used when the device has new snaped points ( to add or create polylines)
  public _statusChanged = new Subject<DeviceData>();
  statusChanged$ = this._statusChanged.asObservable();

  // Use for alarms component, to update device model data
  public _gotDeviceModel = new Subject<DeviceData>();
  gotDeviceModel$ = this._gotDeviceModel.asObservable();

  // Used when the device has new snaped points ( to add or create polylines)
  public _initializeMap = new Subject<DeviceData[]>();
  initializeMap$ = this._initializeMap.asObservable();

  // Used to update last location of the device
  public _loadingData = new Subject<boolean>();
  public loadingData$ = this._loadingData.asObservable();

  // Used to update device data
  public _deviceUpdated = new Subject<DeviceData>();
  deviceUpdated$ = this._deviceUpdated.asObservable();

  lastPositionsReady = new Subject<DeviceData[]>();

  // Used to flag when route data is ready in device objects
  // public _routeDataInitiallyLoaded = new Subject<DeviceData[]>();
  // routeDataInitiallyLoaded$ = this._routeDataInitiallyLoaded.asObservable();

  user = null;
  devicesIds = [];
  enabledDevicesIds = [];
  socketDevicesIds = [];
  otherDevicesIds = [];
  socketAllowedDevices = 32;
  timerUpdate;
  onlyDevices = [];
  devicesSubscribe;
  isInitialized = false;
  public notificationsReady = new Subject();
  public wifiHomeMarkersReady = new Subject();
  public geofencesReady = new Subject();
  public allPointsReady = new Subject();
  allPointsLoaded = false;
  isPaused = false;

  wssURL = '';
  liveTrackinHandler: liveTrackingDevicesHandle;
  socketPoints = [];
  //_ Used to not update the route tab distance from snapedpoints when is only requesting data
  //_ For the slider view
  sliderDeviceId = null;
  constructor(
    private authService: AuthenticationService,
    private appService: AppService,
    private apiService: ApiService,
    private permissionsService: PermissionsService,
    private globalEventService: GlobalEventService,
    private http: HttpClient,
    private storage: StorageService,
    private routeService: RouteService,
    private _translate: TranslateService,
    private websocketService: WebsocketService,
    private mapService: MapService,
    private userActivity: UserActivityService,
  ) {
    //this.init();
    //this.getFirstData();
  }

  async init() {
    this._loadingData.next(true);

    if (this.appService.devices) this.assignDevices(this.appService.devices);

    // Get devices data from server
    if (this.devicesSubscribe) this.devicesSubscribe.unsubscribe();

    this.devicesSubscribe = this.appService._devices.subscribe((r: any) => {
      // console.warn('[DEBUG] DEVICES LOADED');
      this.assignDevices(r);
    });

    this.globalEventService.getMessageCode().subscribe((data) => {
      if (data == 'STOP_BACKGROUND_API') {
        this.stopTimerUpdate();
        this.websocketService.close();
      }
    });

    this.userActivity.visibility$.subscribe((res) => {
      if (res) {
        if (this.allPointsLoaded && this.isPaused) {
          // console.log('RESUME REQUESTING MAP DATA');
          this.updateLastPosition();
        }
      }
    });

    this.isInitialized = true;
  }

  //_ Only used to initialize the basics data to show the list of devices in device-managament page
  liteInit() {
    this._loadingData.next(true);
    this.onlyDevices = this.appService.devices;
    this.user = this.appService.user;

    const tempData = Array<DeviceData>();
    this.devicesIds = [];
    this.enabledDevicesIds = [];

    this.appService.devices.forEach((dev) => {
      const newDev = this.appService.userSettings.hide_all_devices_default
        ? { ...dev, deviceshow: 0 }
        : dev;
      tempData[newDev.id] = new DeviceData(newDev);
      if (newDev.deviceshow == 1) this.enabledDevicesIds.push(newDev.id);
    });

    this.devicesIds = Object.keys(tempData);
    this.devices = tempData;

    this.otherDevicesIds = this.enabledDevicesIds.splice(
      this.socketDevicesIds.length,
    );

    this.isInitialized = true;
    this._initializeMap.next(this.devices);
  }

  async assignDevices(devices) {
    this.onlyDevices = devices;
    this.user = this.appService.user;
    // this.user.snap_to_road = false; //_ Debug for not snaped route flag ...

    const tempData = Array<DeviceData>();
    this.devicesIds = [];
    this.enabledDevicesIds = [];

    devices.forEach((dev) => {
      const newDev = this.appService.userSettings.hide_all_devices_default
        ? { ...dev, deviceshow: 0 }
        : dev;

      tempData[newDev.id] = new DeviceData(newDev);
      if (newDev.deviceshow == 1) this.enabledDevicesIds.push(newDev.id);
    });

    this.devicesIds = Object.keys(tempData);
    await this.getFirstData(tempData);

    if (this.appService.userSettings.enable_socket) {
      this.socketDevicesIds = this.enabledDevicesIds.splice(
        0,
        this.socketAllowedDevices,
      );
      await this.initiateSocket(this.socketDevicesIds);
    }
    this.otherDevicesIds = this.enabledDevicesIds.splice(
      this.socketDevicesIds.length,
    );

    this.startTimerUpdate();
  }

  // Loading all first data from the api and update each device
  // object into the Devices list variable of this class
  async getFirstData(devices) {
    // Get logged user for use his map settings for the devices
    ///this.appService.user$.subscribe( r => this.user = r );

    // Wait until devices exist or change.
    // Here initialize all logic and call to initialize subject
    // For first loading on the map

    // Setted this.devices is the list of all the devices
    // This variable is used to all the functions to update the device object
    // Of each devices item
    try {
      this.devices = devices;

      //_ Load permissions
      this.loadPermissions();

      // Get last location.
      await this.startLastPosition(this.enabledDevicesIds);

      // console.warn('[DEBUG] INITIALIZE DEVICE DATA ', devices);
      // Trigger initializeMap subject to draw the map for first time
      this._initializeMap.next(this.devices);
    } catch (error) {
      this.appService.onLoadCustomerDataError.next(error);
    }
    //_ Initialize liveTracking
    this.liveTrackinHandler = new liveTrackingDevicesHandle(this);
    this.liveTrackinHandler.Initialize();

    //Get geofences:
    this.getGeoFences(this.devicesIds); //_ async

    // Get All dataPoints
    // And initialize the data for each device in the list
    //_ Only get alldatapoints if monitorMode is false
    if (this.mapService.mapMonitorMode.value) {
      this.allPointsLoaded = true;
      this.allPointsReady.next(this.devices);
    } else await this.getAllPoints(); //_ has to be await to get first and last point for notifications

    this.getNotifications(this.enabledDevicesIds); //_ async

    this.getWifiHomeMarkers(this.enabledDevicesIds);

    // Update Last positions interval
    // Each 30 seconds call updateLastPosition to trigger new newDataPoint, directions and move the marker
    // this.startTimerUpdate();
  }

  async initiateSocket(socketDevicesIds) {
    this.startSocket(socketDevicesIds);
  }

  startTimerUpdate() {
    if (this.timerUpdate) {
      this.stopTimerUpdate();
      this.timerUpdate = undefined;
    }

    if (
      this.userActivity.platform.isDesktopBrowser() &&
      this.userActivity.getAppInactiveTime() <
        this.userActivity.TIME_TO_PAUSE_ACTIVITY
    ) {
      //_ 5 minutes pause time
      this.isPaused = false;
      this.timerUpdate = setInterval((x) => {
        this.updateLastPosition();
      }, 10000);
    } else if (this.userActivity.platform.isDesktopBrowser()) {
      // console.log ('STOP REQUESTING DATA - user left the page');
      this.isPaused = true;
    } else {
      this.timerUpdate = setInterval((x) => {
        this.updateLastPosition();
      }, 10000);
    }
  }

  startSocket(enabledDevicesIds) {
    this.authService.socketInit(enabledDevicesIds).then(
      (response) => {
        if (response) {
          let wssURL = response['wss'];
          if (response['softReject']) {
            let softRejectedDeviceIds = this.onlyDevices
              .filter(
                (device) => response['softReject'].indexOf(device.imei) > -1,
              )
              .map((x) => x.id);
            this.otherDevicesIds.push(...softRejectedDeviceIds);
          }
          this.websocketService.connect(wssURL);
          this.websocketService.messages$.subscribe((msg) => {
            this.handleWebsocketResponse(this.websocketService, msg, wssURL);
          });
        } else {
          this.otherDevicesIds.push(...enabledDevicesIds);
        }
      },
      (error) => {
        this.otherDevicesIds.push(...enabledDevicesIds);
      },
    );
  }

  reconnectSocket(websocketService, wssURL) {
    websocketService.connect(wssURL);
    websocketService.messages$.subscribe((msg) => {
      this.handleWebsocketResponse(this.websocketService, msg, wssURL);
    });
  }

  handleWebsocketResponse(websocketService, msg, wssURL) {
    let receivedDevice = null;
    if (msg == 'ready') {
      console.log(msg);
    } else if (msg == 'hb') {
      this.websocketService.sendMessage(msg);
    } else if (msg == 'ok') {
      console.log(msg);
    } else if (msg == 'timeout') {
      this.reconnectSocket(websocketService, wssURL);
    } else {
      receivedDevice = JSON.parse(msg);
      if (receivedDevice['type'] == 'LOCATION') {
        let point = {
          id: receivedDevice['data']['id'],
          lat: receivedDevice['data']['lat'],
          lng: receivedDevice['data']['lon'],
          direction: receivedDevice['data']['direction'],
          dateunix: receivedDevice['data']['time'],
          battery: receivedDevice['data']['battery'],
          speed: receivedDevice['data']['spd'],
          iddevice: receivedDevice['myId'],
          steps: 0,
          heartbeat: 0,
          accuracy: 0,
          wifi: null,
          note: null,
        };
        this.socketPoints.push(point);
        this.savePoint(point, true);
      } else if (receivedDevice['type'] == 'NOTIFICATION') {
        console.log(receivedDevice);
      }
    }
  }

  stopTimerUpdate() {
    clearInterval(this.timerUpdate);
  }

  async getAllPoints() {
    const deviceIDs = this.getRequestProps(this.enabledDevicesIds);
    const postData = {
      deviceIDs,
      wifi: 1,
      gps: 1,
    };
    // await this.http.post(ENV.ROUTE + '/api/trackerdata/getalldatapoints', postData).subscribe(
    // res => {
    //     return res;
    // });

    await this.authService
      .getAllDatapoints(deviceIDs)
      .then(async (res) => {
        if (res.success.allData.length > 0) {
          // If has datapoints

          res.success.allData.forEach((data) => {
            // The data points need to be reorder by the dateunix propertie
            if (data.datapoints && Array.isArray(data.datapoints)) {
              data.datapoints = data.datapoints.sort((a, b) =>
                a.dateunix > b.dateunix ? 1 : -1,
              );
              this.devices[data.device.id].init(data.datapoints);
              //this._routeDataInitiallyLoaded.next(this.devices);
            }

            //_ Wrong lat or lng data
            if (data.faulty_markers.length > 0)
              console.error('Wrong Lat or Lng', data.faulty_markers);
          });
          setTimeout(() => {
            //Prevents a page loading error that prevents points on the map from loading.
            this.allPointsLoaded = true;
            this.allPointsReady.next(this.devices);
          }, 700);
        } else return [];
      })
      .catch((e) => {
        return [];
        //Catch error ...
      });
  }

  //_ Get Device data points
  //_ Get only for one device
  async getDataPoints(ids) {
    ids = this.getRequestProps(ids);
    return this.authService
      .getAllDatapoints(ids)
      .then(async (res) => {
        if (res.success.allData.length > 0)
          // If has datapoints
          return res.success.allData[0];
        else return [];
      })
      .catch((error) => console.log('ERROR: ', error));
  }

  // Get points from Valhalla service
  // The response from api for pauseMarkers are
  // data = End position
  // pause_start = startPosition
  // pause_display = ?
  // device
  getSnapedAndPausePoints2(
    dataPoints,
    length = 0.05,
    snapFlag = true,
    device = null,
  ) {
    //console.log('deviceData.service-getSnapedAndPausePoints2-device',device);
    //let snapType = device?.properties.route_profile;

    //_ Filter datapoints to ignore wifi points
    const dataPointsT = dataPoints.filter((pt) => pt.wifi == null);
    const isDateRange = device?.properties.spurmodus == 4;
    const cancelablePromise = new CancelablePromise<any>(
      (resolve, reject) => {},
    );
    if (dataPointsT.length > 0 || isDateRange) {
      const startDate = isDateRange
        ? device.properties.spurdatum
        : dataPointsT[0].dateunix;
      const endDate = isDateRange
        ? device.properties.spurdatumbis
        : dataPointsT[dataPointsT.length - 1].dateunix;
      const idDevice = isDateRange ? device.id : dataPointsT[0].iddevice;

      const valhallaRequest = this.authService
        ._getValhallaResponses({
          firstId: startDate,
          lastId: endDate,
          iddevice: idDevice,
          snapFlag,
          pause_time: this.appService.userSettings.pause_time,
          useGraphhopper: this.appService.userSettings.use_graphhopper ?? 0,
        })
        .subscribe((res: any) => {
          // If get response from vallhalla, then send the points
          // to the function getLegs to get array of legs (line strings) of the route
          if (res)
            if (res.success)
              cancelablePromise.resolvePromise({
                snapPoints: this.getLegs(res.success, length, idDevice),
                pauses: res.success.pause_markers,
              });
          cancelablePromise.resolvePromise({ snapPoints: [], pauses: [] });
        });

      cancelablePromise.setHttpSubscription(valhallaRequest);
    } else cancelablePromise.resolvePromise({ snapPoints: [], pauses: [] });

    return cancelablePromise;
  }

  getSnapedAndPausePoints(
    dataPoints,
    length = 0.0001,
    snapFlag = true,
    device = null,
    useDatapoints = false,
  ): CancelablePromise<any> {
    //_ Filter datapoints to ignore wifi points
    const dataPointsT = dataPoints.filter((pt) => pt.wifi == null);
    const isDateRange = device?.properties.spurmodus == 4;
    let subscription = null;
    const promise = new CancelablePromise((resolve, reject) => {
      if (dataPointsT.length > 0 || isDateRange) {
        const firstId = isDateRange
          ? device.properties.spurdatum
          : dataPointsT[0].dateunix;
        const lastId = isDateRange
          ? device.properties.spurdatumbis
          : dataPointsT[dataPointsT.length - 1].dateunix;
        const iddevice = isDateRange ? device.id : dataPointsT[0].iddevice;

        const postData = {
          firstId: firstId,
          lastId: lastId,
          iddevice,
          snapFlag,
          pause_time: this.appService.userSettings?.pause_time ?? 3,
          useGraphhopper: this.appService.userSettings.use_graphhopper ?? 0,
        };

        //_ Send datapoints to snap using those points
        if (useDatapoints) {
          postData['dataPoints'] = dataPointsT;
          postData['useDatapoints'] = useDatapoints;
        }

        subscription = this.authService
          ._getValhallaResponses(postData)
          .subscribe((res: any) => {
            // console.log('GET VALHALLA CANCELABLE PROMISE', res);
            // If get response from vallhalla, then send the points
            // to the function getLegs to get array of legs (line strings) of the route
            if (res?.success) {
              this.assignDistanceToDevice(iddevice, res.success);
              this.devices[dataPointsT[0].iddevice].tripsData =
                ExchangeTripSegmentByCoors(res.success);
              resolve({
                snapPoints: this.getLegs(res.success, length, iddevice),
                pauses: res.success.pause_markers,
              });
            } else {
              this.assignDistanceToDevice(iddevice, {
                success: { optimized_distance: 0 },
              });
              resolve({ snapPoints: [], pauses: [] });
            }
          });
      } else resolve({ snapPoints: [], pauses: [] });
    });

    promise.setHttpSubscription(subscription);
    return promise;
  }

  assignDistanceToDevice(deviceId, data) {
    let device = this.appService.devices?.find(
      (device) => device.id == deviceId,
    );

    if (device) {
      device['tripDistance'] = data.ptop_distance ? data.ptop_distance : 0;
      device['optimizedDistance'] = data.optimized_distance
        ? data.optimized_distance
        : data.ptop_distance
          ? data.ptop_distance
          : 0;
      this.routeService.distanceUpdated.next(device);
    }
  }

  // Function used to get an array of str legs getted from Valhalla
  // Lenght is the distance of the line string, used to filter the response
  getLegs(vallResponse, length, deviceId = null) {
    let shapes = [];
    if (vallResponse.trips) {
      if (this.appService.userSettings.use_graphhopper == 0) {
        vallResponse.trips.forEach((trip0) => {
          if (trip0.trip) {
            let trip1 = trip0.trip;
            // If distance of the trip is > length
            if (trip1.trip) {
              trip1.trip.legs.forEach((leg) => {
                // If distance of the leg is > length then add the shape to the return shape variable
                if (leg.summary.length > length) {
                  shapes.push(leg.shape);
                }
              });
            } else {
              //_ Autogenerate string legs for missing valhalla response path
              //_ Invert lng, lat > lat, lng
              let coors = trip0.coordinates.map((c) => [c[1], c[0]]);
              // coors = this.getPointsRange(coors[0], coors[coors.length-1], deviceId);
              const newShapeStr = polyline.encode(coors, 6);
              shapes.push(newShapeStr);
            }
          } else {
            //_ Need more test, because some times line starts before|after prevous snaped segment line
            // console.log('Trip dont have sub trip', trip0);
            let coors = trip0.coordinates.map((c) => [c[1], c[0]]);
            const newShapeStr = polyline.encode(coors, 6);
            shapes.push(newShapeStr);
          }
        });
      } else if (this.appService.userSettings.use_graphhopper == 1) {
        const graphData = getGraphhopperLegs(vallResponse);
        shapes = graphData.shapes;
      }
    }

    return shapes;
  }

  //_ Get points from Datapoints between two points passed
  getPointsRange(pA, pB, deviceId) {
    const dp = this.devices[deviceId].dataPoints.map(
      (p) => p.lat + '-' + p.lng,
    );
    const startIndex = dp.indexOf(pA[0] + '-' + pA[1]);
    const endIndex = dp.indexOf(pB[0] + '-' + pB[1]);
    const rangePoints = this.devices[deviceId].dataPoints
      .slice(startIndex, endIndex + 1)
      .map((p) => [p.lat, p.lng]);
    return rangePoints;
  }

  // Use to update all data for all the devices
  // Update Last Positions
  // Update all position and markers
  async updateLastPosition() {
    this.stopTimerUpdate();
    this._loadingData.next(true);
    if (this.otherDevicesIds.length) {
      await this._updateLastPosition(this.otherDevicesIds);
    } else {
      this.updateOnlineStatus(null, true);
      this._loadingData.next(false);
    }
    this.startTimerUpdate();
  }

  //_ Delegate handler to update only the devicesIds passed as parameters
  async _updateLastPosition(devicesIds) {
    const lastPointsData = Object.values(this.devices)
      .filter((d) => devicesIds.indexOf(d.id) !== -1)
      .map((d) => {
        return { deviceId: d.id, dateunix: d.lastPoint?.dateunix - 1 };
      });

    await this.authService
      .getAllLastPositionsPost(lastPointsData, true)
      .then(async (res) => {
        if (res.success) {
          //_ Save in local storage last position
          this.storage.set('LASTPOINTS.DATETIME', moment().unix());
          const lastPoints = Object.keys(res.success).map(
            (k) => res.success[k][res.success[k].length - 1],
          );
          await this.storage.set(Constants.LASTPOINTS, lastPoints);

          Object.keys(res.success).forEach(async (deviceId) => {
            if (this.devices[deviceId]) {
              const deviceLastPoints = res.success[deviceId];
              const deviceWithoutLastpoint =
                this.devices[deviceId].lastPoint == null;
              if (
                deviceLastPoints.length > 1 ||
                (deviceWithoutLastpoint && deviceLastPoints.length == 1)
              ) {
                //_ Always shuold return more than 1; the first one is the previous lastPoint
                // deviceLastPoints.shift(); //_ Remove first point
                const device = this.devices[deviceId];
                const lastPoint = deviceLastPoints[deviceLastPoints.length - 1];
                // this.appService.showToast('DEBUG', 'Device: ' + device.properties.name + ' - newPoints: ' + deviceLastPoints.length, 3000, 'danger');
                // If are differents then trigger deviceMove observable
                // And add new dataPoint and trigger newDatapoint
                if (
                  (device.lastLocation[1] != lastPoint.lat &&
                    device.lastLocation[0] != lastPoint.lng &&
                    lastPoint.dateunix > device.lastPoint?.dateunix) ||
                  deviceWithoutLastpoint
                ) {
                  if (deviceWithoutLastpoint)
                    this.setupLastPosition(deviceLastPoints);
                  this.devices[deviceId].previousLastLocation = [
                    device.lastLocation[0],
                    device.lastLocation[1],
                  ];
                  this.devices[deviceId].realtimePoints =
                    this.devices[deviceId].realtimePoints.concat(
                      deviceLastPoints,
                    );
                  this.devices[deviceId].newRealtimePoints = deviceLastPoints;
                  this.devices[deviceId].realtimeDirectionMarkers =
                    this.devices[deviceId].realtimeDirectionMarkers.concat(
                      deviceLastPoints,
                    );
                  this.devices[deviceId].newDirectionMarkers = deviceLastPoints;
                  this.devices[deviceId].lastLocation = [
                    lastPoint.lng,
                    lastPoint.lat,
                  ];
                  this.devices[deviceId].lastPoint = lastPoint;
                  this.devices[deviceId].unSnapedPoints =
                    this.devices[deviceId].unSnapedPoints.concat(
                      deviceLastPoints,
                    );
                  this.devices[deviceId].pointsForPauses =
                    this.devices[deviceId].pointsForPauses.concat(
                      deviceLastPoints,
                    );
                  // Trigger the subject, to move the device with all data updated
                  this._deviceMoved.next(this.devices[deviceId]);
                }

                // check if the device is online or not

                this.updateOnlineStatus(lastPoint);
              }
            }
          });
          // const lastPointsToSave = Object.keys(this.devices).map(k => this.devices[k].lastPoint);
          // console.log('LAST POINTS TO SAVE **', lastPointsToSave);
          // await this.storage.set(Constants.LASTPOINTS, lastPointsToSave);
        }
        this._loadingData.next(false);
      })
      .catch((error) => {
        this._loadingData.next(false);
      });
  }

  savePoint(point, isSocket = false) {
    const device = this.devices[point.iddevice];
    const lastPoint = this.devices[point.iddevice].lastPoint;
    // If are differents then trigger deviceMove observable
    // And add new dataPoint and trigger newDatapoint
    if (
      device.lastLocation[1] != point.lat &&
      device.lastLocation[0] != point.lng &&
      point.dateunix > lastPoint.dateunix
    ) {
      this.devices[point.iddevice].previousLastLocation = [
        device.lastLocation[0],
        device.lastLocation[1],
      ];
      this.devices[point.iddevice].realtimePoints.push(point);
      this.devices[point.iddevice].newRealtimePoints = [lastPoint, point];
      this.devices[point.iddevice].realtimeDirectionMarkers.push(point);
      this.devices[point.iddevice].newDirectionMarkers = [lastPoint, point];
      this.devices[point.iddevice].lastLocation = [point.lng, point.lat];
      this.devices[point.iddevice].lastPoint = point;
      this.devices[point.iddevice].unSnapedPoints.push(point);
      this.devices[point.iddevice].pointsForPauses.push(point);
      // Trigger the subject, to move the device with all data updated
      this._deviceMoved.next(this.devices[point.iddevice]);
    }

    // check if the device is online or not

    this.updateOnlineStatus(point, isSocket);
  }

  // Update Last Location of each device stored in the devices array list of this class.
  // No one subject is trigger.
  // This function is called only onece before the map initialized subject is tregger
  async startLastPosition(devicesId) {
    //_ Get last position from storage
    return new Promise(async (resolve, reject) => {
      const storedPoints = await this.storage.get(Constants.LASTPOINTS);
      // const time = await this.storage.get('LASTPOINTS.DATETIME');
      // const dtime = time ? time : moment().unix(); //_ catch if doesnt exist datetime of lastpoints

      // if (storedPoints && moment().diff(moment.unix(dtime), 'minutes') < 5 || (storedPoints && useStorage)) {
      if (storedPoints) {
        if (storedPoints.length > 0) {
          this.setupLastPosition(storedPoints);
          // Trigger lastlocations to load the markers on the map
          this.lastPositionsReady.next(this.devices);
          // resolve(true);
        }
      }
      // else{
      // await new Promise( (resolve) => { setTimeout( () => resolve(true), 5000) } );
      await this.authService
        .getAllLastPositionsPost(devicesId)
        .then(async (res) => {
          if (res.success) {
            //_ Save in local storage last position
            this.storage.set(Constants.LASTPOINTS, res.success);
            this.setupLastPosition(res.success);
          }
          resolve(true);
        });
      // }
    });
  }

  //_ Add to the last location storaged
  async getLastPosition(devicesId) {
    //_ Get last position from storage
    return new Promise(async (resolve, reject) => {
      await this.authService
        .getAllLastPositionsPost(devicesId)
        .then(async (res) => {
          if (res.success) {
            this.setupLastPosition(res.success);
            const lastStoragedLocations = await this.storage.get(
              Constants.LASTPOINTS,
            );
            //_ Push new last location on storaged locations
            if (lastStoragedLocations && res.success[0]) {
              lastStoragedLocations.push(res.success[0]);
              await this.storage.set(
                Constants.LASTPOINTS,
                lastStoragedLocations,
              );
            }
          }
          resolve(true);
        });
    });
  }

  setupLastPosition(points) {
    //_ If there is no points in the response
    //_ should use the lat lng of the device to show the last location
    // if (points.length == 0){
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      var device = this.devices[point.iddevice];
      if (point && device) {
        // If are differents then trigger deviceMove observable
        // And add new dataPoint and trigger newDatapoint
        device.lastPoint = point;
        device.realtimePoints.push(point);
        device.newRealtimePoints.push(point);
        device.online =
          moment().diff(moment.unix(point.dateunix), 'minutes') < 10;

        // Adding the point to direction markers.
        device.lastLocation = [point.lng, point.lat];
        device.previousLastLocation = [point.lng, point.lat];
      }
    }
    // }else{
    // this.devices.forEach( d => {
    //     const point = [d.properties.lat, d.properties.lng];
    //     d.lastPoint = point;
    //     // Adding the point to direction markers.
    //     device.lastLocation = [point.lng, point.lat];
    //     device.previousLastLocation = [point.lng, point.lat];
    // })
    // }
  }

  updateOnlineStatus(point, isSocket = false) {
    // Check if time to show the pulse effect is less than 10 minutes.
    // And if the device online propertie is different to isOnline status
    if (point && point !== 'undefined') {
      if (isSocket) {
        Object.keys(this.devices).forEach((deviceId) => {
          let deviceLastPoint = this.devices[deviceId]['lastPoint'];
          if (deviceLastPoint) {
            let isOnline =
              moment().diff(
                moment.unix(deviceLastPoint['dateunix']),
                'minutes',
              ) < 3; //was 10
            if (
              isOnline != this.devices[deviceId].online &&
              this.devices[deviceId] != point.iddevice
            ) {
              this.devices[deviceId].online = isOnline;
              this._statusChanged.next(this.devices[deviceId]);
            } else {
              this.devices[point.iddevice].online = true;
              this._statusChanged.next(this.devices[point.iddevice]);
            }
          }
        });
      } else {
        if (this.devices[point.iddevice]) {
          let isOnline =
            moment().diff(moment.unix(point.dateunix), 'minutes') < 3; //was 10
          if (isOnline != this.devices[point.iddevice]?.online) {
            this.devices[point.iddevice]['online'] = isOnline;
            this._statusChanged.next(this.devices[point.iddevice]);
          }
        }
      }
    } else {
      Object.keys(this.devices).forEach((deviceId) => {
        let deviceLastPoint = this.devices[deviceId]['lastPoint'];
        if (deviceLastPoint) {
          let isOnline =
            moment().diff(moment.unix(deviceLastPoint['dateunix']), 'minutes') <
            3; //was 10
          this.devices[deviceId].online = isOnline;
          this._statusChanged.next(this.devices[deviceId]);
        }
      });
    }
  }

  // Return an array with the lat, lng of all the devices
  // Used to set the bound of the map to see all devices
  getDevicesBound() {
    let points = [];
    Object.values(this.devices).forEach((d) => {
      // Only add devices that is showing in the map
      // And has a lastPoint => the same to add the marker in the map
      if (d.properties.deviceshow && d.lastPoint)
        if (d.lastLocation) points.push(d.lastLocation);
    });
    return points;
  }

  // Get Geofences from the api
  async getGeoFences(devicesId) {
    let resData = [];
    if (devicesId.length > 0) {
      await this.authService
        .getGeoFences(devicesId)
        .then((res) => {
          if (res.success.length > 0) resData = res.success;
        })
        .catch((e) => {});

      // Assing geofences in device properties
      Object.values(this.devices).forEach((d) => {
        if (resData) {
          resData.forEach((geofence) => {
            geofence.prevName = geofence.name;
            if (d.id == geofence.iddevice)
              this.devices[d.id].geofences.push(geofence);
            // d.geofences.push(geofence);
          });
        }
      });
      this.geofencesReady.next(this.devices);
    }
  }

  // Update geofence when change location or size, this function is called
  // From geofence_center.ts file
  //
  setGeofence(geofenceNew) {
    console.log({
      iddvice: this.devices[geofenceNew.iddevice],
      geofenceNew,
      devices: this.devices,
    });
    let index = this.devices[geofenceNew.iddevice].geofences.findIndex(
      (obj) => obj.id === geofenceNew.id,
    );
    this.devices[geofenceNew.iddevice].geofences[index] = geofenceNew;
    this._devices.next(this.devices);
  }

  //_ Use to update device data
  //_ Used in device managment page
  updateDeviceInfo(device) {
    this.devices[device.id].properties = device.properties;
    this.devices[device.id].color = device.properties.spurfarbe;
    this.devices[device.id].properties.share_link =
      device.properties.share_link;
    this.devices[device.id].properties.threedModel_name =
      device.properties.threedModel_name;
    this.devices[device.id].properties.live_tracking_start_time =
      device.properties.live_tracking_start_time;
    this.devices[device.id].properties.live_tracking_duration =
      device.properties.live_tracking_duration;
    this.devices[device.id].properties.deviceshow =
      device.properties.deviceshow;
    this.devices[device.id].geofences = device.geofences;

    //_ Update wifi markers
    if (device.wifiMarkers)
      this.devices[device.id].wifiMarkers = device.wifiMarkers;

    this._deviceUpdated.next(this.devices[device.id]);
  }

  //_ Get devie Permissions
  //_ And store in permissions variable of the deviceData
  loadPermissions() {
    this.permissionsService.getPermissions(this.user.id).then((r) => {
      Object.values(this.devices).forEach((d) => {
        if (r[d.id])
          d.permissions = {
            see: r[d.id].see,
            modify: r[d.id].modify,
            share: r[d.id].share,
          };
      });
    });
  }

  //_ Get all notifications by date range
  getNotifications(devices) {
    const data = [];
    //_ Create array with deviceId and date range
    devices.forEach((id) => {
      const device = this.devices[id];
      if (device.dataPoints.length > 0 || device.properties.spurmodus === 4) {
        //_ Get data if the device has datapoints
        const isDateRange = device.properties.spurmodus == 4;
        const startDate = isDateRange
          ? device.properties.spurdatum
          : device.dataPoints[0].dateunix;
        const endDate = isDateRange
          ? device.properties.spurdatumbis
          : device.dataPoints[device.dataPoints.length - 1].dateunix;
        data.push({ id, startDate, endDate });
      }
    });

    this.authService
      .getAllNotifications(data)
      .then((response: any) => {
        const notifications = response.success;
        Object.keys(notifications).forEach((deviceId) => {
          this.devices[deviceId].notifications = notifications[deviceId];
        });
        this.notificationsReady.next(this.devices);
      })
      .catch((error) =>
        this.appService.showToast(
          '',
          this._translate.instant('toast.errorToGetNotification'),
          3000,
          'danger',
        ),
      );
    this._loadingData.next(false);
  }

  async getOnlySnapedPoints(dataPoints, length = 0.05, device) {
    if (dataPoints.length > 0) {
      //_ Add routing options if userSetting use graphhopper
      let routingOptions = null;
      // this.appService.userSettings.use_graphhopper = 1;
      const useGraphhopper = this.appService.userSettings.use_graphhopper == 1;

      if (device && useGraphhopper)
        routingOptions = {
          useGraphhopper: useGraphhopper,
          device: {
            route_profile: device.route_profile,
            route_accuracy: device.route_accuracy,
          },
          model: device.device_models,
        };
      return await this.apiService
        .getPolySTR(dataPoints, routingOptions)
        .then((res: any) => {
          if (res) {
            if (useGraphhopper) {
              const graphShapes = getGraphhopperLegs({
                trips: [{ trip: res.data }],
              });
              return graphShapes.shapes;
            } else {
              let shapes = [];
              if (res.data) {
                if (res.data.trip) {
                  res.data.trip.legs.forEach((leg) => {
                    // If distance of the leg is > length then add the shape to the return shape variable
                    if (leg.summary.length > length) {
                      shapes.push(leg.shape);
                    }
                  });
                }
              }

              return shapes;
            }
          }
        })
        .catch((error) => {
          // console.log('[LIVE_TRCKING] ERROR FROM SERVER', error);
          return [];
        });
    } else return [];
  }

  async enableDevice(deviceId) {
    this.enabledDevicesIds.push(deviceId);
    //_ Check if has time remain then, should start live tracking timer
    if (!this.liveTrackinHandler.shouldStop(deviceId))
      this.liveTrackinHandler.startLiveTracking(deviceId);

    return this.getLastPosition([deviceId]);
  }

  async disableDevice(deviceId) {
    //_ If is running live tracking timer, should stop
    if (this.liveTrackinHandler.isRunning(deviceId)) {
      this.liveTrackinHandler.stopLiveTracking(deviceId);
    } else
      this.enabledDevicesIds.splice(
        this.enabledDevicesIds.indexOf(deviceId),
        1,
      );

    //_ Delete the last position on the storaged locations
    const lastStoragedLocations = await this.storage.get(Constants.LASTPOINTS);
    const index = lastStoragedLocations
      .map((p) => p.iddevice)
      .indexOf(deviceId);
    if (index != -1) lastStoragedLocations.splice(index, 1);

    await this.storage.set(Constants.LASTPOINTS, lastStoragedLocations);
  }

  getRequestProps(devicesIds) {
    const data = [];
    devicesIds.forEach((dId) => {
      const device = this.devices[dId];
      data.push({
        id: device.id,
        spurmodus: device.properties.spurmodus,
        spurminuten: device.properties.spurminuten,
        spurpunkte: device.properties.spurpunkte,
        selected_date_range: device.properties.selected_date_range,
        spurdatum: device.properties.spurdatum,
        spurdatumbis: device.properties.spurdatumbis,
      });
    });

    return data;
  }

  disableAllAutofocus() {
    Object.keys(this.devices).forEach((k) => {
      this.devices[k].autoFocus = false;
    });
  }

  resetDeviceDatapoints(deviceId) {
    this.devices[deviceId].dataPoints = [];
    this.devices[deviceId].newDataPoints = [];
    this.devices[deviceId].directionMarkers = [];
    this.devices[deviceId].newDirectionMarkers = [];
    return this.devices[deviceId];
  }

  getAllDataPointsLength() {
    let length = 0;
    Object.keys(this.devices).forEach((k) => {
      length += this.devices[k].dataPoints?.length ?? 0;
    });

    // console.log('ALL DATA POINTS OF DEVICES', length)
    return length;
  }

  getWifiHomeMarkers(deviceIds) {
    this.authService
      .getDevicesWifis(this.appService.user.id, deviceIds)
      .subscribe((res: any) => {
        console.log('DEVICES WIFI ', res);
        if (res.success) {
          const devicesRes = res.success;

          Object.keys(devicesRes).forEach((deviceId) => {
            if (devicesRes[deviceId]?.length > 0) {
              this.devices[deviceId]?.parseAndStoreWifis(
                devicesRes[deviceId][0],
              );
            }

            this.devices[deviceId]?.wifiMarkers.forEach((wifi) => {
              const deviceShow = this.devices[deviceId].properties.deviceshow;
              wifi.deviceshow = deviceShow;
            });

            this.wifiHomeMarkersReady.next(this.devices);
            // console.log('WIFI MARKERS ' + this.devices[deviceId]?.properties.name, this.devices[deviceId]?.wifiMarkers);
          });
        }
      });
  }
}
