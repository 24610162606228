import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { IonContent, ModalController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { DeviceWarningComponent } from 'src/app/members/map/devices-sidebar/device-warning/device-warning.component';
import { DeviceSettingComponent } from 'src/app/members/device-management/components/device-setting/device-setting.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeviceListService } from '../devices-list/device-list.service';
import { DeviceDataService } from '../../devicesData.service';
import { DeviceNoteComponent } from '../device-note/device-note.component';
import { DevicesSidebarService } from '../devices-sidebar.service';
import { NewPullupService } from '../../new-pullup/pullup.service';
import { animations } from 'src/app/animations/animations';
import { PlatformLocation } from '@angular/common';
import { ClipboardService } from 'src/app/services/plugins/clipboard.service';
import { StorageService } from 'src/app/services/storage.service';
import { Constants } from 'src/app/constants.enum';
import { DevicesListHomeComponent } from '../home/home.component';
import { DefaultFilters, cleanObject } from '../filters/filters-helper';
import { ApiService } from 'src/app/services/api.service';
import { Subject, first } from 'rxjs';
import { Swiper } from 'swiper';
import { MapService } from "../../components/map/map.service";
import { startDevicesListTour, startDeviceTour } from '../devices-list/list/devices-list-tour';
import { TourService } from 'src/app/components/tour/tour.service';
import { DeviceSubmenuService } from './device-submenu/device-submenu.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-device-item',
  templateUrl: './device-item.component.html',
  styleUrls: ['./device-item.component.scss'],
  animations: animations
})
export class DeviceItemComponent implements OnInit, AfterViewInit {
  @Input() itemList = null;
  @Input() isPullup = false;
  @Input() callback: any;
  @Input() disablingAlarms = false;
  @Input() dragActive = false;
  @ViewChild('deviceToolbar', { read: ElementRef }) deviceToolbar: ElementRef;
  // @ViewChild('sliders') sliders: IonSlides;
  // @ViewChild('collapsedSliders', { static: false }) collapsedSliders: IonSlides;
  // @ViewChildren(IonSlides) slides: QueryList<IonSlides>;
  @ViewChildren('sliders') slides: QueryList<ElementRef>;
  currentSlider?: Swiper;
  
  device = null;
  hideToggle = false;
  show_imeis = true;
  user_id;
  isOpeninPullup = false;
  isSmallScreen:boolean = false;
  observables = {};
  enableScroll = true;
  toolbarIsHide = false;
  isIos = false;
  deviceToolbarHeight = 85;
  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  userSettings: any;
  deviceIds = [];
  flatList = [];
  flatListReady = new Subject();
  slideOpts: any = {
    initialSlide: 1, 
    speed: 100,
    effect: 'cards',
    spaceBetween: 0,
    autoHeight: true,
    edgeSwipeDetection: 'prevent',
    pagination: false,
    loop: true
  };
  slideDevices = [];
  allDevices = [];
  pullupBreakpoint = 'middle'
  tourSubscriber = null;
  stopTourSubscriber = null;

  //public _deviceSubmenuService: DeviceSubmenuService
  constructor(private devicesService: DeviceDataService, private popoverCtrl: PopoverController,
    public deviceListService: DeviceListService, private modalCtrl: ModalController,
    private appService: AppService, public _translate: TranslateService,
    private authService: AuthenticationService, private platform: Platform, public storage: StorageService, public router: Router, public drawerService: NewPullupService,
    private clipboard: ClipboardService, public devicesSidebarService: DevicesSidebarService, public _deviceSubmenuService: DeviceSubmenuService,    
    public pullUpService: NewPullupService, private apiService: ApiService, private mapService: MapService, public tourService: TourService) { }

  ngOnInit() {
    //_ Get the device from the list of devices
    //_ All properties of the devices are in PROPERTIES variable
    this.isOpeninPullup = this.devicesSidebarService.isOpeninPullup;
    this.device = this.devicesService.devices[this.itemList.device_id];
    
    this.user_id = this.appService.user.id;
    this.subscribeChanges();

    this.isIos = this.platform.is('ios');

    this.appService.deviceMenuisOpen.subscribe(async (isOpen:boolean) => {
      this.userSettings = await this.storage.get(Constants.USER_SETTINGS);
 
    });
    this.userSettings = this.storage.get(Constants.USER_SETTINGS);

    this.observables['pullupBreakpoint'] = this.pullUpService.currentBreakPoint.subscribe(breakName => {
      this.pullupBreakpoint = breakName;
     });

    //_ Swipe device when trigger from other places
    //_ It should wait flatList is ready to access slider dom element to slide it
    this.observables['triggerSwipeDevice'] = this.devicesSidebarService.triggerSwipeDevice.subscribe( r => {
      const swipeDevice = async () => {
        if (this.allDevices.length > 1){
          if (this.toolbarIsHide){
            this.slides.last.nativeElement.swiper.slideTo(2, 1000);
            setTimeout(() => this.slides.last.nativeElement.swiper.slideTo(0, 1000), 1300);
          }
          else {
            this.slides.first.nativeElement.swiper.slideTo(2, 1000);
            setTimeout(() => this.slides.first.nativeElement.swiper.slideTo(0, 1000), 1300);
          }
        }
      }

      if (this.observables['flatListReady']) this.observables['flatListReady'].unsubscribe();
      if (this.flatList.length > 0)
        setTimeout( () => { swipeDevice(); }, 600);
      else{
        this.observables['flatListReady'] = this.flatListReady.subscribe( r => {
          setTimeout( () => { swipeDevice(); }, 600);
        })
      }
      
    })

    this.observables['startDevicesListTour'] = this.devicesSidebarService.startDevicesListTour.subscribe( r => {
      if (r){
        this.devicesSidebarService.navigation.popToRoot()
      }
    });

    this.observables['startDevicesListTour'] = this.devicesSidebarService.startDevicesListTour.subscribe( r => {
      if (r){        
        if (this.appService.devices.length === 1) {
          startDeviceTour(this);
        }
      }
    });
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.getScreenSize();
  }

  popToRoot() {
    this.devicesSidebarService.navigation.popToRoot()
    this.devicesSidebarService.navigation.setRoot(DevicesListHomeComponent, {
      navigation:  this.devicesSidebarService.navigation,
      isPullup: false,
      data: this.appService.devices,
      user_id: this.appService.user.id,
    }); // FirstPage is a class of an Angular component
  }

  ngOnDestroy() {
    Object.keys(this.observables).forEach(k => this.observables[k].unsubscribe());

    if (this.tourSubscriber) this.stopTourSubscriber?.unsubscribe();
    if (this.stopTourSubscriber ) this.tourSubscriber?.unsubscribe();
  }

  ngAfterViewInit() {
    this.devicesSidebarService.enableDragPullup.next(true);
    this.isEnablePullup = true;

    this.devicesSidebarService.dragableHeader.next('.draggable-header-item');

    //_ Enable / Disable scroll on breakpooint for Pullup
    if (this.devicesSidebarService.isOpeninPullup)
      this.enableScroll = this.pullUpService.currentBreakPoint.value == 'top' ? true : false;
    this.observables['pullupBreakpoint'] = this.pullUpService.breakPointChanged.subscribe(bp => {
      const scrollTop = this.content.nativeElement.shadowRoot.querySelector('.inner-scroll').scrollTop;
      if (bp == 'top' && scrollTop > 0) {
        this.isEnablePullup = false;
        setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 355);
      }
      // console.log('SCROOLL IS ON TOP', scrollTop);
      this.enableScroll = bp == 'top' ? true : false;
    });

    setTimeout(() => {
      const dragableAreaHight = window.screen.width <= 768 ? 15 : 0;
      this.deviceToolbarHeight = this.deviceToolbar.nativeElement.clientHeight + dragableAreaHight;
    }, 300);

    //_ Load flat devices list to swipe feature
    const filters = Object.assign({}, this.devicesSidebarService.filters.get().value);
    filters.itemsPerPage = -1;
    this.apiService.getSortedDeviceGroupList(1, cleanObject(filters, DefaultFilters) ).then( (r: any) => {
      this.flatList = r.data.pagination.data.filter(d => !d['isHeader']);
      this.createSliders(this.flatList);

      //_ Trigger for animate swipe gesture when is open by guided tour
      if (this.flatList.length > 0)
        this.flatListReady.next(true);
    }).catch( error => { return {error} } );
    
    setTimeout( () => {
      if (this.tourSubscriber == null && this.appService.devices.length === 1) {        
        startDeviceTour(this);
      }
    }, 600)    
  }

  ionViewDidEnter(){
  }

  subscribeChanges() {
    this.observables['onChanges'] = this.devicesService.deviceUpdated$.subscribe((r) => {
      if (this.device.id == r.properties.id) {
        this.device.properties.name = r.properties.name;
        this.device.color = r.properties.spurfarbe;
        this.device.properties.iconname = r.properties.iconname;
        this.device.properties.iconusecustom = r.properties.iconusecustom;
        this.device.properties.iconcustomimage = r.properties.iconcustomimage;
        this.device.threedModel_name = r.properties.threedModel_name;
        this.device.threedModel_color = r.properties.threedModel_color;
        this.user_id = Object.assign({}, this.appService.user.id);
      }
    });
  }

  async passToggleValue(event, item) {
    if(!item)
    {
      return;
    }
    let current_device = this.devicesService.devices[item.id];
    if (
      current_device.lastPoint == null &&
      current_device.deviceModel.model_nr == 9069
    )
      this.showDeviceWarning();

    const toggleValues = {
      deviceId: item.id,
      checked: event,
    };

    item.properties.deviceshow = event;
    this.itemList.deviceshow = event;
    this.deviceListService.toggleDevice(toggleValues);
    this.devicesService.updateDeviceInfo(item);
    this.mapService._closeSlider.next(!event);
  }

  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async showDeviceWarning() {
    const popup = await this.popoverCtrl.create({
      component: DeviceWarningComponent,
      cssClass: "paj-popover-template",
      translucent: false,
      backdropDismiss: false,
    });

    await popup.present();
  }

  async DeviceSettings(item, ev) {
    ev.preventDefault();
    ev.stopPropagation();

    const device = this.devicesService.devices[item.id];
    const md = await this.modalCtrl.create({
      component: DeviceSettingComponent,
      componentProps: {
        id: item.id,
        name: item.name,
        color: item.spurfarbe,
        iconname: item.iconname,
        iconusecustom: item.iconusecustom,
        iconcustomimage: item.iconcustomimage,
        uploadedFile: item.uploadedFile,
        privacy_mode: device.properties.privacy_mode,
        device_viewlink: device.properties.share_link ? true : false,
        url: '',
        user_id: this.appService.user.id,
      },
    });

    await md.present();
    // const { data } = await md.onWillDismiss();
    // if (data) this.updateDeviceMapSetting(data, item);
  }

  async updateDeviceMapSetting(data, item) {
    await this.authService
      .updateDeviceMapSetting(
        data.id,
        data.name,
        data.color,
        data.iconname,
        data.iconusecustom,
        data.device_viewlink,
        data.privacy_mode
      )
      .then((res) => {
        this.devicesService.devices[data.id].properties.name = data.name;
        this.devicesService.devices[data.id].properties.spurfarbe = data.color;
        this.devicesService.devices[data.id].properties.share_link = data.device_viewlink;
        this.devicesService.devices[data.id].properties.iconname = data.iconname;
        this.devicesService.devices[data.id].properties.iconusecustom = data.iconusecustom;
        this.devicesService.devices[data.id].properties.iconcustomimage = data.iconcustomimage;
        this.devicesService.updateDeviceInfo(this.devicesService.devices[data.id]);

        //_ Apply changes in the selected device
        this.device.properties = this.devicesService.devices[data.id].properties;
        this.user_id = Object.assign({}, this.appService.user.id);

        //item.color = data.color;
        //item.name = data.name;
        this.appService.showToast(
          this._translate.instant(
            "deviceManagement.deviceSettings.deviceSettingsText"
          ),
          this._translate.instant("deviceManagement.mapSettingMessage"),
          2000,
          "success"
        );

      })
      .catch((e) => {
        this.appService.showToast("", this._translate.instant('toast.errorStoringData'), 3000, "danger");
      });
  }

  //_ Update itemList data on the previous list.
  //_ 
  // ionViewWillLeave() {
  //   this.callback(this.itemList);
  // }

  async copytoClipBoard(imei, ev) {
    ev.preventDefault(); //Prevent call ionChange that setted automatic the value of selectedMenuItem
    ev.stopImmediatePropagation();
    await this.clipboard.copyToClipboard(imei);
    await this.appService.showToast('', this._translate.instant("info.copySuccess"), 2000, "success");

  }

  async openNote(item, ev) {
    ev.stopPropagation();
    const md = await this.modalCtrl.create({
      component: DeviceNoteComponent,
      backdropDismiss: false,
      componentProps: {
        note: item.note,
        note_color: item.note_color,
        deviceId: item.id,
        onUpdate: (data) => {
          item.note = data.note;
          item.note_color = data.note_color;
          // item = Object.assign({}, item);
        }
      }
    });

    await md.present();
  }

  flyTo(event) {
    if (event) {
      event.preventDefault(); //Prevent call ionChange that setted automatic the value of selectedMenuItem
      event.stopImmediatePropagation();
    }

    if (!this.device.properties.deviceshow)
      this.passToggleValue(true, this.device);

      if(this.devicesService.devices[this.device.properties.id].lastPoint){
      
        this.deviceListService.flyToDevice.next(this.device.properties.id);
      }
      else{
        setTimeout(() => {
          this.deviceListService.flyToDevice.next(this.device.properties.id);
        }, 1000);
      }
    // this.deviceListService.flyToDevice.next(this.device.id);

    this.pullUpService.setBreakPoint.next('middle')
  }

  isEnablePullup = true;
  topReached(ev) {
    if (ev.isOnTop && !this.isEnablePullup) {
      this.devicesSidebarService.enableDragPullup.next(true);
      this.isEnablePullup = true;
    }
    if (!ev.isOnTop && this.isEnablePullup) {
      setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 350);
      this.isEnablePullup = false;
    }

  }

  onHideShow(status) {
    this.toolbarIsHide = status.isHide;
  }

  previousEnablePullupStatus = true;
  isDragging = false;
  headerGestures(ev) {
    if (!this.isDragging && !ev.event.isFinal) {
      this.isDragging = true;
      this.previousEnablePullupStatus = this.isEnablePullup;
      this.devicesSidebarService.enableDragPullup.next(true);
    } else {
      if (ev.event.isFinal && this.isDragging) {
        this.isDragging = false;
        setTimeout(() => {
          this.isEnablePullup = this.pullUpService.currentBreakPoint.value != 'top' ? true : this.previousEnablePullupStatus;
          this.devicesSidebarService.enableDragPullup.next(this.isEnablePullup)
        }, 350);
      }
    }
  }
  
  //function for pulling up and down the pullup on button click
  enablePullup() {
    this.pullUpService.currentBreakPoint.value == 'top' ? this.pullUpService.setBreakPoint.next('middle') : this.pullUpService.setBreakPoint.next('top')
  }

  clearSelectedDevice(){
    this.devicesSidebarService.selectedDevice.next(null);
  }

  //_ Device Sliders methods
  createSliders(flatList){
    this.slideOpts = { 
      initialSlide: 1, 
      speed: 100,
      effect: 'cards',
      spaceBetween: 0,
      autoHeight: true,
      edgeSwipeDetection: 'prevent',
      pagination: false,
      loop: true,
      //_ No swipe when is browser size
      // breakpoints: {
      //   769: {
      //     noSwiping: true
      //   }
      // },

      // on: {
      //   slideChange: this.deviceSwiped()
      // }
    };

    this.allDevices = [];
    //_ First get a list of all devices in same sort of flat sorted list
    flatList.forEach(dlist => {
      const device = this.devicesService.devices[dlist.device_id];
      if (device)
        this.allDevices.push(device);
    });

    //_ Add device into allDevices in case it doesnt exists; (that happend when some filter is applied)
    if (!this.allDevices.find(d => d.id == this.device.id)) 
      this.allDevices.push(this.device);
      
    //_ Then create the three slide items
    if (this.slides?.length > 0)
      this.createThreeSlideArray(this.allDevices);    
  }

  //_ Create three slide devices array
  //_ Thinking in performance, to not render all the items of the list in the dom
  slidesIndexes = {};
  async createThreeSlideArray(allDevices){
    this.slideDevices = [];
    this.slidesIndexes = {};
    let currentIndex = allDevices.map(d => d.properties.id).indexOf(this.device.properties.id);

    //_ Calculate the three indexes | prev, current, next
    this.slidesIndexes['prevIndex'] = currentIndex === 0 ? allDevices.length - 1 : currentIndex - 1;
    this.slidesIndexes['currentIndex'] = allDevices.map(d => d.properties.id).indexOf(this.device.properties.id);
    this.slidesIndexes['nextIndex'] = currentIndex === allDevices.length - 1 ? 0 : currentIndex + 1;

    currentIndex = 1;
    //_ Pushing devices in right sort
    if (allDevices.length > 1)
      Object.keys(this.slidesIndexes).forEach(k => { this.slideDevices.push( allDevices[this.slidesIndexes[k]] ) });
    else { //_ Prevent add same device when allDevices length == 0
      this.slideDevices.push(allDevices[0]);
      currentIndex = 0;
    }

    //_ Update sliders elements; always selected slider will be 1 < 0, 1, 2 > the middle item
    this.slides.forEach( async (sliderX) => {
      await sliderX.nativeElement.swiper.update();
      sliderX.nativeElement.swiper.slideTo(currentIndex, 0);
    });
    
    //_ Update toolbar height
    setTimeout(() => {
      const dragableAreaHight = window.screen.width <= 768 ? 15 : 0;
      this.deviceToolbarHeight = this.deviceToolbar.nativeElement.clientHeight + dragableAreaHight;
    }, 200);
  }

  deviceSwiped(){
    let slideIndex = this.slides.first.nativeElement.swiper.activeIndex;
    if (this.toolbarIsHide)
      slideIndex = this.slides.last.nativeElement.swiper.activeIndex;

    //_ Not refresh list when is fired swipe event while render the element in dom (when collapsed toolbar is shown/hide)
    if (this.device?.properties.id == this.slideDevices[slideIndex]?.properties.id) 
      return;
    
    this.device = null;
    setTimeout( () => {
      this.device = this.slideDevices[slideIndex];
      this.devicesSidebarService.selectedDevice.next(this.device.properties.id);
      this.createThreeSlideArray(this.allDevices);
    });

  }

   async enableDisableAllAlerts(event, item){
    await this.authService.updateDeviceAlarms(this.device.properties.id,'alarm_enabled',item.properties.alarm_enabled == 1 ? 0 : 1 )
    .then(
      res => {
        this.devicesService.devices[item.properties.id].properties.alarm_enabled = res['success'].alarm_enabled;
        item.properties.alarm_enabled = res['success'].alarm_enabled;
        this.devicesService.updateDeviceInfo(item);
        //this.appService.showToast(null, this._translate.instant("enableDisableAllAlerts.allUpdated"), 2000, 'success');
        this.appService.showToast(
          null,
          item.properties.alarm_enabled
            ? this._translate.instant("enableDisableAllAlerts.alertEnabled")
            : this._translate.instant("enableDisableAllAlerts.alertDisabled"),
          3000,
          "success"
        );
      }).catch(e => {
        this.appService.showToast(null, this._translate.instant("enableDisableAllAlerts.errorWhileUpdating"), 2000, 'danger');
      });
  }
  //________________________________________________

  // swiperReady (el) {
  //   const deviceSwipedEvent = (e) => {
  //     if (this.slides.length > 0) this.deviceSwiped()
  //   }

  //   setTimeout(() => {
  //     this.slides?.forEach(slideX => {
  //       slideX.nativeElement.swiper?.off('slideChange', deviceSwipedEvent);
  //       slideX.nativeElement.swiper?.on('slideChange', deviceSwipedEvent);
  //     })
  //   }, 100);
    
  // }
  debounceTimer = null;
  swiperReady (el) {
    //_ swiperslidechange added in html tag element; so this code is in deprecated
    // if(this.debounceTimer)
    // clearInterval(this.debounceTimer)
    // this.debounceTimer = setTimeout(() => {
    //   const deviceSwipedEvent = (e) => {
    //     console.log('slide changed', e);
    //     if (this.slides.length > 0){
    //       this.deviceSwiped()
    //     } 
    //   }

    //   setTimeout(() => {
    //     this.slides?.forEach(slideX => {
    //       console.log('SLIDE FOUND', slideX.nativeElement.swiper)
    //       slideX.nativeElement.swiper.off('swiperslidechange', deviceSwipedEvent.bind(this));
    //       slideX.nativeElement.swiper.on('swiperslidechange', deviceSwipedEvent.bind(this));
    //     })
    //   }, 300);
    // }, 50);
  }

  swipeFromTour(){
    this.devicesSidebarService.triggerSwipeDevice.next(true);
  }
  getScreenSize() {
    this.isSmallScreen = document.documentElement.clientWidth < 769 ? true : false;
  }  
}
