import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';

import { Style } from './class/style';
import { Layer, layerType } from './class/layer';
import { DataSource } from './class/dataSource';
import { Data, dataSourceType, dataTypes } from './class/data';
import { Geometry, GeometryTypes } from './class/geometry';
import { Feature } from './class/feature';
import { ApiService } from './../../../../services/api.service';

import { Effects } from './class/effects';
import {
  GHDecodeString,
  GenerateUUID,
  cloneObject,
  getDeviceImageName,
  mapUtil,
  resizeImage,
  stringToPoints,
  svgToData,
} from './class/mapUtil';

/**************************************************************** */
import {
  DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS,
  MapService,
} from './map.service';
import {
  DARK_ATMOSPHERE,
  directionMarker,
  MapStylesList,
} from './class/mapInterfaces';
import { IProperties } from './class/Iproperties';
import { AppService } from 'src/app/app.service';

// Used for Geofences
import { Geofences, styles } from './class/geofences';
import * as MapboxDraw from './class/mapbox-draw/customMapboxDraw.js';
//import * as MapboxDraw from '@mapbox/mapbox-gl-draw'
import {
  CircleMode,
  DragCircleMode,
  SimpleSelectMode,
} from './class/custom-drawer-libs/mapbox-gl-draw-circle';
import StaticMode from './class/custom-drawer-libs/mapbox-gl-draw-static-mode';
import { customDragMode } from './class/customDragmode';

import { CenterPoint } from './class/geofence_center';
import { CustomDirectMode } from './class/mapbox-draw/customDirectMode';
import { PanFeature } from "./class/features/PanFeature";
// import { notificationsMarkers } from "src/app/members/notifications/notificationsUtil";
import { DeviceImageService } from 'src/app/pipes/deviceImagePipe';
import { MediaApiService } from 'src/app/components/media-managment/media-api.service';
import {
  IonFab,
  IonFabButton,
  MenuController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { applyDarkStyle } from './class/darkMode';
import { StorageService as Storage } from '../../../../services/storage.service';
import { first, takeUntil, takeWhile } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { distance as Tdistance, lineString, along } from '@turf/turf';
import { getSource } from './class/castMaplibreData';
import { ThreedHelper } from './class/features/3dmodelHelper';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/constants.enum';
import {
  AppModesArray,
  AppModesType,
} from '../app-modes-popup/app-modes-helper';
import { SpeedCameras } from './class/features/speed-cameras';
import { ClientLocation } from './class/features/clientLocation';
import { DeviceSettingComponent } from 'src/app/members/device-management/components/device-setting/device-setting.component';
import { DeviceDataService } from '../../devicesData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DeviceListService } from 'src/app/members/map/devices-sidebar/devices-list/device-list.service';
import { SharedDeviceService } from 'src/app/public/shared-link-map/sharedDevice.service';
import { AlertSettingComponent } from '../../devices-sidebar/device-item/device-submenu/alert/modal/alert-setting/alert-setting.component';
import { AvailableHooks, MapComponentEvents } from './class/componentHooks';
import { SubAccountsMarkers } from './class/features/subAccountsMarkers';
import { DynamicComponentService } from '../dinamic.component.service';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { App } from '@capacitor/app';
import { AlarmMarkers } from './class/features/alarmMarkers';
import { PauseMarkers } from './class/features/pauseMarkers';
import { Watermark } from './class/features/watermark';
import * as moment from 'moment';
import { DeviceNameMarkers } from './class/features/deviceNameMarkers';
import { DevicesDashboardService } from '../devices-dashboard/devices-dashboard.service';
import { HighSpeedPolylines } from './class/features/highSpeedPolylines';
import { animations } from 'src/app/animations/animations';
import { Capacitor } from '@capacitor/core';
import { ClusterDataSource } from './class/ClusterDataSource';
import { ImageStackManagament } from './class/imageStackManagament';
import { CsPopoverComponent } from 'src/app/components/cspopover/cspopover.component';
import {
  AVAILABLE_SYMBOL_MARKERS,
  SymbolMarkers,
} from './class/features/symbolMarkers';
import { MainMapInterface } from './main-map.interface';
import { RouteSliderAnimation } from './class/features/routeSliderAnimation';
import { environment } from 'src/environments/environment';
import { Terrain3D } from './class/features/Terrain3D';
import { HeatMapFeature } from './class/features/heatmap';
import { DynamicMapLib } from './dinamic-map-lib';
import { WifiHomeMarkers } from './class/features/wifiHomeMarkers';
import { GeofenceLineIndicatingFeature } from './class/features/geofenceLineIndicatingFeature';

declare var window: any;

//_ Animation compatible classes for browsers
var requestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame;
var cancelAnimationFrame =
  window.cancelAnimationFrame ||
  window.webkitCancelRequestAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelRequestAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.oCancelRequestAnimationFrame ||
  window.oCancelAnimationFrame ||
  window.msCancelRequestAnimationFrame ||
  window.msCancelAnimationFrame;
const turf = require('@turf/turf');

@Component({
  selector: 'main-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: animations,
})
export class MapComponent
  extends DynamicMapLib
  implements MainMapInterface, OnInit, OnDestroy
{
  @ViewChild('fab') fab: IonFab;
  @ViewChild('FabSubmenu') FabSubmenu: IonFab;
  @ViewChild('graphhopperLegend', { read: ElementRef })
  graphHopperLegendRef: ElementRef;
  @ViewChild('mapMonitorLegend', { read: ElementRef })
  mapMonitorLegendRef: ElementRef;

  public map: any;
  @Input() height = '100vh';
  @Input() width = '100%';
  @Input() pitch = 55;
  @Input() bearing = 0;
  @Input() interactive = true;
  @Input() fakeUser = null;
  @Input() hideFabButton = false;
  @Input() animatedRoute: boolean = false;
  @Input() dragableRouteMarkers: boolean = false;
  @Input() enableSpeedCameras: boolean = true;
  @Input() timerTemplate: TemplateRef<any> = null;
  @Input() sliderTemplate: TemplateRef<any> = null;
  @Input() alwaysShowPolylines: boolean = false;
  @Input() alwaysShowDirections: boolean = false;
  @Input() hasTabBar: boolean = false;
  @Input() markerSize = null;
  @Input() filterSource = { directions: true };
  @Input() hideLegends = false;
  @Input() customerSettings = null;
  @Input() useMapbox = false;

  //_ Outputs / hooks for features
  @Output() public onClickFeature = new EventEmitter<{}>();
  @Output() public onClickMap = new EventEmitter<{}>();
  @Output() public onMouseEventMap = new EventEmitter<{}>();
  @Output() public onClickRoute = new EventEmitter<{}>();
  @Output() public onMarkerMoved = new EventEmitter<{}>();
  @Output() public onDragMap = new EventEmitter<{}>();

  //_ Outputs / hooks for Geofence
  @Output() public geoMouseDown = new EventEmitter<{}>();
  @Output() public geoMouseUp = new EventEmitter<{}>();
  @Output() public geoDragCenter = new EventEmitter<{}>();
  @Output() public geoDragVertex = new EventEmitter<{}>();
  @Output() public geoVertexMouseUp = new EventEmitter<{}>();

  @Output() public onAnimationEnd = new EventEmitter<{}>();
  @Output() public onAnimationStops = new EventEmitter<{}>();
  @Output() public onMapLoaded: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  HOOKS = new MapComponentEvents();
  currentBearing = -0;

  devicesSource: any = null;

  routeLayer: any = null;
  markerLayer: any = null;

  infoLayer: any = null;

  primaryColor: string = '#FF4E00';
  id: any = '';
  markers = [];

  /******************************************************** */
  mapStyle = 'paj';
  dataSource = null; //Use for all features
  directionsSource = null;
  markersSource = null;

  scrollDirectionsSource = null;
  geofenceSource = null;
  geofenceDrawer: MapboxDraw = null;
  markersImages = []; // Used to reaload images after change map style
  setStyle = false;
  panFeature;
  Features = null;
  markersLayer = null;
  routesLayer = null;
  directionsLayer = null;
  wifiDirectionsLayer = null;
  scrollDirectionsLayer = null; // to show the direction marker on top of all layers
  effectsLayer = null;
  OnlineLayer = null;
  userSettings;
  // pausesLayer = null;
  circlesLayer: any = null;
  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  user;
  // showPauses = true;

  // alarmsSource = null;
  // alarmsLayer = null;
  // alarmImages = notificationsMarkers;
  // showAlarms = true;
  timer: any;
  mapReady = new BehaviorSubject(false);
  isDarkmode: boolean = false;
  appModes: AppModesType;

  clusterBgMarkers = null;
  clusterCountMarkers = null;
  clusterMarkers = null;

  onlineDotLayer = null;

  performance = {
    clusterMarkers: false,
    defaultImage: false,
    showDotEffect: true,
    noAnimatedMarker: false,
    use3dMarkers: false,
  };
  defaultDeviceImage = 'assets/device_markers/paj_iconset_logo.svg';
  thickness = 5;
  isFabListOpen = true;
  mapType = '';
  animations = [];
  isArfRunning = false;
  timerCheckFrame = null;

  threedMarkers = null;
  threedHelper = new ThreedHelper();
  threedLayer = null;
  dimension = '2d';
  stopListeners = new Subject();

  deviceMenuOpen = false;
  mainMenuOpen = false;

  //_ MAP FEATURES
  speedCamerasClass = new SpeedCameras(this);
  subAccountsMarkersClass = new SubAccountsMarkers(this);
  clientLocationClass = new ClientLocation(this);
  pauseMarkersClass = new PauseMarkers(this);
  alarmMarkersClass = new AlarmMarkers(this);
  symbolMarkersClass = new SymbolMarkers(this);
  waterMarkClass = new Watermark(this);
  deviceNameMarkers = new DeviceNameMarkers(this);
  RouteSliderAnimationClass = new RouteSliderAnimation(this);
  HighSpeedPolylinesClass = new HighSpeedPolylines(this);
  terrain3D = new Terrain3D(this);
  heatMapClass = new HeatMapFeature(this);
  wifiHomeMarkers = new WifiHomeMarkers(this);
  geofenceLineIndicatingFeature = new GeofenceLineIndicatingFeature(this);
  isBrowser = false;

  gpsWatcher: any = null;
  currentLocation: any = null;
  clickedOrTaped = false;
  dashboardMenuIsOpen = false;
  isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios';
  isSliderOpen = false;
  activateFab = false;
  fabValue = false;
  rescaleImageManagament = new ImageStackManagament();

  graphhopperLegendIsOpen = false;
  mapMonitorLegendIsOpen = false;
  mapZooming = false;
  savedPitch: number = 0;
  isSliderViewEnable = false;
  default3DMapStyle = 'mapbox_standard';
  unloadedDirectionSource = false;
  isSmallScreen = false;
  defaultDesktopZoom = 2.6794180609958724;
  defaultMobileZoom = 0.6311770352459876;
  approachingZoom = false;
  zoomChanged = false;
  petmodeUI: boolean = false;
  constructor(
    private api: ApiService,
    public platform: Platform,
    public centerPoint: CenterPoint,
    public mapService: MapService,
    public appService: AppService,
    private _geofences: Geofences,
    private imageService: DeviceImageService,
    private mediaApi: MediaApiService,
    private menuController: MenuController,
    private http: HttpClient,
    public storage: Storage,
    public authService: AuthenticationService,
    public _translate: TranslateService,
    public modalCtrl: ModalController,
    private devicesService: DeviceDataService,
    private route: ActivatedRoute,
    private location: Location,
    private _deviceListService: DeviceListService,
    public dinamicComponent: DynamicComponentService,
    private locationAccuracy: LocationAccuracy,
    private dashboardService: DevicesDashboardService,
    public apiService: ApiService,
    private rendered: Renderer2,
    private popoverController: PopoverController,
    private router: Router,
  ) {
    //_ INITIALIZE MAP LIB CLASS
    super();

    this.id = GenerateUUID();
    // this.checkArf();
    this.route.params.subscribe((params: any) => {
      if (params && params.deviceId) {
        this.appService.devices$.subscribe((data: any) => {
          var item = data.find((x) => x.id == params.deviceId);
          if (params.component == 'deviceSettings' && item) {
            this.DeviceSettings(item);
          } else if (params.component == 'alertSettings' && item) {
            this.AlertSettings(item, data);
          }
        });

        const url = this.router.routerState.snapshot.url;

        if (!url?.includes('/logbook/')) this.location.go('/map');
      }
    });
    this.isBrowser = this.platform.is('desktop');
  }

  async DeviceSettings(item) {
    const md = await this.modalCtrl.create({
      component: DeviceSettingComponent,
      componentProps: {
        id: item.id,
        name: item.name,
        color: item.spurfarbe,
        iconname: item.iconname,
        iconusecustom: item.iconusecustom,
        iconcustomimage: item.iconcustomimage,
        uploadedFile: item.uploadedFile,
        device_viewlink: item.share_link ? true : false,
        url: '',
        user_id: this.appService.user.id,
      },
    });
    await md.present();
  }

  async AlertSettings(item, data) {
    const md = await this.modalCtrl.create({
      component: AlertSettingComponent,
      componentProps: {
        deviceID: item.id,
        data: data,
        map: this.map,
        isOnDeviceList: false,
      },
    });
    await md.present();
  }

  async ngOnInit() {
 this.petmodeUI = await this.storage.get(Constants.PET_MODE);
 console.log('petmodeUI',this.petmodeUI)
    this.getScreenSize();
    this.closeFab();
    let style = getComputedStyle(document.body);
    this.primaryColor = style.getPropertyValue('--ion-color-primary');
    this.userSettings =
      this.customerSettings ??
      (await this.storage.get(Constants.USER_SETTINGS));
    //_ Load performance vars before create sources or layers
    this.storage.storageReady.subscribe((r) => {
      if (r) {
        this.performance = {
          clusterMarkers: this.mapService.showClusterMarkers.value,
          defaultImage: this.mapService.showDefaultImage.value,
          showDotEffect: this.mapService.showDotEffect.value,
          noAnimatedMarker: this.mapService.noAnimatedMarker.value,
          use3dMarkers: this.mapService.use3dMarkers.value,
        };

        this.markersSource = this.createDataSource('markersSource');
        this.dataSource = this.createDataSource('dataSource');

        this.directionsSource = this.createDataSource('directionsSource');
        this.scrollDirectionsSource = this.createDataSource(
          'scrollDirectionsSource',
        );
        this.geofenceSource = this.createDataSource('geofenceSource');
        // this.alarmsSource = this.createDataSource("alarmsSource");

        this.effectsLayer = this.createLayer('effects', 'markersSource');
        this.markersLayer = this.createLayer('markers', 'markersSource');
        this.routeLayer = this.createLayer('route', 'dataSource');
        // this.pausesLayer = this.createLayer("pauses", "dataSource");
        this.directionsLayer = this.createLayer(
          'directions',
          'directionsSource',
        );
        this.scrollDirectionsLayer = this.createLayer(
          'scrollDirections',
          'scrollDirectionsSource',
        );

        // this.alarmsLayer = this.createLayer("alarms", "alarmsSource");
        this.circlesLayer = this.createLayer('circles', 'markersSource');
        this.wifiDirectionsLayer = this.createLayer(
          'wifiDirections',
          'directionsSource',
        );

        this.clusterBgMarkers = this.createLayer(
          'clusterBgMarkers',
          'markersSource',
        );
        this.clusterCountMarkers = this.createLayer(
          'clusterCountMarkers',
          'markersSource',
        );
        this.clusterMarkers = this.createLayer(
          'clusterMarkers',
          'markersSource',
        );

        this.onlineDotLayer = this.createLayer('onlineDot', 'markersSource');
        this.threedLayer = this.threedHelper.createLayer('markersSource');
        1;

        this.observables['appModeChanged'] =
          this.appService.appModeChanged.subscribe((r) => {
            this.updateAccordingToAppModes();
          });
        this.isDarkmode = this.appService.darkMode;
        this.observables['darkMode'] = this.appService.darkMode$.subscribe(
          (r) => {
            if (r != this.isDarkmode) {
              this.isDarkmode = r;
              if (this.map)
                this.setMapStyle(this.mapStyle, this.dimension, null);
            }
          },
        );

        //set map style after map is ready and usersettings loaded from server
        this.mapReady.subscribe((status) => {
          if (!status) return;
          if (status) {
            this.onMapLoaded.emit(status);
          }
          //_ Set map style is triggering for darkMode reload all sources and layers;
          //_ so need to check if is really necesary to do this after map end load
          // setTimeout(() => {
          //   if (this.map) this.setMapStyle(this.mapStyle, this.dimension, null);
          // }, 500);
        });

        this.observables['heatMapMode'] =
          this.mapService.heatMapMode$.subscribe((r) => {
            setTimeout(
              () => this.setMapStyle(this.mapStyle, this.dimension, null),
              500,
            );
          });

        this.observables['dashboardMenuIsOpen'] =
          this.dashboardService.dashboardSidebarIsOpen.subscribe((status) => {
            this.dashboardMenuIsOpen = status;
          });

        if (this.appService.user) {
          this.user = this.appService.user;
          this.createMap(this.appService.user);
          return;
        }

        if (!this.fakeUser && !this.user) {
          this.appService.user$.pipe(first()).subscribe((u: any) => {
            this.createMap(u);
          });
        } else {
          this.createMap(this.fakeUser);
        }
      }
    });

    if (!this.fakeUser && this.appService.user) {
      const now = new Date();
      let data = {
        last_app_activity: now.toISOString().replace('Z', '').replace('T', ' '),
      };
      this.api.updateCustomer(data, this.appService.user.id);
    }
    // this.api.updateCustomer(, this.user.id)
    this.observables['deviceMenuisOpen'] =
      this.appService.deviceMenuisOpen.subscribe((val: boolean) => {
        if (val && this.fab?.activated) {
          this.fab.close();
          this.isFabListOpen = false;
          this.mapService.isFabMenuOpen.next(this.isFabListOpen);
          this.markersMenu = false;
        }
      });

    this.isArfRunning = (await App.getState()).isActive;
    App.addListener('appStateChange', (status) => {
      this.isArfRunning = status.isActive;
      if (!status.isActive) this.stopArf();
    });

    this.appService._userSettings$.subscribe(async (data: any) => {
      this.userSettings = await this.storage.get(Constants.USER_SETTINGS);
      //const projectionChanged = this.userSettings.map_projection !== data['map_projection'];
      const projectionChanged =
        (data.prev_projection &&
          data.prev_projection !== data.map_projection) ||
        this.userSettings.map_projection !== data['map_projection'];

      //_ change by default mercator in case map_projection is not a supported projection
      if (
        this.userSettings.map_projection != 'globe' ||
        this.userSettings.map_projection != 'mercator'
      )
        this.userSettings.map_projection = 'mercator';

      if (this.isFabListOpen) {
        this.fab?.close();
        this.isFabListOpen = false;
        this.mapService.isFabMenuOpen.next(this.isFabListOpen);
        this.markersMenu = false;
      }

      console.log('MAP SETTING CHANGED', { projectionChanged, data });
      //_ Change map projection
      if (projectionChanged && this.userSettings) {
        //this.map?.setProjection(this.userSettings.map_projection);
        this.map?.setProjection(data['map_projection']);
      }

      // if (this.map) {
      //   this.setMapStyle(this.mapStyle, this.dimension, null);
      // }
    });
  }

  updateAccordingToAppModes() {
    this.showSlider = true;
    this.isFabListOpen = true;
    this.activateFab = true;
    // this.mapService.isFabMenuOpen.next(this.isFabListOpen);
    this.storage.get(Constants.APP_MODES).then((res) => {
      console.log('APP MODES', {
        res: res,
        appModes: JSON.parse(JSON.stringify(this.appModes ?? [])),
      });
      const defaultAppModes = {
        NO_MODE: true,
        CAR: true,
        PET: true,
        PEOPLE: true,
        BUSINESS: true,
      };
      this.appModes = res ?? defaultAppModes;
      if (!this.appModes?.no_mode) {
        //_ if pauses are visibles AND no car mode and no one mode is selected
        //_ then it should hide the pauses beacuse button in html template is hidden so not possible to hide if user wants to hide it.
        //_ not should be trigger to show from here because user could have disabled so doesnt have sense...
        if (
          this.mapService.showPauses.value &&
          this.appModes.car_mode === 0 &&
          this.appModes.no_mode === 0
        )
          this.pauseMarkersClass.showHidePauseMarker(false);

        //_ Old code
        // this.appModes = res ?? defaultAppModes;
        // this.pauseMarkersClass.showHidePauseMarker(this.appModes.car_mode)
      }
    });
  }

  alreadyCreated = false;
  async createMap(user) {
    // if (!this.alreadyCreated) return;

    // this.alreadyCreated = true;
    // this.delay(200);
    this.user = user;
    this.mapStyle = user.map;
    this.useMapbox = this.shouldUseMapboxLib(this.mapStyle).useMapbox;
    await this.setupMapLibrary(this.useMapbox);

    this.buildMap();
    this.loadListeners();

    if (user.map == '3d') {
      setTimeout(
        () => this.map.easeTo({ pitch: 70, bearing: this.map?.getBearing() }),
        500,
      );
    }

    this.appService.language$.subscribe((language) => {
      this.mapboxControlsTranslation();
    });
  }

  setupHeatMap() {
    this.storage.get('heatMapMode').then(async (res) => {
      if (res) {
        this.hideAlarms();
        this.hidePauses();
        this.hidePolylines();
        this.hideDirectionMarkers("gps");
        //this.HighSpeedPolylinesClass.hide();
        this.speedCamerasClass.hideCameras();
      }
    });
  }

  ngOnDestroy() {
    //console.log('DESTROYIING MAP COMPONENT ... >>>>>>>>>>');
    Object.keys(this.observables).forEach((k) =>
      this.observables[k].unsubscribe(),
    );

    window.clearInterval(this.timer);
    this.stopListeners.complete();
    this.HOOKS.onDestroy.emit({});
    this.HOOKS.offAll();
    this.clientLocationClass.stopClientLocation();
    this.map.remove();

    this.panFeature?.destroy();
  }

  showSlider = false;
  observables = {};
  loadListeners() {
    // Listen when map style change, this could use with app settings or with a button
    // Wherever you want you can change the style of the main map
    this.observables['styleChanged'] = this.mapService.style$.subscribe((s) => {
      console.log('CHANGE MAP STYLE', s);
      this.user.map = s;
      this.dimension = this.mapService.mapStyleDimension.value;

      this.setMapStyle(s, this.dimension, null);
    });

    this.observables['mapStyleDimension'] =
      this.mapService.mapStyleDimension.subscribe((s) => {
        this.dimension = s;
      });

    //_ Update icon size
    this.observables['iconSize'] = this.mapService.iconSize.subscribe((ic) => {
      if (this.map) this.changeDeviceMarkerSize(ic);
    });

    //_ Update icon size
    this.observables['thicknessChanged'] = this.mapService.thickness.subscribe(
      (size) => {
        this.thickness = size;
        if (this.map) this.thicknessChanged(size);
      },
    );

    //_ Change direction markers
    this.observables['RouteIconChanged'] = this.mapService.routeIcon$.subscribe(
      (ri) => {
        this.changeDirectionIcon(ri);
      },
    );

    this.observables['showSlider'] = this.mapService.showSlider.subscribe(
      (ri) => {
        this.showSlider = ri;
        this.isSliderOpen = ri;
        if (ri) {
          this.isFabListOpen = false;
          this.markersMenu = false;
          this.mapService.isFabMenuOpen.next(this.isFabListOpen);
        }
      },
    );
    //checking the fab menu is open or not
    this.observables['fabMenuOpen'] = this.mapService.isFabMenuOpen.subscribe(
      (status) => {
        this.fabValue = status;
      },
    );

    //_ Show/Hide pauses marker
    this.observables['showPauses'] = this.mapService.showPauses.subscribe(
      (show) => {
        console.warn('PAUSE VALUE', show);
        this.showHidePauseMarker(show);
      },
    );

    //_ Show polylines
    this.observables['showPolylines'] = this.mapService.showPolylines.subscribe(
      (show) => {
        if (this.alwaysShowPolylines) {
          this.showPolylines = true;
        } else {
          this.showPolylines = show;
        }
      },
    );

    // this.observables["showHeatMap"] = this.mapService.showHideHeatMap.subscribe(
    //   (show) => {
    //     this.isHeatMapMode = show;
    //   }
    // );

    //_ Show notifications
    this.observables['showNoifications'] =
      this.mapService.showNotifications.subscribe((show) => {
        this.showHideAlarms(show);
      });

    //_ Show Direction markers
    this.observables['showDirectionMarkers'] =
      this.mapService.showDirectionMarkers.subscribe((show) => {
        if (this.alwaysShowDirections) {
          this.showDirections = true;
        } else {
          this.showDirections = show;
        }
      });

    //_ Show Wifi Direction markers
    this.observables['showWifiMarkers'] =
      this.mapService.showWifiMarkers.subscribe((show) => {
        this.showWifiMarkers = show;
      });

    //_ MapStyle
    this.observables['mapStyle'] = this.mapService.mapStyle.subscribe(
      (style) => {
        // this.user.map = style;
        // this.dimension = this.mapService.mapStyleDimension.value;
        // this.setMapStyle(style, this.dimension, null);
        this.mapStyle = style;
      },
    );

    //_ Performance settings
    this.observables['shoowClusterMarkers'] =
      this.mapService.showClusterMarkers.subscribe((show) => {
        this.performance.clusterMarkers = show;
        this.clusterize(show);
      });

    this.observables['showDotEffect'] = this.mapService.showDotEffect.subscribe(
      (show) => {
        this.performance.showDotEffect = show;
        this.setBlinkingEffect(show);
      },
    );

    this.observables['showoDefaultImage'] =
      this.mapService.showDefaultImage.subscribe((show) => {
        this.performance.defaultImage = show;
        // this.setBlinkingEffect(show);
      });

    this.observables['noAnimateMarker'] =
      this.mapService.noAnimatedMarker.subscribe((show) => {
        this.performance.noAnimatedMarker = show;
        this.shouldCheckArf = !show;
        // this.setBlinkingEffect(show);
      });

    this.observables['use3dMarkers'] = this.mapService.use3dMarkers.subscribe(
      (show) => {
        this.performance.use3dMarkers = show;
      },
    );

    this.observables['deviceMenuOpen'] =
      this.appService.deviceMenuisOpen.subscribe((status) => {
        this.deviceMenuOpen = status;
        // setTimeout(() => this.moveSubFabMenu(), 400);
        if (!status) {
          document
            .getElementById('map-bottom-controls')
            .setAttribute('style', '');
        }
      });

    this.observables['mainMenuOpen'] = this.appService.menuClosed.subscribe(
      (status) => {
        this.mainMenuOpen = status;
      },
    );
  }

  getId() {
    return this.id;
  }

  async buildMap() {
    await this.delay(200);
    let dragRotate = this.mapStyle == '3d' ? true : false;
    let touchRotate = this.mapStyle == '3d' ? true : false;
    let style = await this.createMapStyle(this.mapStyle);

    const isSharedLink = window.location.href.indexOf('share') > -1;

    if (!['paj', 'pajLite'].includes(this.mapStyle) && isSharedLink) {
      style = await this.createMapStyle('pajLite');
    }

    // this.MAP_LIB.accessToken = environment.MAPBOX_TOKEN;

    //_ Reduce the pixel ratio only for mobile devices
    const isMobile =
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() == 'ios' ||
        Capacitor.getPlatform() == 'android');
    if (isMobile) window.devicePixelRatio = Constants.MAP_PIXEL_RATIO;

    const mapOptions = {
      container: 'map-' + this.id,
      style: style,
      zoom: 0,
      maxZoom: 18,
      center: [13.404954, 52.520008], //Berlin, Germany Lat and Lng
      pitch: this.mapStyle === 'mapbox_standard' ? 50 : this.pitch, //this.pitch, // pitch in degrees
      bearing: this.bearing,
      interactive: this.interactive,
      pitchWithRotate: true,
      dragRotate,
      //_ Search how to wrap this in new maplibre lib
      // touchRotate,
      renderWorldCopies: false,
      antialias: this.performance.use3dMarkers, //_ Cause performance issue activate this
      projection: this.userSettings?.map_projection ?? 'mercator',
      // pixelRatio: 1
    };

    //_ Remove cache tiles for ios
    if (window['isSafari']) mapOptions['maxTileCacheSize'] = 0;

    //_ CREATE MAP
    this.map = new this.MAP_LIB.Map(mapOptions);

    let tc = this;
    this.panFeature = new PanFeature(this.map);
    // First load
    this.map.on('load', async () => {
      //_ Mapboxgl v3
      // this.map.setConfigProperty('basemap', 'lightPreset', 'dusk'); // dusk, dawn, day, and night

      // Add an event listener to track changes in pitch
      this.map.on('pitch', () => {
        tc.savedPitch = this.map.getPitch();
        // Save the pitch to localStorage
        localStorage.setItem('savedPitch', this.savedPitch.toString());
      });

      //_ Post event for metrics
      // this.authService.countlyMetrics({ key: 'Map Init ' + (this.useMapbox ? 'mapbox' : 'maplibre')  }).subscribe();

      tc.map.resize();

      tc.firstLoad();
      tc.onClickMarkers();
      this.updateAccordingToAppModes();
      // Load all data after change the style of the map to 3D
      tc.map.on('style.load', async () => {
        tc.firstLoad();
      });

      // trigger styledata when change to rasted style
      tc.map.on('styledata', async (e) => {
        if (tc.setStyle && tc.mapStyle != '3d') {
          // Wait one second to load all layers again
          // Fix trick: load all layers after drawer layers were loaded
          await setTimeout(() => {
            tc.setStyle = false;
            tc.firstLoad(false);
          }, 1000);
        }
      });

      tc.geofenceDrawer.changeMode('static');

      this.map.once('idle', () => {
        // tc.clusterize(tc.performance.clusterMarkers, 'Idle');
        tc.mapReady.next(true);
        this.mapService._drawer.next(this.geofenceDrawer);

        tc.HOOKS.onReady.emit(true);
        this.map.on('zoom', (data) => {
          const currentZoom = tc.map.getZoom();
          this.ShowHideGeofenceMoveIcon(currentZoom);
        });

        this.map.on('popupopen', function () {
          const closeButton = document.querySelector(
            '.mapboxgl-popup-close-button',
          );
          if (closeButton) {
            closeButton.textContent = '';
            closeButton.setAttribute('tabindex', '-1');
          }
        });

        // if(this.dimension== '3d'){
        //   // this.setMapStyle(this.mapStyle, this.dimension, null);

        // }
        // this.applyMapDimension(this.dimension);

        const currentDimension = this.mapService.mapStyleDimension.value;
        if (currentDimension) {
          setTimeout(() => {
            this.applyMapDimension(currentDimension);
          }, 700);
        }
      });

      // Fix toggle click info mapboox bottom right corner
      this.fixMapInfo();
    });

    //_ Initialize Geofences
    //this.initGeofence();

    this.map.addControl(
      new this.NavigationControl({
        showCompass: false,
        showZoom: true,
        visualizePitch: false,
      }),
      'top-left',
    );

    // this.geoTrackerCap = new geolocationControlForCapacitor();
    // this.map.addControl(this.geoTrackerCap, "bottom-right");

    this.geoTracker = new this.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
    });
    this.map.addControl(this.geoTracker, 'bottom-right');

    //_ Enable pitch first load
    //_ Search how to do this in new maplibre
    // this.map["dragRotate"]._mousePitch._enabled = true;

    // Initialize Geofences
    await this.initGeofence();

    //_ Initialize 3dMarers class
    if (this.performance.use3dMarkers) this.threedHelper.init(this);
  }

  ShowHideGeofenceMoveIcon(currentZoom) {
    const dqs = document.querySelectorAll('.geofence-marker');
    if (this.isSmallScreen)
      if (currentZoom <= this.defaultMobileZoom) {
        if (this.approachingZoom || !this.zoomChanged) {
          dqs.forEach((element: any) => {
            element.style.visibility = 'hidden';
          });
        }
        this.approachingZoom = false;
      } else {
        if (!this.approachingZoom) {
          dqs.forEach((element: any) => {
            element.style.visibility = 'visible';
          });
        }
        this.approachingZoom = true;
      }
    else if (currentZoom <= this.defaultDesktopZoom) {
      if (this.approachingZoom || !this.zoomChanged) {
        dqs.forEach((element: any) => {
          element.style.visibility = 'hidden';
        });
      }
      this.approachingZoom = false;
    } else {
      if (!this.approachingZoom) {
        dqs.forEach((element: any) => {
          element.style.visibility = 'visible';
        });
      }
      this.approachingZoom = true;
    }

    this.zoomChanged = true;
  }

  geoTracker = null;
  geoTrackerCap = null;
  // Use for first time load
  // And used after change the style to realod all sources, images and layers
  //_ TODO :: FIX THIS RECURRENT CALL ISSUE
  async firstLoad(loadImages = true) {
    // console.log('firstLoad',this.markersImages)
    // Load images
    // Load markers images when change the style
    if (loadImages) {
      if (this.markersImages.length > 0) {
        this.markersImages.forEach(async (mi) => {
          if (mi.isEffect) {
            if (!this.map.hasImage(mi.name)) {
              this.map.addImage(mi.name, mi.url);
            }
          } else
            await this.map.loadImage(mi.url, (error, image) => {
              if (!this.map.hasImage(mi.name) && !error)
                this.map.addImage(mi.name, image);
            });
        });
      }

      // LOAD DIRECTION IMAGE ONLY ONECE for use in Direction layer
      if (this.user) {
        await this.map.loadImage(
          directionMarker[this.user.route_icons],
          (error, image) => {
            if (!this.map.hasImage('direction-marker') && !error)
              this.map.addImage('direction-marker', image, { sdf: true });
          },
        );
      }

      // LOAD DOT IMAGE ONLY ONECE for use in onlineDotLayer
      if (this.user) {
        await this.map.loadImage(
          'assets/images/map/dot.png',
          (error, image) => {
            if (!this.map.hasImage('dot-marker') && !error)
              this.map.addImage('dot-marker', image, { sdf: true });
          },
        );

        //_ THIS PART WAS MOVED HAS A NEW FEATURE IN features/symbolMarkers.ts file
        //_ End flag image
        // const endFlag: any = await resizeImage('assets/images/render-video/end-marker.svg', 75, 141, "white", false, true);
        // await this.map.loadImage(endFlag, (error, image) => {
        //   if (!this.map.hasImage("end-marker") && !error)
        //       this.map.addImage("end-marker", image);
        // });

        // //_ Start flag image
        // const startFlag: any = await resizeImage('assets/images/render-video/start-marker.svg', 75, 141, "white", false, true);
        // await this.map.loadImage(startFlag, (error, image) => {
        //   if (!this.map.hasImage("start-marker") && !error)
        //       this.map.addImage("start-marker", image);
        // });
      }

      // LOAD PAUSE IMAGE ONLY ONECE for use in Direction layer
      // const pauseImage: any = await svgToData(
      //   "assets/images/map/markers/stop-marker.svg",
      //   50,
      //   50
      // );
      // await this.map.loadImage(pauseImage, (error, image) => {
      //   if (!this.map.hasImage("pause-marker") && !error)
      //     this.map.addImage("pause-marker", image);
      // });

      //_ Load wifi marker image
      await this.map.loadImage(directionMarker['wifi'], (error, image) => {
        if (!error)
          this.map.addImage('direction-marker-wifi', image, { sdf: true });
      });

      //_ Load wifi marker image
      const img: any = await resizeImage(
        'assets/device_markers/paj_iconset_logo.svg',
        122,
        170,
        'white',
        false,
      );
      await this.map.loadImage(img, (error, image) => {
        if (!error)
          this.map.addImage('default-device-marker', image, { sdf: false });
      });

      // LOAD NOTIFICATIONS IMAGES ONLY ONECE for use in Alarms layer
      // this.alarmImages.forEach(async (img) => {
      //   if (img != "") {
      //     //let bubbleImage = await ImageWithBubble(img, 61, 50, 'white', 10);
      //     const imgData: any = await svgToData(img, 91.5, 75); //_ Ratio image w/h
      //     await this.map.loadImage(imgData, (error, image) => {
      //       const imageName = img.replace(/^.*[\\\/]/, "");
      //       if (!this.map.hasImage(imageName) && !error)
      //         this.map.addImage(imageName, image);
      //     });
      //   }
      // });

      if (this.enableSpeedCameras) this.speedCamerasClass.loadImages();

      this.HOOKS.onLoadImages.emit({});
    }

    //_ Adding Sources to the map
    if (!this.map.getSource('markersSource')) {
      this.markersSource.cluster = this.performance.clusterMarkers;
      this.map.addSource('markersSource', this.markersSource);
    }

    if (!this.map.getSource('dataSource'))
      this.map.addSource('dataSource', this.dataSource);
    if (!this.map.getSource('directionsSource'))
      this.map.addSource('directionsSource', {
        ...this.directionsSource,
        data: { features: [] },
      });
    if (!this.map.getSource('scrollDirectionsSource'))
      this.map.addSource('scrollDirectionsSource', this.scrollDirectionsSource);
    // if (!this.map.getSource("alarmsSource"))
    //   this.map.addSource("alarmsSource", this.alarmsSource);

    this.HOOKS.onLoadSource.emit({});
    if (this.animatedRoute) {
      // this.createAnimation()
      // this.createAnimationLayers() due to animation issue its commented. need to fix this
    }

    //_ Adding Layers to the map
    //_ The second parameter is to show behind other layer like the z-index works

    if (!this.map.getLayer('clusterCountMarkers'))
      this.map.addLayer(this.clusterCountMarkers); // This layers is on top of all others !!

    if (!this.map.getLayer('clusterBgMarkers'))
      this.map.addLayer(this.clusterBgMarkers, 'clusterCountMarkers');

    if (!this.map.getLayer('clusterMarkers'))
      this.map.addLayer(this.clusterMarkers, 'clusterBgMarkers');

    if (!this.map.getLayer('scrollDirections'))
      this.map.addLayer(this.scrollDirectionsLayer, 'clusterBgMarkers');

    if (!this.map.getLayer('onlineDot'))
      this.map.addLayer(this.onlineDotLayer, 'clusterMarkers');

    if (!this.map.getLayer('markers') && !this.performance.use3dMarkers)
      this.map.addLayer(this.markersLayer, 'onlineDot');
    else if (this.performance.use3dMarkers && !this.map.getLayer('markers')) {
      this.map.addLayer(this.threedLayer, 'onlineDot');
    }

    if (!this.map.getLayer('effects'))
      this.map.addLayer(this.effectsLayer, 'markers');

    if (!this.map.getLayer('route'))
      this.map.addLayer(this.routeLayer, 'markers');

    if (!this.map.getLayer('circles'))
      this.map.addLayer(this.circlesLayer, 'markers');

    // if (!this.map.getLayer("pauses"))
    //   this.map.addLayer(this.pausesLayer, "markers");
    // if (!this.map.getLayer("alarms"))
    //   this.map.addLayer(this.alarmsLayer, "markers");
    if (!this.map.getLayer('wifiDirections'))
      this.map.addLayer(this.wifiDirectionsLayer, 'markers');

    if (!this.map.getLayer('directions'))
      this.map.addLayer(this.directionsLayer, 'markers');

    if (this.enableSpeedCameras) this.speedCamerasClass.load();

    // this.map.moveLayer("directions", "markers");

    this.HOOKS.onLoadLayer.emit({});
    // console.warn('[DEBUG] USER  INTERACTED 1');
    if (this.filterSource.directions) this.filterDirectionsMarkers(true);

    if (loadImages) this.applyAtmosphere();
    else {
      setTimeout(() => this.applyAtmosphere(), 500);
    }
  }

  // Use for change style, to generate style with layer and source
  async createMapStyle(mapStyle) {
    let style: any = new Style();
    //_ Only used for raster tiles
    if (mapStyle == 'pajLite') {
      style = new Style();
      let layer = new Layer('simple-tiles');

      //Maptiler Satellite Tiles test
      // if (this.user.isadmin == 1) {
      //   style.sources["raster-tiles"].tiles.push(urlStyles['mtsatellite']);
      // }else{
      //   style.sources["raster-tiles"].tiles.push(urlStyles[mapStyle]);
      // }

      style.sources['raster-tiles'].tiles.push(MapStylesList[mapStyle].url);

      layer.type = layerType.raster;
      layer.source = 'raster-tiles';
      style.layers.push(layer);
    }
    //_ Used for vector tiles
    else {
      // if (this.dimension == "3d" && !this.isMapboxStyle(mapStyle)) {
      //   style = MapStylesList["3d"].url;
      // } else {
      const newStyle = MapStylesList[mapStyle.toLowerCase()]?.url;
      style = newStyle ?? MapStylesList.paj.url;
      // }
      //_ If app is in Darkmode, it should exchange layers colors applyDarkStyle
      // const mapboxNoSopportOfDakrStyle = ['mapbox_standard', 'mapbox_outdoor_v11'];
      if (this.isDarkmode || this.heatMapClass?.isEnabled) {
        //_ Mapbox standard style uses light presets so we can use night to show as dark mode :)
        if (this.mapStyle === 'mapbox_standard') {
          setTimeout(
            () => this.map.setConfigProperty('basemap', 'lightPreset', 'night'),
            300,
          );
        } else if (this.mapStyle === 'mapbox_outdoor_v11') {
          style = Constants.MAP_TILES_MAPBOX_DARK_V10;
        } else if (!this.mapStyle.startsWith('mapbox')) {
          style = await new Promise((resolve) => {
            this.http.get(style).subscribe((res) => {
              resolve(res);
            });
          });

          style = applyDarkStyle(style, mapStyle);
        }
      }
    }

    console.log('NEW STYLE ', style);
    return style;
  }

  isMapboxStyle(styleName) {
    console.log('STYLE NAME', styleName);
    return styleName?.toLowerCase().startsWith('mapbox');
  }

  initGeofence() {
    const customDirectMode = new CustomDirectMode(this.centerPoint);
    this.geofenceDrawer = new MapboxDraw({
      userProperties: true,
      styles: styles('#000'),
      displayControlsDefault: false,
      geofencesSource: this.geofenceSource,
      controls: {
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: customDirectMode.customMode,
        simple_select: SimpleSelectMode,
        static: StaticMode,
        DrageMode: customDragMode,
      },
    });

    this.centerPoint.setupMapListeners(this, this.geofenceDrawer);

    let tc = this;

    //_ when change to 'DrageMode'
    this.map.on('click', async (e) => {
      if (tc.geofenceDrawer) {
        if (!tc.geofenceDrawer.getSelected().features.length) {
          tc.geofenceDrawer.changeMode('static');

          //_ Remove active class for geofence center marker
          tc.centerPoint.geoMarkerFeatures.forEach((feature) => {
            const el = window[feature.id].getElement();
            if (el) el.classList.remove('active');
          });

          //_ THIS METHODS ARE NO USED BECUASE MARKER OPACITY IS CHANGED USING in geofence_center service
          // tc.setOpacityGeofenceMarker();
        }
      }
      //this.menuController.isOpen() ? await this.menuController.close() : ''
    });

    // this.map.on("touchstart", async (e) => {
    //   if (tc.geofenceDrawer) {
    //     if (!tc.geofenceDrawer.getSelected().features.length)
    //       tc.setOpacityGeofenceMarker();
    //   }
    // });

    this.map.on('touchend', async (e) => {
      if (tc.geofenceDrawer) {
        if (!tc.geofenceDrawer.getSelected().features.length) {
          tc.geofenceDrawer.changeMode('static');
        }

        //_ Remove active class for geofence center marker
        tc.centerPoint.geoMarkerFeatures.forEach((feature) => {
          const el = window[feature.id].getElement();
          if (el) el.classList.remove('active');
        });

        this.menuController.isOpen() ? await this.menuController.close() : '';
      }
    });

    //_ Override methods
    this._geofences.overrideDrawFunctions(
      this.geofenceDrawer,
      this.map,
      [],
      '#f00',
    );

    // Add geofenceDrawer to the map as a control
    this.map.addControl(this.geofenceDrawer, 'top-right');

    //_ Geofence Hooks for center marker
    this.observables['mouseDown'] = this.centerPoint.mouseDown.subscribe(
      (event) => {
        this.geoMouseDown.emit(event);
      },
    );

    this.observables['mouseUp'] = this.centerPoint.mouseUp.subscribe(
      (event) => {
        this.geoMouseUp.emit(event);
      },
    );

    // this.centerPoint.mouseMove.subscribe( center => {
    // });

    //_ Geofence Hooks for Vertex
    this.observables['dragVertex'] = this.centerPoint.dragVertex.subscribe(
      (event) => {
        this.geoDragVertex.emit(event);
      },
    );

    this.observables['dragVertexEnd'] =
      this.centerPoint.dragVertexEnd.subscribe((event) => {
        this.geoVertexMouseUp.emit(event);
      });
  }

  setOpacityGeofenceMarker() {
    const geoMarkers: any = document.getElementsByClassName('geofence-marker');
    if (Object.keys(geoMarkers).length > 0) {
      for (const key in geoMarkers) {
        if (geoMarkers.hasOwnProperty(key)) {
          const item = geoMarkers[key];
          item.classList.remove('active');
        }
      }
    }
  }

  public async _addMarker(
    device,
    _image = null,
    updateMap = false,
    size = { width: 122, height: 170 },
  ) {
    //_ if use3dMarkers should only add a 3d marker no custom layers markers
    if (this.performance.use3dMarkers) {
      this.add3dMarker(device, updateMap);
      return;
    }

    //_ Prevent not duplicate the marker when already exist and call more than 1 times addMarker
    let featureIndex = this.markersSource.data.features
      .map((x: any) => x.properties['deviceId'])
      .indexOf(device.id);
    if (featureIndex != -1) {
      this._moveMarker(device, updateMap);
      return;
    }

    if (this.performance.defaultImage) _image = this.defaultDeviceImage;

    // if no pass image parameter then get device image from mapUtil function
    if (_image) this.addMarker(device, _image, true, updateMap, size);
    else {
      //_ Get image from image service
      this.imageService
        .getImage(device.properties, this.user.id)
        .subscribe(async (r) => {
          if (r != this.mediaApi.whitePicture)
            this.addMarker(device, r, false, updateMap, size);
        }); //getDeviceImage(device, this.user.id);
    }
  }

  add3dMarker(device, updateMap = false, layerOptions = {}) {
    let name = 'marker-' + device.id;
    let geometry = new Geometry();
    let properties: any = {} as IProperties;
    const iconSize = this.markerSize
      ? this.markerSize
      : this.mapService.iconSize.value
        ? this.mapService.iconSize.value
        : { width: 50, hight: 50 };

    geometry.type = GeometryTypes.Point;
    geometry.coordinates = device.lastLocation;
    properties.icon = name;
    properties['image'] = ''; //_ name of the image to show instead icon
    properties.size = (iconSize.width * 100) / 170 / 100;
    properties['deviceShow'] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
    properties['layerType'] = 'marker'; //Type of feature
    properties['deviceId'] = device.id;
    properties['online'] = device.online;
    properties['deviceName'] = device.properties.name;
    properties['deviceColor'] = device.properties.spurfarbe;
    properties['lastPoint'] = device.lastPoint;
    properties['showBlinking'] = !this.performance.showDotEffect;
    properties['modelName'] = device.properties.threedModel_name;
    properties.color = device.color;
    properties['isSliderViewOpen'] = this.isSliderViewEnable;

    Object.keys(layerOptions).forEach((key) => {
      properties[key] = layerOptions[key];
    });

    //_ Only add feature to markersSource to store that and use for animation and motion
    //_ Not update the marker in the map if performance use3dMarkers is enabled
    let fmarker = this._createFeature(
      geometry,
      properties,
      'marker-' + device.id,
    );
    this.markersSource.data.features.push(fmarker);

    if (this.map.getSource('markersSource') && updateMap)
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
    if (device.lastPoint) this.threedHelper.addObject(fmarker);
  }

  async addMarker(
    device,
    img,
    isDefault = false,
    updateMap = false,
    size = { width: 122, height: 170 },
    layerOptions = {},
  ) {
    //_ Not add marker if location is null or undefined
    if (
      device.lastLocation[0] == undefined ||
      device.lastLocation[1] == undefined
    )
      return;
    let name = 'marker-' + device.id;

    //_ Set the name of the image to reuse this one.
    let imageName = isDefault ? img.split('/')[2] : getDeviceImageName(device);
    if (
      this.markersSource.data.features
        .map((x) => x.properties.icon)
        .indexOf(name) == -1
    ) {
      if (img.endsWith('.svg'))
        //_ Change aspect ratio if is a paj_iconset svg image
        img = await this.rescaleImageManagament.resizeImage(
          img,
          size.width,
          size.height,
          'white',
          false,
          false,
          null,
          imageName,
        );
      else
        img = await this.rescaleImageManagament.resizeImage(
          img,
          170,
          170,
          'white',
          false,
          true,
          null,
          imageName,
        );
    }

    //_ Verify if exist the image in other icon property of the marker
    let imageAdded = !(
      this.markersSource.data.features
        .map((x) => x.properties.image)
        .indexOf(imageName) == -1
    );

    if (!imageAdded) {
      imageAdded = await this.loadImage(device, imageName, img); //.then(
      //   (r: boolean) => (imageAdded = r)
      // );
    }

    if (imageAdded) {
      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = device.lastLocation;
      properties.icon = name;
      properties['image'] = imageName; //_ name of the image to show instead icon

      // This is a porcentage of the selected width in settings
      const iconSize = this.markerSize
        ? this.markerSize
        : this.mapService.iconSize.value
          ? this.mapService.iconSize.value
          : { width: 50, hight: 50 };
      properties.size = (iconSize.width * 100) / 170 / 100;
      properties['deviceShow'] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
      properties['layerType'] = 'marker'; //Type of feature
      properties['deviceId'] = device.id;
      properties['online'] = device.online;
      properties['showBlinking'] = !this.performance.showDotEffect;
      properties['icon-offset'] = [0, 0];
      properties['isSliderViewOpen'] = this.isSliderViewEnable;

      Object.keys(layerOptions).forEach((key) => {
        properties[key] = layerOptions[key];
      });

      // properties['subImage'] = '';
      properties.color = device.color;

      //_ If is online should load online pulse image
      if (device.online && !this.performance.showDotEffect) {
        const effectId = 'effect-' + device.id;
        properties['effect'] = effectId;
        let imgEffect = new Effects().PulseShadow(
          this.map,
          '#000',
          device.color,
          80,
        );

        if (!this.map.hasImage(effectId)) {
          this.map.addImage(effectId, imgEffect, { pixelRatio: 1 });
          this.markersImages.push({
            name: effectId,
            url: imgEffect,
            isEffect: true,
          });
        }
      }

      //_ Prevent not duplicate the marker when already exist and call more than 1 times addMarker
      // if (featureIndex != -1)
      //   this.markersSource.data.features[featureIndex].geometry = geometry;
      // else{
      let fmarker = this._createFeature(
        geometry,
        properties,
        'marker-' + device.id,
      );
      this.markersSource.data.features.push(fmarker);
      // }

      if (this.map.getSource('markersSource') && updateMap)
        getSource(this.map, 'markersSource').setData(this.markersSource.data);
      this.HOOKS.onMarkerAdded.emit({
        feature: fmarker,
        device: device,
        updateMap,
      });
    }
  }

  public async addLayerMarkerUsingExistentImage(
    name,
    lng,
    lat,
    size,
    title,
    data = null,
    alignment = 'viewport',
    props,
    updateMap = true,
  ) {
    let marker = this.createFeature(
      [lng, lat],
      name,
      title,
      size,
      'center',
      data,
      alignment,
    );
    marker.properties['image'] = name; //_ name of the image to show instead icon
    marker.properties.size = size;
    marker.properties['deviceShow'] = 1; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
    marker.properties['layerType'] = 'marker'; //Type of feature

    //_ Apply custom props to the marker
    Object.keys(props).forEach((key) => {
      marker.properties[key] = props[key];
    });

    // marker.properties["deviceId"] = device.id;
    // marker.properties["online"] = device.online;
    // marker.properties["deviceName"] = device.properties.name;
    // marker.properties["deviceColor"] = device.properties.spurfarbe;
    // marker.properties["lastPoint"] = device.lastPoint;
    // marker.properties["showBlinking"] = !this.performance.showDotEffect;
    // marker.properties["modelName"] = device.properties.threedModel_name;

    this.markersSource.data.features.push(marker);
    if (getSource(this.map, 'markersSource')) {
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
      this.HOOKS.onMarkerAdded.emit({
        feature: marker,
        device: null,
        updateMap,
      });
    }
  }

  imagesLoadingInMap = {};
  async loadImage(device, name, src) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!this.map.hasImage(name) && !this.imagesLoadingInMap[name]) {
        this.imagesLoadingInMap[name] = name;
        this.map.loadImage(src, async (error, image) => {
          // If the image cant be loaded, call this function with a default icon
          if (error) {
            this._addMarker(
              device,
              'assets/device_markers/paj_iconset_logo.svg',
            ); //throw error;
            console.log('ERROR: ', error);
            resolve(false);
          } else {
            if (
              !this.map.hasImage(name) &&
              !this.markersImages.some((mimg) => mimg.name == name)
            ) {
              await this.map.addImage(name, image);
              this.markersImages.push({ name: name, url: src });
            }
            resolve(true);
          }
        });
      } else resolve(true);
    });
  }

  public async _moveMarker(device, updateMap = false) {
    let name = 'marker-' + device.id;

    this.cancelAnimation(device);
    // Search the marker Feature by yname
    let feature = this.markersSource.data.features.find((f) => f.id == name);
    if (feature) {
      let index = this.markersSource.data.features.indexOf(feature);
      //Get the index of the feature
      if (index > -1) {
        // device['id'] == 163189 ? console.log(device['id'], index, device.lastLocation[0], device.lastLocation[1]) : '';
        // console.log(device['id'], this.markersSource.data.features[index].geometry.coordinates);
        this.markersSource.data.features[index].geometry.coordinates =
          device.lastLocation;
        //_ Move the 3d model if use3dMarkers is tru
        if (this.performance.use3dMarkers)
          this.threedHelper.moveObject(device.id, device.lastLocation);
        // Only update datasource if updateMap flag is true
        if (getSource(this.map, 'markersSource') && updateMap)
          getSource(this.map, 'markersSource').setData(this.markersSource.data);
      }
    }
  }

  public async animateMarker(device) {
    let name = 'marker-' + device.id;

    this.cancelAnimation(device);

    const JUST_MOVE_THE_MARKER = () => {
      // this._addDataPoints(device, false, true, false);
      if (this.performance.use3dMarkers) {
        this.threedHelper.moveObject(device.id, device.lastLocation);
      } else {
        this._moveMarker(device, true);
      }
    };

    //_ No animate the marker if is not running Arf
    if (this.performance.noAnimatedMarker || !this.isArfRunning) {
      //_ Should move the 3dMarker and exit of the method if performance.use3dMarkers is enabled
      JUST_MOVE_THE_MARKER();
      return;
    }

    // Search the marker Feature by yname
    let feature = this.markersSource.data.features.find((f) => f.id == name);
    if (feature) {
      let index = this.markersSource.data.features.indexOf(feature);
      //Get the index of the feature
      if (index > -1) {
        //_ Save first and last point to stop the animation if lastPoint changed
        // const previousLastPoint = this.markersSource.data.features[index].properties['animation'].lastPoint;
        let firstPoint = feature.geometry.coordinates;
        let lastPoint = device.lastLocation;
        this.markersSource.data.features[index].properties['animation'] = {
          firstPoint,
          lastPoint,
          pointData: device.lastPoint,
          animatingPoints: [...device.newDirectionMarkers],
        };

        //_ get distance between two points
        let distanceBetweenPoints = 0;
        if (firstPoint && lastPoint) {
          distanceBetweenPoints = Math.abs(
            Tdistance(firstPoint, lastPoint, { units: 'meters' }),
          );
        }
        if (
          device.newSnapedPoints.length > 0 &&
          distanceBetweenPoints < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS
        ) {
          //_ Animate the marker using snaped points
          let points = device.newSnapedPoints;
          const useGraphhopper =
            this.appService?.userSettings?.use_graphhopper ??
            this.customerSettings?.useGraphhopper;
          if (!useGraphhopper)
            points = this.getPointsFromStringLegs(device.newSnapedPoints);
          else points = this.generatePointsForPath(device.newSnapedPoints);
          // points.push(device.lastLocation);
          // console.log('MAKE AIMATION 1');
          this.makeAnimation(0, points, index, device, true);
        } else {
          //_ Animate the marker & generating points
          if (firstPoint && lastPoint) {
            if (distanceBetweenPoints > this.MAX_DISTANCE_TO_SPLIT_SEGMENTS) {
              // console.log('MAKE AIMATION 2');
              JUST_MOVE_THE_MARKER();
              return;
            } else {
              //_ Generate points between new points (no snaped)
              let points = this.generatePointsForPath(device.newRealtimePoints);
              if (points.length) {
                // console.log('MAKE AIMATION 3');
                this.makeAnimation(0, points, index, device, false);
              }
            }
          } else {
            // this.markersSource.data.features[index].geometry.coordinates = device.lastLocation;
            // getSource(this.map, "markersSource").setData(this.markersSource.data);
            JUST_MOVE_THE_MARKER();
          }
        }
      }
    }
  }

  generatePointsForPath(points) {
    let generatedPoints = [];
    points.forEach((p, index) => {
      if (index > 0) {
        let pointA = null;
        let pointB = null;
        if (Array.isArray(p)) {
          pointA = { lng: p[0], lat: p[1] };
          pointB = { lng: points[index - 1][0], lat: points[index - 1][1] };
        } else {
          pointA = p;
          pointB = points[index - 1];
        }

        //_ [lng, lat];
        generatedPoints = generatedPoints.concat(
          this.generatePointsBetweenTwoPoints(
            [pointB.lng, pointB.lat],
            [pointA.lng, pointA.lat],
          ),
        );
      }
    });

    return generatedPoints;
  }

  //_ Check if a point is in the viewport of the map
  pointInView(point): boolean {
    return this.map ? this.map.getBounds().contains(point) : false;
  }

  pointInViewWithinRadius(point, radiusInMeters): boolean {
    if (this.map) {
      const center = this.map.getCenter();
      const distance = turf.distance(center.toArray(), point, {
        units: 'meters',
      });

      return distance <= radiusInMeters;
    }

    return false;
  }

  updateDataSourceSubject = new Subject<void>();
  updateSourceSubscriptions: any = {};
  async makeAnimation(
    animIndex,
    points,
    animFeature,
    device,
    areSnapedPoints,
    timestamp = null,
    time = { start: null, lastTime: null },
  ) {
    const feature = this.markersSource.data.features[animFeature];
    // device['id'] == 163189 ? console.log(this.markersSource.data.features[animFeature].properties.animation.animatingPoints): '';
    //_ Move the marker and add polyline
    const moveTo = (point, index, animationEnds = false) => {
      this.markersSource.data.features[animFeature].geometry.coordinates =
        point;
      if (animationEnds) {
        const slicedPoints = points.slice((points.length - 1 - index) * -1);
        // console.log('Ending animation', { points: {...points}, leftPoints: slicedPoints, index });
        slicedPoints.forEach((p, indexP) => {
          // console.log('index inner', indexP);
          //_ Only add the route point if monitor mode is disabled
          if (!this.mapService.mapMonitorMode.value) {
            this.addSinglePoint(
              device,
              'realtime-route-' + device.id,
              p,
              indexP == slicedPoints.length - 1,
            );
          }
        });
      } else {
        //_ Only add the route point if monitor mode is disabled
        if (!this.mapService.mapMonitorMode.value) {
          this.addSinglePoint(
            device,
            'realtime-route-' + device.id,
            point,
            true,
            index == 0,
          );
        }
      }

      //_ Move focus only when animation ends or is near from last point of animation
      if (
        animationEnds ||
        (index >= points.length - 4 && index <= points.length)
      ) {
        if (device.autoFocus === true) {
          // console.log('Doing autofocus');
          this.map.flyTo({
            center: [
              points[points.length - 1][0],
              points[points.length - 1][1],
            ],
          });
        }
      }

      //_ Start animating 3dmodel
      if (this.performance.use3dMarkers)
        this.threedHelper.moveObject(device.id, point);

      if (this.map.getSource('markersSource')) {
        clearTimeout(this.updateSourceSubscriptions['markersSource']);
        this.updateSourceSubscriptions['markersSource'] = setTimeout(() => {
          // console.warn('START UPDATING markersSource - ' + moment().format('HH:mm:ss'))
          getSource(this.map, 'markersSource').setData(this.markersSource.data);
        }, 100);
      }

      //_ Move the focus
      // if (this.devicesService.devices[device.id].autoFocus){
      //   this.map.flyTo({
      //     center: point,
      //   });
      // }
    };

    // animIndex++;
    let elapsed = 0;
    if (timestamp) {
      //_ Check prev time exists
      if (time.lastTime) elapsed = timestamp - time.lastTime;

      if (!time.start) time.start = timestamp;
      const lapsed = timestamp - time.start;
      //_ Fix last time when passed 100 ms
      if (elapsed >= 200 || !time.lastTime) time.lastTime = timestamp;

      const timeToNext = Math.round(10000 / points.length);
      const relativeIndex = Math.round(lapsed / timeToNext);
      animIndex = relativeIndex;
      // console.log('ELAPSED TIME', { timestamp, elapsed, time, timeToNext });
    }

    //_ Draw next point in map only if elapsed time is greater than 100 ms
    if (elapsed >= 200 || !time.lastTime) moveTo(points[animIndex], animIndex);

    //_ Break animation if ARF is not running
    if (animIndex < points.length - 1 && this.isArfRunning) {
      //&& checkLastPoint(lastPoint, points[points.length-1]) ){
      // console.log('Animation running', device.id);
      // console.log('Before request');

      return (this.animations[device.id] = {
        animation: requestAnimationFrame((_timestamp) =>
          this.makeAnimation(
            animIndex++,
            points,
            animFeature,
            device,
            areSnapedPoints,
            _timestamp,
            time,
          ),
        ),
        feature: animFeature,
        point: points[animIndex],
        cancelAnimation: () => {
          // console.log('CANCEL Animation index is: ', device.properties.name);
          if (animIndex < points.length - 1) {
            // console.log('Animation index is less than the length of points', {animIndex, pointsLength: points.length-1});
            moveTo(points[points.length - 1], animIndex++, true);
            // device.newDirectionMarkers = [
            //   feature.properties.animation.pointData,
            // ];
            device.newDirectionMarkers =
              feature.properties.animation.animatingPoints;
            //_ Dont forget to add the route markers when animation ends
            this._addDirectionMarkers(device, false, true, false);
            // this.onAnimationStops.emit({ device, lastPointIndex: animIndex, points, areSnapedPoints });
          }
          // console.log('Canceled: ', device.properties.name);
        },
      });

      // return;
    }

    //_ Dont forget to add the route markers when animation ends
    device.newDirectionMarkers = feature.properties.animation.animatingPoints;
    this._addDirectionMarkers(device, false, true, false);
  }

  cancelAnimation(device) {
    if (this.animations[device.id]) {
      this.animations[device.id].cancelAnimation();
      cancelAnimationFrame(this.animations[device.id].animation);
      this.animations[device.id] = undefined;
    }
  }

  //_ Used in animation knowing the featureId this method add new polyline point
  addSinglePoint(
    device,
    featureId,
    point,
    updateMap = true,
    createNewFeature = false,
  ) {
    let isNew = false;
    let routeFeatures = this.dataSource.data.features.filter(
      (d) => d.properties.id == featureId,
    ); //this.searchFeatureById(this.dataSource, featureId);

    // console.log('ROUTE FEATURE in singlePoint', { featureId, routeFeatures })
    let distancePrevPoint = 0;
    if (createNewFeature && routeFeatures.length > 0) {
      if (
        routeFeatures[routeFeatures.length - 1] &&
        routeFeatures[routeFeatures.length - 1].geometry
      ) {
        distancePrevPoint = Tdistance(
          routeFeatures[routeFeatures.length - 1].geometry.coordinates[
            routeFeatures[routeFeatures.length - 1].geometry.coordinates
              .length - 1
          ],
          point,
          { units: 'meters' },
        );
      }
    }

    let routeFeature = null;
    //_ Check in case some last point is not near to the new point; then use same feature otherwise create new one
    if (
      routeFeatures &&
      distancePrevPoint < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS
    )
      routeFeature = routeFeatures[routeFeatures.length - 1];

    if (routeFeature) {
      // console.log('Feature realtime animation found', { distancePrevPoint, polyPoint: routeFeature.geometry.coordinates[routeFeature.geometry.coordinates.length-1].concat(point) });
      routeFeature.properties['deviceShow'] = device.properties.deviceshow
        ? 1
        : 0;
      // console.log('result', routeFeature.geometry.coordinates.find(x=>x[0] == point[0] && x[1] == point[1]));
      // let alreadyExists = routeFeature.geometry.coordinates.find(x=>x[0] == point[0] && x[1] == point[1]);
      // if(!alreadyExists)
      // {
      //   routeFeature.geometry.coordinates.push(point);
      // }
      routeFeature.properties['isSliderViewOpen'] = this.isSliderViewEnable;
      routeFeature.geometry.coordinates.push(point);
    } else {
      // console.log('Feature realtime animation NOT FOUND');
      let properties = {} as IProperties;
      let geometry = new Geometry();
      isNew = true;
      geometry.type = GeometryTypes.LineString;
      geometry.coordinates.push(point);
      properties.color = device.color;
      properties['layerType'] = 'route'; //Type of feature
      properties['deviceShow'] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties.size = this.thickness;
      properties['border-color'] = '#000';
      properties['border-width'] = 0;
      properties['id'] = featureId;
      properties['deviceId'] = device.id;
      routeFeature = this._createFeature(geometry, properties);
      routeFeature['generateId'] = true;
      properties['showPolylines'] = this.showPolylines;
      properties['isSliderViewOpen'] = this.isSliderViewEnable;
    }

    if (!isNew) {
      let index = this.dataSource.data.features.indexOf(routeFeature);
      // Remove the feature and add new route feature
      this.dataSource.data.features.slice(index, 1, routeFeature);
    } else this.dataSource.data.features.push(routeFeature);

    if (this.map.getSource('dataSource') && updateMap && this.showPolylines) {
      clearTimeout(this.updateSourceSubscriptions['dataSource']);
      this.updateSourceSubscriptions['dataSource'] = setTimeout(() => {
        // console.warn('START UPDATING dataSource - ' + moment().format('HH:mm:ss'))
        getSource(this.map, 'dataSource').setData(this.dataSource.data);
      }, 100);
    }
  }

  getPointsFromStringLegs(legs) {
    let points = [];
    legs.forEach((l) => {
      let lastP = null;

      stringToPoints(l, 6).forEach((np) => {
        if (lastP) {
          let genPoints = this.generatePointsBetweenTwoPoints(lastP, np);
          points = points.concat(genPoints);
        }
        lastP = np;
        // points.push(np);
      });
    });
    return points;
  }

  //_ Generate points for the animation
  generatePointsBetweenTwoPoints(pA, pB, length = 100) {
    const line = lineString([pA, pB]);
    const options: any = { units: 'meters' };
    const dist = Tdistance(pA, pB, options);
    const distStep = 2; //d5st < 200 ? 0.5 : 1; //dist/length;

    if (dist < 2) return [pA, pB]; //_ Return same points if distance is less than 2 meters

    let rPoints = [];
    for (let i = 0; i <= dist; i += distStep) {
      const p = along(line, i, options);
      rPoints.push(p.geometry.coordinates);
    }

    rPoints.push(pB);
    return rPoints;
  }

  //_ Check Arf
  shouldCheckArf = true;
  checkingArf = null;
  checkArf() {
    // this.checkingArf = requestAnimationFrame(() => {
    //   if (this.timerCheckFrame) clearInterval(this.timerCheckFrame);
    //   // console.log('Checking animation ARF');
    //   this.isArfRunning = true;
    //   this.timerCheckFrame = setTimeout(() => (this.isArfRunning = false), 500);
    //   if (this.shouldCheckArf) this.checkArf();
    // });
  }

  //_ Stop the loop to check if ARF is running
  async stopArf() {
    if (this.shouldCheckArf) {
      // this.shouldCheckArf = false;
      // cancelAnimationFrame(this.checkingArf);
      // clearInterval(this.timerCheckFrame);
      this.isArfRunning = false;
      // this.checkingArf = null;

      //_ Cancel all animations from the array
      for (const k of Object.keys(this.animations)) {
        // console.log('Canceling all animations', k);
        if (this.animations[k]?.animation) {
          // console.time('Canceling animation frame');
          cancelAnimationFrame(this.animations[k].animation);
          //_ wait a bit to prevent draw a wrong polyline from the index of the animated point
          await this.delay(200);
          // console.timeEnd('Canceling animation frame');
          this.animations[k] ? this.animations[k].cancelAnimation() : '';
          // console.log('Called method to cancel');
        }
      }
      this.animations = [];
    }
  }

  //_ 250km/h = 70mt/sec so in (30sec = 2100mt) but depends of the delay if the device send location after more than 30 sec and car is in highway, distance will be greether than 2100 mt
  MAX_DISTANCE_TO_SPLIT_SEGMENTS = DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS;
  MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT = 300;
  // Function to add Datapoints, Snaped points and Realtime points
  // Manage differente features for datapoints/snaped points got it in first load
  // And create other feature to show the realtime points
  public async _addDataPoints(
    device,
    removeAll = false,
    realTime = false,
    updateMap = false,
    layerOptions = {},
  ) {
    if (this.dataSource) {
      // If removeAll = true then search the feature of this device, and update all points.
      // propertieId is the name of the feature, when is realtime or route loaded on scrollbar or on first time
      let propertieId = realTime
        ? 'realtime-route-' + device.id
        : 'route-' + device.id;

      //_ Get device route features
      let routeFeatures = this.searchFeaturesByDeviceId(
        this.dataSource,
        device.id,
      );

      // console.log('[DEBUG] Route features 1', { routeFeatures: {...routeFeatures}, removeAll, deviceName: device.properties.name });
      let newFeature: any = null;
      const propertieIdRT = 'realtime-route-' + device.id;
      //_ filter to delete the features (realtime or normal route)
      if (removeAll && routeFeatures) {
        if (layerOptions['isSliderData']) {
          routeFeatures = routeFeatures.filter(
            (f) => f.properties['isSliderData'] !== true,
          );
        } else {
          routeFeatures = routeFeatures.filter(
            (f) =>
              f.properties.id != propertieId &&
              f.properties.id != propertieIdRT,
          );
        }
      }

      //_ Get the realtime feature of the device
      if (realTime) {
        const realtimeFeatures = this.dataSource.data.features.filter(
          (d) => d.properties.id === propertieIdRT,
        );
        if (realtimeFeatures.length > 0) {
          newFeature = realtimeFeatures[realtimeFeatures.length - 1];
        }

        routeFeatures = routeFeatures.filter(
          (d) => d.properties.id !== 'realtime-route-' + device.id,
        );
      }

      // console.log('[DEBUG] Route features after filter', { routeFeatures: {...routeFeatures}, removeAll, deviceName: device.properties.name, newFeature, propertieIdRT });

      //_ Base feature data
      let properties = {} as IProperties;
      let geometry = new Geometry();
      geometry.type = GeometryTypes.LineString;
      properties.color = device.color;
      properties['layerType'] = 'route'; //Type of feature
      properties['deviceShow'] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties.size = this.thickness;
      // properties["border-color"] = "#000";
      // properties["border-width"] = 0;
      properties['id'] = propertieId;
      properties['deviceId'] = device.id;

      //_ Create new feature in case doesnt found the realtime feature to concat geometries
      if (newFeature === null)
        newFeature = this._createFeature(geometry, properties);

      newFeature['generateId'] = true;
      properties['showPolylines'] = this.showPolylines;
      properties['isSliderViewOpen'] = this.isSliderViewEnable;

      Object.keys(layerOptions).forEach((key) => {
        properties[key] = layerOptions[key];
      });

      newFeature.properties['deviceShow'] = device.properties.deviceshow
        ? 1
        : 0;
      let deviceFeatures: any = routeFeatures;

      //_ Add polylines when device has new Data Points

      if (device.newDataPoints.length > 0) {
        // && !realTime
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          device.newDataPoints,
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      //_ Pushing new realtime points
      if (
        device.newRealtimePoints.length > 0 &&
        realTime &&
        device.newSnapedPoints.length == 0
      ) {
        //
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          device.newRealtimePoints,
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      // Add realtime polylines when device has new realtime Points
      // Only if is realtime flag true
      if (realTime && device.newSnapedPoints.length == 0) {
        //_ Get realtimeFeature from routeFeatures or push newFeature to start adding points for that line
        //_ deviceFeatures = routeFeatures;
        //_ propertieId = realtime-device.id
        //_ **** DEPRECATED ***
        // let realtimeFeature = deviceFeatures.find(f => f.properties.id == propertieId);
        // if (realtimeFeature == undefined)
        //   deviceFeatures.push(newFeature);
        // let realtimeFeatureIndex = deviceFeatures.map(f => f.properties.id).indexOf(propertieId);
        // device.newRealtimePoints.forEach((p) => {
        //   if (p.wifi == null) //_ No take the wifi points for the polylines
        //     deviceFeatures[realtimeFeatureIndex].geometry.coordinates.push([p.lng, p.lat]);
        // });
        //_ *******************
        //_ Remove all realtime-route features, and recalculate for segments to delete long lines between realtime points
        // deviceFeatures = deviceFeatures.filter(
        //   (f) => f.properties.id == "route-" + device.id
        // );
        // const newFeatures = this.createFeaturesFromDataPoints(
        //   newFeature,
        //   device.realtimePoints
        // ); // realtimeSnapedPoints
        // deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      // Add polylines when device has new Snaped Points
      let snapedArray: any = [];
      const useGraphhopper =
        this.appService?.userSettings?.use_graphhopper ??
        this.customerSettings?.useGraphhopper;
      device.newSnapedPoints.forEach((p) => {
        // Iterate array of new points returned after convert the str polilynes to coordinates
        //_ Graphhopper implementation :: remove realTime check in condition
        //_ when is implemented graphhopper for renderPoints method in backend
        // this.appService.userSettings.use_graphhopper = 1;
        if (!useGraphhopper)
          stringToPoints(p, 6).forEach((np) => {
            snapedArray.push({ lng: np[0], lat: np[1], wifi: null });
          });
        else {
          snapedArray.push({ lng: p[0], lat: p[1], wifi: null });
        }
      });

      //_ Add snaped features to the Device Features array
      if (snapedArray.length > 0) {
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          snapedArray,
          true,
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      //_ Add new segmented features
      //_ Filter to get only all route features of the other devices not for this device
      //_ Then merge current Datasource too new Device Source Data
      let deviceSourceData = this.dataSource.data.features.filter(
        (d) =>
          d.properties.deviceId != device.id &&
          d.properties.layerType == 'route',
      );

      deviceSourceData = deviceSourceData.concat(deviceFeatures);
      this.dataSource.data.features = deviceSourceData;

      // Added new feature to the dataSource
      // Only update datasource if updateMap flag is true
      if (
        this.map?.getSource('dataSource') &&
        updateMap &&
        this.showPolylines
      ) {
        getSource(this.map, 'dataSource').setData(
          this.simplifyPolylines(this.dataSource),
        );
        this.HOOKS.onAddPolylines.emit({
          source: this.dataSource,
          name: 'dataSource',
        });
      }
    }
  }
  async checkIfMapData() {
    if (this.dataSource.data.features.length == 0 || this.checkDevicesData())
      await this.appService.showToast(
        '',
        this._translate.instant('dashboard.noData'),
        2000,
        'danger',
        'top',
      );
  }

  checkDevicesData() {
    return Object.values(this.devicesService.devices)
      .map((device) => device.dataPoints)
      .map((dataPoints) =>
        dataPoints.length == 0
          ? true
          : dataPoints.every(
              (value) =>
                value.lat === dataPoints[0].lat &&
                value.lng === dataPoints[0].lng,
            ),
      )
      .every((value) => value === true);
  }

  filterDataSource() {
    let filteredFeatures = [...this.dataSource.data.features];
    if (!this.showPolylines) {
      filteredFeatures = filteredFeatures.filter(
        (f) => f.properties.layerType != 'route',
      );
    }

    // const oldSize = this.getVariableSizeInMB(this.dataSource.data);
    // const size = this.getVariableSizeInMB(filteredFeatures);
    // console.warn('Polylines source data', { dataSource: this.dataSource.data, oldSize: oldSize, newDataSource: filteredFeatures, size: size });
    return { ...this.dataSource.data, features: filteredFeatures };
  }

  simplifyPolylines(source) {
    const tolerance = 0.01;
    //_ delete extra properties to reduce the size of data source
    if (source.data.features?.length > 0) {
      source.data.features.map((f) => {
        if (f.properties.lastPointData) delete f.properties.lastPointData;
        return f;
      });

      //_ Simplify gometry coordinates
      source.data.features.map((f) => {
        try {
          const simplify = turf.simplify(f, { tolerance });
          return simplify;
        } catch (error) {
          return f;
        }
      });
    }

    return source.data;
  }
  createFeaturesFromDataPoints(
    initialFeature,
    dataPoints,
    isSnapPoints = false,
  ) {
    const featureData = cloneObject(initialFeature);
    let lastLocation: any = null;
    let secondLastLocation: any = null;
    let lastFeature: any = null;
    let newFeatures: any = [];

    dataPoints.forEach((p, index) => {
      // console.log('POINT ID', p.id);
      if (p.wifi == null || p.wifi == undefined) {
        //_ No take the wifi points for the polylines{
        //_ First get lastLocation from lastFeature
        if (lastFeature) {
          lastLocation =
            lastFeature.geometry.coordinates[
              lastFeature.geometry.coordinates.length - 1
            ];
          if (lastFeature.geometry.coordinates.length > 1) {
            secondLastLocation =
              lastFeature.geometry.coordinates[
                lastFeature.geometry.coordinates.length - 2
              ];
          }
        }

        if (lastLocation) {
          let distanceLastLocToNewLoc = Math.abs(
            Tdistance(lastLocation, [p.lng, p.lat], { units: 'meters' }),
          );

          let distanceSecondLastLocToNewLoc = 0;
          if (secondLastLocation) {
            distanceSecondLastLocToNewLoc = Math.abs(
              Tdistance(secondLastLocation, [p.lng, p.lat], {
                units: 'meters',
              }),
            );
          }

          //_ Then check if last feature coordinate and new coordinate is longer than X meters
          //_ And split in new feature to cut the line into two or more segments
          //_ Also check the time diff in seconds for everypoint
          //************** CHECK TIME DIFF -> but need to fix for valhalla points  */
          let diffSeconds = 30;
          if (lastFeature.properties['lastPointData'] && p.dateunix) {
            const startDate = moment.unix(
              lastFeature.properties['lastPointData'].dateunix,
            );
            const endDate = moment.unix(p.dateunix);
            diffSeconds = Math.abs(startDate.diff(endDate, 'seconds'));
          }

          let MAX_DISTANCE_PER_SECONDS = Math.abs(
            (this.MAX_DISTANCE_TO_SPLIT_SEGMENTS / 30) * diffSeconds,
          );

          //_ Debug distances and time diff
          // if (distanceLastLocToNewLoc > 1000){
          //   console.log('DATA', { distanceLastLocToNewLoc, MAX_DISTANCE_TO_SPLIT_SEGMENTS: this.MAX_DISTANCE_TO_SPLIT_SEGMENTS, diffSeconds, points: { lastLocation, p }});
          // }

          //_ For snap points max lengh not can be calculated by the dateunix so use an small max distance to break the line
          if (isSnapPoints)
            MAX_DISTANCE_PER_SECONDS = this.MAX_DISTANCE_TO_SPLIT_SEGMENTS * 5;

          p['last_loc_distance'] = distanceLastLocToNewLoc;
          p['second_last_loc_distance'] = distanceSecondLastLocToNewLoc;
          //_ Added condition to check max diff in seconds from previous point and distance driven in that time
          //_ Conditionnally to distance < MAX_DISTANCE_PER_SECONDS and diffSeconds < MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT
          if (
            distanceLastLocToNewLoc < MAX_DISTANCE_PER_SECONDS &&
            diffSeconds < this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT
            // || isSnapPoints
          ) {
            //_ Previous check
            // if (distanceLastLocToNewLoc < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS){
            // console.log('Distance in meters < MAX_DISTANCE_PER_SECONDS', {distanceLastLocToNewLoc, MAX_DISTANCE_PER_SECONDS, MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT: this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT, diffSeconds, });
            if (
              p['second_last_loc_distance'] > 0 &&
              p['last_loc_distance'] > p['second_last_loc_distance']
            ) {
              // Removed point if the distance from last location is greater than second last location
              // Logically the last point is close to the current point than the second last point
              // lastFeature.geometry.coordinates.pop();
            }
            lastFeature.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature.properties['lastPointData'] = p; //isSnapPoints ? p : null;
          }
          //_ push new lastFeature and create new feature for next segment
          else {
            // console.log('Distance in meters > MAX_DISTANCE_PER_SECONDS', {distanceLastLocToNewLoc, MAX_DISTANCE_PER_SECONDS, MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT: this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT});
            // console.log('Spliting polyline', newFeatures.length + 1);
            lastFeature.properties['lastPointData'] = p; //isSnapPoints ? p : null;
            newFeatures.push(cloneObject(lastFeature));
            const newSegment = cloneObject(featureData);
            newSegment.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature = cloneObject(newSegment);
          }
        } else {
          // console.log('Creating First Point');
          initialFeature.geometry.coordinates.push([p.lng, p.lat]);
          initialFeature.properties['lastPointData'] = p; //isSnapPoints ? p : null;
          lastFeature = cloneObject(initialFeature);
          // newFeatures.push(newFeature);
        }
      }

      //_ when finish the looop add lastFeature in case is not added and has some coordinates
      if (
        index == dataPoints.length - 1 &&
        lastFeature?.geometry.coordinates.length > 0
      )
        newFeatures.push(lastFeature);
    });

    return newFeatures;
  }

  // Add direction feature on top for use in scroll features
  // Add directions markers features
  public async _addScrollDirection(feature, removeAll = false) {
    if (removeAll)
      // check flag to delete all features
      this.scrollDirectionsSource.data.features = [];

    if (feature)
      // check if feature is not null
      this.scrollDirectionsSource.data.features.push(feature);

    if (this.map.getSource('scrollDirectionsSource'))
      //Update feature in the source of the map
      getSource(this.map, 'scrollDirectionsSource').setData(
        this.scrollDirectionsSource.data,
      );
  }

  // Add directions markers points
  public async _addDirectionMarkers(
    device,
    removeAll = false,
    realTime = false,
    updateMap = false,
    layerOptions = {},
  ) {
    if (removeAll) {
      // Remove all features that start with 'direction-' propertie id
      // No realtime directions
      const propertieIdRT = 'realtime-direction-' + device.id;
      if (realTime)
        this.directionsSource.data.features =
          this.directionsSource.data.features.filter((f) => {
            return !f.id.startsWith('realtime-direction-' + device.id);
          });
      else
        this.directionsSource.data.features =
          this.directionsSource.data.features.filter((f) => {
            return (
              !f.id.startsWith('direction-' + device.id) &&
              !f.id.startsWith(propertieIdRT)
            );
          });
    }

    device.newDirectionMarkers.forEach(async (p) => {
      // name is the propertie id, to disting between realtime and no realtime directions markers
      let name = realTime
        ? 'realtime-direction-' + device.id + '-' + p.id
        : 'direction-' + device.id + '-' + p.id;

      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = [
        Number(p.lng?.toFixed(6)),
        Number(p.lat?.toFixed(6)),
      ];
      if (p.speed <= 2) {
        // This is done using  driving data managament
        // properties.icon = "direction-marker-wifi";
        properties['showDirections'] = this.showDirections;
      } else {
        // properties.icon = "direction-marker";
        properties['showDirections'] = this.showDirections;
      }

      //_ Change direction color to green if is WifiHome
      // p.wh = 2; //_ For testing propose
      const haloColor = p.wh ? '#00DC2F' : '#FFFFFF';

      // properties.size = 0.5;
      properties['deviceId'] = device.id;
      properties['id'] = name;
      properties['layerType'] = 'directions'; //Type of feature
      properties['deviceShow'] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties['color'] = device.color;
      properties['isHomeWifi'] = p.wh ?? null;
      properties['haloColor'] = haloColor;

      // properties["invertColor"] = "#fff"; //invert(device.color, true);
      properties['rotate'] = p.direction;
      properties['speed'] = p.speed;
      properties['directionId'] = p.id;

      properties['origin'] = p.wifi ? 'wifi' : 'gps';
      properties['isSliderViewOpen'] = this.isSliderViewEnable;
      // properties["isSliderData"] = true;

      Object.keys(layerOptions).forEach((key) => {
        properties[key] = layerOptions[key];
      });

      let fdirection = this._createFeature(geometry, properties, name);
      this.directionsSource.data.features.push(fdirection);
    });

    // Update datasource to update the map layers
    // Only update datasource if updateMap flag is true
    if (this.map) {
      if (this.filterSource.directions) {
        this.DirectionsClusterSource.setData(this.directionsSource);
      } else if (this.map.getSource('directionsSource') && updateMap) {
        getSource(this.map, 'directionsSource').setData(
          this.directionsSource.data,
        );
      }
    }
  }

  DirectionsClusterSource = new ClusterDataSource(this.directionsSource);
  filterDirectionsMarkers(forceReload = false) {
    //_ If showDirections Toggle button is off OR not loaded directionsSource
    if (!this.showDirections || this.unloadedDirectionSource) return;

    if (this.FilterDirectionsVars.filterTimer)
      clearTimeout(this.FilterDirectionsVars.filterTimer);

    this.FilterDirectionsVars.filterTimer = setTimeout(() => {
      const bounds = this.map.getBounds();
      // const size = this.getVariableSizeInMB(this.directionsSource.data);
      const reducedDirectionsSource = this.DirectionsClusterSource.getFeatures(
        bounds,
        this.map.getZoom(),
      );
      // const reducedSize = this.getVariableSizeInMB(reducedDirectionsSource);

      // console.warn('[DEBUG] FILTER DIRECTIONS MARKERS');
      //_ Check if is loaded the source AND if features needUpdate AND mouse is not draggin AND if showDirections is true
      const shouldUpdateMapSource =
        this.map.getSource('directionsSource') &&
        reducedDirectionsSource.needUpdate &&
        this.showDirections;

      if (shouldUpdateMapSource || forceReload) {
        getSource(this.map, 'directionsSource')?.setData({
          features: reducedDirectionsSource.features,
        });
        // console.warn('UPDATING SOURCE MAP ', { old: this.directionsSource.data, oldSize: size + ' MB', reducedDirections: reducedDirectionsSource, reducedSize: reducedSize + ' MB' })
      }
    }, 200);
  }

  getVariableSizeInMB(variable) {
    var jsonString = JSON.stringify(variable);
    var bytes = new Blob([jsonString]).size;
    var megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  }

  // Improve the render time to only update datasource onece each time has new data
  // Can update only which data source you want to update
  updatingDataSources = null;
  updateDataSources(which = { directions: true, markersAndRoutes: true }) {
    if (!this.map) return;
    //_ Prevent multiple calls to update source
    if (this.updatingDataSources) clearTimeout(this.updatingDataSources);

    this.updatingDataSources = setTimeout(() => {
      if (which.directions) {
        if (this.filterSource.directions) {
          this.DirectionsClusterSource.setData(this.directionsSource);
          if (
            !this.mapService.mapMonitorMode.value &&
            this.filterSource.directions
          ) {
            this.filterDirectionsMarkers();
          }
        } else if (getSource(this.map, 'directionsSource'))
          getSource(this.map, 'directionsSource').setData(
            this.directionsSource.data,
          );
      }
      if (which.markersAndRoutes) {
        if (this.map.getSource('dataSource')) {
          getSource(this.map, 'dataSource').setData(this.filterDataSource());
        }

        if (this.map.getSource('markersSource'))
          getSource(this.map, 'markersSource').setData(this.markersSource.data);
      }
    }, 100);
  }

  /***************** CREATE|MOVE ONLINE EFFECT LAYER *********************** */
  async _onlineEffect(device, effect = null, updateMap = false) {
    let id = 'marker-' + device.id;
    let img = !effect
      ? new Effects().PulseShadow(
          this.map,
          '#000',
          device.properties.spurfarbe,
          this.performance.use3dMarkers ? 200 : 80,
        )
      : effect;
    let index = this.markersSource.data.features
      .map((x) => x.properties.icon)
      .indexOf(id);

    if (index != -1) {
      const effectId = 'effect-' + device.id;
      this.markersSource.data.features[index].properties.effect = effectId;
      this.markersSource.data.features[index].properties.online = true;

      if (!this.map.hasImage(effectId)) {
        this.map.addImage(effectId, effect, { pixelRatio: 1 });
        this.markersImages.push({
          name: effectId,
          url: effect,
          isEffect: true,
        });
      }
    }

    if (this.map.getSource('markersSource') && updateMap)
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
  }

  async _deleteEffect(device) {
    const id = 'marker-' + device.id;
    let index = this.markersSource.data.features
      .map((x) => x.properties.icon)
      .indexOf(id);

    if (index != -1) {
      const effectId = 'effect-' + device.id;
      if (this.map.hasImage(effectId)) this.map.removeImage(effectId);

      this.markersImages.splice(
        this.markersImages.map((x) => x.id).indexOf(effectId),
        1,
      );
      this.markersSource.data.features[index].properties.online = false;
      this.markersSource.data.features[index].properties.effect = null;
    }

    if (this.map?.getSource('markersSource'))
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
  }

  /************************************************************************************ */

  // The response from api for pauseMarkers are
  // data = End position
  // pause_start = startPosition
  // pause_display = marker position
  // device
  public async _addPauseMarker(
    device,
    deleteDevicePauses = false,
    realtime = false,
    layerOptions = {},
  ) {
    this.pauseMarkersClass.addPauseMarkers(
      device,
      deleteDevicePauses,
      realtime,
      layerOptions,
    );
  }

  // Search feature by propertie Id
  searchFeatureById(datasource, id) {
    return datasource.data.features.find((d) => d.properties.id == id);
  }

  // Search feature by propertie Id
  searchFeaturesByDeviceId(datasource, deviceId, layerName = 'route') {
    return datasource.data.features.filter(
      (d) => d.properties.deviceId == deviceId,
    );
  }

  removeArrayItem(arr, name) {
    return arr.filter((e) => {
      return e.properties.icon !== name;
    });
  }

  //Marks in divs thats not recorded by recordRTC in canvas
  public async removeMarkers() {
    this.markers.forEach((marker) => {
      marker.remove(this.map);
    });
    this.markers = [];
  }

  public async fitBounds(
    points,
    Xpadding: any = 20,
    Xspeed = 1.2,
    linear = true,
    event = null,
    props = {},
  ) {
    points = points.filter(function (el) {
      return el[0] != null && el[1] != null;
    });
    var bounds = points.reduce(
      function (bounds, coord) {
        return bounds.extend(coord);
      },
      new this.LngLatBounds(points[0], points[0]),
    );

    if (points.length > 0) {
      const delay = this.performance.use3dMarkers ? 800 : 0;

      //_ Fix fitBounds for different map projections
      //_ This is going back to mercator projection to center the map and then changing to the userSetting projection
      if (this.useMapbox) {
        if (
          this.userSettings.map_projection !== 'mercator' ||
          this.userSettings.map_projection !== 'globe'
        )
          this.map?.setProjection('mercator');
      }

      setTimeout(() => {
        this.map.fitBounds(
          bounds,
          { duration: 0, padding: Xpadding, ...props },
          //{ padding: Xpadding, speed: Xspeed, linear: linear },
          event,
        );

        //_ Fix fitBounds for different map projections
        if (this.useMapbox)
          setTimeout(
            () => this.map?.setProjection(this.userSettings.map_projection),
            50,
          );
      }, delay);
    }
  }

  public async flyTo(
    x,
    y,
    Xzoom = 10,
    Xspeed = 1,
    pitch = this.pitch,
    bearing = this.bearing,
  ) {
    await this.map.flyTo({
      center: [x, y],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      curve: Xzoom,
      speed: Xspeed,
      pitch: pitch,
      bearing: bearing,
      maxDuration: Xspeed,
    });

    return 0;
  }

  public async _flyTo(x, y, zoom, props = {}) {
    await this.map.flyTo({
      center: [x, y],
      zoom: zoom,
      ...props,
    });
  }

  public async centerTo(x, y, Xzoom = 10, Xspeed = 1) {
    await this.map.flyTo({
      center: [x, y],
      essential: false, // this animation is considered essential with respect to prefers-reduced-motion
      zoom: Xzoom,
      duration: Xspeed,
    });
  }

  public createDataSource(which = 'route') {
    let nsource: DataSource = <DataSource>{};
    nsource.data = <Data>{};
    nsource.type = dataSourceType.geojson;
    nsource.data.features = [];
    nsource.data.type = dataTypes.FeatureCollection;
    nsource['generateId'] = true;

    if (which == 'markersSource') {
      nsource['cluster'] = this.performance.clusterMarkers; //_ Take care this value is not loaded at startup
      nsource['clusterMaxZoom'] = 12; //_ Max zoom to cluster join features
      nsource['clusterRadius'] = 50; //_ Radius of each cluster when clustering features
    }

    if (which == 'directionsSource') {
      nsource['cluster'] = true; //_ Take care this value is not loaded at startup
      nsource['clusterMaxZoom'] = 9; //_ Max zoom to cluster join features (hide direction markers)
      nsource['clusterRadius'] = 25; //_ Radius of each cluster when clustering features
      nsource['buffer'] = 0;
    }

    if (which == 'geofenceSource') {
      return {
        type: 'FeatureCollection',
        features: [],
      };
    }

    return nsource;
  }

  public _createFeature(geometry, properties, id = null) {
    let feature = new Feature();
    feature.geometry = geometry;
    feature.properties = properties;

    if (id) feature.id = id;
    return feature;
  }

  public createFeature(
    points,
    iconName,
    title,
    size = 0.25,
    justify = 'center',
    data = null,
    alignment = 'auto',
    colors = {
      fill: this.primaryColor,
      border: 0,
      'border-color': this.primaryColor,
    },
    geometryType: GeometryTypes = GeometryTypes.Point,
  ) {
    let feature = new Feature();
    feature.geometry.type = geometryType;
    feature.geometry.coordinates = points;
    feature.properties.title = title;
    feature.properties.icon = iconName;
    feature.properties['image'] = iconName;
    feature.properties.size = size;
    feature.properties.justify = justify;
    feature.properties.alignment = alignment;
    feature.properties.color = colors.fill;
    feature.properties['border-color'] = colors['border-color'];
    feature.properties['border-width'] = colors.border;
    if (data) feature.properties.data = data;
    return feature;
  }

  public createLayer(id, source) {
    let layer = new Layer(id);
    layer.source = source;

    if (id == 'route') {
      layer.type = layerType.line;
      layer.layout['line-join'] = 'round';
      layer.layout['line-cap'] = 'round';
      layer.paint['line-color'] = ['get', 'color'];
      layer.paint['line-width'] = ['get', 'size'];
      //layer['slot'] = 'top';
      // [
      //   "case",
      //   ["boolean", ["feature-state", "hover"], false],
      //   8,
      //   ["get", "size"],
      // ];

      layer['filter'] = [
        'all',
        ['==', ['get', 'layerType'], 'route'], // Select only markers features from the dataSource
        ['==', ['get', 'deviceShow'], 1], // Only show if deviceShow == 1
        ['==', ['get', 'showPolylines'], true],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];

      layer.maxzoom = 20;
      layer.minzoom = 4;
    }

    // To show circles in the last point behind the marker
    if (id == 'circles') {
      layer.type = layerType.circle;
      layer.paint['circle-radius'] = 7;
      layer.paint['circle-color'] = ['get', 'color'];
      layer.paint['circle-opacity'] = 1.0;
      layer.paint['circle-pitch-alignment'] = 'map';
      //layer['slot'] = 'bottom';

      layer['filter'] = [
        'all',
        ['==', ['get', 'layerType'], 'marker'], // Select only markers features from the dataSource
        ['==', ['get', 'deviceShow'], 1], // Only show if deviceShow == 1
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];
    }

    if (id == 'markers' || id == 'effects' || id == 'clusterMarkers') {
      layer.type = layerType.symbol;
      layer.layout['icon-image'] = ['get', 'image'];
      layer.layout['icon-rotate'] = ['get', 'rotate'];
      layer.layout['text-anchor'] = 'top';
      layer.layout['icon-size'] = ['get', 'size'];
      layer.layout['icon-anchor'] = 'bottom';
      layer.layout['text-field'] = ['get', 'title'];
      layer.layout['text-offset'] = [0, -6]; //2
      layer.layout['icon-offset'] = ['get', 'icon-offset'];
      layer.layout['icon-allow-overlap'] = true;
      layer.layout['text-allow-overlap'] = true;
      layer.layout['icon-rotation-alignment'] = 'viewport'; //could be viewport|map

      //_ Slots pleacements
      // if (id === 'markers')
      //layer['slot'] = 'top';

      layer['filter'] = [
        'all',
        ['==', ['get', 'layerType'], 'marker'], //_ Select only markers features from the dataSource
        ['==', ['get', 'deviceShow'], 1], //_ Show only if deviceShow == 1
        // ['==', 'point_count', 0] //_ Only show if doesnt has more clusters points
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];

      // Show only if the device is online - Online effect
      if (id == 'effects') {
        layer.layout['icon-size'] = 1;
        layer.layout['icon-image'] = ['get', 'effect'];
        layer.layout['icon-anchor'] = 'center'; // center effect image
        layer.layout['icon-rotation-alignment'] = 'map';
        //layer['slot'] = 'bottom';

        layer['filter'] = [
          'all',
          ['==', ['get', 'online'], true],
          ['==', ['get', 'deviceShow'], 1], // Only show if deviceShow == 1
          ['==', ['get', 'showBlinking'], true],
          [
            'any',
            ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
            ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
          ],
        ];

        // layer.maxzoom = 20;
        // layer.minzoom = 4;
      }
    }

    if (
      id == 'directions' ||
      id == 'scrollDirections' ||
      id == 'wifiDirections'
    ) {
      layer.type = layerType.symbol;
      // layer['slot'] = 'bottom';
      // layer.layout["icon-image"] = ["get", "icon"]; // direction-marker-wifi
      // layer.layout["icon-image"] = [
      //   "case",
      //   ["<=", ["get", "speed"], 0],
      //   "direction-marker-wifi",
      //   "direction-marker",
      // ];

      layer.layout['icon-image'] = [
        'case',
        [
          'all',
          ['<=', ['get', 'speed'], 0],
          ['!', ['==', ['typeof', ['get', 'isHomeWifi']], 'undefined']],
          ['!=', ['get', 'isHomeWifi'], null],
        ],
        'direction-marker-wifi',
        'direction-marker',
      ];

      layer.layout['icon-rotate'] = ['get', 'rotate'];
      layer['paint']['icon-color'] = ['get', 'color'];
      layer.paint['icon-halo-color'] = ['get', 'haloColor']; // "#fff"
      layer.paint['icon-halo-width'] = 1.5;
      layer.layout['icon-size'] = 0.5;

      // This propertie allow to show always all the markers
      layer.layout['icon-allow-overlap'] = this.dragableRouteMarkers;
      layer.layout['icon-rotation-alignment'] = 'map'; //['get', 'alignment']; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
      layer.maxzoom = 20;
      layer.minzoom = 4;

      layer['filter'] = [
        'all',
        ['==', ['get', 'layerType'], 'directions'], // Select only markers features from the dataSource
        ['==', ['get', 'deviceShow'], 1], // Only show if deviceShow == 1
        ['==', ['get', 'showDirections'], true],
        ['==', ['get', 'origin'], 'gps'],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];

      if (id == 'wifiDirections') {
        //layer['slot'] = 'top';
        layer.layout['icon-image'] = 'direction-marker-wifi';
        layer.paint['icon-halo-color'] = '#00aaff';
        layer['filter'][4] = ['==', ['get', 'origin'], 'wifi'];
        layer.maxzoom = 20;
        layer.minzoom = 4;
      }

      // show on top of all the others features
      if (id == 'scrollDirections') {
        //layer['slot'] = 'top';
        layer.paint['icon-halo-color'] = 'red';
        layer.layout['icon-allow-overlap'] = true;
        layer.maxzoom = 20;
        layer.minzoom = 4;
      }
    }

    // if (id == "pauses") {
    //   layer.type = layerType.symbol;
    //   layer.layout["icon-image"] = ["get", "icon"];
    //   layer.layout["icon-size"] = ["get", "size"];
    //   layer.layout["icon-allow-overlap"] = false;
    //   layer.layout["icon-rotation-alignment"] = "map"; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
    //   layer.maxzoom = 20;
    //   layer.minzoom = 4;

    //   layer["filter"] = [
    //     "all",
    //     ["==", "layerType", "pause"], // Select only markers features from the dataSource
    //     ["==", "deviceShow", 1], // Show only if deviceShow == 1
    //     ["==", "showPauses", 1], //_ Flag from user settings
    //   ];
    // }

    if (id == 'alarms' || id == 'speedCameras') {
      //layer['slot'] = 'top';
      layer.type = layerType.symbol;
      layer.layout['icon-image'] = ['get', 'icon'];
      layer.layout['icon-size'] = ['get', 'size'];
      layer.layout['icon-anchor'] = 'bottom';
      layer['paint']['icon-color'] = ['get', '#ff0000'];
      layer.layout['icon-allow-overlap'] = false;
      layer.layout['icon-rotation-alignment'] = 'viewport'; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
      layer.maxzoom = 20;
      layer.minzoom = 4;

      layer['filter'] = [
        'all',
        ['==', 'layerType', 'alarms'], // Select only markers features from the dataSource
        ['==', 'deviceShow', 1], // Show only if deviceShow == 1
        ['==', 'showAlarms', true], //_ Flag from user settings
        // ['==', 'isRead', 1],  //_ Flag from user settings show unread/read alarms :: custom condition
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];

      if (id == 'speedCameras') {
        //layer['slot'] = 'top';
        layer.maxzoom = 20;
        layer.minzoom = 9;
        layer.layout['icon-allow-overlap'] = true;
        //layer['slot'] = 'top';
        // layer.layout["icon-ignore-placement"] = true;
        layer['filter'] = [
          'all',
          ['==', ['get', 'layerType'], 'speedCameras'], // Select only markers features from the dataSource
          ['==', ['get', 'show'], true], //_ Flag from user settings
          [
            'any',
            ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
            ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
          ],
        ];
      }
    }

    if (id == 'clusterMarkers') {
      //layer['slot'] = 'top';
      layer.layout['icon-size'] = 0.35;
      layer.layout['icon-image'] = 'default-device-marker';

      layer['filter'] = ['has', 'point_count'];
    }

    if (id == 'clusterBgMarkers') {
      //layer['slot'] = 'top';
      layer.type = layerType.symbol;
      layer.layout['icon-image'] = 'direction-marker-wifi';
      layer['paint']['icon-color'] = this.primaryColor;
      // layer.paint["icon-halo-width"] = 0.5;
      // layer.paint["icon-halo-color"] = "#fff";
      layer.paint['icon-translate'] = [-25, -50];
      layer.paint['icon-translate-anchor'] = 'viewport';
      layer.layout['icon-allow-overlap'] = true;
      // layer.layout["text-allow-overlap"] = true;
      layer.layout['icon-size'] = 1;

      layer['filter'] = ['has', 'point_count'];
    }

    if (id == 'clusterCountMarkers') {
      //layer['slot'] = 'top';
      layer.type = layerType.symbol;
      layer.paint['text-translate'] = [-25, -50];
      layer.paint['text-color'] = '#ffffff';
      layer.layout['text-field'] = '{point_count_abbreviated}';
      // layer.layout["text-font"] = ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      layer.layout['text-size'] = 13;
      layer.layout['text-allow-overlap'] = true;
      layer.paint['text-translate-anchor'] = 'viewport';

      layer['filter'] = ['has', 'point_count'];
    }

    // To show circles in the last point behind the marker
    if (id == 'onlineDot') {
      //layer['slot'] = 'top';
      layer.type = layerType.symbol;
      layer.layout['icon-image'] = 'dot-marker';
      layer['paint']['icon-color'] = '#00ee6e';
      // layer.paint["icon-halo-width"] = 0.1;
      // layer.paint["icon-halo-color"] = "#fff";
      layer.paint['icon-translate'] = [5, -20];
      layer.paint['icon-translate-anchor'] = 'viewport';
      layer.layout['icon-allow-overlap'] = true;
      layer.layout['icon-size'] = 0.2;

      layer['filter'] = [
        'all',
        ['==', ['get', 'online'], true], // Select only markers features from the dataSource
        // ["==", "enable", !this.performance.blinkEffect], // Select only markers features from the dataSource
        ['==', ['get', 'deviceShow'], 1], // Only show if deviceShow == 1
        ['==', ['get', 'showBlinking'], false],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
        ],
      ];
    }

    return layer;
  }

  public stringToCoordinates(str, precision) {
    let mu = new mapUtil(this.api);
    return mu.stringToCoordinates(str, precision);
  }

  currentZoom = 0;
  FilterDirectionsVars = {
    zoomingTimer: null,
    mouseIsReleased: true,
    mouseUpTimer: null,
    filterTimer: null,
  };
  userHasInteracted = false;
  async onClickMarkers() {
    let tc = this;
    let mapMoved = false;

    // Set cursor for mouse enter and mouse leave for markers layers
    this.map.on('mouseenter', 'markers', function () {
      tc.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'markers', function () {
      tc.map.getCanvas().style.cursor = 'default';
    });
    // this.map.on("mouseenter", "pauses", function () {
    //   tc.map.getCanvas().style.cursor = "pointer";
    // });
    // this.map.on("mouseleave", "pauses", function () {
    //   tc.map.getCanvas().style.cursor = "default";
    // });

    this.map.on('mouseleave', 'directions', function () {
      tc.map.getCanvas().style.cursor = 'default';
    });

    this.map.on('mouseenter', 'directions', function () {
      tc.map.getCanvas().style.cursor = tc.dragableRouteMarkers
        ? 'move'
        : 'pointer';
    });

    this.map.on('mouseleave', 'wifiDirections', function () {
      tc.map.getCanvas().style.cursor = 'default';
    });

    this.map.on('mouseenter', 'wifiDirections', function () {
      tc.map.getCanvas().style.cursor = 'pointer';
    });

    //_ Dragable markers
    const dragging = tc.dragMarker.bind(this);
    this.dragonDown = async (e) => {
      // Prevent drag marker when two touches is detected
      if (e.originalEvent?.touches?.length > 1) return;
      else {
        //_ Look deeper to check when event is cancelled; when touch with other finger starts zoom event or pitch event
        // const canceledTouchEvent = await new Promise((resolve) => {
        //   setTimeout(() => {
        //     resolve(e.originalEvent.cancelable);
        //   }, 100)
        // });
        // if (canceledTouchEvent)
        //   return;
      }

      e.preventDefault();
      let features = tc.map.queryRenderedFeatures(e.point);
      tc.dragingCoordinates = e.lngLat;
      tc.dragingFeature = features.length > 0 ? features[0] : null;
      tc.map.getCanvas().style.cursor = 'grab';

      // const existsFeature = this.directionsSource.data.features.map(f => f.properties['directionId']).find(tc.dragingFeature.properties.directionId);
      // if (existsFeature)

      tc.map.on('mousemove', dragging);
      tc.map.on('touchmove', dragging);

      // if (window.innerWidth > 400) {
      tc.map.once('mouseup', (e) => {
        if (!tc.dragingFeature) return;
        const coors = e.lngLat;
        tc.map.getCanvas().style.cursor = 'default';
        tc.map.off('touchmove', dragging);
        tc.map.off('mousemove', dragging);
        if (
          coors.lat != tc.dragingCoordinates.lat ||
          coors.lng != tc.dragingCoordinates.lng
        )
          tc.onMarkerMoved.emit({
            feature: tc.dragingFeature,
            coordinates: e.lngLat,
            e,
          });

        tc.dragingFeature = null;
      });
      // } else {
      tc.map.once('touchend', (e) => {
        if (!tc.dragingFeature) return;
        const coors = e.lngLat;
        tc.map.off('mousemove', dragging);
        tc.map.off('touchmove', dragging);
        if (
          coors.lat != tc.dragingCoordinates.lat ||
          coors.lng != tc.dragingCoordinates.lng
        )
          tc.onMarkerMoved.emit({
            feature: tc.dragingFeature,
            coordinates: e.lngLat,
            e,
          });
        tc.dragingFeature = null;
      });
      // }
    };

    if (this.dragableRouteMarkers == true) {
      this.map.on('mousedown', 'directions', this.dragonDown);
      this.map.on('touchstart', 'directions', this.dragonDown);
    }

    this.map.on('mouseenter', 'directions', function () {
      tc.map.getCanvas().style.cursor = this.dragableRouteMarkers
        ? 'move'
        : 'pointer';
    });

    // this.map.on("mouseenter", "alarms", function () {
    //   tc.map.getCanvas().style.cursor = "pointer";
    // });
    // this.map.on("mouseleave", "alarms", function () {
    //   tc.map.getCanvas().style.cursor = "default";
    // });

    this.map.on('mouseenter', 'scrollDirections', function () {
      tc.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'scrollDirections', function () {
      tc.map.getCanvas().style.cursor = 'default';
    });

    this.map.on('mouseenter', 'symbolsLayer', function (e) {
      let features = tc.map.queryRenderedFeatures(e.point, {
        layers: ['symbolsLayer'],
      });
      if (features.length > 0) {
        if (
          features[0].properties['image'] ==
            AVAILABLE_SYMBOL_MARKERS['start-marker'] ||
          features[0].properties['image'] ==
            AVAILABLE_SYMBOL_MARKERS['end-marker']
        ) {
          tc.map.getCanvas().style.cursor = tc.dragableRouteMarkers
            ? 'move'
            : 'pointer';
        }
      }
    });
    this.map.on('mouseleave', 'symbolsLayer', function () {
      tc.map.getCanvas().style.cursor = 'default';
    });

    /***************************************************************** */
    // Configure MAP click triggers
    // When click over a feature, send the feature depends if is marker, direction or pause
    this.map.on('click', function (e) {
      if (this.clickedOrTaped) return;
      this.clickedOrTaped = true;
      setTimeout(() => (this.clickedOrTaped = false), 260);

      let features = tc.map.queryRenderedFeatures(e.point, {
        layers: [
          'markers',
          'alarms_circle',
          'pauses',
          'alarms',
          'scrollDirections',
          'directions',
          'wifiDirections',
          'symbolsLayer',
          'carMarkerLayer',
          'wifiHomeLayer',
        ],
      });
      console.log('CLICK EVENT', features);
      if (features.length > 0) {
        if (
          features[0].layer.id == 'carMarkerLayer' ||
          features[0].layer.id == 'alarms_circle' ||
          features[0].layer.id == 'markers' ||
          features[0].layer.id == 'directions' ||
          features[0].layer.id == 'pauses' ||
          features[0].layer.id == 'scrollDirections' ||
          features[0].layer.id == 'alarms' ||
          features[0].layer.id == 'wifiDirections' ||
          features[0].layer.id == 'wifiHomeLayer' ||
          (features[0].layer.id == 'symbolsLayer' &&
            (features[0].properties['image'] ==
              AVAILABLE_SYMBOL_MARKERS['start-marker'] ||
              features[0].properties['image'] ==
                AVAILABLE_SYMBOL_MARKERS['end-marker']))
        ) {
          console.log('DEBUG - clickEvent', {
            geofenceDrawer: tc.geofenceDrawer,
            mode: tc.geofenceDrawer?.getMode(),
          });
          //_ check if a gefence is in edit mode and prevent click event
          if (tc.geofenceDrawer) {
            if (tc.geofenceDrawer?.getMode() !== 'static') return;
          }

          tc.onClickFeature.emit({
            e: e,
            feature: features[0],
            features: features,
            screenBounds: tc.getBoundingMarker(features[0]),
          });
        } else tc.onClickMap.emit(e);
      } else tc.onClickMap.emit(e);
    });

    // For mobile devices touch event
    this.map.on('touchend', function (e) {
      //_ ignore tap event if was fired before by click event
      if (this.clickedOrTaped) return;
      this.clickedOrTaped = true;
      setTimeout(() => (this.clickedOrTaped = false), 260);
      // console.log('TOUCH EVENT', e);
      let features = tc.map.queryRenderedFeatures(e.point, {
        layers: [
          'markers',
          'alarms_circle',
          'pauses',
          'alarms',
          'scrollDirections',
          'directions',
          'wifiDirections',
          'symbolsLayer',
          'carMarkerLayer',
          'wifiHomeLayer',
        ],
      });
      if (features.length > 0 && !mapMoved) {
        if (features[0].layer.id == 'markers') {
          tc.closeFab();
        }
        if (
          features[0].layer.id == 'carMarkerLayer' ||
          features[0].layer.id == 'markers' ||
          features[0].layer.id == 'directions' ||
          features[0].layer.id == 'pauses' ||
          features[0].layer.id == 'scrollDirections' ||
          features[0].layer.id == 'alarms' ||
          features[0].layer.id == 'wifiHomeLayer' ||
          (features[0].layer.id == 'symbolsLayer' &&
            (features[0].properties['image'] ==
              AVAILABLE_SYMBOL_MARKERS['start-marker'] ||
              features[0].properties['image'] ==
                AVAILABLE_SYMBOL_MARKERS['end-marker']))
        ) {
          //_ check if a gefence is in edit mode and prevent click event
          if (tc.geofenceDrawer) {
            if (tc.geofenceDrawer.getMode() !== 'static') return;
          }
          tc.onClickFeature.emit({
            e: e,
            feature: features[0],
            features: features,
            screenBounds: tc.getBoundingMarker(features[0]),
          });
        } else if (!mapMoved) tc.onClickMap.emit(e);
      } else if (!mapMoved) tc.onClickMap.emit(e);
      mapMoved = false;
    });

    //_ Handler output for drag/pan map
    this.map.on('drag', (e) => {
      mapMoved = true;
      // tc.FilterDirectionsVars.mouseIsReleased = false;
      tc.onDragMap.emit(e);
    });

    this.map.on('dragend', (e) => {
      mapMoved = false;
    });

    //_ TRIGGERS TO FILTER DIRECTIONS MARKERS AND LOAD ON THE MAP
    if (this.filterSource.directions) {
      this.map.on('idle', (e) => {
        if (tc.userHasInteracted) {
          tc.filterDirectionsMarkers();
          tc.userHasInteracted = false;
        }
      });

      this.map.on('dragstart', () => {
        if (tc.FilterDirectionsVars.filterTimer)
          clearTimeout(tc.FilterDirectionsVars.filterTimer);
        tc.userHasInteracted = true;
      });

      //_ Add event listener for map zoom AND only trigger filterDirections when map zoom out
      this.map.on('zoom', (e) => {
        // console.log('ZOOMING', this.map.getZoom());
        tc.mapZooming = true;
        // console.log('ZOOM level', this.map.getZoom())
        if (tc.FilterDirectionsVars.filterTimer)
          clearTimeout(tc.FilterDirectionsVars.filterTimer);
        tc.userHasInteracted = true;

        setTimeout(() => (tc.mapZooming = false), 2000);
      });
    }

    //_____________________ END TRIGGERS TO FILTER DIRECTIONS ______________________________________
  }

  closeFab() {
    setTimeout(() => {
      if (!this.fab) return;
      if (this.fab.activated) {
        this.fab.close();
        this.isFabListOpen = false;
        this.mapService.isFabMenuOpen.next(this.isFabListOpen);
        // this.markersMenu = false;
      }
    }, 100);
  }

  //_ Dragable marker Methods
  dragingFeature = null;
  dragingCoordinates = null;
  dragonDown = null; //_ Method to start dragin on mouse down
  dragMarker(e) {
    if (this.dragingFeature && this.dragableRouteMarkers) {
      let features = this.map.queryRenderedFeatures(e.point);
      const coords = e.lngLat;

      this.map.getCanvas().style.cursor = 'grabbing';

      // Update the Point feature in `geojson` coordinates
      this.directionsSource.data.features.forEach((f) => {
        if (
          f.properties['directionId'] ==
          this.dragingFeature.properties.directionId
        )
          f.geometry.coordinates = [coords.lng, coords.lat];
      });
      getSource(this.map, 'directionsSource').setData(
        this.directionsSource.data,
      );

      if (
        this.dragingFeature.layer.id == 'symbolsLayer' &&
        (this.dragingFeature.properties['image'] ==
          AVAILABLE_SYMBOL_MARKERS['start-marker'] ||
          this.dragingFeature.properties['image'] ==
            AVAILABLE_SYMBOL_MARKERS['end-marker'])
      ) {
        // Update the symbol feature in `geojson` coordinates
        this.symbolMarkersClass.source.data.features.forEach((f) => {
          if (
            f.properties['directionId'] ==
            this.dragingFeature.properties.directionId
          )
            f.geometry.coordinates = [coords.lng, coords.lat];
        });
        getSource(this.map, this.symbolMarkersClass.SOURCE_NAME).setData(
          this.symbolMarkersClass.source.data,
        );
      }
    }
  }

  disableDragableDirections() {
    if (this.map) {
      this.map.off('mousedown', 'directions', this.dragonDown);
      this.map.off('touchstart', 'directions', this.dragonDown);
      this.map.off('mousedown', 'symbolsLayer', this.dragonDown);
      this.map.off('touchstart', 'symbolsLayer', this.dragonDown);
      this.dragableRouteMarkers = false;
    }
  }

  enableDragableDirections() {
    if (this.map) {
      this.map.on('mousedown', 'directions', this.dragonDown);
      this.map.on('touchstart', 'directions', this.dragonDown);
      this.map.on('mousedown', 'symbolsLayer', this.dragonDown);
      this.map.on('touchstart', 'symbolsLayer', this.dragonDown);
      this.dragableRouteMarkers = true;
    }
  }

  /**************************************************************************** */
  // --------------------------------- GEOfenceS --------------------------------
  /**************************************************************************** */
  newGeofenceGeometry(geofence) {
    let geometry;
    const coordinatesArray = JSON.parse('[' + geofence.coordinates + ']');
    geometry = {
      type: 'Polygon',
      coordinates: coordinatesArray,
    };
    return geometry;
  }

  newGeofenceFeature(geofence, geometryShape) {
    const feature = {
      id: geofence.id.toString() + '_' + geofence.iddevice.toString(),
      type: 'Feature',
      properties: {},
      geometry: geometryShape,
    };
    const obj = JSON.parse(geofence.options);
    let properties;
    switch (geofence.shape_type) {
      case 1: // draw circle geomtry
        const lnglat = [0, 0];
        const circleProperties = { center: [], isCircle: true, radiusInKm: 0 };
        const options = JSON.parse(geofence.options);
        let cc = options.center.split('[');
        cc = cc[1].split(',');
        lnglat[0] = +cc[0];
        cc = cc[1].split(']');
        lnglat[1] = +cc[0];
        circleProperties.center = lnglat;
        circleProperties.radiusInKm = +options.radius;
        circleProperties.radiusInKm = circleProperties.radiusInKm / 1000; // in kilometers
        properties = circleProperties;
        break;
      case 2: // draw rectangle
        properties = {};
        break;
      case 3: // draw polygon
        properties = {};
        break;
      default:
        break;
    }

    feature.properties = properties;
    feature.properties['geoId'] = geofence.id;
    return feature;
  }

  _addGeofence(device, updateMap = false) {
    //_ alarm_geofence toggle option for v1 - removed for v2
    // if (device.properties.alarm_geofence) {
    if (device.geofences) {
      device.geofences.forEach((geofence) => {
        const geometryShape = this.newGeofenceGeometry(geofence);
        let feature = this.newGeofenceFeature(geofence, geometryShape);

        const deviceGeofence = this.geofenceSource.features.find(
          (f) => feature.id == f.id,
        );
        if (deviceGeofence) feature = deviceGeofence;

        feature.properties['deviceId'] = device.id;
        feature.properties['deviceShow'] = device.properties.deviceshow ? 1 : 0;
        feature.properties['deviceName'] = device.properties.name;
        feature.properties['name'] = geofence.name;
        feature['status'] = geofence.status;
        feature['options'] = geofence.options;
        feature['id'] = geofence.id + '_' + device.id;
        feature.properties['devicePermissions'] = device.permissions;

        //_ If not exists Add the feature, otherwise repleace in the geofenceSource features
        if (!deviceGeofence) {
          this.geofenceSource.features.push(feature);
        } else {
          const index = this.geofenceSource.features
            .map((f) => f.id)
            .indexOf(feature.id);
          this.geofenceSource.features[index] = feature;
        }

        //_ This method is not used anymore
        // this.setOpacityGeofenceMarker();
      });

      this.ShowHideGeofenceMoveIcon(
        this.isSmallScreen ? this.defaultMobileZoom : this.defaultDesktopZoom,
      );
    }
    // }
  }

  _createGeoFeature(geoDB, deviceShow, device) {
    const geometryShape = this.newGeofenceGeometry(geoDB);
    const feature = this.newGeofenceFeature(geoDB, geometryShape);
    const objOptions = JSON.parse(geoDB.options);
    feature.properties['portColor'] = objOptions.fillColor;
    feature.properties['opacity'] = objOptions.opacity;
    feature.properties['deviceId'] = geoDB.iddevice;
    feature.properties['deviceShow'] = deviceShow;
    feature.properties['deviceName'] = device.properties.name;
    feature.properties['name'] = geoDB.name;
    feature.properties['devicePermissions'] = device.permissions;
    feature['status'] = geoDB.status;
    feature['options'] = geoDB.options;
    feature['id'] = geoDB.id + '_' + geoDB.iddevice;
    this.geofenceSource.features.push(feature);

    return feature;
  }

  // This function draw each feature in geofenceSource
  drawGeofences(geofence = null, createCenter = true) {
    if (!this.geofenceDrawer) return;
    this.geofenceSource.features.forEach((geofence) => {
      if (geofence.status && geofence.properties.deviceShow)
        if (!this.geofenceDrawer.get(geofence.id))
          this.drawGeofence(geofence, createCenter);
    });
  }

  drawGeofence(geofence, createCenter = true) {
    let obj = JSON.parse(geofence.options);
    obj.fillColor = obj.fillColor ?? '#000000';
    obj.opacity = obj.opacity ?? 0.25;

    this.geofenceDrawer.add(geofence);
    this.geofenceDrawer.setFeatureProperty(
      geofence.id,
      'portColor',
      obj.fillColor,
    );
    this.geofenceDrawer.setFeatureProperty(geofence.id, 'opacity', obj.opacity);

    // this.geofenceDrawer.setFeatureProperty(geofence.id, "opacity", 0.1);
    this.geofenceDrawer.add(this.geofenceDrawer.get(geofence.id));
    if (createCenter)
      this.centerPoint.createSinglePoint(
        geofence,
        this,
        this.geofenceDrawer,
        [],
      );
    this.geofenceLineIndicatingFeature.addLineIndication(geofence);
  }

  openGeofencePopupAndSetEditMode(feature) {
    // Set edit Mode
    if (Boolean(feature.properties['devicePermissions']?.modify)) {
      this.map.dragPan.enable();
      this.geofenceDrawer.changeMode('direct_select', {
        featureId: feature.id,
      });
    }
    // open popup
    if (feature) {
      const polygon = turf.polygon(feature.geometry.coordinates);
      const centroid = turf.centroid(polygon);
      const event = {
        lngLat: {
          lng: centroid.geometry.coordinates[0],
          lat: centroid.geometry.coordinates[1],
        },
      };
      this.centerPoint.mouseUp.next({ feature, polygon, centroid, event });
      this.centerPoint.setCenterPointActive(feature.id);
    }
  }

  setShowPropertie(device, show) {
    // Update markers
    this.markersSource.data.features.forEach((f) => {
      if (f.properties['deviceId'] == device.id)
        f.properties['deviceShow'] = show ? 1 : 0;
      if (this.performance.use3dMarkers) {
        this.threedHelper.showHide(device.id, show);
      }
    });

    // Update route features
    this.dataSource.data.features.forEach((f) => {
      if (f.properties['deviceId'] == device.id)
        f.properties['deviceShow'] = show ? 1 : 0;
    });

    // Update directions features
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties['deviceId'] == device.id)
        f.properties['deviceShow'] = show ? 1 : 0;
    });

    // Update Alarms features
    // this.alarmsSource.data.features.forEach((f) => {
    //   if (f.properties["deviceId"] == device.id)
    //     f.properties["deviceShow"] = show ? 1 : 0;
    // });
    this.alarmMarkersClass.updateDeviceData(device, show ? 1 : 0);

    // Update Geofences features
    //_ alarm_geofence toggle option for v1 - removed for v2
    // if (show && device.properties.alarm_geofence == 1) this.showGeoFences(device);
    if (show) this.showGeoFences(device);
    else this.hideGeoFences(device);

    if (this.map.getSource('markersSource'))
      getSource(this.map, 'markersSource').setData(this.markersSource.data);

    if (this.map.getSource('dataSource'))
      getSource(this.map, 'dataSource').setData(this.filterDataSource());

    if (this.map?.getSource('directionsSource')) {
      if (this.filterSource.directions) {
        this.DirectionsClusterSource.setData(this.directionsSource);
        this.filterDirectionsMarkers();
      } else
        getSource(this.map, 'directionsSource').setData(
          this.directionsSource.data,
        );
    }

    // if (this.map.getSource("alarmsSource"))
    //   getSource(this.map, "alarmsSource").setData(this.alarmsSource.data);

    this.pauseMarkersClass.updateDeviceData(device, show ? 1 : 0);
    this.wifiHomeMarkers.updateDeviceShow(device.id, show ? 1 : 0);
  }

  // Set the style parsing a key of urlStyles Interface
  // osm, paj, pajHills, 3d = style
  async setMapStyle(style, dimension, event) {
    console.log('SET MAP STYLE', style);
    // style = (dimension == '3d') ? dimension : style;
    // dimension = style == '3d' ? style : dimension;

    if (event) {
      event.stopPropagation();
    }

    this.dimension = dimension;
    this.setStyle = true; // Flag to only update one time with styledata event
    this.mapStyle = style;
    // if (dimension == "3d" && style == "pajLite") {
    //   this.mapStyle = this.default3DMapStyle;
    // }

    // this.mapService.mapStyle.next(this.mapStyle);

    //____________________
    //_ SET MAP STYLE DESTROYING AND LOADING AGAIN ALL IN THE MAP
    //_ Still in testing to improve performance
    // this.mapService.mapStyle.next(this.mapStyle);
    // this.mapService.mapStyleDimension.next(dimension);
    // setTimeout(() => this.createMap(this.user), 500);
    // return;
    //_____________

    this.map.setStyle(await this.createMapStyle(this.mapStyle));

    //_ Set projection
    // if (this.mapStyle === 'mapbox_standard' || this.dimension === '3d')
    //   this.map.setProjection('globe');
    // else
    //   this.map.setProjection(null);

    this.mapService.mapStyle.next(this.mapStyle);
    this.applyMapDimension(dimension);
  }

  applyMapDimension(dimension, event = null) {
    if (event) {
      event.stopPropagation();
    }

    const savedPitch = localStorage.getItem('savedPitch');
    this.dimension = dimension;
    this.mapService.mapStyleDimension.next(dimension);

    if (dimension == '3d' && this.map) {
      this.map['dragRotate'].enable();
      this.map['touchPitch'].enable();
      this.map['touchZoomRotate'].enable();
      //this.map.easeTo({ pitch: 70, bearing: this.map?.getBearing() });
      this.map.easeTo({
        pitch: +savedPitch === 0 ? 70 : +savedPitch,
        bearing: this.map?.getBearing(),
      });
    } else if (this.map) {
      this.map['dragRotate'].disable();
      //_ Search how to do this in maplibre
      // this.map["dragRotate"]._mousePitch._enabled = true;
      this.map.touchPitch.disable();
      this.map.easeTo({ pitch: 0, bearing: 0 });
    }
  }

  resetBearing(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.platform.width() > 678) {
      this.map.easeTo({ bearing: -0 });
      this.currentBearing = -0;
    } else {
      switch (this.currentBearing) {
        case -0:
          this.currentBearing = -90;
          break;
        case -90:
          this.currentBearing = -180;
          break;
        case -180:
          this.currentBearing = -270;
          break;
        case -270:
          this.currentBearing = -360;
          break;
        case -360:
          this.currentBearing = -0;
          break;

        default:
          this.currentBearing = -0;
          break;
      }
      // this.currentBearing == -0 ? this.currentBearing = -90 : this.currentBearing = -0;

      this.map.easeTo({ bearing: this.currentBearing });
    }
  }

  preventDefault(event) {
    if (window.innerWidth > 678) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.resetBearing(event);
    }
  }

  preventDefaultSColorGradient(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async setBearing(rad, event) {
    event.stopPropagation();
    this.currentBearing += rad;
    //  this.map.easeTo({this.currentBearing:0});
    this.map.easeTo({ bearing: this.currentBearing });
  }

  // Activate / Dissable directions markers
  showDirections = true;
  showWifiMarkers = true;
  toggleDirectionMarkers(event = null, type = 'gps', showToast = true) {
    if (event) {
      event.stopPropagation();
      this.fab.activated = true;
    }
    if (type == 'gps') {
      this.showDirections = !this.showDirections;
      if (this.showDirections == true && showToast) {
        this.appService.showToast(
          '',
          this._translate.instant('fabbuttonToast.routeDirectionOn'),
          2000,
          'success',
        );

        //_ Update directions source data or filter and update the map
        if (this.filterSource.directions) {
          this.DirectionsClusterSource.forceUpdate = true;
          this.filterDirectionsMarkers();
        } else if (this.map.getSource('directionsSource'))
          getSource(this.map, 'directionsSource').setData(
            this.directionsSource.data,
          );
        this.checkIfMapData();
      } else if (this.showDirections == false && showToast) {
        this.appService.showToast(
          '',
          this._translate.instant('fabbuttonToast.routeDirectionOff'),
          2000,
          'success',
        );

        //_ REMOVE THE SOURCE FROM MAPBOX
        getSource(this.map, 'directionsSource').setData({ features: [] });
      }
    } else {
      this.showWifiMarkers = !this.showWifiMarkers;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    if (type == 'gps')
      this.mapService.showDirectionMarkers.next(this.showDirections);
    else this.mapService.showWifiMarkers.next(this.showWifiMarkers);

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource('directionsSource')) {
        if (this.filterSource.directions) this.filterDirectionsMarkers();
        else
          getSource(this.map, 'directionsSource').setData(
            this.directionsSource.data,
          );
      }

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
    }
  }

  hideDirectionMarkers(type) {
    if (type == 'gps') {
      this.showDirections = false;
    } else {
      this.showWifiMarkers = false;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    if (type == 'gps')
      this.mapService.showDirectionMarkers.next(this.showDirections);
    else this.mapService.showWifiMarkers.next(this.showWifiMarkers);

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource('directionsSource')) {
        if (this.filterSource.directions)
          this.DirectionsClusterSource.setData(this.directionsSource);
        else
          getSource(this.map, 'directionsSource').setData(
            this.directionsSource.data,
          );
      }

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
    }
  }

  async showDirectionMarkersIfEnabled(type) {
    if (type == 'gps') {
      let value = await this.storage.get('showDirectionMarkers');
      this.showDirections = value ? value : false;
    } else {
      let value = await this.storage.get('showWifiMarkers');
      this.showWifiMarkers = value ? value : false;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties['origin'] == type)
        f.properties['showDirections'] =
          type == 'gps' ? this.showDirections : this.showWifiMarkers;
    });

    if (type == 'gps')
      this.mapService.showDirectionMarkers.next(this.showDirections);
    else this.mapService.showWifiMarkers.next(this.showWifiMarkers);

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource('directionsSource')) {
        if (this.filterSource.directions)
          this.DirectionsClusterSource.setData(this.directionsSource);
        else
          getSource(this.map, 'directionsSource').setData(
            this.directionsSource.data,
          );
      }

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
    }
  }

  async toggleHeatMap(event) {
    if (event) event.stopPropagation();
    await this.heatMapClass.toggleHeatmap();

    if (this.heatMapClass?.isEnabled) {
      this.appService.showToast(
        '',
        this._translate.instant('fabbuttonToast.heatMapOn'),
        2000,
        'success',
      );
      await this.setMapStyle(this.mapStyle, this.dimension, null);
      this.hideAlarms();
      this.hidePauses();
      this.hidePolylines();
      this.hideDirectionMarkers("gps");
      //this.HighSpeedPolylinesClass.hide();
      this.speedCamerasClass.hideCameras();
      this.checkIfMapData();
    } else {
      this.appService.showToast(
        '',
        this._translate.instant('fabbuttonToast.heatMapOff'),
        2000,
        'success',
      );
      this.setMapStyle(this.mapStyle, this.dimension, null);
      this.showAlarmsIfEnabled();
      this.showPausesIfEnabled();
      this.showPolylinesIfEnabled();
      this.showDirectionMarkersIfEnabled('gps');
      this.HighSpeedPolylinesClass.showIfEnabled();
      let value = await this.storage.get('showSpeedCameras');
      this.speedCamerasClass.showCamerasIfEnabled(value ? value : false);
    }
  }

  async hidePolylines() {
    this.showPolylines = false;
    this.dataSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.mapService.showPolylines.next(this.showPolylines);

    // And then update the source in the map :)
    if (this.map) {
      // if (this.map.getSource("dataSource"))
      //   getSource(this.map, "dataSource").setData(this.filterDataSource());
      if (this.map.getSource('dataSource'))
        getSource(this.map, 'dataSource').setData(this.dataSource.data);

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
    }
  }

  async showPolylinesIfEnabled() {
    let value = await this.storage.get('showPolylines');
    this.showPolylines = value ? value : false;
    this.dataSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.mapService.showPolylines.next(this.showPolylines);

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource('dataSource'))
        getSource(this.map, 'dataSource').setData(this.filterDataSource());

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
    }
  }

  showPolylines = true;
  async togglePolylines(event, showToast = true) {
    if (event) event.stopPropagation();
    this.showPolylines = !this.showPolylines;
    if (this.showPolylines == true && showToast) {
      this.appService.showToast(
        '',
        this._translate.instant('fabbuttonToast.polyLinesOn'),
        2000,
        'success',
      );
    } else if (this.showPolylines == false && showToast) {
      this.appService.showToast(
        '',
        this._translate.instant('fabbuttonToast.polyLinesOff'),
        2000,
        'success',
      );
    }

    this.dataSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties['showPolylines'] = this.showPolylines;
    });

    this.mapService.showPolylines.next(this.showPolylines);

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource('dataSource')) {
        getSource(this.map, 'dataSource').setData(this.filterDataSource());
      }

      if (this.map.getSource('scrollDirectionsSource'))
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );

      if (this.showPolylines == true && showToast) {
        await this.checkIfMapData();
      }
    }
  }

  changeDeviceMarkerSize(iconSize) {
    this.markersSource.data.features.forEach((f) => {
      if (f.properties.icon)
        if (f.properties.icon.startsWith('marker-')) {
          f.properties.size = (iconSize.width * 100) / 170 / 100;
        }
    });

    // And then update the source in the map :)
    if (this.map.getSource('markersSource'))
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
  }

  thicknessChanged(size) {
    this.dataSource.data.features.forEach((f) => {
      if (f.properties.layerType == 'route') f.properties.size = size;
    });

    // And then update the source in the map :)
    if (this.map.getSource('dataSource'))
      getSource(this.map, 'dataSource').setData(this.filterDataSource());
  }

  // Change direction marker icon
  async changeDirectionIcon(icon) {
    // First delete the previous image
    this.map.removeImage('direction-marker');

    // Load the new image
    await this.map.loadImage(directionMarker[icon], (error, image) => {
      if (!error) this.map.addImage('direction-marker', image, { sdf: true });
    });
  }

  infoVisible = true;
  fixMapInfo() {
    setTimeout(() => {
      // const attributionContainer: any = document.querySelector('.maplibregl-ctrl-bottom-right');
      const prefix = 'mapboxgl'; //_ maplibregl
      const attributionContainer: any = document.querySelector(
        '.' + prefix + '-ctrl-bottom-right',
      );
      if (!attributionContainer) return;

      const attributionDiv: any = document.querySelector(
        '.' + prefix + '-ctrl-attrib-inner',
      );

      if (!attributionDiv) return;

      const createLink = (link, text) => {
        const aTag = document.createElement('a');
        aTag.href = link;
        aTag.target = '_blank';
        aTag.textContent = text;
        return aTag;
      };

      const link1 = createLink(
        'https://www.maptiler.com/license/maps/',
        '© MapTiler',
      );
      const link2 = createLink(
        'https://www.openstreetmap.org/copyright',
        '© OpenStreetMap',
      );

      // Append the links to the attribution div
      attributionDiv.innerHTML = '';
      attributionDiv.appendChild(link1);
      attributionDiv.appendChild(link2);
      this.rendered.setStyle(attributionContainer, 'opacity', 1);
    }, 1000);
  }

  //_ Update route color, direction marker color
  //_ And the device marker
  async updateDevice(device) {
    // console.warn('[DEBUG] UPDATING DEVICE', device);
    let imageName = getDeviceImageName(device);
    this.imageService
      .getImage(device.properties)
      .subscribe(async (img: any) => {
        if (img != this.mediaApi.whitePicture) {
          if (img.endsWith('.svg'))
            //_ Change aspect ratio if is a paj_iconset svg image
            img = await resizeImage(img, 122, 170, 'white', false);
          else img = await resizeImage(img, 170, 170, 'white', false, true);

          const imageLoaded = await this.loadImage(device, imageName, img);
          this.markersSource.data.features.forEach((f) => {
            if (f.properties['deviceId'] == device.id)
              f.properties.color = device.color;
            //_ Change 3dmodel Color and model if is different
            if (this.performance.use3dMarkers) {
              this.threedHelper.updateModel(
                device.id,
                device.color,
                device.properties.threedModel_name,
              );
              // this.threedHelper.changeColor(device.id, device.color);
              // const currentModel = this.threedHelper.getModel(device.id);
              // if (device.properties.iconusecustom == 2 && currentModel)
              //   if (currentModel.feature.properties.modelName != device.threedModel_name)
              //     this.threedHelper.updateMarker(device.id, device.threedModel_name);
            }
            if (
              f.properties['deviceId'] == device.id &&
              f.properties['layerType'] == 'marker' &&
              imageLoaded
            ) {
              f.properties['image'] = imageName;
            }
          });

          this.dataSource.data.features.forEach((f) => {
            if (f.properties['deviceId'] == device.id)
              f.properties.color = device.color;
          });

          // And then update the source in the map :)
          getSource(this.map, 'markersSource').setData(this.markersSource.data);
          getSource(this.map, 'dataSource').setData(this.filterDataSource());
        }
      }); // getDeviceImage(device, this.user.id);

    this.directionsSource.data.features.forEach((f) => {
      if (f.properties['deviceId'] == device.id)
        f.properties.color = device.color;
    });

    //_ Update pulse effect marker image
    const onlineId = 'effect-' + device.id;
    if (this.map.hasImage(onlineId)) {
      const newEffectImage = new Effects().PulseShadow(
        this.map,
        '#000',
        device.color,
        80,
      );

      //_ Remove online marker image from map and list
      this.map.removeImage(onlineId);
      this.markersImages = this.markersImages.filter(
        (im) => im.name != onlineId,
      );
      //_ Add new online marker image to the map and list
      this.map.addImage(onlineId, newEffectImage, { pixelRatio: 1 });
      this.markersImages.push({
        name: onlineId,
        url: newEffectImage,
        isEffect: true,
      });
    }

    // And then update the source in the map :)
    if (this.filterSource.directions)
      this.DirectionsClusterSource.setData(this.directionsSource);
    else
      getSource(this.map, 'directionsSource').setData(
        this.directionsSource.data,
      );
    this.deviceNameMarkers.updateName(device);
    if (this.filterSource.directions) this.filterDirectionsMarkers(true);

    this.wifiHomeMarkers.updateDeviceWifiMarkers(device);
  }

  showHidePauseMarker(show) {
    this.pauseMarkersClass.showHidePauseMarker(show);
    // this.showPauses = show;
    // this.dataSource.data.features.forEach((f) => {
    //   f.properties["showPauses"] = show ? 1 : 0;
    // });

    // // And then update the source in the map :)
    // if (this.map) {
    //   if (this.map.getSource("dataSource"))
    //     getSource(this.map, "dataSource").setData(this.dataSource.data);
    // }
  }

  _addNotifications(device, removeAll = false, layerOptions = {}) {
    this.alarmMarkersClass._addAlarms(device, removeAll, layerOptions);
    // if (removeAll && this.alarmsSource)
    //   this.alarmsSource.data.features = this.alarmsSource.data.features.filter(
    //     (data) => data.properties["deviceId"] != device.id
    //   );

    // //this.dataSource.data.features = this.dataSource.data.features.filter(function( f ) {
    // //  return !(f.properties['layerType'] == 'pause' && f.properties['deviceId'] == device.id);
    // //});

    // device.notifications.forEach(async (not,i) => {
    //   let geometry = new Geometry();
    //   let properties = {} as IProperties;
    //   geometry.type = GeometryTypes.Point;
    //   geometry.coordinates = [not.lng, not.lat];
    //   properties.icon = this.alarmImages[not.meldungtyp].replace(
    //     /^.*[\\\/]/,
    //     ""
    //   );
    //   properties.size = 0.6;
    //   properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the pause marker
    //   properties["layerType"] = "alarms"; //Type of feature
    //   properties["alarmId"] = not.id;
    //   properties["deviceId"] = device.id;
    //   properties["showAlarms"] = this.showAlarms;

    //   let notMarker = this._createFeature(
    //     geometry,
    //     properties,
    //     "alarm-" + not.id
    //   );

    //   // Adding the feature to the data Source if not exist...
    //   if (!this.alarmsSource.data.features.find((f) => notMarker.properties['alarmId'] == f.properties.alarmId))
    //     this.alarmsSource.data.features.push(notMarker);
    // });

    // if (this.map.getSource("alarmsSource"))
    //   getSource(this.map, "alarmsSource").setData(this.alarmsSource.data);
  }

  showHideAlarms(show) {
    this.alarmMarkersClass.showHideAlarms(show);
    // this.showAlarms = show;
    // this.alarmsSource.data.features.forEach((f) => {
    //   f.properties["showAlarms"] = show;
    // });

    // // And then update the source in the map :)
    // if (this.map) {
    //   if (this.map.getSource("alarmsSource"))
    //     getSource(this.map, "alarmsSource").setData(this.alarmsSource.data);
    // }
  }

  async hideAlarms() {
    this.alarmMarkersClass.hideAlarms();
    // this.showAlarms = false;
    // this.mapService.showNotifications.next(this.showAlarms);
  }

  async showAlarmsIfEnabled() {
    this.alarmMarkersClass.showAlarmsIfEnabled();
    // let value = await this.storage.get('showNotifications');
    // this.showAlarms = value ? value : false;
    // this.mapService.showNotifications.next(this.showAlarms);
  }

  async toggleAlarms(ev = null, showToast = true) {
    if (ev) {
      ev.stopPropagation();
    }
    this.alarmMarkersClass.toggleAlarms(ev, showToast);

    // this.showAlarms = !this.showAlarms;
    // this.mapService.showNotifications.next(this.showAlarms);
    // if (this.showAlarms == true && showToast) {
    //   this.appService.showToast('', this._translate.instant('fabbuttonToast.showAlertOn'), 2000, 'success');
    // }
    // else if (showToast) {
    //   this.appService.showToast('', this._translate.instant('fabbuttonToast.showAlertOff'), 2000, 'success');
    // }
  }

  markersMenu = false;
  showMarkersMenu(ev = null) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      ev.srcEvent?.stopPropagation();
    }

    this.markersMenu = !this.FabSubmenu.activated;
    this.moveSubFabMenu();
  }

  moveSubFabMenu() {
    // const markersMenu = document.getElementsByClassName('markers-menu')[0];
    // const parent = document.querySelector('#mapAlarmMarker');
    // const getTop = (el) => {
    //   return el.offsetTop + (el.offsetParent && getTop(el.offsetParent));
    // }
    // const getLeft = (el) => {
    //   return el.offsetLeft + (el.offsetParent && getLeft(el.offsetParent));
    // }
    // if (markersMenu && parent){
    //   const elTop = window.innerHeight - getTop(parent);
    //   const elRight = window.innerWidth - getLeft(parent);
    // }
  }

  async hidePauses() {
    this.pauseMarkersClass.hidePauses();
    // this.showPauses = false;
    // this.mapService.showPauses.next(this.showPauses);
  }

  async showPausesIfEnabled() {
    this.pauseMarkersClass.showPausesIfEnabled();
  }

  togglePauses(ev, showToast = true) {
    const toggleValue = !this.pauseMarkersClass.show;
    this.updateSingleSetting('show_pauses_in_map', toggleValue, true);
    this.pauseMarkersClass.togglePauses(ev, showToast);
  }

  clearAllData(options = {}) {
    let defaultOptions = {
      dataSource: true,
      directions: true,
      markers: true,
      scrollDirections: true,
      alarms: true,
      pauses: true,
    };
    const clearOptions = { ...defaultOptions, ...options };

    if (this.map) {
      // this.directionsSource = this.createDataSource("markersSource");
      if (clearOptions.directions)
        this.directionsSource = this.createDataSource('directionsSource');

      if (clearOptions.dataSource)
        this.dataSource = this.createDataSource('dataSource');

      if (clearOptions.alarms) this.alarmMarkersClass.clearData();

      if (clearOptions.pauses) this.pauseMarkersClass.clearData();

      if (clearOptions.markers)
        this.markersSource = this.createDataSource('markersSource');

      if (clearOptions.scrollDirections)
        this.scrollDirectionsSource = this.createDataSource(
          'scrollDirectionsSource',
        );

      try {
        getSource(this.map, 'markersSource').setData(this.markersSource.data);
        getSource(this.map, 'directionsSource').setData({
          ...this.directionsSource.data,
          features: [],
        });
        getSource(this.map, 'dataSource').setData({
          ...this.dataSource.data,
          features: [],
        });
        // getSource(this.map, "alarmsSource").setData(this.alarmsSource.data);
        getSource(this.map, 'scrollDirectionsSource').setData(
          this.scrollDirectionsSource.data,
        );
        this.HighSpeedPolylinesClass.clearData();
      } catch {}
    }
  }

  async startRouteAnimation() {
    let animationspeed = 10;
    // var features = this.map.getSource('dataSource').data.features[0];
    var data = this.dataSource.data;
    if (this.map.getSource('routeDemo'))
      getSource(this.map, 'routeDemo').setData(data);
    const coordinates = data.features[0].geometry.coordinates.reverse();
    if (coordinates.length <= 500) {
      animationspeed = 10;
    } else if (coordinates.length > 501) {
      animationspeed = 0.2;
    } else if (coordinates.length > 2000) {
      animationspeed = 0.1;
    }

    // start by showing just the first coordinate
    data.features[0].geometry.coordinates = [coordinates[0]];

    // on a regular basis, add more coordinates from the saved list and update the map
    let i = 0;
    this.timer = setInterval(() => {
      if (i < coordinates.length) {
        data.features[0].geometry.coordinates.push(coordinates[i]);

        getSource(this.map, 'dataSource').setData(data);
        // this.map.panTo(coordinates[i]);
        i++;
      } else {
        window.clearInterval(this.timer);
      }
    }, animationspeed);
  }

  createAnimationLayers() {
    this.map.addLayer({
      id: 'trace',
      type: 'line',
      source: 'dataSource',
      paint: {
        'line-color': '#000000',
        'line-opacity': 1,
        'line-width': 5,
      },
    });
    this.map.addSource('routeDemo', {
      type: 'geojson',
      data: null,
    });

    this.map.addLayer({
      id: 'route-demo',
      type: 'line',
      source: 'routeDemo',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#a6a6a6',
        'line-opacity': 1,
        'line-width': 5,
      },
    });
  }

  hasMarker(deviceId) {
    let feature = false;
    this.markersSource.data.features.forEach((f) => {
      if (
        f.properties['deviceId'] == deviceId &&
        f.properties['layerType'] == 'marker'
      ) {
        feature = f;
        return;
      }
    });
    return feature;
  }

  hideGeoFences(device) {
    this.geofenceSource.features.forEach((geofence) => {
      let geofenceID = geofence.id.split('_');
      if (device.id === +geofenceID[1]) {
        const index = this.geofenceSource.features
          .map((obj) => obj.id)
          .indexOf(geofence.id);
        this.geofenceDrawer.delete(geofence.id);
        this.centerPoint.removeCenterPoint(geofence.id);
        delete this.geofenceSource.features[index];
      }
    });
  }

  showGeoFences(device) {
    device.geofences.forEach((geo) => {
      if (geo.status && device.properties.deviceshow) {
        const geometryShape = this.centerPoint.getGeometryShape(geo);
        const feature = this.centerPoint.getShapeFeature(geo, geometryShape);
        feature.properties['devicePermissions'] = device.permissions;

        this.geofenceDrawer.add(feature);
        const featureId = geo.id.toString() + '_' + geo.iddevice.toString();
        let obj = JSON.parse(geo.options);

        if (obj.fillColor == 'undefined') {
          obj.fillColor = '#000';
        }
        let draw = this.geofenceDrawer;
        let shape = draw.get(featureId);

        // feature["status"] = geofence.status;
        // feature["options"] = geofence.options;
        // feature["id"] = geofence.id + "_" + device.id;

        this.geofenceDrawer.add(draw.get(featureId));
        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'deviceId',
          device.id,
        );
        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'deviceShow',
          device.properties.deviceshow ? 1 : 0,
        );
        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'deviceName',
          device.properties.name,
        );
        this.geofenceDrawer.setFeatureProperty(featureId, 'name', geo.name);
        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'portColor',
          obj.fillColor,
        );
        this.geofenceDrawer.setFeatureProperty(featureId, 'geoId', geo.id);

        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'devicePermissions',
          device.permissions,
        );
        // let obj = JSON.parse(geofence.options);
        this.geofenceDrawer.setFeatureProperty(
          featureId,
          'opacity',
          obj.opacity,
        );
        const newFeature = draw.get(featureId);
        if (
          this.geofenceSource.features.map((x) => x.id).indexOf(featureId) == -1
        ) {
          this.geofenceSource.features.push(newFeature);
        }

        this.centerPoint.createSinglePoint(
          shape,
          this,
          this.geofenceDrawer,
          device,
        );
        this.geofenceLineIndicatingFeature.addOrUpdateLineFeature(shape);
      }
    });
  }

  //_ Performance methods
  clusterize(isEnable, from = 'obs') {
    this.markersSource.data.cluster = isEnable;
    if (this.map) {
      if (this.map.getSource('markersSource')) {
        const style = this.map.getStyle();
        style.sources['markersSource']['cluster'] = isEnable;
        this.map.setStyle(style);
      }
    }
  }

  setBlinkingEffect(show) {
    if (this.map) {
      if (this.map.getSource('markersSource')) {
        this.markersSource.data.features.forEach((f) => {
          f.properties.showBlinking = !show;
        });
      }

      if (this.map.getSource('markersSource'))
        getSource(this.map, 'markersSource').setData(this.markersSource.data);
    }
  }

  async updateSettings(settings) {
    await this.authService
      .updateCustommerSettings(settings)
      .then((res) => {})
      .catch((error) => {
        console.log('ERROR: ', error);
      });
  }

  //_ Status of the ion fab list
  openFabList(ev = null) {
    // if (ev) ev.preventDefault();
    setTimeout(() => {
      this.isFabListOpen = this.fab.activated;
      this.mapService.isFabMenuOpen.next(this.isFabListOpen);
      // if (!this.isFabListOpen) this.markersMenu = false;
    }, 100);
  }

  toggleFabList(status) {
    this.fab.activated = status;
    this.mapService.isFabMenuOpen.next(status);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenSize();
    if (this.map) this.map.resize();
    //recheck the  fabs
    // this.appService.deviceMenuisOpen.next(false)
    // this.moveSubFabMenu();
  }

  // testFn(value) {
  //   this.threedHelper.rotate(149305, value);
  // }
  mapboxControlsTranslation() {
    const setTitle = (elClass, title) => {
      const el: any = document.querySelector(elClass);
      if (el) el.title = title;
    };

    setTimeout(() => {
      setTitle(
        '.maplibregl-ctrl-zoom-in',
        this._translate.instant('mapBoxControls.zoomIn'),
      );
      setTitle(
        '.maplibregl-ctrl-zoom-out',
        this._translate.instant('mapBoxControls.zoomOut'),
      );
    }, 1000);
  }

  getBoundingMarker(feature) {
    // const featureData = this.searchFeatureById(this.markersSource, feature.properties.id);
    const featureIndex = this.markersSource.data.features
      .map((x: any) => x.properties['deviceId'])
      .indexOf(feature.properties.deviceId);
    const featureData = this.markersSource.data.features[featureIndex];
    console.log('featureData', featureData);
    if (!featureData) return null;

    const coors = featureData.geometry.coordinates;
    const markerSize = 200;

    const centerPixel = this.map.project(new this.LngLat(coors[0], coors[1]));
    if (centerPixel)
      return {
        top: centerPixel.y,
        left: centerPixel.x,
        height: markerSize * feature.properties.size,
        width: markerSize * feature.properties.size,
      };
    else return null;
  }

  toggleSpeedCameras(ev) {
    if (ev) ev.stopPropagation();
    this.speedCamerasClass.toggleFeature(true);
  }

  toggleClientLocation(ev) {
    if (ev) ev.stopPropagation();
    this.clientLocationClass.toggleFeature();
  }

  //_ Add new listener for the hooks|events
  public on(eventName: AvailableHooks, fn: (event) => void) {
    return this.HOOKS.on(eventName, fn);
  }

  //_ Remove listener for the hooks|events
  public off(eventId) {
    return this.HOOKS.off(eventId);
  }

  //_ Update Single User Setting
  updateSingleSetting(settingName, value, showToast = false) {
    this.userSettings[settingName] = value;

    //_ Update setting in storage and backend
    this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    if (
      this.appService.user &&
      this.appService.user.id &&
      this.appService.user.id != -1
    ) {
      this.authService
        .updateUserSettings(this.appService.user.id, this.userSettings)
        .then((r: any) => {
          if (r.success && showToast)
            this.appService.showToast(
              '',
              this._translate.instant('deviceManagement.mapSettingMessage'),
              2000,
              'success',
            );
        })
        .catch((error) => {
          console.log('ERROR: ', error);
          this.appService.showToast(
            '',
            this._translate.instant('deviceManagement.errorUpdateSettings'),
            3000,
            'danger',
          );
        });
    }
  }

  async showInfoPopup(event, title, description, image, useGif) {
    let popup = await this.popoverController.create({
      componentProps: {
        title: this._translate.instant(title),
        description: description ? this._translate.instant(description) : null,
        image,
        useGif,
      },
      component: CsPopoverComponent,
      event: event,
      mode: 'ios',
      translucent: false,
    });

    await popup.present();
    popup = null;
  }

  graphhopperLegendAction(event) {
    event.stopPropagation();
    this.graphhopperLegendIsOpen = true;
  }

  mapMonitorLegendAction(event) {
    event.stopPropagation();
    this.mapMonitorLegendIsOpen = true;
  }

  @HostListener('document:click', ['$event'])
  closeOpenedLegends() {
    if (this.graphhopperLegendIsOpen || this.mapMonitorLegendIsOpen) {
      if (this.graphHopperLegendRef) this.graphhopperLegendIsOpen = false;
      if (this.mapMonitorLegendRef) this.mapMonitorLegendIsOpen = false;
    }
  }

  //_ Method used to filter all source features isSliderViewOpen
  enableSliderView() {
    this.setSliderView(true);
  }

  disableSliderView() {
    this.setSliderView(false);
  }

  setSliderView(isEnable) {
    this.isSliderViewEnable = isEnable;
    //_ FIRST OF ALL - Propagate the sliderStatus change for feature classes
    this.HOOKS.onSetSliderView.emit({ isEnable });

    //_ FIRST: FILTER ALL CURRENT SOURCES
    this.markersSource.data.features.forEach((f) => {
      f.properties['isSliderViewOpen'] = isEnable;
    });
    this.dataSource.data.features.forEach((f) => {
      f.properties['isSliderViewOpen'] = isEnable;
    });

    this.directionsSource.data.features.forEach((f) => {
      f.properties['isSliderViewOpen'] = isEnable;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties) f.properties['isSliderViewOpen'] = isEnable;
    });

    //_ If slider view is close; needs to delete/filter sliderData features
    //_ -- IMPORTANT -- check also if is only slider data other wise should not be removed
    if (!isEnable) {
      this.markersSource.data.features = this.filterFeaturesWithoutSliderProps(
        this.markersSource.data.features,
      );
      this.markersSource.data.features = this.cleanSliderFeatureProps(
        this.markersSource.data.features,
      );

      this.dataSource.data.features = this.filterFeaturesWithoutSliderProps(
        this.dataSource.data.features,
      );
      this.dataSource.data.features = this.cleanSliderFeatureProps(
        this.dataSource.data.features,
      );

      this.directionsSource.data.features =
        this.filterFeaturesWithoutSliderProps(
          this.directionsSource.data.features,
        );
      this.directionsSource.data.features = this.cleanSliderFeatureProps(
        this.directionsSource.data.features,
      );

      this.scrollDirectionsSource.data.features =
        this.filterFeaturesWithoutSliderProps(
          this.scrollDirectionsSource.data.features,
        );
      this.scrollDirectionsSource.data.features = this.cleanSliderFeatureProps(
        this.scrollDirectionsSource.data.features,
      );
    }

    //_ SECOND: UPDATE MAP DATA SOURCES
    this.updateDataSources({ directions: true, markersAndRoutes: true });
  }

  filterFeaturesWithoutSliderProps(feature) {
    return feature.filter(
      (f) => !f.properties['isSliderData'] || !f.properties['isOnlySliderData'],
    );
  }

  //_ Remove the extra properties to clean the feature properties with slider props
  cleanSliderFeatureProps(features) {
    return features.map((feature) => {
      if (feature.properties['isSliderData'])
        delete feature.properties['isSliderData'];
      if (feature.properties['isOnlySliderData'])
        delete feature.properties['isOnlySliderData'];
      return feature;
    });
  }

  //_ Apply atmosphere to the map style
  //_ Using MapStyleList and if is projection globe or dimension 3d
  async applyAtmosphere() {
    if (!this.useMapbox) return;

    this.userSettings =
      this.customerSettings ??
      (await this.storage.get(Constants.USER_SETTINGS));
    if (this.userSettings.map_projection === 'globe') {
      let atmosphere =
        MapStylesList[this.mapStyle]?.atmosphere ??
        MapStylesList.paj.atmosphere;

      if (this.isDarkmode) atmosphere = DARK_ATMOSPHERE;
      this.map?.setFog(atmosphere);
    } else {
      //_ Fix for background color of mapbox_standard style
      if (this.mapStyle === 'mapbox_standard') {
        let atmosphere =
          MapStylesList[this.mapStyle]?.atmosphere ??
          MapStylesList.paj.atmosphere;
        atmosphere.color = MapStylesList[this.mapStyle].backround;
        this.map?.setFog(atmosphere);
      }
    }
  }

  shouldUseMapboxLib(mapStyle: string = null): {
    useMapbox: boolean;
    reload: boolean;
  } {
    const newStyle =
      MapStylesList[(mapStyle ?? this.user.map).toLowerCase()]?.url;
    const style = newStyle ?? MapStylesList.paj.url;
    console.log('MAP STYLE SELECTED', style);
    const useMapboxLib = style.startsWith('mapbox');
    let reload = false;
    if (
      (useMapboxLib && !this.useMapbox) ||
      (!useMapboxLib && this.useMapbox)
    ) {
      reload = true;
    }

    return { useMapbox: useMapboxLib, reload };
  }

  unloadDirectionsSource() {
    if (this.map.getSource('directionsSource')) {
      getSource(this.map, 'directionsSource').setData({ features: [] });
    }
    // console.log('UNLOAD DIRECTIONS SOURCE', getSource(this.map, "directionsSource"))
    this.unloadedDirectionSource = true;
  }

  loadDirectionsSource() {
    if (!this.map) return;

    if (this.map.getSource('directionsSource')) {
      getSource(this.map, 'directionsSource').setData(
        this.directionsSource.data,
      );
    } else {
      this.map.addSource('directionsSource', this.directionsSource);
    }

    this.unloadedDirectionSource = false;
    this.filterDirectionsMarkers(true);
    // console.log('LOAD DIRECTIONS SOURCE', getSource(this.map, "directionsSource"))
  }

  getScreenSize() {
    this.isSmallScreen =
      document.documentElement.clientWidth < 769 ? true : false;
  }

  getRunningAnimations() {
    return this.animations;
  }
}
