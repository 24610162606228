<ion-header class="ion-no-border">
    <ion-toolbar class="no-padding" color="light" position="top">
        <!-- <ion-buttons *ngIf="!user?.isadmin" slot="end" class="close-button">
      <ion-button (click)="closeNotification()">
        <ion-icon name="close" class="white-icon"></ion-icon>
      </ion-button>
    </ion-buttons> -->
        <ion-item class="notification-title" color="transparent" lines="none" detail="false">
            <ion-buttons slot="start" style="margin-right: 15px;">
                <ion-icon name="notifications-outline"></ion-icon>
            </ion-buttons>

            <ion-label slot="start" class="notifications-component" style="overflow: visible;">
                {{ "notifications.notificationsText" | translate }}
            </ion-label>

            <ion-buttons slot="start" style="margin: 0 0 0 15px;">
                <ion-badge color="primary" style="padding: 5px; font-weight: normal;">{{ "dashboard.unread" | translate }}:
                    <!-- {{ notifications?.length }} -->
                    <!-- {{ notifications?.length > 99 ? '99+' : this.notificationCount?.number_of_unread }}  -->
                    {{ nCount > 99 ? '99+' : nCount }}
                </ion-badge> 
            </ion-buttons>

          <ion-buttons slot="end" class="ion-no-margin">
          <ion-button id="popover-button" size="small" fill="clear" *ngIf="user">
            <ion-icon class="circular-button" color="light" name="ellipsis-vertical-outline"></ion-icon>
          </ion-button>
          </ion-buttons>

            <ion-popover mode="ios" trigger="popover-button" [dismissOnSelect]="true">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ion-item *ngIf="selectedNotifications?.length === 0 && notifications?.length > 0" (click)="markAllRead()"
                      lines="none" [button]="true" [detail]="false">{{ "map.markAllReadText" | translate }}</ion-item>
                    <ion-item *ngIf="selectedNotifications?.length > 0" (click)="markSelectedNotifications()"
                      lines="none" [button]="true" [detail]="false">{{ "map.markSelectionAsReadText" | translate }} ({{ selectedNotifications?.length }})</ion-item>
                    <ion-item lines="none" [button]="true" [detail]="false" (click)="close()">{{ "dashboard.showALL" | translate }}</ion-item>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
        </ion-item>
    </ion-toolbar>
</ion-header>

<ion-content class="not-list content-background">
    <ion-refresher mode="md" slot="fixed" (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="center-content" [class.fade-out]="!loading" [class.fade-in]="loading">
        <!-- Loading data ... -->
        <ion-label class="ion-padding">{{ "dashboard.pleaseWait" | translate }}
        </ion-label>
    </div>
    <div class="center-content" [class.fade-out]="!loading" [class.fade-in]="loading">
        <!-- Loading data ... -->
        <ion-spinner color="primary" style="width: 22px"></ion-spinner>
    </div>

    <div *ngIf="notifications?.length == 0 && allNotifysRead == false && !loading" style="height: 100% !important;" [class.fade-in]="notifications?.length < 1 && !loading">
        <ion-img src="./../../../assets/images/notifications.svg" style="height: 200px; margin: 0 auto; padding: 25px;"></ion-img>
        <br />
        <div style="font-size: 14px; font-weight: bold; display: block; text-align: center;">
            {{ "dashboard.noNotifications" | translate }}
        </div>
    </div>

    <div *ngIf="allNotifysRead" style="height: 100% !important;" [class.fade-in]="notifications?.length < 1 && !loading">
        <ion-img src="./../../../assets/images/notifications.svg" style="height: 200px; margin: 0 auto; padding: 25px;"></ion-img>
        <br />
        <div style="font-size: 14px; font-weight: bold; display: block; text-align: center;">
            {{ "dashboard.noNotificationsForRead" | translate }}
        </div>
    </div>

    <ion-list *ngIf="notifications?.length > 0 && allNotifysRead == false" style="height: 100%">
        <ion-item button *ngFor="let item of notifications" lines="full" class="list-item" style="padding-bottom: 0px" detail="false">

            <ion-icon [src]="alarmImages[item.meldungtyp]" class="alarm-notification-icon" slot="start" color="primary"></ion-icon>
            <ion-label (click)="openDetails(item)">
                <h6 class="alarm-heading-text-color">
                    {{ item.bezeichnung | translate }}
                </h6>
                <p class="alarm-device-name-color">{{ item.deviceName }}</p>
                <p class="date-style">
                    <ion-icon name="time-outline"></ion-icon>
                    {{ item?.dateStr }}
                    <!-- {{ item.dateunix | humanizeTime: "date":"D MMMM YYYY, HH:mm:ss" }} -->
                </p>
            </ion-label>
            <ion-checkbox (ionChange)="selectItem($event, item)" labelPlacement="start" mode="ios" slot="end">

            </ion-checkbox>
        </ion-item>
    </ion-list>
    <!--/ion-virtual-scroll-->
</ion-content>

<!-- *ngIf="user.id > 0"  -->
<!-- <ion-footer style="margin-bottom: 0px; padding-bottom: 0px" color="light">
  <div>
    <ion-toolbar class="no-padding" color="light">
        <ion-button style="text-transform: none" *ngIf="selectedNotifications?.length === 0 && notifications?.length > 0" (click)="markAllRead()" fill="clear" expand="full" size="small" shape="round">{{ "map.markAllReadText" | translate }}</ion-button>
        <ion-button style="text-transform: none" *ngIf="selectedNotifications?.length > 0" (click)="markSelectedNotifications()" fill="clear" expand="full" size="small" shape="round">{{ "map.markSelectionAsReadText" | translate }} ({{ selectedNotifications?.length }})</ion-button>
        <ion-button style="text-transform: none" routerDirection="forward" size="small" shape="round" fill="clear" expand="full" (click)="close()">{{ "dashboard.showALL" | translate }}</ion-button>
    </ion-toolbar>
  </div>
</ion-footer> -->
