import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { animations } from 'src/app/animations/animations';
import { NotificationsService } from 'src/app/services/notifications.service';
import { DevicesDashboardService } from './devices-dashboard.service';

@Component({
  selector: 'app-devices-dashboard',
  templateUrl: './devices-dashboard.component.html',
  styleUrls: ['./devices-dashboard.component.scss'],
  animations: animations
})
export class DevicesDashboardComponent implements OnInit, OnDestroy {
  @Input()petmode: boolean = false;
  @Input('type') type: string = '';
  @Input() hideHeader: boolean = false
  selectedTab = 'activity';
  observables = [];
  nCount = 0;
  isSmallScreen = false;
  constructor(private modalCtrl: ModalController, private notifService: NotificationsService,
    private devicesActivityService: DevicesDashboardService) { }

  ngOnInit() {
    this.observables["counterChanged"] =
      this.notifService.counterChanged.subscribe(async (n: any) => {
        let notifications: any = await this.notifService.getNotificationsCount({});
        this.nCount = notifications.number_of_unread        
      });

    this.observables["counterInstantChanged"] = this.notifService.changeUnreadNots.subscribe(async(r:any) => {      
        if(this.nCount > 0){
          this.nCount = this.nCount + r;
        }
      })

    this.getScreenSize();
    /*
        this.notifService.notifications.subscribe(async(r: any) => {
      let notifications: any = await this.notifService.getNotificationsCount({});
      this.nCount = notifications.number_of_unread
    })
    */
  }

  close() {
    // this.modalCtrl.dismiss();
    this.devicesActivityService.openInMobiles.next(false);
  }


  selectTab(whichOne) {
    this.selectedTab = whichOne;
  }

  ngOnDestroy(): void {
    Object.keys(this.observables).forEach(o => this.observables[o].unsubscribe());
  }

  getScreenSize() {
    this.isSmallScreen =
      document.documentElement.clientWidth < 768 ? true : false;
  }

}
